import moment from 'moment';
import { TrainingVisitStatus } from './trainingVisitStatus';

export type TrainingUserInfoResponse = {
  Date: string;
  GroupName: string;
  VisitStatus: TrainingVisitStatus;
};

export class TrainingUserInfo {
  date: moment.Moment = moment();
  groupName: string = '';
  visitStatus: TrainingVisitStatus = TrainingVisitStatus.Skip;

  public static fromResponse(response: TrainingUserInfoResponse) {
    let result = new TrainingUserInfo();
    result.date = moment(response.Date);
    result.groupName = response.GroupName;
    result.visitStatus = response.VisitStatus;
    return result;
  }
}
