import { observable, action } from 'mobx';

export class EditRegionDialogData {
  @observable
  isOpen: boolean = false;

  @observable
  regionId: string = '';

  @observable
  regionName: string = '';

  @observable
  saving: boolean = false;

  @action
  showDialog(regionId: string, regionName: string) {
    this.isOpen = true;
    this.regionId = regionId;
    this.regionName = regionName;
  }

  @action
  hideDialog() {
    this.isOpen = false;
    this.saving = false;
  }

  @action
  setRegionName(regionName: string) {
    this.regionName = regionName;
  }

  @action
  setSaving(saving: boolean) {
    this.saving = saving;
  }
}
