import React from 'react';
import { Button } from 'antd';
import { useUserEditDialog } from '../UserEditDialog/UserEditDialogProvider';
import './UserEditButton.scss';
import { EditOutlined } from '@ant-design/icons';

export interface IUserEditButtonProps {
  userId: string;
  light?: boolean;
  onSave?: (userId: string) => void;
}

export const UserEditButton: React.FC<IUserEditButtonProps> = ({ userId, onSave, light = false }) => {
  const { showUserEditDialog } = useUserEditDialog();

  const handleEdit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    showUserEditDialog(userId, null, handleSave);
  };

  const handleSave = (userId: string) => {
    onSave && onSave(userId);
  };

  return (
    <Button type={!light ? 'primary' : 'ghost'} onClick={handleEdit} className="UserEditButton">
      <EditOutlined />
    </Button>
  );
};
