import React, { useState } from 'react';
import { Modal, Button, Row, Input, Col, DatePicker, Table, Space, message } from 'antd';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { useStore } from '../../../stores/storeProvider';
import FormItem from '../../FormItem';
import styles from './UserPaymetDialog.module.scss';
import { PaymentOperation, PaymentOperationStatus } from '../../../models/payments/paymentOperation';
import { DateUtils } from '../../../utils/date';
import { UserRoleName } from '../../../models/userRole';

const { TextArea } = Input;

export const UserPaymetDialog = observer(() => {
  const { appStore, paymentsStore } = useStore();

  const [operationsTableColumns] = useState([
    {
      title: 'Дата',
      render: (_: any, item: PaymentOperation) => {
        return <div>{DateUtils.formatEx(item.date, 'DD.MM.YYYY HH:mm')}</div>;
      },
    },
    {
      title: 'Операция',
      render: (_: any, item: PaymentOperation) => {
        return <div>{item.orderID}</div>;
      },
    },
    {
      title: 'Описание',
      render: (_: any, item: PaymentOperation) => {
        return <div>{item.description}</div>;
      },
    },
  ]);

  const handleSave = action(() => {
    paymentsStore.savePaymentDialog();
  });

  const handleCancel = () => {
    paymentsStore.closePaymentDialog();
  };

  const handleShowOperations = () => {
    paymentsStore.showPaymentOperationsDialog(paymentsStore.paymentDialogPayment!.id);
  };

  const handleCloseOperations = () => {
    paymentsStore.closePaymentOperationsDialog();
  };

  const handleDelete = () => {
    Modal.confirm({
      title: 'Внимание',
      content: 'Вы действительно хотите удалить платёж',
      okText: 'Подтвердить удаление',
      cancelText: 'Отмена',
      onOk: async () => {
        try {
          await paymentsStore.deletePayment();
          setTimeout(() => {
            message.success(`Платёж удалён`, 2);
          }, 100);
        } catch (Error) {}
      },
    });
  };

  if (paymentsStore.paymentDialogPayment === null) {
    return null;
  }

  return (
    <div>
      <Modal
        visible={paymentsStore.isPaymentDialogOpen}
        title={`Платёж (${paymentsStore.paymentDialogPayment.title}) за ${paymentsStore.paymentDialogPayment?.getPeriodTitle()}`}
        onCancel={handleCancel}
        width={400}
        footer={[
          <Button key="apply" onClick={handleSave} type="primary">
            Сохранить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={12}>
            <FormItem label="Оплачено">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={paymentsStore.paymentDialogPayment?.sumPayed || ''}
                readOnly={!appStore.account?.isAdminOrMainManager}
                onChange={action((e) => {
                  paymentsStore.paymentDialogPayment!.sumPayed = Number(e.target.value);
                })}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="К оплате">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={paymentsStore.paymentDialogPayment?.sumToPay || ''}
                readOnly={!appStore.account?.isAdminOrMainManager}
                onChange={action((e) => {
                  paymentsStore.paymentDialogPayment!.sumToPay = Number(e.target.value);
                })}
              />
            </FormItem>
          </Col>
        </Row>

        {appStore.account?.isAdminOrManager && (
          <Row>
            <Col span={12}>
              <FormItem label="Перенесено на следующий месяц">
                <Input
                  style={{ width: '100%' }}
                  placeholder=""
                  readOnly={!appStore.account?.isAdminOrMainManager}
                  value={paymentsStore.paymentDialogPayment?.sumToNextMonth || ''}
                  onChange={action((e) => {
                    paymentsStore.paymentDialogPayment!.sumToNextMonth = Number(e.target.value);
                  })}
                />
              </FormItem>
            </Col>
          </Row>
        )}

        <Row>
          <Col span={24}>
            <FormItem label="Дата платежа">
              <DatePicker
                style={{ width: '100%' }}
                placeholder=""
                format="DD.MM.YYYY"
                value={paymentsStore.paymentDialogPayment!.date}
                onChange={action((value) => {
                  paymentsStore.paymentDialogPayment!.date = value;
                })}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label="Комментарий">
              <TextArea
                style={{ width: '100%' }}
                placeholder=""
                value={paymentsStore.paymentDialogPayment!.comment}
                onChange={action((e) => {
                  paymentsStore.paymentDialogPayment!.comment = e.target.value;
                })}
                autoSize
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label="Комментарий для родителей">
              <TextArea
                style={{ width: '100%' }}
                placeholder=""
                value={paymentsStore.paymentDialogPayment!.clientComment}
                onChange={action((e) => {
                  paymentsStore.paymentDialogPayment!.clientComment = e.target.value;
                })}
                autoSize
              />
            </FormItem>
          </Col>
        </Row>
        {paymentsStore.paymentDialogPayment && !paymentsStore.paymentDialogPayment.isNew && (
          <Row>
            <Col span={24}>
              <Space>
                <Button type="dashed" onClick={handleShowOperations}>
                  операции
                </Button>
                {(appStore.account?.isAdmin || appStore.account?.hasRole(UserRoleName.MainManager)) && (
                  <Button type="dashed" onClick={handleDelete}>
                    удалить платёж
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        )}
      </Modal>

      <Modal
        visible={paymentsStore.isPaymentOperationsDialogOpen}
        title="Операции по оплате"
        onCancel={handleCloseOperations}
        width={900}
        footer={[
          <Button key="cancel" onClick={handleCloseOperations}>
            Закрыть
          </Button>,
        ]}
      >
        <Table
          className={styles.payment_operations_table}
          columns={operationsTableColumns}
          expandable={{
            expandedRowRender: (item) => <div className={styles.operation_detail}>{item.detail}</div>,
            rowExpandable: (record) => true,
            expandRowByClick: true,
          }}
          dataSource={paymentsStore.paymentDialogOperations}
          rowKey="id"
          size="small"
          pagination={false}
          rowClassName={(item) => {
            if (item.status === PaymentOperationStatus.Error) {
              return styles.error_operation;
            }
            if (
              item.status === PaymentOperationStatus.Confirmed ||
              item.status === PaymentOperationStatus.ManualChange ||
              item.status === PaymentOperationStatus.CalcPayments
            ) {
              return styles.success_operation;
            }
            return '';
          }}
        />
      </Modal>
    </div>
  );
});
