import React, { useState, useEffect } from 'react';
import './UserNotes.scss';
import { FaCommentDots } from 'react-icons/fa';
import Modal from 'antd/lib/modal/Modal';
import { Button, Tooltip, Row, Col, Spin, Divider, Space } from 'antd';
import { UserNote, UserDisplayModel, UserLogItem } from '../../../models/user';
import { UsersApi } from '../../../api/user';
import { useStore } from '../../../stores/storeProvider';
import { DateUtils } from '../../../utils/date';
import classNames from 'classnames';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import Spacer from '../../../ui-kit/Spacer';

interface IUserNotesProps {
  user: UserDisplayModel;
  children?: never;
}

export const UserNotes: React.FC<IUserNotesProps> = ({ user }) => {
  const { appStore } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [notes, setNotes] = useState<UserNote[]>([]);
  const [logItems, setLogItems] = useState<UserLogItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [newNoteText, setNewNoteText] = useState('');
  const [showUserHistory, setShowUserHistory] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleAdd = () => {
    setNewNoteText('');
    setIsAddOpen(true);
  };

  const handleSave = () => {
    const saveNote = async () => {
      try {
        setSaving(true);
        const savedUserNoteResponse = await UsersApi.saveUserNote(user.Id, newNoteText);
        setNotes([new UserNote(savedUserNoteResponse), ...notes]);
        setIsAddOpen(false);
      } catch (error) {
        appStore.setErrorMessage((error as Error).message);
      } finally {
        setSaving(false);
      }
    };

    saveNote();
  };

  const handleNewNoteTextChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewNoteText(e.target.value);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleAddCancel = () => {
    setIsAddOpen(false);
  };

  const handleShowUserHistory = () => {
    setShowUserHistory(!showUserHistory);
  };

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        setNotes([]);
        setLoading(true);
        const userNotesResponse = await UsersApi.fetchUserNotes(user.Id);
        const userNotes = userNotesResponse.map((noteResponse) => new UserNote(noteResponse));
        setNotes(userNotes);
      } catch (error) {
        appStore.setErrorMessage((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    const fetchHistory = async () => {
      try {
        setLogItems([]);
        setLoading(true);
        const response = await UsersApi.fetchUserLogItems(user.Id);
        setLogItems(response);
      } catch (error) {
        appStore.setErrorMessage((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      if (showUserHistory) fetchHistory();
      else fetchNotes();
    }
  }, [appStore, isOpen, showUserHistory, user.Id]);

  return (
    <div>
      <Tooltip title="заметки">
        <FaCommentDots className="UserNotesIcon" onClick={handleClick} size="20" />
      </Tooltip>

      <Modal
        visible={isOpen}
        title={`Заметки - ${user.DisplayName}`}
        onCancel={handleCancel}
        footer={
          <Row>
            {appStore.account?.isAdmin && (
              <>
                <Button key="add" onClick={handleShowUserHistory}>
                  {showUserHistory ? 'Показать заметки' : 'Показать историю'}
                </Button>
                <Spacer />
              </>
            )}
            <Button key="add" onClick={handleAdd}>
              Добавить заметку
            </Button>
            <Button key="back" onClick={handleCancel}>
              Закрыть
            </Button>
          </Row>
        }
      >
        {loading && <Spin size="large" />}

        {showUserHistory &&
          logItems.map((logItem) => (
            <div key={logItem.Id}>
              <div className="LogItem-Header">
                <Space>
                  <div>{moment(logItem.Date).format('DD.MM.YYYY HH:mm:SS')}</div>
                  <div>{logItem.Type}</div>
                  <Spacer />
                  <div>{logItem.AuthorName}</div>
                </Space>
              </div>
              <div>
                {logItem.Description.map((desc) => (
                  <div key={desc}>{desc}</div>
                ))}
              </div>
              {logItem.ChangeItems.map((item) => (
                <div key={item}>{item}</div>
              ))}
              <Divider />
            </div>
          ))}

        {!showUserHistory && (
          <Row gutter={16} className="UserNotes">
            <Col span="12">
              {notes
                .filter((e) => e.DisplayType.length === 0)
                .map((note) => (
                  <div key={`${note.Id}${note.Date}${note.Text}`} className="UserNote">
                    <div className="UserNote-Info">
                      {DateUtils.format_DDMMYYYY(note.Date)} {note.Author}
                    </div>
                    <div className="UserNote-Text">{note.Text}</div>
                  </div>
                ))}
            </Col>
            <Col span="12">
              {notes
                .filter((e) => e.DisplayType.length !== 0)
                .map((note) => (
                  <div key={`${note.Id}${note.Date}${note.Text}`} className="UserNote">
                    <div className="UserNote-Info">
                      {DateUtils.format_DDMMYYYY(note.Date)} {note.Author}
                    </div>
                    <div
                      className={classNames('UserNote-Text', {
                        UserNote__Important: note.DisplayType === 'important' || note.DisplayType === '[important]',
                        'UserNote__Important-Warn': note.DisplayType === 'important_warn',
                      })}
                    >
                      {note.Text}
                    </div>
                  </div>
                ))}
            </Col>
          </Row>
        )}
      </Modal>

      <Modal
        visible={isAddOpen}
        title="Добавление заметки"
        onCancel={handleAddCancel}
        footer={[
          <Button key="add" onClick={handleSave} loading={saving}>
            Сохранить
          </Button>,
          <Button key="back" onClick={handleAddCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <TextArea value={newNoteText} onChange={handleNewNoteTextChanged} />
      </Modal>
    </div>
  );
};
