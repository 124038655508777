export type TrainingGroupResponse = {
  ID: number;
  Name: string;
};

export type TrainingGroupsResponse = {
  groups: TrainingGroupResponse[];
  currentGroupId: number | null;
};

export class TrainingGroup {
  id: number = 0;
  name: string = '';

  public static fromResponse(resp: TrainingGroupResponse) {
    const result = new TrainingGroup();
    result.id = resp.ID;
    result.name = resp.Name;
    return result;
  }
}

export class TrainingGroups {
  groups: TrainingGroup[] = [];
  currentGroupId: number | null = null;

  public static fromResponse(resp: TrainingGroupsResponse) {
    const result = new TrainingGroups();
    result.currentGroupId = resp.currentGroupId;
    result.groups = resp.groups.map((e) => TrainingGroup.fromResponse(e));
    return result;
  }
}
