import { PaymentsParams } from './../../../models/payments/paymentsParams';
import { action, observable, runInAction } from 'mobx';
import { AppStore } from '../../../stores/AppStore';
import { PaymentApi } from '../../../api/payment';

export class ParamsPageStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  loading: boolean = false;

  @observable
  saving: boolean = false;

  @observable
  paymentParams: PaymentsParams = new PaymentsParams();

  @action
  async load(): Promise<void> {
    try {
      this.loading = true;
      const paymentParams = await PaymentApi.fetchPaymentsParams();
      runInAction(() => {
        this.paymentParams = paymentParams;
        this.loading = false;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  async save() {
    try {
      this.saving = true;
      await PaymentApi.savePaymentsParams(this.paymentParams);
      runInAction(() => {
        this.saving = false;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
      runInAction(() => {
        this.saving = false;
      });
    }
  }
}

let localStore: ParamsPageStore | null = null;

export const useLocalStore = (appStore: AppStore) => {
  if (localStore === null) {
    localStore = new ParamsPageStore(appStore);
  }
  return localStore;
};
