import { isEmpty } from 'lodash';
import { observable, action, runInAction, computed } from 'mobx';
import { AppStore } from './AppStore';
import { Group } from '../models/group';
import { GroupsApi } from '../api/groups';

export class GroupStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  groups: Group[] = [];

  @computed
  get activeGroups() {
    return this.groups.filter((group) => group.isActive) || [];
  }

  @computed
  get inactiveGroups() {
    return this.groups.filter((group) => !group.isActive) || [];
  }

  getGroupById(groupId: number): Group | null {
    return this.groups.find((group) => group.id === groupId) || null;
  }

  getGroupNameById(groupId: number | null): string {
    return groupId !== null ? this.getGroupById(groupId)?.name || '' : '';
  }

  getGroupNamesByIds(groupIds: number[] | null): string {
    return (groupIds || []).map((id) => this.getGroupById(id)?.name).join(', ');
  }

  @action
  async resetGroups() {
    this.groups = [];
  }

  @action
  async loadGroups(force: boolean = false) {
    try {
      if (!force && !isEmpty(this.groups)) {
        return;
      }
      const groups = await GroupsApi.fetchGroups();
      runInAction(() => {
        this.groups = groups;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }
}
