import React from 'react';
import './FormItem.scss';
import classNames from 'classnames';

interface IFormItemProps {
  label: string;
  className?: string;
  simple?: boolean;
}

export const FormItem: React.FC<IFormItemProps> = ({ label, children, className, simple = false }) => {
  return (
    <div className={classNames('FormItem', className)}>
      <div className="FormItem-Label">{label}</div>
      <div className={classNames({ 'FormItem-ControlSimple': simple, 'FormItem-Control': !simple })}>{children}</div>
    </div>
  );
};
