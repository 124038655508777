import React from 'react';
import { useStore } from '../../../../stores/storeProvider';
import { observer } from 'mobx-react';
import { UserEditDialogState } from '../UserEditDialogState';
import { UserSaveMode, UserStatus } from '../../../../models/user';
import { DateUtils } from '../../../../utils/date';

export interface IUserEditSaveModeInfoProps {
  editDialogState: UserEditDialogState;
}

export const UserEditSaveModeInfo: React.FC<IUserEditSaveModeInfoProps> = observer(({ editDialogState }) => {
  const { appStore } = useStore();

  return (
    <>
      {editDialogState.userModel.saveMode === UserSaveMode.TestTraining &&
        editDialogState.isNewTestTraining &&
        !editDialogState.isTestTrainingNeedTransfer && (
          <div>
            {`Записан на пробную тренировку ${DateUtils.format(
              editDialogState.testTrainingDate
            )} в группу ${appStore.groupStore.getGroupNameById(editDialogState.testTrainingGroupId)}`}
          </div>
        )}

      {editDialogState.userModel.saveMode === UserSaveMode.TestTraining &&
        editDialogState.userModel.prevStatus !== UserStatus.ClaimVisit &&
        editDialogState.isNewTestTraining &&
        editDialogState.isTestTrainingNeedTransfer && (
          <div>
            {`Перенос пробной тренировки c ${DateUtils.format(editDialogState.userModel.RegisterDate)} на ${DateUtils.format(
              editDialogState.testTrainingDate
            )} в группу ${appStore.groupStore.getGroupNameById(editDialogState.testTrainingGroupId)}`}
          </div>
        )}

      {editDialogState.userModel.saveMode === UserSaveMode.TestTraining &&
        editDialogState.userModel.prevStatus === UserStatus.ClaimVisit &&
        editDialogState.isNewTestTraining &&
        editDialogState.isTestTrainingNeedTransfer && (
          <div>
            {`Повторная пробная тренировка ${DateUtils.format(
              editDialogState.testTrainingDate
            )} в группе ${appStore.groupStore.getGroupNameById(editDialogState.testTrainingGroupId)}`}
          </div>
        )}

      {editDialogState.userModel.saveMode === UserSaveMode.Reserve &&
        (editDialogState.userModel.prevStatus === UserStatus.Reserve ? (
          <div>{`Резерв изменён, в ${appStore.groupStore.getGroupNamesByIds(
            editDialogState.userModel.reserveGroupsIds
          )}, звонок ${DateUtils.format(editDialogState.userModel.reserveCallDate)}, ${
            editDialogState.userModel.reserveComment
          }`}</div>
        ) : (
          <div>{`Перевод в Резерв с ${DateUtils.format(
            editDialogState.userModel.reserveDateFrom
          )}, в ${appStore.groupStore.getGroupNamesByIds(editDialogState.userModel.reserveGroupsIds)}, звонок ${DateUtils.format(
            editDialogState.userModel.reserveCallDate
          )}, ${editDialogState.userModel.reserveComment}`}</div>
        ))}

      {editDialogState.userModel.saveMode === UserSaveMode.Archive &&
        (editDialogState.userModel.prevStatus === UserStatus.Archive ||
        editDialogState.userModel.prevStatus === UserStatus.ArchiveNotVisit ? (
          <div>{`Архив изменён, звонок ${DateUtils.format(editDialogState.userModel.archiveCallDate)}, ${
            editDialogState.userModel.archiveComment
          }`}</div>
        ) : (
          <div>{`Перевод в Архив с ${DateUtils.format(editDialogState.userModel.archiveDateFrom)}, звонокv ${DateUtils.format(
            editDialogState.userModel.archiveCallDate
          )}, ${editDialogState.userModel.archiveComment}`}</div>
        ))}

      {editDialogState.userModel.saveMode === UserSaveMode.ChangeGroup && (
        <div>
          {`Перевод в группу ${appStore.groupStore.getGroupNameById(editDialogState.userModel.newGroupId)}
              с ${DateUtils.format(editDialogState.userModel.newGroupDateFrom)}`}
        </div>
      )}

      {editDialogState.userModel.saveMode === UserSaveMode.AddToSecondGroup && (
        <div>
          {`Добавление в группу ${appStore.groupStore.getGroupNameById(editDialogState.userModel.newGroupId)}
              с ${DateUtils.format(editDialogState.userModel.newGroupDateFrom)}`}
        </div>
      )}

      {editDialogState.userModel.newDiscountSelected && (
        <div>
          {`Изменение скидки с ${DateUtils.format(editDialogState.userModel.newDiscountDateFrom)} на ${
            editDialogState.userModel.newDiscountId === null
              ? 'без скидки'
              : appStore.discountsStore.getNameById(editDialogState.userModel.newDiscountId)
          }`}
        </div>
      )}

      {editDialogState.userModel.newAbonnementId !== null && (
        <div>
          {`Изменение абонемента с ${DateUtils.format(
            editDialogState.userModel.newAbonnementDateFrom
          )} на ${appStore.abonnementsStore.getNameById(editDialogState.userModel.newAbonnementId)}`}
        </div>
      )}
    </>
  );
});
