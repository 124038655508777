import { observable } from 'mobx';

export type TrainingsExportParamsResponse = {
  VisitSum: string;
  VisitNotPayedSum: string;
  SkipSum: string;
  HealthSkipSum: string;
  HealthCertificateSum: string;
  VacationSum: string;
  TestSum: string;
  CoachExFrom1Cnt: number | null;
  CoachExFrom2Cnt: number | null;
  CoachExFrom3Cnt: number | null;
  CoachExFrom1Sum: number | null;
  CoachExFrom2Sum: number | null;
  CoachExFrom3Sum: number | null;
};

export class TrainingsExportParams {
  @observable
  visitSum: string = '';

  @observable
  visitNotPayedSum: string = '';

  @observable
  skipSum: string = '';

  @observable
  healthSkipSum: string = '';

  @observable
  healthCertificateSum: string = '';

  @observable
  vacationSum: string = '';

  @observable
  testSum: string = '';

  @observable
  CoachExFrom1Cnt: number | null = null;
  @observable
  CoachExFrom2Cnt: number | null = null;
  @observable
  CoachExFrom3Cnt: number | null = null;
  @observable
  CoachExFrom1Sum: number | null = null;
  @observable
  CoachExFrom2Sum: number | null = null;
  @observable
  CoachExFrom3Sum: number | null = null;

  public static fromResponse(resp: TrainingsExportParamsResponse) {
    const result = new TrainingsExportParams();
    result.visitSum = resp.VisitSum;
    result.visitNotPayedSum = resp.VisitNotPayedSum;
    result.skipSum = resp.SkipSum;
    result.healthSkipSum = resp.HealthSkipSum;
    result.healthCertificateSum = resp.HealthCertificateSum;
    result.vacationSum = resp.VacationSum;
    result.testSum = resp.TestSum;
    result.CoachExFrom1Cnt = resp.CoachExFrom1Cnt;
    result.CoachExFrom2Cnt = resp.CoachExFrom2Cnt;
    result.CoachExFrom3Cnt = resp.CoachExFrom3Cnt;
    result.CoachExFrom1Sum = resp.CoachExFrom1Sum;
    result.CoachExFrom2Sum = resp.CoachExFrom2Sum;
    result.CoachExFrom3Sum = resp.CoachExFrom3Sum;
    return result;
  }
}
