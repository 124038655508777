import axios from 'axios';

export const download = async (url: string, data: any) => {
  console.info(`[app] download ${url}`);
  try {
    const response = await axios.post(url, data, {
      responseType: 'blob',
    });
    if (response.data && response.data.error) {
      throw new Error(response.data.error);
    }
    const blob = new Blob([response.data], {
      type: response.data.type,
    });
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    const disposition = response.headers['content-disposition'];
    let fileName = 'unknown';
    if (disposition && disposition.indexOf('attachment') !== -1) {
      if (disposition.indexOf('UTF-8') !== -1) {
        const regex = /filename[^;=\n]*=(UTF-8(['"]*))?(.*)/;
        const matches = regex.exec(disposition);
        if (matches != null && matches[3]) {
          fileName = matches[3].replace(/['"]/g, '');
        }
      } else {
        const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = regex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }
    }
    link.setAttribute('download', decodeURI(fileName));
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};
