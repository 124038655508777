import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Select, Button, message, Row, Col, Input } from 'antd';
import { useStore } from '../../../../stores/storeProvider';
import { observer } from 'mobx-react';
import { UserEditDialogState } from '../UserEditDialogState';
import { action } from 'mobx';
import FormItem from '../../../FormItem';
import { AddSourceGroupDialog } from '../../../../pages/Settings/Sources/AddSourceGroupDialog';
import { isEmpty } from 'lodash';

const { Option } = Select;

export interface IUserEditAddNewSourceDialogProps {
  editDialogState: UserEditDialogState;
  isOpen: boolean;
  onClose: () => void;
}

export const UserEditAddNewSourceDialog: React.FC<IUserEditAddNewSourceDialogProps> = observer(
  ({ editDialogState, isOpen, onClose }) => {
    const { sourcesStore } = useStore();
    const [groupTitle, setGroupTitle] = useState('');
    const [sourceTitle, setSourceTitle] = useState('');
    const [isAddSourceGroupOpen, setIsAddSourceGroupOpen] = useState(false);

    useEffect(() => {
      if (isOpen) {
        setGroupTitle('');
        setSourceTitle('');
      }
    }, [isOpen]);

    const handleAdd = action(() => {
      if (isEmpty(groupTitle)) {
        message.warning('Группа не выбрана', 3);
        return;
      }

      if (isEmpty(sourceTitle)) {
        message.warning('Не задано имя источника', 3);
        return;
      }

      sourcesStore.addSource(groupTitle, sourceTitle).then(
        action(() => {
          editDialogState.userModel.Source = sourceTitle;
          onClose();
        })
      );
    });

    const handleCancel = () => {
      onClose();
    };

    const handleAddGroup = (groupTitle: string) => {
      sourcesStore.addSourceGroup(groupTitle).then(() => {
        setIsAddSourceGroupOpen(false);
        setGroupTitle(groupTitle);
      });
    };

    return (
      <div>
        <AddSourceGroupDialog
          isOpen={isAddSourceGroupOpen}
          onClose={() => setIsAddSourceGroupOpen(false)}
          onAdd={handleAddGroup}
        />

        <Modal
          visible={isOpen}
          title="Добавление нового источника"
          onCancel={handleCancel}
          footer={[
            <Button key="apply" onClick={handleAdd} type="primary">
              Добавить
            </Button>,
            <Button key="cancel" onClick={handleCancel}>
              Закрыть
            </Button>,
          ]}
        >
          <Row>
            <Col span={12}>
              <FormItem label="Группа">
                <Select
                  style={{ width: '100%' }}
                  value={groupTitle}
                  onChange={(value) => {
                    if (value === 'add-new-group') {
                      setIsAddSourceGroupOpen(true);
                    } else {
                      setGroupTitle(value);
                    }
                  }}
                >
                  {sourcesStore.sourceGroups.map((sourceGroup) => (
                    <Option key={sourceGroup.Id} value={sourceGroup.Title}>
                      {sourceGroup.Title}
                    </Option>
                  ))}
                  <Option value="add-new-group">+ новая группа</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Источник">
                <Input
                  style={{ width: '100%' }}
                  placeholder=""
                  value={sourceTitle}
                  onChange={(e) => setSourceTitle(e.target.value)}
                />
              </FormItem>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
);
