import { Abonnement, AbonnementSaveModel, AbonnementResponse } from './../models/abonnement';
import axios from 'axios';

export const AbonnementsApi = {
  fetchAbonnements: async () => {
    const response = await axios.get<{ abonnements: AbonnementResponse[] }>('/Settings/AbonnementsData');
    return response.data.abonnements.map((e) => Abonnement.fromResponse(e));
  },
  deleteAbonnement: async (id: string) => {
    await axios.post('/api/settings/DeleteAbonnement', { id: id });
  },
  saveAbonnement: async (abonnement: AbonnementSaveModel) => {
    await axios.post('/api/settings/SaveAbonnement', abonnement);
  },
};
