import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/storeProvider';
import { useLocation, Switch, Route, Redirect, Router } from 'react-router-dom';
import { RouteNames, buildLoginUrl, browserHistory } from '../../pages/routes';
import LoginPage from '../../pages/Login';
import Loading from '../../pages/Loading';
import { UserEditDialogProvider } from '../User/UserEditDialog/UserEditDialogProvider';
import Layout from '../Layout';
import UsersPage from '../../pages/Users';
import GroupsPage from '../../pages/Groups';
import ErrorPage from '../../pages/Error';
import SourcesPage from '../../pages/Settings/Sources';
import SettingsPage from '../../pages/Settings';
import PaymentsSettingsPage from '../../pages/Settings/Payments';
import ReportsPage from '../../pages/Reports';
import PaymentsPage from '../../pages/Payments';
import GroupsSettingsPage from '../../pages/Settings/Groups';
import PlacesSettingsPage from '../../pages/Settings/Places';
import AbonnementsSettingsPage from '../../pages/Settings/Abonnements';
import DiscountsSettingsPage from '../../pages/Settings/Discounts';
import TrainingsPage from '../../pages/Trainings';
import NotificationsPage from '../../pages/Notifications';
import RegionsSettingsPage from '../../pages/Settings/Regions';
import StaffSettingsPage from '../../pages/Settings/Staff';
import RentsReport from '../../pages/Reports/RentsReport';
import { UserRoleName } from '../../models/userRole';
import ParamsSettingsPage from '../../pages/Settings/Params';

export const AppRoutes = observer(() => {
  const { appStore } = useStore();
  const location = useLocation();

  const reportsManagerLayout = <Route component={ReportsPage}></Route>;

  const commonLayout = (
    <>
      <Route exact path={RouteNames.Users}>
        <UsersPage />
      </Route>

      <Route exact path={RouteNames.Groups}>
        <GroupsPage />
      </Route>

      <Route exact path="/">
        <Redirect to={RouteNames.Users} />
      </Route>
    </>
  );

  const adminOrManagerLayout = (
    <>
      <Route exact path={RouteNames.Users}>
        <UsersPage />
      </Route>

      <Route exact path={RouteNames.RentsReport}>
        <RentsReport />
      </Route>

      <Route exact path={RouteNames.Reports}>
        <ReportsPage />
      </Route>

      <Route exact path={RouteNames.Payments}>
        <PaymentsPage />
      </Route>

      <Route exact path={RouteNames.Settings}>
        <SettingsPage />
      </Route>

      <Route exact path={RouteNames.SettingsSources}>
        <SourcesPage />
      </Route>

      <Route exact path={RouteNames.SettingsPayments}>
        <PaymentsSettingsPage />
      </Route>

      <Route exact path={RouteNames.SettingsGroups}>
        <GroupsSettingsPage />
      </Route>

      <Route exact path={RouteNames.SettingsPlaces}>
        <PlacesSettingsPage />
      </Route>

      <Route exact path={RouteNames.SettingsAbonnements}>
        <AbonnementsSettingsPage />
      </Route>

      <Route exact path={RouteNames.SettingsDiscounts}>
        <DiscountsSettingsPage />
      </Route>

      <Route exact path={RouteNames.SettingsRegions}>
        <RegionsSettingsPage />
      </Route>

      <Route exact path={RouteNames.SettingsStaff}>
        <StaffSettingsPage />
      </Route>

      {appStore.account && (appStore.account.isAdmin || appStore.account.hasRole(UserRoleName.MainManager)) && (
        <Route exact path={RouteNames.SettingsParams}>
          <ParamsSettingsPage />
        </Route>
      )}

      <Route exact path={RouteNames.Trainings}>
        <TrainingsPage />
      </Route>

      <Route exact path={RouteNames.Notifications}>
        <NotificationsPage />
      </Route>

      <Route exact path={RouteNames.Groups}>
        <GroupsPage />
      </Route>
      <Route exact path="/">
        <Redirect to={RouteNames.Users} />
      </Route>
    </>
  );

  const coachLayout = (
    <>
      <Route exact path={RouteNames.Users}>
        <UsersPage />
      </Route>

      <Route exact path={RouteNames.Groups}>
        <GroupsPage />
      </Route>

      <Route exact path={RouteNames.Trainings}>
        <TrainingsPage />
      </Route>

      <Route exact path="/">
        <Redirect to={RouteNames.Trainings} />
      </Route>
    </>
  );

  let layout = commonLayout;
  if (appStore.account?.hasRole(UserRoleName.ReportsManager)) layout = reportsManagerLayout;
  else if (appStore.account?.isAdminOrManager) layout = adminOrManagerLayout;
  else if (appStore.account?.isCoach) layout = coachLayout;

  return (
    <Router history={browserHistory}>
      {!appStore.initialized && <Loading />}

      {appStore.initialized && (
        <Switch>
          <Route
            path="/redir"
            component={Redirect}
            loc="https://cabinet.football71.ru/Home/Shedule?orderId=0792a480-e127-7b63-9641-33b7021094d7&lang=ru"
          />

          <Route exact path={RouteNames.Login}>
            <LoginPage />
          </Route>

          {appStore.initFailed && <ErrorPage />}

          {!appStore.initFailed && (
            <Layout>
              {!appStore.account && !appStore.initFailed && (
                <Redirect to={buildLoginUrl(`${location.pathname}${location.search}`)} />
              )}

              {appStore.account && <UserEditDialogProvider>{layout}</UserEditDialogProvider>}
            </Layout>
          )}
        </Switch>
      )}
    </Router>
  );
});
