import { PaymentOperation } from './../models/payments/paymentOperation';
import { PaymentApi } from './../api/payment';
import { AdditionalPayment } from './../models/payments/additionalPayment';
import { observable, action, runInAction } from 'mobx';
import { AppStore } from './AppStore';
import { isEmpty } from 'lodash';
import { Payment } from '../models/payments/payment';

export class PaymentsStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  additionalPayments: AdditionalPayment[] = [];

  @observable
  isPaymentDialogOpen: boolean = false;

  @observable
  isPaymentOperationsDialogOpen: boolean = false;

  @observable
  paymentDialogPayment: Payment | null = null;

  @observable
  paymentDialogOperations: PaymentOperation[] = [];

  paymentDialogSuccessCallback: ((payment: Payment) => void) | null = null;

  @action
  async loadAdditionalPayments(force: boolean = false) {
    try {
      if (!isEmpty(this.additionalPayments) && !force) {
        return;
      }

      const payments = await PaymentApi.fetchAdditionalPayments();

      runInAction(() => {
        this.additionalPayments = payments;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  getAdditionalPayment(id: string) {
    return this.additionalPayments.filter((e) => e.id === id)[0];
  }

  getPaymentTitle(additionalPaymentId: string | null) {
    return isEmpty(additionalPaymentId) ? 'занятия' : this.getAdditionalPayment(additionalPaymentId!).title;
  }

  @action
  async showPaymentDialog(paymentId: number, successCallback: (payment: Payment) => void) {
    try {
      this.isPaymentDialogOpen = true;
      this.paymentDialogSuccessCallback = successCallback;
      const payment = await PaymentApi.fetchPayment(paymentId);
      runInAction(() => {
        this.paymentDialogPayment = payment;
        this.paymentDialogPayment.title = this.getPaymentTitle(payment.additionalPaymentId);
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async showNewPaymentDialog(payment: Payment, successCallback: (payment: Payment) => void) {
    try {
      this.isPaymentDialogOpen = true;
      this.paymentDialogPayment = payment;
      this.paymentDialogSuccessCallback = successCallback;
      this.paymentDialogPayment.title = this.getPaymentTitle(payment.additionalPaymentId);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async showPaymentOperationsDialog(paymentId: number) {
    try {
      this.isPaymentOperationsDialogOpen = true;
      const operations = await PaymentApi.fetchPaymentOperations(paymentId);
      runInAction(() => {
        this.paymentDialogOperations = operations;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async closePaymentOperationsDialog() {
    this.isPaymentOperationsDialogOpen = false;
    this.paymentDialogOperations = [];
  }

  @action
  async closePaymentDialog() {
    this.isPaymentDialogOpen = false;
    this.paymentDialogPayment = null;
    this.paymentDialogSuccessCallback = null;
    this.closePaymentOperationsDialog();
  }

  async savePaymentDialog() {
    try {
      if (this.paymentDialogPayment) {
        const savedPayment = await PaymentApi.savePayment(this.paymentDialogPayment);
        this.paymentDialogSuccessCallback && this.paymentDialogSuccessCallback(savedPayment);
        this.closePaymentDialog();
      }
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async deletePayment() {
    try {
      if (this.paymentDialogPayment) {
        await PaymentApi.deletePayment(this.paymentDialogPayment.id);
        this.paymentDialogSuccessCallback && this.paymentDialogSuccessCallback(this.paymentDialogPayment);
        this.closePaymentDialog();
      }
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }
}
