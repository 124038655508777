import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import styles from './SettingsPage.module.scss';
import { useStore } from '../../stores/storeProvider';
import PageContainer from '../../components/PageContainer';
import { Card } from 'antd';
import { navigateToRoute, RouteNames } from '../routes';
import GroupsImg from '../../assets/images/groups.png';
import PlacesImg from '../../assets/images/places.png';
import AbonnementsImg from '../../assets/images/abonnements.png';
import DiscountsImg from '../../assets/images/discounts.png';
import SourcesImg from '../../assets/images/sources.png';
import PaymentsImg from '../../assets/images/payments.png';
import RegionsImg from '../../assets/images/regions2.png';
import StaffImg from '../../assets/images/staff.png';
import ParamsImg from '../../assets/images/params.png';
import { UserRoleName } from '../../models/userRole';

export const SettingsPage = observer(() => {
  const { appStore, sourcesStore } = useStore();

  useEffect(() => {
    document.title = 'Торнадо - Настройки';
    appStore.setCurrentPageTitle('Настройки');

    sourcesStore.loadSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer className={styles.page}>
      <Card className={styles.page_item} hoverable onClick={() => navigateToRoute(RouteNames.SettingsGroups)} size="small">
        <div className={styles.page_item_content}>
          <div className={styles.page_item_image}>
            <img alt="example" className={styles.page_item_image} src={GroupsImg} />
          </div>
          <div className={styles.page_item_title}>Группы</div>
        </div>
      </Card>

      <Card className={styles.page_item} hoverable onClick={() => navigateToRoute(RouteNames.SettingsPlaces)} size="small">
        <div className={styles.page_item_content}>
          <div className={styles.page_item_image}>
            <img alt="example" className={styles.page_item_image} src={PlacesImg} />
          </div>
          <div className={styles.page_item_title}>Площадки</div>
        </div>
      </Card>

      <Card className={styles.page_item} hoverable onClick={() => navigateToRoute(RouteNames.SettingsAbonnements)} size="small">
        <div className={styles.page_item_content}>
          <div className={styles.page_item_image}>
            <img alt="example" className={styles.page_item_image} src={AbonnementsImg} />
          </div>
          <div className={styles.page_item_title}>Абонементы</div>
        </div>
      </Card>

      <Card className={styles.page_item} hoverable onClick={() => navigateToRoute(RouteNames.SettingsDiscounts)} size="small">
        <div className={styles.page_item_content}>
          <div className={styles.page_item_image}>
            <img alt="example" className={styles.page_item_image} src={DiscountsImg} />
          </div>
          <div className={styles.page_item_title}>Скидки</div>
        </div>
      </Card>

      {/* <Card className={styles.page_item} hoverable>
        Разделы
      </Card> */}

      <Card className={styles.page_item} hoverable onClick={() => navigateToRoute(RouteNames.SettingsSources)} size="small">
        <div className={styles.page_item_content}>
          <div className={styles.page_item_image}>
            <img alt="example" className={styles.page_item_image} src={SourcesImg} />
          </div>
          <div className={styles.page_item_title}>Источники</div>
        </div>
      </Card>

      <Card className={styles.page_item} hoverable onClick={() => navigateToRoute(RouteNames.SettingsPayments)} size="small">
        <div className={styles.page_item_content}>
          <div className={styles.page_item_image}>
            <img alt="example" className={styles.page_item_image} src={PaymentsImg} />
          </div>
          <div className={styles.page_item_title}>Оплаты</div>
        </div>
      </Card>

      {appStore.account?.isAdmin && (
        <Card className={styles.page_item} hoverable onClick={() => navigateToRoute(RouteNames.SettingsRegions)} size="small">
          <div className={styles.page_item_content}>
            <div className={styles.page_item_image}>
              <img alt="example" className={styles.page_item_image} src={RegionsImg} />
            </div>
            <div className={styles.page_item_title}>Разделы</div>
          </div>
        </Card>
      )}

      {(appStore.account?.isAdmin || appStore.account?.hasRole(UserRoleName.MainManager)) && (
        <Card className={styles.page_item} hoverable onClick={() => navigateToRoute(RouteNames.SettingsParams)} size="small">
          <div className={styles.page_item_content}>
            <div className={styles.page_item_image}>
              <img alt="example" className={styles.page_item_image} src={ParamsImg} />
            </div>
            <div className={styles.page_item_title}>Параметры</div>
          </div>
        </Card>
      )}

      <Card className={styles.page_item} hoverable onClick={() => navigateToRoute(RouteNames.SettingsStaff)} size="small">
        <div className={styles.page_item_content}>
          <div className={styles.page_item_image}>
            <img alt="example" className={styles.page_item_image} src={StaffImg} />
          </div>
          <div className={styles.page_item_title}>Сотрудники</div>
        </div>
      </Card>
    </PageContainer>
  );
});
