import { createBrowserHistory } from 'history';

export const browserHistory = createBrowserHistory();

export enum RouteNames {
  AppRoot = '/app2',
  Users = '/app2/users',
  Trainings = '/app2/trainings',
  Payments = '/app2/payments',
  Reports = '/app2/reports',
  RentsReport = '/app2/reports/rents',
  Settings = '/app2/settings/',
  SettingsSources = '/app2/settings/sources',
  SettingsPayments = '/app2/settings/payments',
  SettingsGroups = '/app2/settings/groups',
  SettingsPlaces = '/app2/settings/places',
  SettingsAbonnements = '/app2/settings/abonnements',
  SettingsDiscounts = '/app2/settings/discounts',
  SettingsRegions = '/app2/settings/regions',
  SettingsStaff = '/app2/settings/staff',
  SettingsParams = '/app2/settings/params',
  Groups = '/app2/groups',
  Login = '/app2/login',
  Notifications = '/app2/notifications',
}

export const RouteParamNames = {
  ReturnUrl: 'returnUrl',
};

export const buildLoginUrl = (pathname: string) => `${RouteNames.Login}?${RouteParamNames.ReturnUrl}=${pathname}`;

export const navigate = (url: string) => browserHistory.push(url);
export const navigateToRoute = (routeName: RouteNames) => navigate(routeName);
