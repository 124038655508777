import { Button, Input, Spin } from 'antd';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import FormItem from '../../../components/FormItem';
import PageContainer from '../../../components/PageContainer';
import { useStore } from '../../../stores/storeProvider';
import { useLocalStore } from './store';
import TextArea from 'antd/lib/input/TextArea';
import styles from './styles.module.scss';

export const ParamsSettingsPage = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  useEffect(() => {
    document.title = 'Торнадо / Настройки / Параметры';
    appStore.setCurrentPageTitle('Настройки / Параметры');
    store.load();
  }, [appStore, store]);

  const handleSave = () => {
    store.save();
  };

  return (
    <PageContainer className={styles.page}>
      <Spin spinning={store.loading}>
        <div>
          <Button key="add" onClick={handleSave} loading={store.saving} type="primary">
            Сохранить
          </Button>
        </div>

        <div className={styles.group}>Параметры эквайринга Сбербанка:</div>

        <div className={styles.group_item}>
          <FormItem label="Адрес инициализации оплаты">
            <Input
              style={{ width: '100%' }}
              value={store.paymentParams.SberOperatorInitUrl}
              onChange={action((e) => (store.paymentParams.SberOperatorInitUrl = e.target.value))}
            />
          </FormItem>
        </div>

        <div className={styles.group_item}>
          <FormItem label="Адрес проверки статуса платежа">
            <Input
              style={{ width: '100%' }}
              value={store.paymentParams.SberOperatorStatusUrl}
              onChange={action((e) => (store.paymentParams.SberOperatorStatusUrl = e.target.value))}
            />
          </FormItem>
        </div>

        <div className={styles.group_item}>
          <FormItem label="Логин">
            <Input
              style={{ width: '100%' }}
              value={store.paymentParams.SberOperatorUser}
              onChange={action((e) => (store.paymentParams.SberOperatorUser = e.target.value))}
            />
          </FormItem>
        </div>

        <div className={styles.group_item}>
          <FormItem label="Пароль">
            <Input.Password
              style={{ width: '100%' }}
              value={store.paymentParams.SberOperatorPassword}
              onChange={action((e) => (store.paymentParams.SberOperatorPassword = e.target.value))}
            />
          </FormItem>
        </div>

        <div className={styles.group_item}>
          <FormItem label="Реквизиты">
            <TextArea
              style={{ width: '100%' }}
              autoSize
              value={store.paymentParams.Requisites}
              onChange={action((e) => (store.paymentParams.Requisites = e.target.value))}
            />
          </FormItem>
        </div>
      </Spin>
    </PageContainer>
  );
});
