export enum TrainingVisitStatus {
  Visit = 1,
  Skip = 2,
  HealthCertificate = 3,
  Vacation = 4,
  Test = 5,
  HealthSkip = 6,
  TestSkip = 7,
}

export const getTrainingVisitStatusTitle = (visitStatus: TrainingVisitStatus) => {
  if (visitStatus === TrainingVisitStatus.Visit) return 'Посещено';
  if (visitStatus === TrainingVisitStatus.Skip) return 'Пропуск';
  if (visitStatus === TrainingVisitStatus.HealthCertificate) return 'Справка';
  if (visitStatus === TrainingVisitStatus.Vacation) return 'Отпуск';
  if (visitStatus === TrainingVisitStatus.Test) return 'Пробная';
  if (visitStatus === TrainingVisitStatus.HealthSkip) return 'Пропуск по болезни';
  if (visitStatus === TrainingVisitStatus.TestSkip) return 'Пропуск пробной';
  return '';
};
