import { Popover, Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './UserPayments.module.scss';
import { DateUtils } from '../../../utils/date';
import { UserPaymentCalcTraining } from '../models/userPaymentCalcTraining';
import { UserTrainingStatusIcon } from '../../../components/User/UserTrainings/UserTrainingStatusIcon';
import { getTrainingStatusTitle } from '../../../models/trainings/training';
import { getTrainingVisitStatusTitle } from '../../../models/trainings/trainingVisitStatus';

interface UserTrainigVisitsStatProps {
  trainings: UserPaymentCalcTraining[];
  title: string;
}

export const UserTrainigVisitsStat: React.FC<UserTrainigVisitsStatProps> = observer(({ trainings, title }) => {
  return (
    <div className={styles.calc_trainings_item}>
      <div>{title}</div>
      <div>
        <Popover
          content={
            <div className={styles.calc_trainings_list}>
              {trainings.map((trainingInfo) => (
                <>
                  <div>{DateUtils.format(trainingInfo.training.date)}</div>
                  <div>{getTrainingStatusTitle(trainingInfo.training.status)}</div>
                  <div>{trainingInfo.visitStatus ? getTrainingVisitStatusTitle(trainingInfo.visitStatus) : 'Нет отметки'}</div>
                </>
              ))}
            </div>
          }
          title={title}
          trigger="hover"
        >
          {/* <Space>
            {[
              TrainingVisitStatus.Visit,
              TrainingVisitStatus.Skip,
              TrainingVisitStatus.HealthSkip,
              TrainingVisitStatus.HealthCertificate,
              TrainingVisitStatus.Vacation,
              TrainingVisitStatus.Test,
            ].map((visitStatus) => (
              <>
                <div>
                  <UserTrainingStatusIcon visitStatus={visitStatus} />
                </div>
                <div> {trainings.filter((e) => e.visitStatus === visitStatus).length}</div>
              </>
            ))}
          </Space> */}
          <Space>
            {trainings
              .filter((e) => e.visitStatus !== null)
              .map((training) => (
                <>
                  <div>
                    <UserTrainingStatusIcon visitStatus={training.visitStatus!} />
                  </div>
                </>
              ))}
          </Space>
        </Popover>
      </div>
    </div>
  );
});
