import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Col, Input } from 'antd';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import FormItem from '../../../FormItem';
import { UsersApi } from '../../../../api/user';

export interface IUserEditResetPasswordDialogProps {
  userId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const UserEditResetPasswordDialog: React.FC<IUserEditResetPasswordDialogProps> = observer(
  ({ userId, isOpen, onClose }) => {
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const handleApply = action(() => {
      if (!password) {
        message.warning('Введите новый пароль', 3);
        return;
      }

      if (password.length < 4) {
        message.warning('Пароль должен быть не менее 4 символов', 3);
        return;
      }

      if (!passwordConfirm) {
        message.warning('Введите подтверждение пароля', 3);
        return;
      }

      if (password !== passwordConfirm) {
        message.warning('Пароли не совпадают', 3);
        return;
      }

      UsersApi.resetPassword(userId, password).then(() => {
        message.success('Пароль успешно сброшен', 3);
      });

      onClose();
    });

    const handleCancel = () => {
      onClose();
    };

    return (
      <Modal
        visible={isOpen}
        title="Сброс пароля"
        onCancel={handleCancel}
        width={500}
        footer={[
          <Button key="apply" onClick={handleApply} type="primary">
            Сбросить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={12}>
            <FormItem label="Новый пароль">
              <Input.Password
                style={{ width: '100%' }}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Подтверждение пароля">
              <Input.Password
                style={{ width: '100%' }}
                value={passwordConfirm}
                onChange={(e) => {
                  setPasswordConfirm(e.target.value);
                }}
              />
            </FormItem>
          </Col>
        </Row>
      </Modal>
    );
  }
);
