import { StaffUser, StaffUserJson } from './models/staffUser';
import axios from 'axios';
import { StaffSettingsPageParams } from './models/staffSettingsPageParams';

export const StaffSettingsPageApi = {
  fetchCoaches: async (params: StaffSettingsPageParams) => {
    const response = await axios.get<StaffUserJson[]>('/api/users/GetCoachesSettings', params);
    return response.data.map((e) => StaffUser.fromJson(e));
  },

  fetchManagers: async (params: StaffSettingsPageParams) => {
    const response = await axios.get<StaffUserJson[]>('/api/users/GetManagersSettings', params);
    return response.data.map((e) => StaffUser.fromJson(e));
  },

  saveStaffUser: async (user: StaffUser): Promise<void> => {
    await axios.post('/api/users/SaveStaffUser', {
      userModel: user.toJson(),
    });
  },
};
