import React, { useState, useEffect, ReactText } from 'react';
import './UserEditDialog.scss';
import Modal from 'antd/lib/modal/Modal';
import {
  Button,
  Input,
  Row,
  Space,
  Spin,
  Col,
  DatePicker,
  Dropdown,
  Menu,
  Select,
  Calendar,
  Radio,
  Divider,
  message,
  TreeSelect,
} from 'antd';
import { SelectInfo } from 'rc-menu/lib/interface';
import { useStore } from '../../../stores/storeProvider';
import { UserGender, UserStatus, getUserStatusTitle, UserSaveMode, formatPhoneNumber } from '../../../models/user';
import FormItem from '../../FormItem';
import { observer } from 'mobx-react';
import { action, runInAction } from 'mobx';
import { PHONE_NUMBER_MASK, UserEditDialogPanels, UserEditDialogState } from './UserEditDialogState';
import MaskedInput from 'antd-mask-input';
import Spacer from '../../../ui-kit/Spacer';
import moment from 'moment';
import classNames from 'classnames';
import { CopyOutlined, HistoryOutlined } from '@ant-design/icons';
import { UserEditReserveDialog } from './Components/UserEditReserveDialog';
import { UserEditArchiveDialog } from './Components/UserEditArchiveDialog';
import { UserEditChangeGroupDialog } from './Components/UserEditChangeGroupDialog';
import { UserEditSaveModeInfo } from './Components/UserEditSaveModeInfo';
import { UserEditChangeLoginDialog } from './Components/UserEditChangeLoginDialog';
import { UserEditShowExistingButton } from './Components/UserEditShowExistingButton';
import { UserEditDialogProvider } from './UserEditDialogProvider';
import { UserNotesPanel } from '../UserNotes/UserNotesPanel';
import { UserEditAddToGroupDialog } from './Components/UserEditAddToGroupDialog';
import { UserEditChangeDiscountDialog } from './Components/UserEditChangeDiscountDialog';
import { UserEditChangeAbonnementDialog } from './Components/UserEditChangeAbonnementDialog';
import { UserEditResetPasswordDialog } from './Components/UserEditResetPasswordDialog';
import UserPayments from '../UserPayments';
import UserTrainings from '../UserTrainings';
import { TreeNode } from 'antd/lib/tree-select';
import { isEmpty } from 'lodash';
import { UserEditAddNewSourceDialog } from './Components/UserEditAddNewSourceDialog';
import { UserEditFamilyInfoDialog } from './Components/UserEditFamilyInfoDialog';
import { DateUtils } from '../../../utils/date';
import { UserRoleName } from '../../../models/userRole';
import { UserEditAddToSecondGroupDialog } from './Components/UserEditAddToSecondGroupDialog';

const { Option } = Select;

export interface IUserEditDialogProps {
  userId: string | null;
  isOpen: boolean;
  userRoleName: UserRoleName | null;
  isReadonly: boolean;
  onSave: (userId: string) => void;
  onClose: () => void;
}

export const UserEditDialog: React.FC<IUserEditDialogProps> = observer(
  ({ userId, userRoleName, isOpen, isReadonly, onClose, onSave }) => {
    const { appStore, sourcesStore } = useStore();
    const [dialogState, setDialogState] = useState(new UserEditDialogState(userId, userRoleName, appStore));

    const [isTestTrainingOpen, setIsTestTrainingOpen] = useState(false);
    const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
    const [isReserveDialogOpen, setIsReserveDialogOpen] = useState(false);
    const [isStatusesHistoryOpen, setIsStatusesHistoryOpen] = useState(false);
    const [isGroupsHistoryOpen, setIsGroupsHistoryOpen] = useState(false);
    const [isAbonnementsHistoryOpen, setIsAbonnementsHistoryOpen] = useState(false);
    const [isDiscountsHistoryOpen, setIsDiscountsHistoryOpen] = useState(false);
    const [isChangeAbonnementDialogOpen, setIsChangeAbonnementDialogOpen] = useState(false);
    const [isChangeDiscountDialogOpen, setIsChangeDiscountDialogOpen] = useState(false);
    const [isChangeGroupDialogOpen, setIsChangeGroupDialogOpen] = useState(false);
    const [isAddToGroupDialogOpen, setIsAddToGroupDialogOpen] = useState(false);
    const [isAddToSecondGroupDialogOpen, setIsAddToSecondGroupDialogOpen] = useState(false);
    const [isChangeLoginDialogOpen, setIsChangeLoginDialogOpen] = useState(false);
    const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState(false);
    const [isAddNewSourceDialogOpen, setIsAddNewSourceDialogOpen] = useState(false);
    const [isEditFamilyDialogOpen, setIsEditFamilyDialogOpen] = useState(false);

    const [sourceTreeExpandedKeys, setSourceTreeExpandedKeys] = useState<string[]>([]);
    const [isSourceSelectOpen, setIsSourceSelectOpen] = useState(false);

    const [currentPanel, setCurrentPanel] = useState(UserEditDialogPanels.Info);

    useEffect(() => {
      if (isOpen) {
        var newDialogState = new UserEditDialogState(userId, userRoleName, appStore);
        newDialogState.init();
        setDialogState(newDialogState);
        setCurrentPanel(UserEditDialogPanels.Info);
        sourcesStore.loadSources();
      }
    }, [isOpen, userId, userRoleName, appStore, sourcesStore]);

    const handleSave = () => {
      const saveUser = async () => {
        const userSaveResult = await dialogState.save();

        if (userSaveResult) {
          if (userSaveResult.msg) {
            appStore.setNotificationMessage(userSaveResult.msg);
          }

          onSave(userSaveResult.userID);
        }
      };

      if (
        dialogState.userModel.PhoneNumber &&
        dialogState.userModel.PhoneNumber.replace('_', '').length !== PHONE_NUMBER_MASK.length
      ) {
        message.warning('Неверный формат телефона', 3);
        return;
      }

      if (
        dialogState.userModel.PhoneNumber2 &&
        dialogState.userModel.PhoneNumber2.replace('_', '').length !== PHONE_NUMBER_MASK.length
      ) {
        message.warning('Неверный формат телефона 2', 3);
        return;
      }

      saveUser();
    };

    const handleCancel = () => {
      onClose();
    };

    const handleTestTraining = () => {
      runInAction(() => {
        if (dialogState.userModel.RegisterDate !== '') {
          dialogState.testTrainingDate = moment(dialogState.userModel.RegisterDate);
          dialogState.testTrainingMonth = moment(dialogState.userModel.RegisterDate);
        }

        if (dialogState.userInfo.groups.length > 0) dialogState.testTrainingGroupId = dialogState.userInfo.groups[0];

        dialogState.loadTrainingsForSelect();

        setIsTestTrainingOpen(true);
      });
    };

    const handleTestTrainingCancel = () => {
      runInAction(() => {
        setIsTestTrainingOpen(false);
        dialogState.testTrainingGroupId = null;
        dialogState.testTrainingDate = null;
        dialogState.testTrainings = [];
      });
    };

    const handleTestTrainingApply = () => {
      runInAction(() => {
        if (dialogState.testTrainingDate === null) {
          message.warning('Не задана дата тестовой тренировки', 3);
          return;
        }

        if (dialogState.testTrainingGroupId === null) {
          message.warning('Не задана группа для пробной тренировки', 3);
          return;
        }

        dialogState.userModel.saveMode = UserSaveMode.TestTraining;
        dialogState.userModel.Status = UserStatus.Claim;
        dialogState.userModel.newTestTrainingDate = dialogState.testTrainingDate.format('DD.MM.YYYY');
        dialogState.userModel.newTestTrainingGroupId = dialogState.testTrainingGroupId;
        setIsTestTrainingOpen(false);
      });
    };

    const handleSelectPanel = (info: SelectInfo) => {
      setCurrentPanel(info.key as UserEditDialogPanels);
    };

    const handleSelectSource = (value: string, node: any) => {
      if (node.children && node.children.length > 0) {
        const nodeKey = node.key as string;
        setSourceTreeExpandedKeys(
          sourceTreeExpandedKeys.includes(nodeKey)
            ? sourceTreeExpandedKeys.filter((key) => key !== nodeKey)
            : sourceTreeExpandedKeys.concat(nodeKey)
        );
        setIsSourceSelectOpen(true);
      }
    };

    const handleExpandSourceTree = (expandedKeys: ReactText[]) => {
      setSourceTreeExpandedKeys(expandedKeys as string[]);
    };

    const trainingsCalendarCellRenderer = (date: moment.Moment) => {
      const training = dialogState.testTrainings.find((e) => e.date.isSame(date, 'day'));
      return (
        <div
          className={classNames('CalendarCell', {
            'CalendarCell__has-training': !!training,
            CalendarCell__selected: dialogState.testTrainingDate !== null && dialogState.testTrainingDate.isSame(date, 'day'),
          })}
        >
          <div className="CalendarCell-Day">{date.format('DD')}</div>
          <div className="CalendarCell-Time">
            <div>
              {training &&
                `${training.fromTime.substring(0, training.fromTime.length - 3)} - ${training.toTime.substring(
                  0,
                  training.toTime.length - 3
                )}`}
            </div>
          </div>
        </div>
      );
    };

    const clientForm = (
      <div className="UserEditDialog-Form">
        {!dialogState.isNewUser && (
          <Menu className="UserEditDialog-Menu" defaultSelectedKeys={[UserEditDialogPanels.Info]} onSelect={handleSelectPanel}>
            <Menu.Item key={UserEditDialogPanels.Info}>Информация</Menu.Item>
            <Menu.Item key={UserEditDialogPanels.Notes}>Заметки</Menu.Item>
            <Menu.Item key={UserEditDialogPanels.Payments}>Платежи</Menu.Item>
            <Menu.Item key={UserEditDialogPanels.Trainings}>Тренировки</Menu.Item>
          </Menu>
        )}

        {currentPanel === UserEditDialogPanels.Info && (
          <div className="UserEditDialog-InfoPanel">
            <Row>
              <Col span={12}>
                <FormItem label="Фамилия">
                  <Input
                    value={dialogState.userModel.LastName}
                    onChange={action((e) => {
                      dialogState.userModel.LastName = e.target.value;
                    })}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Имя">
                  <Input
                    value={dialogState.userModel.FirstName}
                    onChange={action((e) => {
                      dialogState.userModel.FirstName = e.target.value;
                    })}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col span={7}>
                <FormItem label="День рождения">
                  <DatePicker
                    value={
                      dialogState.userModel.BirthDay !== '' && dialogState.userModel.BirthDay !== null
                        ? moment(dialogState.userModel.BirthDay)
                        : null
                    }
                    format="DD.MM.YYYY"
                    onChange={action((date) => {
                      dialogState.userModel.BirthDay = date?.toISOString() || '';
                    })}
                  />
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label="Пол">
                  <Radio.Group
                    onChange={action((e) => {
                      dialogState.userModel.Gender = e.target.value;
                    })}
                    value={dialogState.userModel.Gender}
                  >
                    <Radio value={UserGender.Male}>Мальчик</Radio>
                    <Radio value={UserGender.Female}>Девочка</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col span={8}>
                <FormItem label="Телефон">
                  <MaskedInput
                    mask="+7 (111) 111-11-11"
                    value={formatPhoneNumber(dialogState.userModel.PhoneNumber)}
                    onChange={action((e) => {
                      const value = e.target.value.replace(' ', '').replace('(', '');
                      if (value.startsWith('+78') || value.startsWith('+77')) {
                        dialogState.userModel.PhoneNumber = '+7';
                      } else {
                        dialogState.userModel.PhoneNumber = formatPhoneNumber(e.target.value);
                      }

                      dialogState.checkLogin();
                    })}
                  />
                </FormItem>
              </Col>
              <Col span={16}>
                <FormItem label="Контакты">
                  <Input
                    value={dialogState.userModel.Description}
                    onChange={action((e) => {
                      dialogState.userModel.Description = e.target.value;
                    })}
                  />
                </FormItem>
              </Col>
            </Row>
            {dialogState.checkLoginResult && dialogState.checkLoginResult.exists && (
              <Row>
                <Col span={24}>
                  <div className="UserEditDialog-ExistingUserInfo">
                    <div className="UserEditDialog-ExistingUserInfo-User">
                      {`В системе уже зарегистрирован пользователь с таким номером: ${dialogState.checkLoginResult.info}`}
                    </div>
                    <div className="UserEditDialog-ExistingUserInfo-Actions">
                      <UserEditDialogProvider>
                        <UserEditShowExistingButton userId={dialogState.checkLoginResult.userId} />
                      </UserEditDialogProvider>
                      <Button
                        type="link"
                        onClick={() => {
                          setIsChangeLoginDialogOpen(true);
                        }}
                      >
                        изменить логин
                      </Button>
                      {dialogState.userModel.customLogin && <div>{` Новый логин: ${dialogState.userModel.customLogin}`}</div>}
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            <Row>
              <Col span={8}>
                <FormItem label="Телефон 2">
                  <MaskedInput
                    mask="+7 (111) 111-11-11"
                    value={formatPhoneNumber(dialogState.userModel.PhoneNumber2)}
                    onChange={action((e) => {
                      const value = e.target.value.replace(' ', '').replace('(', '');
                      if (value.startsWith('+78') || value.startsWith('+77')) {
                        dialogState.userModel.PhoneNumber2 = '+7';
                      } else {
                        dialogState.userModel.PhoneNumber2 = formatPhoneNumber(e.target.value);
                      }
                    })}
                  />
                </FormItem>
              </Col>
              <Col span={16}>
                <FormItem label="Контакты 2">
                  <Input
                    value={dialogState.userModel.PhoneDesc2}
                    onChange={action((e) => {
                      dialogState.userModel.PhoneDesc2 = e.target.value;
                    })}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <FormItem label="Источник">
                  <TreeSelect
                    style={{ width: '100%' }}
                    value={dialogState.userModel.Source}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    allowClear
                    showSearch
                    treeExpandedKeys={sourceTreeExpandedKeys}
                    onChange={action((value: string) => {
                      if (value === 'add-new-source') {
                        setIsAddNewSourceDialogOpen(true);
                      }
                      if (sourcesStore.isSourceExists(value) || isEmpty(value)) {
                        dialogState.userModel.Source = value;
                      }
                    })}
                    onSelect={handleSelectSource}
                    onTreeExpand={handleExpandSourceTree}
                    open={isSourceSelectOpen}
                    onDropdownVisibleChange={(visible) => setIsSourceSelectOpen(visible)}
                  >
                    {sourcesStore.sourceGroups.map((sourceGroup) => (
                      <TreeNode key={sourceGroup.Title} value={sourceGroup.Title} title={sourceGroup.Title}>
                        {sourceGroup.Sources.map((source) => (
                          <TreeNode key={source.Title} value={source.Title} title={source.Title} />
                        ))}
                      </TreeNode>
                    ))}
                    {!isEmpty(dialogState.userModel.Source) &&
                      !sourcesStore.isSourceExists(dialogState.userModel.Source) &&
                      !dialogState.isNewUser && (
                        <TreeNode value={dialogState.userModel.Source} title={dialogState.userModel.Source} />
                      )}
                    <TreeNode value="add-new-source" title=" + новый источник" />
                  </TreeSelect>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="Примечание к источнику">
                  <Input
                    value={dialogState.userModel.SourceComment}
                    onChange={action((e) => {
                      dialogState.userModel.SourceComment = e.target.value;
                    })}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="Описание">
                  <Input
                    value={dialogState.userModel.Comment}
                    onChange={action((e) => {
                      dialogState.userModel.Comment = e.target.value;
                    })}
                  />
                </FormItem>
              </Col>
            </Row>

            {!dialogState.isNewUser && (
              <Row>
                <Col span={14}>
                  <FormItem label="Статус">
                    <Input readOnly value={getUserStatusTitle(dialogState.userModel.Status)} />
                    <Button onClick={() => setIsStatusesHistoryOpen(true)}>
                      <HistoryOutlined />
                    </Button>
                  </FormItem>
                </Col>
                <Col span={10}>
                  <FormItem label="Группа">
                    <Input readOnly value={dialogState.userInfo.groupNames} />
                    <Button onClick={() => setIsGroupsHistoryOpen(true)}>
                      <HistoryOutlined />
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            )}

            {!dialogState.isNewUser &&
              dialogState.userModel.Status !== UserStatus.Claim &&
              dialogState.userModel.Status !== UserStatus.ClaimRetry &&
              dialogState.userModel.Status !== UserStatus.ClaimNotVisit &&
              dialogState.userModel.Status !== UserStatus.Archive &&
              dialogState.userModel.Status !== UserStatus.ArchiveNotVisit &&
              dialogState.userModel.Status !== UserStatus.Reserve && (
                <Row>
                  <Col span={14}>
                    <FormItem label="Абонемент">
                      <Select
                        className="UserEditDialog-AbonnementSelect"
                        showSearch
                        optionFilterProp="children"
                        value={dialogState.userModel.newAbonnementId || dialogState.currentAbonnementID || ''}
                        dropdownMatchSelectWidth={false}
                        onChange={action((value: string) => {
                          dialogState.userModel.newAbonnementId = value;
                          setIsChangeAbonnementDialogOpen(true);
                        })}
                      >
                        {appStore.abonnementsStore.activeAbonnements.map((item) => (
                          <Option key={item.ID} value={item.ID} title={item.Name}>
                            {item.Name}
                          </Option>
                        ))}
                      </Select>
                      <Button onClick={() => setIsAbonnementsHistoryOpen(true)}>
                        <HistoryOutlined />
                      </Button>
                    </FormItem>
                  </Col>

                  <Col span={10}>
                    <FormItem label="Скидка">
                      <Select
                        className="UserEditDialog-DiscountSelect"
                        showSearch
                        optionFilterProp="children"
                        dropdownMatchSelectWidth={false}
                        value={
                          (dialogState.userModel.newDiscountSelected
                            ? dialogState.userModel.newDiscountId
                            : dialogState.currentDiscountID) || ''
                        }
                        onChange={action((value: string) => {
                          dialogState.userModel.newDiscountSelected = true;
                          dialogState.userModel.newDiscountId = value === '' ? null : value;
                          setIsChangeDiscountDialogOpen(true);
                        })}
                      >
                        <Option key={'0'} value={''} title="нет">
                          нет
                        </Option>
                        {appStore.discountsStore.activeDiscounts.map((item) => (
                          <Option key={item.ID} value={item.ID} title={item.Name}>
                            {item.Name}
                          </Option>
                        ))}
                      </Select>
                      <Button onClick={() => setIsDiscountsHistoryOpen(true)}>
                        <HistoryOutlined />
                      </Button>
                    </FormItem>
                  </Col>
                </Row>
              )}

            {!dialogState.isNewUser && (
              <Row>
                <Col span={24}>
                  <div>{` Дата пробной: ${DateUtils.format(dialogState.userModel.RegisterDate)}`}</div>
                  <div>{` Роль: ${dialogState.userInfo.rolesTitle}`}</div>
                  <div>{` Логин: ${dialogState.userModel.customLogin || dialogState.userModel.UserName}`}</div>
                  {dialogState.familyUsers.length > 0 && (
                    <div>
                      <span>Семья:&nbsp;</span>
                      {dialogState.familyUsers.map((familyUser) => (
                        <span>
                          {familyUser.displayName} ({familyUser.statusTitle})
                        </span>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
            )}

            {!isReadonly && (
              <div className="UserEditDialog-ClientActions">
                <Space wrap size="small">
                  <Button
                    title="Записать на пробную"
                    onClick={handleTestTraining}
                    disabled={
                      !(
                        dialogState.isNewUser ||
                        dialogState.userModel.Status === UserStatus.Reserve ||
                        dialogState.userModel.Status === UserStatus.Archive ||
                        dialogState.userModel.Status === UserStatus.ArchiveNotVisit
                      )
                    }
                  >
                    На пробную
                  </Button>
                  <Button
                    title="Перенести пробную тренировку на другую дату"
                    onClick={handleTestTraining}
                    disabled={!dialogState.isTestTrainingNeedTransfer}
                  >
                    {dialogState.userModel.prevStatus === UserStatus.ClaimVisit ? 'Повторная пробная' : 'Перенос пробной'}
                  </Button>
                  <Button title="Добавить в резерв группы" onClick={() => setIsReserveDialogOpen(true)}>
                    {dialogState.userModel.Status === UserStatus.Reserve ? 'Резерв Изменить' : 'В резерв'}
                  </Button>
                  <Button title="Перенести в архив" disabled={dialogState.isNewUser} onClick={() => setIsArchiveDialogOpen(true)}>
                    {dialogState.userModel.Status === UserStatus.Archive ||
                    dialogState.userModel.Status === UserStatus.ArchiveNotVisit
                      ? 'Архив Изменить'
                      : 'В архив'}
                  </Button>
                </Space>

                <Spacer />

                <Dropdown
                  placement="topRight"
                  overlay={
                    <Menu>
                      {!dialogState.isNewUser &&
                        (dialogState.userModel.Status === UserStatus.Common ||
                          dialogState.userModel.Status === UserStatus.FirstPayment ||
                          dialogState.userModel.Status === UserStatus.PaymentAdded) && (
                          <Menu.Item key="moveToGroup" onClick={() => setIsChangeGroupDialogOpen(true)}>
                            Перевести в другую группу
                          </Menu.Item>
                        )}
                      {!dialogState.isNewUser &&
                        (dialogState.userModel.Status === UserStatus.Reserve ||
                          dialogState.userModel.Status === UserStatus.Archive ||
                          dialogState.userModel.Status === UserStatus.ArchiveNotVisit) && (
                          <Menu.Item key="moveToGroupForce" onClick={() => setIsAddToGroupDialogOpen(true)}>
                            Перевод в группу без пробной
                          </Menu.Item>
                        )}
                      {!dialogState.isNewUser &&
                        (dialogState.userModel.Status === UserStatus.Common ||
                          dialogState.userModel.Status === UserStatus.FirstPayment) && (
                          <Menu.Item key="addToSecondGroup" onClick={() => setIsAddToSecondGroupDialogOpen(true)}>
                            Добавить группу
                          </Menu.Item>
                        )}
                      {!dialogState.isNewUser && (
                        <Menu.Item key="resetPassword" onClick={() => setIsResetPasswordDialogOpen(true)}>
                          Сбросить пароль
                        </Menu.Item>
                      )}
                      {!dialogState.isNewUser && (
                        <Menu.Item key="changeLogin" onClick={() => setIsChangeLoginDialogOpen(true)}>
                          Изменить логин
                        </Menu.Item>
                      )}
                      <Menu.Item key="changeFamily" onClick={() => setIsEditFamilyDialogOpen(true)}>
                        Изменить семью
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button>...</Button>
                </Dropdown>
              </div>
            )}
            <Row>
              <UserEditSaveModeInfo editDialogState={dialogState} />
            </Row>
          </div>
        )}

        <div
          className={classNames('UserEditDialog-NotesPanel', {
            'UserEditDialog-NotesPanel__hidden': currentPanel !== UserEditDialogPanels.Notes || userId === null,
          })}
        >
          <UserNotesPanel userId={userId || ''} isVisible={currentPanel === UserEditDialogPanels.Notes && userId !== null} />
        </div>

        <div
          className={classNames('UserEditDialog-PaymentsPanel', {
            'UserEditDialog-PaymentsPanel__hidden': currentPanel !== UserEditDialogPanels.Payments || userId === null,
          })}
        >
          <UserPayments userId={userId || ''} isVisible={currentPanel === UserEditDialogPanels.Payments && userId !== null} />
        </div>

        <div
          className={classNames('UserEditDialog-TrainingsPanel', {
            'UserEditDialog-TrainingsPanel__hidden': currentPanel !== UserEditDialogPanels.Trainings || userId === null,
          })}
        >
          <UserTrainings userId={userId || ''} isVisible={currentPanel === UserEditDialogPanels.Trainings && userId !== null} />
        </div>
      </div>
    );

    const coachForm = (
      <div>
        <Row>
          <Space>
            <FormItem label="Фамилия">
              <Input
                value={dialogState.userModel.FirstName}
                onChange={action((e) => {
                  dialogState.userModel.FirstName = e.target.value;
                })}
              />
            </FormItem>
            {/* <FormItem label="Имя">
            <Input />
          </FormItem>
          <FormItem label="Отчество">
            <Input />
          </FormItem> */}
          </Space>
        </Row>
      </div>
    );

    return (
      <div>
        <Modal
          className={classNames('UserEditDialog', { UserEditDialog__existing: !dialogState.isNewUser })}
          visible={isOpen}
          title={
            <div>
              {isReadonly ? 'Просмотр пользователя' : dialogState.dialogTitle}{' '}
              {dialogState.userId ? (
                <Space className="UserEditDialog-UserId">
                  id: {dialogState.userId}{' '}
                  <CopyOutlined
                    title="скопирвать идентификатор пользователя"
                    onClick={() => navigator.clipboard.writeText(dialogState.userId!)}
                  />
                </Space>
              ) : null}
            </div>
          }
          onCancel={handleCancel}
          footer={
            <>
              {!isReadonly && (
                <Button key="add" onClick={handleSave} loading={dialogState.saving} type="primary">
                  Сохранить
                </Button>
              )}
              <Button key="back" onClick={handleCancel}>
                Закрыть
              </Button>
            </>
          }
        >
          <Spin spinning={dialogState.loading}></Spin>

          {!dialogState.loading && dialogState.dialogMode !== null && (
            <>
              {dialogState.dialogMode === UserRoleName.Client && clientForm}
              {dialogState.dialogMode === UserRoleName.Coach && coachForm}
            </>
          )}
        </Modal>

        <UserEditArchiveDialog
          editDialogState={dialogState}
          isOpen={isArchiveDialogOpen}
          onClose={() => setIsArchiveDialogOpen(false)}
        />

        <UserEditReserveDialog
          editDialogState={dialogState}
          isOpen={isReserveDialogOpen}
          onClose={() => setIsReserveDialogOpen(false)}
        />

        <UserEditChangeGroupDialog
          editDialogState={dialogState}
          isOpen={isChangeGroupDialogOpen}
          onClose={() => setIsChangeGroupDialogOpen(false)}
        />

        <UserEditAddToGroupDialog
          editDialogState={dialogState}
          isOpen={isAddToGroupDialogOpen}
          onClose={() => setIsAddToGroupDialogOpen(false)}
        />

        <UserEditAddToSecondGroupDialog
          editDialogState={dialogState}
          isOpen={isAddToSecondGroupDialogOpen}
          onClose={() => setIsAddToSecondGroupDialogOpen(false)}
        />

        <UserEditChangeLoginDialog
          editDialogState={dialogState}
          isOpen={isChangeLoginDialogOpen}
          onClose={() => setIsChangeLoginDialogOpen(false)}
        />

        <UserEditChangeDiscountDialog
          editDialogState={dialogState}
          isOpen={isChangeDiscountDialogOpen}
          onClose={() => setIsChangeDiscountDialogOpen(false)}
        />

        <UserEditChangeAbonnementDialog
          editDialogState={dialogState}
          isOpen={isChangeAbonnementDialogOpen}
          onClose={() => setIsChangeAbonnementDialogOpen(false)}
        />

        <UserEditAddNewSourceDialog
          editDialogState={dialogState}
          isOpen={isAddNewSourceDialogOpen}
          onClose={() => setIsAddNewSourceDialogOpen(false)}
        />

        <UserEditFamilyInfoDialog
          editDialogState={dialogState}
          isOpen={isEditFamilyDialogOpen}
          onClose={() => setIsEditFamilyDialogOpen(false)}
        />

        {dialogState.userId !== null && (
          <UserEditResetPasswordDialog
            userId={dialogState.userId}
            isOpen={isResetPasswordDialogOpen}
            onClose={() => setIsResetPasswordDialogOpen(false)}
          />
        )}

        {/* пробная */}
        <Modal
          className="UserEditDialog-TestTraining"
          visible={isTestTrainingOpen}
          title={dialogState.dialogTestTrainingTitle}
          onCancel={handleTestTrainingCancel}
          footer={[
            <Button key="add" onClick={handleTestTrainingApply} loading={dialogState.saving} type="primary">
              Применить
            </Button>,
            <Button key="back" onClick={handleTestTrainingCancel}>
              Закрыть
            </Button>,
          ]}
        >
          <Row>
            <FormItem label="Выберите группу">
              <Select
                showSearch
                style={{ width: 200 }}
                optionFilterProp="children"
                value={dialogState.testTrainingGroupId === null ? '' : dialogState.testTrainingGroupId}
                onChange={action((value: number | string) => {
                  dialogState.testTrainingGroupId = typeof value === 'number' ? value : null;
                  dialogState.loadTrainingsForSelect();
                })}
              >
                <Option value=""> </Option>
                {appStore.groupStore.activeGroups?.map((group) => (
                  <Option key={group.id} value={group.id}>
                    {group.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem label="Выберите месяц">
              <DatePicker
                style={{ width: 200 }}
                picker="month"
                placeholder=""
                value={dialogState.testTrainingMonth}
                onChange={action((value) => {
                  dialogState.testTrainingMonth = value || moment();
                  dialogState.testTrainingDate = value;
                  dialogState.loadTrainingsForSelect();
                })}
              ></DatePicker>
            </FormItem>
            <FormItem label="Пробная тренировка">
              <div>{dialogState.testTrainingDate?.format('DD.MM.YYYY') || 'не выбрана'}</div>
            </FormItem>
          </Row>
          <Row>
            {(dialogState.testTrainings.length > 0 || dialogState.testTrainings.length === 0) && (
              <Spin spinning={dialogState.loading}>
                <Calendar
                  value={dialogState.testTrainingDate || undefined}
                  mode="month"
                  headerRender={() => null}
                  fullscreen={false}
                  dateFullCellRender={trainingsCalendarCellRenderer}
                  onSelect={action((value) => {
                    dialogState.testTrainingDate = value;
                  })}
                />
              </Spin>
            )}
          </Row>
        </Modal>

        {/* история статусов */}
        <Modal
          className="UserEditDialog-StatusesHistory"
          visible={isStatusesHistoryOpen}
          title="История статусов"
          onCancel={() => setIsStatusesHistoryOpen(false)}
          footer={[
            <Button key="back" onClick={() => setIsStatusesHistoryOpen(false)}>
              Закрыть
            </Button>,
          ]}
        >
          {dialogState.userInfo.statuses.map((item) => (
            <div key={item.id}>
              <div>
                {`${item.statusTitle} c ${moment(item.dateFrom).format('DD.MM.YYYY')} ${
                  item.dateTo !== null ? `по ${moment(item.dateTo).format('DD.MM.YYYY')}` : ''
                }`}
              </div>
              <div>{item.description}</div>
              <Divider />
            </div>
          ))}
        </Modal>

        <Modal
          className="UserEditDialog-GroupsHistory"
          visible={isGroupsHistoryOpen}
          title="История групп"
          onCancel={() => setIsGroupsHistoryOpen(false)}
          footer={[
            <Button key="back" onClick={() => setIsGroupsHistoryOpen(false)}>
              Закрыть
            </Button>,
          ]}
        >
          {dialogState.userInfo.groupsHistory.map((item) => (
            <div key={item.id}>
              <div>
                {`${item.name} c ${moment(item.from).format('DD.MM.YYYY')} ${
                  item.to !== null ? `по ${moment(item.to).format('DD.MM.YYYY')}` : ''
                }`}
              </div>
              <Divider />
            </div>
          ))}
        </Modal>

        <Modal
          className="UserEditDialog-AbonnementsHistory"
          visible={isAbonnementsHistoryOpen}
          title="История абонементов"
          onCancel={() => setIsAbonnementsHistoryOpen(false)}
          footer={[
            <Button key="back" onClick={() => setIsAbonnementsHistoryOpen(false)}>
              Закрыть
            </Button>,
          ]}
        >
          {dialogState.userInfo.userAbonnements.map((item) => (
            <div key={item.ID}>
              <div>
                {`${item.Abonnement.Name} c ${moment(item.DateFrom).format('DD.MM.YYYY')} ${
                  item.DateTo !== null ? `по ${moment(item.DateTo).format('DD.MM.YYYY')}` : ''
                }`}
              </div>
              <Divider />
            </div>
          ))}
        </Modal>

        <Modal
          className="UserEditDialog-DiscountsHistory"
          visible={isDiscountsHistoryOpen}
          title="История скидок"
          onCancel={() => setIsDiscountsHistoryOpen(false)}
          footer={[
            <Button key="back" onClick={() => setIsDiscountsHistoryOpen(false)}>
              Закрыть
            </Button>,
          ]}
        >
          {dialogState.userInfo.userDiscounts.map((item) => (
            <div key={item.ID}>
              <div>
                {`${item.Discount.Name} c ${moment(item.DateFrom).format('DD.MM.YYYY')} ${
                  item.DateTo !== null ? `по ${moment(item.DateTo).format('DD.MM.YYYY')}` : ''
                }`}
              </div>
              <Divider />
            </div>
          ))}
        </Modal>
      </div>
    );
  }
);
