import { observable } from 'mobx';
import { PaymentsPageUserRowPayment, PaymentsPageUserRowPaymentResponse } from './paymentsPageUserRowPayment';

export type PaymentsPageUserRowResponse = {
  UserID: string;
  UserDisplayName: string;
  UserGroupsName: string;
  Payments: PaymentsPageUserRowPaymentResponse[];
  AbonnementIds: string[];
  DiscountIds: string[];
};

export class PaymentsPageUserRow {
  userId: string = '';
  userName: string = '';
  groupsName: string = '';
  payments: PaymentsPageUserRowPayment[] = [];
  abonnementIds: string[] = [];
  discountIds: string[] = [];

  @observable
  isActive: boolean = false;

  hasMainPayment() {
    return this.payments.findIndex((e) => e.payment.additionalPaymentId === null) !== -1;
  }

  getAdditionalPaymentsIds() {
    return this.payments.filter((e) => e.payment.additionalPaymentId !== null).map((e) => e.payment.additionalPaymentId);
  }

  getMainPayment() {
    const index = this.payments.findIndex((e) => e.payment.additionalPaymentId === null);
    if (index === -1) return null;
    return this.payments[index];
  }

  public static fromResponse(resp: PaymentsPageUserRowResponse) {
    const result = new PaymentsPageUserRow();
    result.userId = resp.UserID;
    result.userName = resp.UserDisplayName;
    result.groupsName = resp.UserGroupsName;
    result.abonnementIds = resp.AbonnementIds;
    result.discountIds = resp.DiscountIds;
    result.payments = resp.Payments.map((resp) => PaymentsPageUserRowPayment.fromResponse(resp));
    return result;
  }
}
