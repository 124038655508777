import { observable } from 'mobx';
import { UserTrainingInfo, UserTrainingInfoJson } from './../../../models/trainings/userTrainingInfo';
import { TrainingUser, TrainingUserResponse } from '../../../models/trainings/trainingUser';
import { User, UserResponse } from '../../../models/users/user';

export type TrainingsDataResponse = {
  TrainingsIds: number[];
  Users: UserResponse[];
  TrainingUsers: TrainingUserResponse[];
  TestUsersIds: string[];
  MovedUsersIds: string[];
  InactiveUsersIds: string[];
  UsersDescriptions: { [key: string]: string };
  UsersTrainingsInfo: UserTrainingInfoJson[];
};

export class TrainingsData {
  trainingsIds: number[] = [];
  @observable
  users: User[] = [];
  trainingUsers: TrainingUser[] = [];
  testUsersIds: string[] = [];
  movedUsersIds: string[] = [];
  inactiveUsersIds: string[] = [];
  usersDescriptions: Map<string, string> = new Map<string, string>();
  usersTrainingsInfo: UserTrainingInfo[] = [];

  public static fromResponse(resp: TrainingsDataResponse) {
    const result = new TrainingsData();
    result.trainingsIds = resp.TrainingsIds;
    result.users = [new User(), ...resp.Users.map((e) => User.fromResponse(e))];
    let index = 0;
    for (const user of result.users) {
      user.statusOrder = index++;
    }
    result.trainingUsers = resp.TrainingUsers.map((e) => TrainingUser.fromResponse(e));
    result.testUsersIds = resp.TestUsersIds;
    result.movedUsersIds = resp.MovedUsersIds;
    result.inactiveUsersIds = resp.InactiveUsersIds;
    for (var userId in resp.UsersDescriptions) {
      result.usersDescriptions.set(userId, resp.UsersDescriptions[userId]);
    }
    result.usersTrainingsInfo = resp.UsersTrainingsInfo.map((e) => UserTrainingInfo.fromJson(e));
    return result;
  }

  isTestUser(userId: string) {
    return this.testUsersIds.includes(userId);
  }

  isMovedUser(userId: string) {
    return this.movedUsersIds.includes(userId);
  }

  isInactiveUser(userId: string) {
    return this.inactiveUsersIds.includes(userId);
  }

  getUserDescription(userId: string) {
    return this.usersDescriptions.get(userId) || '';
  }

  getUserTrainingInfo(userId: string, trainingId: number) {
    const index = this.usersTrainingsInfo.findIndex((e) => e.userId === userId && e.trainingId === trainingId);
    if (index === -1) return new UserTrainingInfo();
    return this.usersTrainingsInfo[index];
  }
}
