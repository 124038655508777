export type UserAbonnementInfoJson = {
  AbonnementId: string;
  UserId: string;
};

export class UserAbonnementInfo {
  AbonnementId: string = '';
  UserId: string = '';

  public static fromJson(json: UserAbonnementInfoJson) {
    const result = new UserAbonnementInfo();
    result.AbonnementId = json.AbonnementId;
    result.UserId = json.UserId;
    return result;
  }
}
