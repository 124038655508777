import { CaretDownOutlined, SortAscendingOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Space, Table, Divider } from 'antd';
import classNames from 'classnames';
import { isEmpty, sortBy } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FaExclamationTriangle, FaMinusCircle, FaRubleSign } from 'react-icons/fa';
import PageContainer from '../../components/PageContainer';
import UserEditButton from '../../components/User/UserEditButton';
import { UserTrainingStatusIcon } from '../../components/User/UserTrainings/UserTrainingStatusIcon';
import { Training, TrainingStatus } from '../../models/trainings/training';
import { TrainingVisitStatus } from '../../models/trainings/trainingVisitStatus';
import { User } from '../../models/users/user';
import { useStore } from '../../stores/storeProvider';
import { Spacer } from '../../ui-kit';
import { DateUtils, getDayOfWeekShortName } from '../../utils/date';
import { useLocalStore } from './store';
import styles from './styles.module.scss';
import { Toolbar } from './Toolbar/Toolbar';
import { TrainingsCancelDialog } from './TrainingsCancelDialog/TrainingsCancelDialog';
import { TrainingsExportDialog } from './TrainingsExportDialog/TrainingsExportDialog';
import { TrainingsInfoDialog } from './TrainingsInfoDialog/TrainingsInfoDialog';
import { TrainingsTransferDialog } from './TrainingsTransferDialog/TrainingsTransferDialog';
import { TrainingsUnionDialog } from './TrainingsUnionDialog/TrainingsUnionDialog';
import { TrainingsVisitsDialog } from './TrainingsVisitsDialog/TrainingsVisitsDialog';
import { UserPaymentsInfo } from './UserPaymentsInfo/UserPaymentsInfo';

export const TrainingsPage = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  useEffect(() => {
    document.title = 'Торнадо / Тренировки';
    appStore.setCurrentPageTitle('Тренировки');
    store.init();
  }, [appStore, store]);

  const renderTrainingStat = (training: Training) => {
    const stat = store.getTrainigStat(training.id);

    if (!stat) return null;

    return (
      <div className={styles.training_stat}>
        {stat && training.status !== TrainingStatus.Cancel && (
          <Space>
            <div>
              <UserTrainingStatusIcon visitStatus={TrainingVisitStatus.Visit} /> {stat.VisitCount}
            </div>
            <div>
              <UserTrainingStatusIcon visitStatus={TrainingVisitStatus.Test} /> {stat.TestCount}
            </div>
          </Space>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: (
        <Space>
          <div>Ученик</div>
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={() => store.sortUsersByName()}>
                  По имени
                </Menu.Item>

                <Menu.Item key="2" onClick={() => store.sortUsersByStatus()}>
                  По статусу
                </Menu.Item>
              </Menu>
            }
          >
            <SortAscendingOutlined title="сортировка" />
          </Dropdown>
        </Space>
      ),
      render: (_: any, item: User) => {
        return {
          props: {
            className: classNames({
              [styles.test_user]: store.data.isTestUser(item.id),
              [styles.moved_user]: store.data.isMovedUser(item.id),
              [styles.inactive_user]: store.data.isInactiveUser(item.id),
            }),
          },
          children: (
            <>
              {!item.id && <div>Итого: {store.totalInfo.totalCount}</div>}
              {item.id && (
                <div title={`${store.data.isTestUser(item.id) ? 'новый ученик' : ''} ${store.data.getUserDescription(item.id)}`}>
                  <div className={styles.user}>
                    <div>
                      <div className={styles.user_name}>{item.displayName}</div>
                      <div className={styles.user_abonnement}>
                        {store.usersAbonnements
                          .filter((e) => e.UserId === item.id)
                          .map((e) => (
                            <div>{appStore.abonnementsStore.getNameById(e.AbonnementId)}</div>
                          ))}
                      </div>
                    </div>
                    <Spacer />
                    {appStore.account?.isAdminOrManager && <UserEditButton userId={item.id} light />}
                  </div>
                  <div>
                    <Space>
                      {/* <UserNotes user={item} />
                <UserDocument user={user} document={user.contractInfo} />
                <UserDocument user={user} document={user.contractAdditionalInfo} />
                <UserDocument user={user} document={user.healthCertificateInfo} onChange={handleUserDocumentChange} />
                <UserDocument user={user} document={user.insuranceInfo} onChange={handleUserDocumentChange} /> */}
                    </Space>
                  </div>
                </div>
              )}
            </>
          ),
        };
      },
    },
    {
      title: 'Оплата',
      render: (_: any, item: User) => {
        if (!item.id) {
          return (
            <div className={styles.stat}>
              <div title="оплачено / к оплате">
                <span>
                  {store.totalInfo.paymentPaymentSum} / {store.totalInfo.paymentSum}
                </span>
              </div>
              <Space>
                <div title="оплачено полностью">
                  <Space>
                    <FaRubleSign color="green" />
                    <span>{store.totalInfo.paymentFull}</span>
                  </Space>
                </div>
                <div title="оплачено частично">
                  <Space>
                    <FaExclamationTriangle color="orange" />
                    <span>{store.totalInfo.paymentPartial}</span>
                  </Space>
                </div>
                <div title="оплачено полностью">
                  <Space>
                    <FaMinusCircle color="red" />
                    <span>{store.totalInfo.totalCount - store.totalInfo.paymentFull - store.totalInfo.paymentPartial}</span>
                  </Space>
                </div>
              </Space>
            </div>
          );
        }
        return <UserPaymentsInfo userId={item.id} />;
      },
    },
    ...store.trainings.map((training) => ({
      title: (
        <div key={training.id} className={styles.training_header}>
          <Dropdown
            key="regions"
            trigger={['click']}
            overlay={
              <Menu>
                {training.status !== TrainingStatus.Cancel && (
                  <Menu.Item key="1" onClick={() => store.showTrainigVisits(training.id)}>
                    Отметить посещение
                  </Menu.Item>
                )}

                {appStore.account?.isAdminOrManager && training.status !== TrainingStatus.Cancel && (
                  <Divider style={{ margin: '2px 0' }} />
                )}

                {appStore.account?.isAdminOrManager && (
                  <Menu.Item key="2" onClick={() => store.trainingsInfoDialog.showDialog(training, store.groupCoaches)}>
                    Изменить
                  </Menu.Item>
                )}

                {appStore.account?.isAdminOrManager && training.status !== TrainingStatus.Cancel && !training.isTransfer && (
                  <Menu.Item key="3" onClick={() => store.trainingsCancelDialog.showDialog(training.id)}>
                    Отменить
                  </Menu.Item>
                )}

                {appStore.account?.isAdminOrManager && training.status === TrainingStatus.Cancel && (
                  <Menu.Item key="31" onClick={() => store.restoreTrainig(training.id)}>
                    Вернуть
                  </Menu.Item>
                )}

                {appStore.account?.isAdminOrManager && training.status !== TrainingStatus.Cancel && !training.isTransfer && (
                  <Menu.Item key="4" onClick={() => store.trainingsTransferDialog.showDialog(training)}>
                    Перенести
                  </Menu.Item>
                )}

                {appStore.account?.isAdminOrManager && training.status !== TrainingStatus.Cancel && (
                  <Menu.Item
                    key="5"
                    onClick={() =>
                      store.trainingsUnionDialog.showDialog(training.id, training.date, store.getUnionTrainingsIds(training.date))
                    }
                  >
                    Совместная тренировка
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <div className={styles.training_header_menu}>
              <div className={styles.training_date}>
                {training.date.date()} {getDayOfWeekShortName(training.date.isoWeekday())}
              </div>
              <div className={styles.training_time}>
                {training.fromTime} - {training.toTime}
              </div>
              <div className={styles.training_header_drop_indicator}>
                <CaretDownOutlined />
              </div>
            </div>
          </Dropdown>
          <div className={styles.training_place} title={(training.place && training.place.name) ?? ''}>
            {training.place && training.place.name}
          </div>
          <div className={styles.training_coaches} title="Основные тренеры">
            <div>1.&nbsp;</div>
            <div>
              {sortBy(training.coaches, (e) => e.displayName).map((coach) => (
                <div title={coach.displayName}>{coach.displayName.split(' ')[0]}</div>
              ))}
            </div>
            {isEmpty(training.coaches) && (
              <div>
                {sortBy(store.groupCoaches, (e) => e.displayName)
                  .filter((coach) => !coach.isSecondCoach)
                  .map((coach) => (
                    <div title={coach.displayName}>{coach.displayName.split(' ')[0]}</div>
                  ))}
              </div>
            )}
          </div>
          {(training.coachesEx.length > 0 ||
            (isEmpty(training.coaches) && !isEmpty(store.groupCoaches.filter((coach) => coach.isSecondCoach)))) && (
            <div className={styles.training_coaches} title="Вторые тренеры">
              <div>2.&nbsp;</div>
              <div>
                {sortBy(training.coachesEx, (e) => e.displayName).map((coach) => (
                  <div title={coach.displayName}>{coach.displayName.split(' ')[0]}</div>
                ))}
              </div>
              {isEmpty(training.coaches) && (
                <div>
                  {sortBy(store.groupCoaches, (e) => e.displayName)
                    .filter((coach) => coach.isSecondCoach)
                    .map((coach) => (
                      <div title={coach.displayName}>{coach.displayName.split(' ')[0]}</div>
                    ))}
                </div>
              )}
            </div>
          )}
          <div>{store.getUnionTrainingsInfo(training.date)}</div>
          {training.status === TrainingStatus.Cancel && (
            <div className={styles.canceled_training_comment}>
              {!training.isTransfered && <div>отменена</div>}
              {training.isTransfered && <div>перенесена на {DateUtils.format_DDMMYYYY(training.transferTrainigDate)}</div>}
            </div>
          )}
          {training.isTransfer && (
            <div className={styles.transfer_info}>перенесена c {DateUtils.format_DDMMYYYY(training.transferTrainigDate)}</div>
          )}
          {!isEmpty(training.comment) && <div className={styles.comment}>{training.comment}</div>}
        </div>
      ),
      className: classNames(styles.header_training_cell, {
        [styles.canceled_training_cell]: training.status === TrainingStatus.Cancel,
      }),
      render: (_: any, item: User) => {
        const trainingUser = store.data.trainingUsers.find((e) => e.userId === item.id && e.trainingId === training.id);
        const trainingInfo = store.data.getUserTrainingInfo(item.id, training.id);

        if (!item.id) return renderTrainingStat(training);

        return {
          props: {
            className: classNames({
              [styles.canceled_training_cell]: training.status === TrainingStatus.Cancel,
              [styles.inactive_training]: !trainingInfo.isActive,
              [styles.test_training]: trainingInfo.isTestTraining,
            }),
          },
          children: (
            <>
              {trainingUser && (
                <div className={styles.user_training_status}>
                  <UserTrainingStatusIcon visitStatus={trainingUser.visitStatus} />
                </div>
              )}
              {!trainingUser && trainingInfo.isTestTraining && (
                <div className={classNames(styles.user_empty_test_training, styles.user_training_status)}>
                  <UserTrainingStatusIcon visitStatus={TrainingVisitStatus.Test} />
                </div>
              )}
            </>
          ),
        };
      },
    })),
  ];

  return (
    <PageContainer className={styles.page}>
      <Toolbar />

      <Table
        className={styles.trainings_table}
        columns={columns}
        dataSource={store.data.users}
        rowKey="userID"
        bordered
        size="middle"
        loading={store.loading}
        pagination={false}
      />

      <TrainingsExportDialog />
      <TrainingsUnionDialog />
      <TrainingsCancelDialog />
      <TrainingsTransferDialog />
      <TrainingsInfoDialog />
      <TrainingsVisitsDialog />
    </PageContainer>
  );
});
