export const PaymentStatus = {
  Empty: 0,
  NotPayed: 1,
  Success: 2,
  SuccessPayedLess: 3,
  SuccessPayedMore: 4,
  SuccessNothingToPay: 5,
};

export const PaymentStatusInfo = {
  [PaymentStatus.Empty]: {
    title: 'нет данных',
    color: '#37474f',
  },
  [PaymentStatus.NotPayed]: {
    title: 'не оплачено',
    color: '#ef6c00',
  },
  [PaymentStatus.Success]: {
    title: 'оплачено',
    color: '#43a047',
  },
  [PaymentStatus.SuccessNothingToPay]: {
    title: 'нет оплаты',
    color: '#43a047',
  },
  [PaymentStatus.SuccessPayedLess]: {
    title: 'оплачено меньше',
    color: '#afb42b',
  },
  [PaymentStatus.SuccessPayedMore]: {
    title: 'оплачено больше',
    color: '#512da8',
  },
};

export const paymentStatusOptions = Object.keys(PaymentStatusInfo).map((status) => ({
  label: PaymentStatusInfo[Number(status)].title,
  value: Number.parseInt(status, 10),
}));
