import { TrainingVisitStatus } from './trainingVisitStatus';

export type TrainingUserResponse = {
  TrainingId: number;
  UserId: string;
  VisitStatus: TrainingVisitStatus;
};

// Информация об отметке посещения
export class TrainingUser {
  trainingId: number = 0;
  userId: string = '';
  visitStatus: TrainingVisitStatus = TrainingVisitStatus.Skip;

  public static fromResponse(resp: TrainingUserResponse) {
    let result = new TrainingUser();
    result.trainingId = resp.TrainingId;
    result.userId = resp.UserId;
    result.visitStatus = resp.VisitStatus;
    return result;
  }
}
