import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Input, Col, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import FormItem from '../../../../components/FormItem';
import { useLocalStore } from '../store';
import { useStore } from '../../../../stores/storeProvider';
import { action } from 'mobx';

export const EditDiscountDialog = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  const handleSave = action(() => {
    if (isEmpty(store.editingDiscount.Name)) {
      message.warning('Не задано наименование', 3);
      return;
    }
    store.saveEditDiscount();
  });

  const handleCancel = () => {
    store.setIsEditDialogOpen(false);
  };

  return (
    <div>
      <Modal
        visible={store.isEditDialogOpen}
        title={isEmpty(store.editingDiscount.ID) ? 'Добавление скидки' : 'Изменение скидки'}
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleSave} type="primary">
            Сохранить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <FormItem label="Наименование">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editingDiscount.Name}
                onChange={action((e) => (store.editingDiscount.Name = e.target.value))}
              />
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <FormItem label="Описание">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editingDiscount.Description}
                onChange={action((e) => (store.editingDiscount.Description = e.target.value))}
              />
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <FormItem label="Сумма">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editingDiscount.Sum || ''}
                onChange={action((e) => (store.editingDiscount.Sum = isEmpty(e.target.value) ? null : Number(e.target.value)))}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Процент">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editingDiscount.Percent || ''}
                onChange={action(
                  (e) => (store.editingDiscount.Percent = isEmpty(e.target.value) ? null : Number(e.target.value))
                )}
              />
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Checkbox
              checked={store.editingDiscount.IsActive}
              onChange={action((e) => (store.editingDiscount.IsActive = e.target.checked))}
            >
              активен
            </Checkbox>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
