import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, Row, Col, Select } from 'antd';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/storeProvider';
import { useLocalStore } from '../store';
import FormItem from '../../../components/FormItem';
import { DateUtils } from '../../../utils/date';
// import styles from './TrainingsUnionDialog.module.scss';

export const TrainingsUnionDialog = observer(() => {
  const { appStore, groupStore } = useStore();
  const store = useLocalStore(appStore);

  const handleCancel = () => {
    store.trainingsUnionDialog.hideDialog();
  };

  return (
    <div>
      <Modal
        visible={store.trainingsUnionDialog.isOpen}
        title="Совместная тренировка"
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={() => store.saveUnionTrainings()} type="primary">
            Применить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <FormItem
              label={`Группы, тренирующиеся совместно с ${groupStore.getGroupNameById(
                store.params.groupId
              )} ${DateUtils.format_DDMMYYYY(store.trainingsUnionDialog.trainingDate)}`}
            >
              <Select
                style={{ width: '100%' }}
                dropdownMatchSelectWidth={false}
                listHeight={500}
                onChange={(values) => store.trainingsUnionDialog.setUnionGroups(values)}
                mode="multiple"
                value={store.trainingsUnionDialog.unionGroupsIds}
              >
                {groupStore.activeGroups
                  .filter((e) => e.id !== store.params.groupId)
                  .map((group) => (
                    <Select.Option key={group.id} value={group.id}>
                      {group.name}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
