import { EditRegionDialogData } from './EditRegionDialog/EditRegionDialogData';
import { Region } from './../../../models/region';
import { RegionsApi } from './../../../api/regions';
import { action, observable, runInAction } from 'mobx';
import { AppStore } from '../../../stores/AppStore';

export class RegionsPageStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  regions: Region[] = [];

  editRegionDialogData = new EditRegionDialogData();

  async loadRegions() {
    try {
      const regions = await RegionsApi.fetchRegions();
      runInAction(() => {
        this.regions = regions;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async saveRegion(region: Region) {
    try {
      this.editRegionDialogData.setSaving(true);
      await RegionsApi.saveRegion(region);
      await this.loadRegions();
      this.editRegionDialogData.hideDialog();
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async deleteRegion(region: Region) {
    try {
      await RegionsApi.deleteRegion(region);
      await this.loadRegions();
      this.editRegionDialogData.hideDialog();
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }
}

let localStore: RegionsPageStore | null = null;

export const useLocalStore = (appStore: AppStore) => {
  if (localStore === null) {
    localStore = new RegionsPageStore(appStore);
  }
  return localStore;
};
