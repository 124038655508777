import { isEmpty } from 'lodash';
import { observable } from 'mobx';

export type StaffUserJson = {
  ID: string;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  RoleNames: string[];

  PhoneNumber: string;
  Login: string;
  Password: string;
  BirthDay: string;
  GroupIds: number[];
  CoachExGroupIds: number[];
  IsActive: boolean;
  IsCoach: boolean;
  IsManager: boolean;
  IsAdmin: boolean;
};

export class StaffUser {
  id: string = '';
  displayName: string = '';
  @observable
  firstName: string = '';
  @observable
  lastName: string = '';
  @observable
  roleNames: string[] = [];
  @observable
  PhoneNumber: string = '';
  @observable
  Login: string = '';
  @observable
  Password: string = '';
  @observable
  BirthDay: string = '';
  @observable
  GroupIds: number[] = [];
  @observable
  CoachExGroupIds: number[] = [];
  @observable
  IsActive: boolean = true;
  IsCoach: boolean = false;
  IsManager: boolean = false;
  IsAdmin: boolean = false;

  get isNewUser() {
    return isEmpty(this.id);
  }

  public static fromJson(json: StaffUserJson) {
    const result = new StaffUser();
    result.id = json.ID;
    result.displayName = json.DisplayName;
    result.firstName = json.FirstName;
    result.lastName = json.LastName;
    result.roleNames = json.RoleNames;
    result.PhoneNumber = json.PhoneNumber;
    result.Login = json.Login;
    result.Password = json.Password;
    result.BirthDay = json.BirthDay;
    result.GroupIds = json.GroupIds;
    result.CoachExGroupIds = json.CoachExGroupIds;
    result.IsActive = json.IsActive;
    result.IsCoach = json.IsCoach;
    result.IsManager = json.IsManager;
    result.IsAdmin = json.IsAdmin;
    return result;
  }

  public toJson() {
    const json: StaffUserJson = {
      ID: this.id,
      DisplayName: this.displayName,
      FirstName: this.firstName,
      LastName: this.lastName,
      RoleNames: this.roleNames,
      PhoneNumber: this.PhoneNumber,
      Login: this.Login,
      Password: this.Password,
      BirthDay: this.BirthDay,
      GroupIds: this.GroupIds,
      CoachExGroupIds: this.CoachExGroupIds,
      IsActive: this.IsActive,
      IsCoach: this.IsCoach,
      IsManager: this.IsManager,
      IsAdmin: this.IsAdmin,
    };
    return json;
  }
}
