import { StaffUser } from './../models/staffUser';
import { UserRoleName } from './../../../../models/userRole';
import { observable, action, computed } from 'mobx';
import { cloneDeep } from 'lodash';

export class EditStaffUserDialogData {
  @observable
  isOpen: boolean = false;

  @observable
  saving: boolean = false;

  @observable
  user: StaffUser = new StaffUser();

  @observable
  isMainManager: boolean = false;

  @computed
  get hasPlaceRentSum() {
    return this.user.roleNames.includes(UserRoleName.PlaceRentSum);
  }

  hasRole(roleName: string) {
    return this.user.roleNames.includes(roleName);
  }

  @action
  showDialog(user: StaffUser) {
    this.isOpen = true;
    this.user = cloneDeep(user);
    this.isMainManager = user.roleNames.includes(UserRoleName.MainManager);
  }

  @action
  hideDialog() {
    this.isOpen = false;
    this.saving = false;
  }

  @action
  setSaving(saving: boolean) {
    this.saving = saving;
  }

  @action
  setMainManager(isMainManager: boolean) {
    this.isMainManager = isMainManager;
    if (isMainManager && !this.user.roleNames.includes(UserRoleName.MainManager))
      this.user.roleNames.push(UserRoleName.MainManager);
    if (!isMainManager && this.user.roleNames.includes(UserRoleName.MainManager))
      this.user.roleNames = this.user.roleNames.filter((name) => name !== UserRoleName.MainManager);
  }

  @action
  setRole(roleName: string, enabled: boolean) {
    if (enabled && !this.user.roleNames.includes(roleName)) this.user.roleNames.push(roleName);
    if (!enabled && this.user.roleNames.includes(roleName))
      this.user.roleNames = this.user.roleNames.filter((name) => name !== roleName);
  }
}
