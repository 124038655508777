import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popconfirm, Space, Table, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import PageContainer from '../../../components/PageContainer';
import { Discount } from '../../../models/discount';
import { useStore } from '../../../stores/storeProvider';
import { EditDiscountDialog } from './EditDiscountDialog/EditDiscountDialog';
import { useLocalStore } from './store';
import styles from './styles.module.scss';

export const DiscountsSettingsPage = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  useEffect(() => {
    document.title = 'Торнадо / Настройки / Скидки';
    appStore.setCurrentPageTitle('Настройки / Скидки');
  }, [appStore]);

  const handleAdd = () => {
    store.editDiscount(new Discount());
  };

  const [columns] = useState([
    {
      title: 'Наименование',
      render: (_: any, item: Discount) => {
        return <div>{item.Name}</div>;
      },
    },
    {
      title: 'Описание',
      render: (_: any, item: Discount) => {
        return <div>{item.Description}</div>;
      },
    },
    {
      title: 'Сумма',
      render: (_: any, item: Discount) => {
        return <div>{item.Sum}</div>;
      },
    },
    {
      title: 'Процент',
      render: (_: any, item: Discount) => {
        return <div>{item.Percent}</div>;
      },
    },
    {
      title: 'Статус',
      render: (_: any, item: Discount) => {
        return (
          <div>
            <Checkbox checked={item.IsActive}>активный</Checkbox>
          </div>
        );
      },
    },
    {
      title: 'Действия',
      render: (_: any, item: Discount) => {
        return (
          <Space>
            <Tooltip title="изменить" placement="bottom">
              <Button shape="circle" icon={<EditOutlined />} onClick={(e) => store.editDiscount(item)} />
            </Tooltip>
            <Popconfirm
              title="Вы действительно хотите удалить скидку？"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => appStore.discountsStore.deleteDiscount(item.ID)}
            >
              <Tooltip title="удалить" placement="bottom">
                <Button shape="circle" icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
    },
  ]);

  return (
    <PageContainer className={styles.page}>
      <div>
        <Button onClick={handleAdd}>Добавить скидку</Button>
      </div>

      {!isEmpty(appStore.discountsStore.discounts) && (
        <Table className={styles.table} columns={columns} dataSource={appStore.discountsStore.discounts} pagination={false} />
      )}

      <EditDiscountDialog />
    </PageContainer>
  );
});
