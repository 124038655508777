import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Col, Input } from 'antd';
import { observer } from 'mobx-react';
import { UserEditDialogState } from '../UserEditDialogState';
import { action } from 'mobx';
import { clearPhoneNumber } from '../../../../models/user';
import FormItem from '../../../FormItem';

export interface IUserEditChangeLoginDialogProps {
  editDialogState: UserEditDialogState;
  isOpen: boolean;
  onClose: () => void;
}

export const UserEditChangeLoginDialog: React.FC<IUserEditChangeLoginDialogProps> = observer(
  ({ editDialogState, isOpen, onClose }) => {
    const [login, setLogin] = useState('');

    useEffect(() => {
      if (isOpen) {
        setLogin(editDialogState.userModel.customLogin || clearPhoneNumber(editDialogState.userModel.PhoneNumber));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleApply = action(() => {
      if (!login) {
        message.warning('Введите новый логин', 3);
        return;
      }

      editDialogState.userModel.customLogin = login;

      onClose();
    });

    const handleCancel = () => {
      onClose();
    };

    return (
      <div>
        <Modal
          visible={isOpen}
          title="Изменение логина"
          onCancel={handleCancel}
          width={300}
          footer={[
            <Button key="apply" onClick={handleApply} type="primary">
              Применить
            </Button>,
            <Button key="cancel" onClick={handleCancel}>
              Закрыть
            </Button>,
          ]}
        >
          <Row>
            <Col span={24}>
              <FormItem label="Новый логин">
                <Input
                  style={{ width: '100%' }}
                  value={login}
                  onChange={(e) => {
                    setLogin(e.target.value);
                  }}
                ></Input>
              </FormItem>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
);
