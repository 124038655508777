import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Input, Col } from 'antd';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import FormItem from '../../../components/FormItem';

export interface IAddSourceGroupDialogProps {
  isOpen: boolean;
  onAdd: (groupTitle: string) => void;
  onClose: () => void;
}

export const AddSourceGroupDialog: React.FC<IAddSourceGroupDialogProps> = observer(({ isOpen, onAdd, onClose }) => {
  const [groupTitle, setGroupTitle] = useState('');

  const handleAdd = () => {
    if (isEmpty(groupTitle)) {
      message.warning('Не задано имя группы', 3);
      return;
    }

    onAdd(groupTitle);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        visible={isOpen}
        title="Добавление группы источников"
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleAdd} type="primary">
            Добавить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <FormItem label="Имя группы">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={groupTitle}
                onChange={(e) => setGroupTitle(e.target.value)}
              />
            </FormItem>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
