import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, Row, Col, Input, Select } from 'antd';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/storeProvider';
import { useLocalStore } from '../store';
import FormItem from '../../../components/FormItem';
// import styles from './TrainingsInfoDialog.module.scss';

export const TrainingsInfoDialog = observer(() => {
  const { appStore, placesStore } = useStore();
  const store = useLocalStore(appStore);

  const handleCancel = () => {
    store.trainingsInfoDialog.hideDialog();
  };

  return (
    <div>
      <Modal
        visible={store.trainingsInfoDialog.isOpen}
        title="Изменение тренировки"
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={() => store.saveTrainigInfo()} type="primary">
            Применить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <FormItem label="Тренеры">
              <Select
                style={{ width: '100%' }}
                dropdownMatchSelectWidth={false}
                listHeight={500}
                mode="multiple"
                onChange={(value) => store.trainingsInfoDialog.setCoachesIds(value)}
                value={store.trainingsInfoDialog.coachesIds}
              >
                {store.coaches.map((coach) => (
                  <Select.Option key={coach.id} value={coach.id}>
                    {coach.displayName}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <FormItem label="Вторые тренеры">
              <Select
                style={{ width: '100%' }}
                dropdownMatchSelectWidth={false}
                listHeight={500}
                mode="multiple"
                onChange={(value) => store.trainingsInfoDialog.setCoachesExIds(value)}
                value={store.trainingsInfoDialog.coachesExIds}
              >
                {store.coaches.map((coach) => (
                  <Select.Option key={coach.id} value={coach.id}>
                    {coach.displayName}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <FormItem label="Место проведения">
              <Select
                style={{ width: '100%' }}
                dropdownMatchSelectWidth={false}
                listHeight={500}
                onChange={(value) => store.trainingsInfoDialog.setPlaceId(value)}
                value={store.trainingsInfoDialog.placeId}
              >
                {placesStore.activePlaces.map((place) => (
                  <Select.Option key={place.id} value={place.id}>
                    {place.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label="Комментарий">
              <Input.TextArea
                rows={4}
                value={store.trainingsInfoDialog.comment}
                onChange={(e) => store.trainingsInfoDialog.setComment(e.target.value)}
              />
            </FormItem>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
