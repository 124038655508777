import { observable, action } from 'mobx';

export class TrainingsCanceDialogData {
  @observable
  isOpen: boolean = false;

  @observable
  comment: string = '';

  @observable
  trainingId: number = -1;

  @action
  showDialog(trainingId: number) {
    this.isOpen = true;
    this.trainingId = trainingId;
    this.comment = '';
  }

  @action
  hideDialog() {
    this.isOpen = false;
  }

  @action
  setComment(comment: string) {
    this.comment = comment;
  }
}
