import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Input, Col, Checkbox, Select } from 'antd';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import FormItem from '../../../../components/FormItem';
import { useLocalStore } from '../store';
import { useStore } from '../../../../stores/storeProvider';
import { action, runInAction } from 'mobx';
import { AbonnementRecalcType } from '../../../../models/abonnement';

export const EditAbonnementDialog = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  const handleSave = action(() => {
    if (isEmpty(store.editingAbonnement.Name)) {
      message.warning('Не задано наименование', 3);
      return;
    }
    store.saveEditAbonnement();
  });

  const handleCancel = () => {
    store.setIsEditDialogOpen(false);
  };

  return (
    <div>
      <Modal
        visible={store.isEditDialogOpen}
        title={isEmpty(store.editingAbonnement.ID) ? 'Добавление абонемента' : 'Изменение абонемента'}
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleSave} type="primary">
            Сохранить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <FormItem label="Наименование">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editingAbonnement.Name}
                onChange={action((e) => (store.editingAbonnement.Name = e.target.value))}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={14}>
            <FormItem label="Стоимость">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editingAbonnement.Sum}
                onChange={action((e) => (store.editingAbonnement.Sum = Number(e.target.value)))}
              />
            </FormItem>
          </Col>
          <Col span={10}>
            <FormItem label="Тренировок">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editingAbonnement.TrainingsInMonthCount}
                onChange={action((e) => (store.editingAbonnement.TrainingsInMonthCount = Number(e.target.value)))}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={14}>
            <FormItem label="Тип перерасчёта">
              <Select
                style={{ width: '100%' }}
                onChange={(e) => runInAction(() => (store.editingAbonnement.RecalcType = e))}
                value={store.editingAbonnement.RecalcType}
              >
                <Select.Option value={AbonnementRecalcType.None}>Нет</Select.Option>
                <Select.Option value={AbonnementRecalcType.Fix}>Фиксированный</Select.Option>
                <Select.Option value={AbonnementRecalcType.Var}>Переменный</Select.Option>
              </Select>
            </FormItem>
          </Col>
          {store.editingAbonnement.RecalcType === AbonnementRecalcType.Fix && (
            <Col span={10}>
              <FormItem label="Сумма фиксированного перерасчёта">
                <Input
                  style={{ width: '100%' }}
                  placeholder=""
                  value={store.editingAbonnement.RecalcFixSum || ''}
                  onChange={action((e) => (store.editingAbonnement.RecalcFixSum = Number(e.target.value)))}
                />
              </FormItem>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Checkbox
              checked={store.editingAbonnement.IsActive}
              onChange={action((e) => (store.editingAbonnement.IsActive = e.target.checked))}
            >
              активен
            </Checkbox>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
