import { observable } from 'mobx';
export enum AbonnementRecalcType {
  None = 0,
  Fix = 1,
  Var = 2,
}

export type AbonnementResponse = {
  ID: string;
  Name: string;
  IsActive: boolean;
  Description: string;
  Sum: number;
  RecalcType: AbonnementRecalcType;
  RecalcFixSum: number | null;
  TrainingsInMonthCount: number;
};

export class Abonnement {
  ID: string = '';

  @observable
  Name: string = '';

  @observable
  IsActive: boolean = true;

  @observable
  Description: string = '';

  @observable
  Sum: number = 0;

  @observable
  RecalcType: AbonnementRecalcType = AbonnementRecalcType.None;

  @observable
  RecalcFixSum: number | null = null;

  @observable
  TrainingsInMonthCount: number = 0;

  recalcTitle() {
    if (this.RecalcType === AbonnementRecalcType.Fix) return 'фиксированный';
    if (this.RecalcType === AbonnementRecalcType.Var) return 'переменный';
    return 'нет';
  }

  public static fromResponse(resp: AbonnementResponse) {
    const result = new Abonnement();
    result.ID = resp.ID;
    result.Name = resp.Name;
    result.IsActive = resp.IsActive;
    result.Description = resp.Description;
    result.Sum = resp.Sum;
    result.RecalcType = resp.RecalcType;
    result.RecalcFixSum = resp.RecalcFixSum;
    result.TrainingsInMonthCount = resp.TrainingsInMonthCount;
    return result;
  }
}

export type AbonnementSaveModel = {
  ID: string;
  Name: string;
  IsActive: boolean;
  Description: string;
  Sum: number;
  RecalcType: AbonnementRecalcType;
  RecalcFixSum: number | null;
  TrainingsInMonthCount: number;
};

export type UserAbonnement = {
  ID: string;
  Abonnement: AbonnementResponse;
  DateFrom: string;
  DateTo: string | null;
};
