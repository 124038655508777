import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/storeProvider';
import { notification } from 'antd';
import './AppCore.scss';

export const AppCore: React.FC = observer(({ children }) => {
  const { appStore } = useStore();

  useEffect(() => {
    appStore.init();
  }, [appStore]);

  useEffect(() => {
    if (appStore.errorMessage) {
      notification.error({
        message: 'Ошибка',
        description: appStore.errorMessage,
        duration: 0,
        className: 'Notification-Error',
      });
      appStore.clearErrorMessage();
    }
  }, [appStore, appStore.errorMessage]);

  useEffect(() => {
    if (appStore.errorMessage) {
      notification.error({
        message: 'Внимание',
        description: appStore.notificationMessage,
        duration: 0,
      });
      appStore.clearNotificationMessage();
    }
  }, [appStore, appStore.notificationMessage]);

  return <>{children}</>;
});
