import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popconfirm, Space, Table, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import PageContainer from '../../../components/PageContainer';
import { AdditionalPayment } from '../../../models/payments/additionalPayment';
import { useStore } from '../../../stores/storeProvider';
import { EditPaymentDialog } from './EditPaymetDialog/EditPaymentDialog';
import { useLocalStore } from './store';
import styles from './styles.module.scss';

export const PaymentsSettingsPage = observer(() => {
  const { appStore, paymentsStore } = useStore();
  const store = useLocalStore(appStore);

  useEffect(() => {
    document.title = 'Торнадо / Настройки / Оплаты';
    appStore.setCurrentPageTitle('Настройки / Оплаты');
    paymentsStore.loadAdditionalPayments();
  }, [appStore, paymentsStore]);

  const handleAdd = () => {
    store.editPayment(new AdditionalPayment());
  };

  const [columns] = useState([
    {
      title: 'Наименование',
      render: (_: any, item: AdditionalPayment) => {
        return <div>{item.title}</div>;
      },
    },
    {
      title: 'Сумма',
      render: (_: any, item: AdditionalPayment) => {
        return <div>{item.sum}</div>;
      },
    },
    {
      title: 'Статус',
      render: (_: any, item: AdditionalPayment) => {
        return (
          <div>
            <Checkbox checked={item.isActive}>активная</Checkbox>
          </div>
        );
      },
    },
    {
      title: 'Эквайринг',
      render: (_: any, item: AdditionalPayment) => {
        return (
          <div>
            <Checkbox checked={item.acquiring}>эквайринг</Checkbox>
          </div>
        );
      },
    },
    {
      title: 'Действия',
      render: (_: any, item: AdditionalPayment) => {
        return (
          <Space>
            <Tooltip title="изменить" placement="bottom">
              <Button shape="circle" icon={<EditOutlined />} onClick={(e) => store.editPayment(item)} />
            </Tooltip>
            <Popconfirm
              title="Вы действительно хотите удалить оплату？"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => store.deletePayment(item.id)}
            >
              <Tooltip title="удалить" placement="bottom">
                <Button shape="circle" icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
    },
  ]);

  return (
    <PageContainer className={styles.page}>
      <div>
        <Button onClick={handleAdd}>Добавить оплату</Button>
      </div>

      {!isEmpty(paymentsStore.additionalPayments) && (
        <Table className={styles.table} columns={columns} dataSource={paymentsStore.additionalPayments} pagination={false} />
      )}

      <EditPaymentDialog />
    </PageContainer>
  );
});
