import { observable, action } from 'mobx';
import moment from 'moment';

export class TrainingsUnionDialogData {
  @observable
  isOpen: boolean = false;

  @observable
  unionGroupsIds: number[] = [];

  @observable
  trainingId: number = -1;

  @observable
  trainingDate: moment.Moment = moment();

  @action
  showDialog(trainingId: number, trainingDate: moment.Moment, unionGroupsIds: number[]) {
    this.isOpen = true;
    this.trainingId = trainingId;
    this.trainingDate = trainingDate;
    this.unionGroupsIds = unionGroupsIds;
  }

  @action
  hideDialog() {
    this.isOpen = false;
  }

  @action
  setUnionGroups(groupIds: number[]) {
    this.unionGroupsIds = groupIds;
  }
}
