import { TrainingsExportParams, TrainingsExportParamsResponse } from './models/trainingsExportParams';
import { TrainingsData } from './models/trainingsData';
import axios from 'axios';
import { TrainingsPageParams } from './models/trainingsPageParams';
import { download } from '../../api/common';
import { UnionTraining, UnionTrainingResponse } from './models/unionTraining';
import { TrainingsTotalInfo } from './models/trainingsTotalInfo';
import { TrainingGroups } from './models/trainingGroup';

export const TrainingsPageApi = {
  fetchData: async (params: TrainingsPageParams) => {
    const response = await axios.get('/admin/TrainingsData', { params: params });
    return TrainingsData.fromResponse(response.data);
  },
  fetchGroups: async (params: TrainingsPageParams) => {
    const response = await axios.get('/admin/GetTrainingsModelParams', { params: params });
    return TrainingGroups.fromResponse(response.data);
  },
  prepareTrainingsReport: async (params: TrainingsPageParams, groupIds: number[], exportParams: TrainingsExportParams) => {
    await download('/admin/ExportTrainingsToExcel', {
      year: params.year,
      month: params.month,
      groups: groupIds,
      exportParams: exportParams,
    });
  },
  fetchTrainingsExportParams: async () => {
    const response = await axios.get<TrainingsExportParamsResponse>('/trainings/GetTrainingsExcelExportParams');
    return TrainingsExportParams.fromResponse(response.data);
  },
  fetchUnionTrainings: async (groupId: number, year: number, month: number) => {
    const response = await axios.get<UnionTrainingResponse[]>('/api/trainings/GetUnionTrainings', {
      params: { groupId, year, month },
    });
    return response.data.map((e) => UnionTraining.fromResponse(e));
  },
  setUnionTrainings: async (groupId: number, date: moment.Moment, groupIds: number[]) => {
    await axios.post('/api/trainings/SetUnionTrainings', {
      groupId,
      date,
      groupIds,
    });
  },

  fetchTrainingsTotalInfo: async (groupID: number, year: number, month: number) => {
    const response = await axios.get<TrainingsTotalInfo>('/admin/GetTrainingsTotalInfo', {
      params: {
        groupID,
        year,
        month,
      },
    });
    return response.data;
  },
};
