import { LoadingOutlined } from '@ant-design/icons';
import { DatePicker, Modal, Space, Spin, Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { ReportsApi } from '../../../api/reports';
import moment from 'moment';
import styles from '../styles.module.scss';

export const PaymentOperationsReport = observer(() => {
  const [loading, setLoading] = useState(false);
  const [isParamsDialogVisible, setIsParamsDialogVisible] = useState(false);
  const [dateFrom, setDateFrom] = useState<moment.Moment | null>(moment());
  const [dateTo, setDateTo] = useState<moment.Moment | null>(moment());

  const handleDateFromChange = (date: moment.Moment | null) => {
    setDateFrom(date);
  };

  const handleDateToChange = (date: moment.Moment | null) => {
    setDateTo(date);
  };

  const handleReportClick = () => {
    setIsParamsDialogVisible(true);
  };

  const handlePrepare = () => {
    if (loading || !dateFrom || !dateTo) {
      return;
    }
    setIsParamsDialogVisible(false);
    setLoading(true);
    ReportsApi.preparePaymentOperationsReport(dateFrom.toISOString(), dateTo.toISOString()).finally(() => {
      setLoading(false);
    });
  };

  const handleCancel = () => {
    setLoading(false);
    setIsParamsDialogVisible(false);
  };

  const setPeriodToday = () => {
    setDateFrom(moment());
    setDateTo(moment());
  };

  const setPeriodYesterday = () => {
    setDateFrom(moment().subtract(1, 'days'));
    setDateTo(moment().subtract(1, 'days'));
  };

  const setPeriodCurMonth = () => {
    setDateFrom(moment().startOf('month'));
    setDateTo(moment().endOf('month'));
  };

  return (
    <>
      <div className={styles.report_card} onClick={handleReportClick}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loading}>
          <div className={styles.report_card_title}>Операции по платежам</div>
          <div className={styles.report_card_desc}>Выгрузка в Excel операций по платежам за период</div>
        </Spin>
      </div>

      <Modal title="Операции по платежам" visible={isParamsDialogVisible} onOk={handlePrepare} onCancel={handleCancel}>
        <div className={styles.modal_label}>Выберите период отчёта</div>

        <div className={styles.modal_section}>
          <Space>
            <span>c</span> <DatePicker value={dateFrom} onChange={handleDateFromChange} /> <span>по</span>{' '}
            <DatePicker value={dateTo} onChange={handleDateToChange} />
          </Space>
        </div>

        <div className={styles.modal_section}>
          <Space>
            <Tag onClick={setPeriodToday}>за сегодня</Tag>
            <Tag onClick={setPeriodYesterday}>за вчера</Tag>
            <Tag onClick={setPeriodCurMonth}>за этот месяц</Tag>
          </Space>
        </div>
      </Modal>
    </>
  );
});
