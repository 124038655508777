import { UsersApi } from '../api/user';
import { UserSourceGroup } from '../models/userSource';
import { observable, action, runInAction } from 'mobx';
import { AppStore } from './AppStore';
import { isEmpty } from 'lodash';

export class SourcesStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  sourceGroups: UserSourceGroup[] = [];

  isSourceExists(sourceTitle: string) {
    return (
      !isEmpty(sourceTitle) &&
      this.sourceGroups.some((sourceGroup) =>
        sourceGroup.Sources.some((source) => source.Title.toLowerCase() === sourceTitle.toLowerCase())
      )
    );
  }

  @action
  async loadSources(force: boolean = false) {
    try {
      if (!isEmpty(this.sourceGroups) && !force) {
        return;
      }

      const sourceGroups = await UsersApi.fetchSourceGroups();

      runInAction(() => {
        this.sourceGroups = sourceGroups;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  addSourceGroup(groupTitle: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await UsersApi.addUserSourceGroup(groupTitle);

        runInAction(() => {
          this.loadSources(true).then(() => {
            resolve();
          });
        });
      } catch (error) {
        this.appStore.setErrorMessage((error as Error).message);
        reject();
      }
    });
  }

  @action
  renameSourceGroup(oldGroupTitle: string, newGroupTitle: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await UsersApi.renameUserSourceGroup(oldGroupTitle, newGroupTitle);

        runInAction(() => {
          this.loadSources(true).then(() => {
            resolve();
          });
        });
      } catch (error) {
        this.appStore.setErrorMessage((error as Error).message);
        reject();
      }
    });
  }

  @action
  deleteSourceGroup(groupTitle: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await UsersApi.deleteUserSourceGroup(groupTitle);

        runInAction(() => {
          this.loadSources(true).then(() => {
            resolve();
          });
        });
      } catch (error) {
        this.appStore.setErrorMessage((error as Error).message);
        reject();
      }
    });
  }

  @action
  deleteSource(sourceTitle: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await UsersApi.deleteUserSource(sourceTitle);

        runInAction(() => {
          this.loadSources(true).then(() => {
            resolve();
          });
        });
      } catch (error) {
        this.appStore.setErrorMessage((error as Error).message);
        reject();
      }
    });
  }

  @action
  renameSource(oldTitle: string, newTitle: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await UsersApi.renameUserSource(oldTitle, newTitle);

        runInAction(() => {
          this.loadSources(true).then(() => {
            resolve();
          });
        });
      } catch (error) {
        this.appStore.setErrorMessage((error as Error).message);
        reject();
      }
    });
  }

  @action
  addSource(groupTitle: string, sourceTitle: string) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await UsersApi.addUserSource(groupTitle, sourceTitle);

        runInAction(() => {
          this.loadSources(true).then(() => {
            resolve();
          });
        });
      } catch (error) {
        this.appStore.setErrorMessage((error as Error).message);
        reject();
      }
    });
  }
}
