import { AbonnementsApi } from './../../../api/abonnements';
import { cloneDeep } from 'lodash';
import { action, observable } from 'mobx';
import { AppStore } from '../../../stores/AppStore';
import { Abonnement } from '../../../models/abonnement';

export class SettingsAbonnementsPageStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  isEditDialogOpen: boolean = false;

  @observable
  editingAbonnement: Abonnement = new Abonnement();

  @action
  setIsEditDialogOpen(isOpen: boolean) {
    this.isEditDialogOpen = isOpen;
  }

  @action
  editAbonnement(abonnement: Abonnement) {
    this.editingAbonnement = cloneDeep(abonnement);
    this.setIsEditDialogOpen(true);
  }

  @action
  async saveEditAbonnement() {
    try {
      await AbonnementsApi.saveAbonnement({
        ID: this.editingAbonnement.ID,
        Name: this.editingAbonnement.Name,
        IsActive: this.editingAbonnement.IsActive,
        Description: this.editingAbonnement.Description,
        Sum: this.editingAbonnement.Sum,
        RecalcType: this.editingAbonnement.RecalcType,
        RecalcFixSum: this.editingAbonnement.RecalcFixSum,
        TrainingsInMonthCount: this.editingAbonnement.TrainingsInMonthCount,
      });
      this.setIsEditDialogOpen(false);
      await this.appStore.abonnementsStore.loadAbonnements(true);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }
}

let localStore: SettingsAbonnementsPageStore | null = null;

export const useLocalStore = (appStore: AppStore) => {
  if (localStore === null) {
    localStore = new SettingsAbonnementsPageStore(appStore);
  }
  return localStore;
};
