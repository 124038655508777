import { PlaceRentSaveModel } from './../../../models/places/placeRentSaveModel';
import { PlacesApi } from './../../../api/places';
import { cloneDeep, isEmpty } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import { AppStore } from '../../../stores/AppStore';
import { Place } from '../../../models/places/place';
import { PlaceRent } from '../../../models/places/placeRent';
import { UserRoleName } from '../../../models/userRole';

export class SettingsPlacesPageStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  isEditDialogOpen: boolean = false;

  @observable
  isRentHistoryOpen: boolean = false;

  @observable
  saving: boolean = false;

  @observable
  editingPlace: Place = new Place();

  @observable
  editingPlaceRent: PlaceRent = new PlaceRent();

  @observable
  places: Place[] = [];

  @observable
  placesRents: PlaceRent[] = [];

  @action
  setIsEditDialogOpen(isOpen: boolean) {
    this.isEditDialogOpen = isOpen;
  }

  @action
  editPlace(place: Place) {
    this.editingPlace = cloneDeep(place);
    const placeRents = this.placesRents.filter((e) => e.placeId === place.id);
    if (!isEmpty(placeRents)) {
      this.editingPlaceRent = cloneDeep(placeRents[0]);
    } else {
      this.editingPlaceRent = new PlaceRent();
      this.editingPlaceRent.placeId = this.editingPlace.id;
    }
    this.setIsEditDialogOpen(true);
  }

  @action
  deletePlaceRent(rent: PlaceRent) {
    this.placesRents = this.placesRents.filter((e) => e !== rent);
  }

  get canViewRents() {
    return (
      this.appStore.account?.isAdmin ||
      this.appStore.account?.hasRole(UserRoleName.MainManager) ||
      this.appStore.account?.hasRole(UserRoleName.PlaceRentSum)
    );
  }

  @action
  async refresh() {
    try {
      const places = await PlacesApi.fetchAllPlaces();
      if (this.canViewRents) {
        const placesRents = await PlacesApi.fetchAllPlacesRents();
        for (const place of places) {
          if (place.isRentSumForTraining) {
            place.rentInfo = 'за тренировку';
          } else {
            const placeRents = placesRents.filter((e) => e.placeId === place.id);
            if (!isEmpty(placeRents) && !place.isRentSumForTraining) {
              const placeActiveRent = placeRents[0];
              place.rentInfo = !placeActiveRent.sum ? 'нет' : placeActiveRent.sum.toString();
            } else {
              place.rentInfo = 'нет';
            }
          }
        }
        runInAction(() => {
          this.placesRents = placesRents;
        });
      }

      runInAction(() => {
        this.places = places;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async deletePlace(id: number) {
    try {
      await PlacesApi.deletePlace(id);
      await this.refresh();
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async saveEditPlace() {
    const $this = this;
    try {
      this.saving = true;
      const placeId = await PlacesApi.savePlace({
        ID: this.editingPlace.id,
        Name: this.editingPlace.name,
        IsActive: this.editingPlace.isActive,
        GeoCoords: this.editingPlace.geoCoords,
        Address: this.editingPlace.address,
        IsRentSumForTraining: this.editingPlace.isRentSumForTraining,
      });
      if (!this.editingPlace.isRentSumForTraining) {
        await PlacesApi.savePlaceRents(
          placeId,
          PlaceRentSaveModel.fromPlaceRent(this.editingPlaceRent),
          this.placesRents.filter((e) => e.placeId === this.editingPlace.id).map((e) => PlaceRentSaveModel.fromPlaceRent(e))
        );
      }
      await this.refresh();
      this.setIsEditDialogOpen(false);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        $this.saving = false;
      });
    }
  }
}

let localStore: SettingsPlacesPageStore | null = null;

export const useLocalStore = (appStore: AppStore) => {
  if (localStore === null) {
    localStore = new SettingsPlacesPageStore(appStore);
  }
  return localStore;
};
