import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { FaCommentAlt, FaRubleSign } from 'react-icons/fa';
import { Payment } from '../../../models/payments/payment';
import { useStore } from '../../../stores/storeProvider';
import { useLocalStore } from '../store';
import styles from './UserPaymentsInfo.module.scss';
// import SberLogoImg from '../../../assets/images/sber_logo.png';

type UserPaymentsInfoProps = {
  userId: string;
};

export const UserPaymentsInfo: React.FC<UserPaymentsInfoProps> = observer(({ userId }) => {
  const { appStore, paymentsStore } = useStore();
  const store = useLocalStore(appStore);

  const payments = store.payments.filter((e) => e.userId === userId);

  const handleClick = (payment: Payment) => {
    paymentsStore.showPaymentDialog(payment.id, () => store.loadUsersPayments());
  };

  return (
    <div>
      {isEmpty(payments) && <div className={styles.no_payments}>нет</div>}

      {payments.map((payment) => (
        <div className={styles.payment} onClick={() => handleClick(payment)}>
          {payment.sumPayed === null && payment.sumToPay === null && <div className={styles.no_payments}>нет</div>}

          <div className={styles.payment_main}>
            {payment.sumPayed !== null && (
              <div className={styles.payment_payed} title="оплачено">
                {payment.sumPayed} <FaRubleSign className={styles.payment_rub} />
              </div>
            )}

            {payment.sumPayed !== null && payment.sumPayed !== payment.sumToPay && (
              <div className={styles.payments_divider}> / </div>
            )}

            {(payment.sumPayed === null || payment.sumPayed !== payment.sumToPay) && (
              <div className={styles.payment_to_pay} title="к оплате">
                {payment.sumToPay} {payment.sumToPay !== null && <FaRubleSign className={styles.payment_rub} />}
              </div>
            )}

            {/* {payment.isTerminal && <img className={styles.payment_terminal} src={SberLogoImg} alt="" />} */}

            {(!isEmpty(payment.comment) || !isEmpty(payment.clientComment)) && (
              <FaCommentAlt className={styles.payment_comment} title={`${payment.comment} ${payment.clientComment} `} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
});
