import { UserAbonnementInfo, UserAbonnementInfoJson } from './../models/users/userAbonnementInfo';
import { UserSourceGroup } from './../models/userSource';
import { CheckLoginResult, UserLogItem, UserSourcesData } from './../models/user';
import {
  UsersResponse,
  UsersFilterRequest,
  UserNoteResponse,
  UserDisplayResponse,
  UserDeleteResponse,
  UserRoleResponse,
  UserSaveResponse,
  UserInfoModelResponse,
  FamilyUserInfoResponse,
  GroupCoachResponse,
} from './types/user';
import axios from 'axios';
import { UserDocumentType, UserDocumentEditModel, UserModel } from '../models/user';
import { DateUtils } from '../utils/date';
import { User, UserResponse } from '../models/users/user';
import { GroupCoachModel } from '../models/group';

export const UsersApi = {
  fetchUsers: async (params: UsersFilterRequest) => {
    const response = await axios.post<UsersResponse>('/Admin/UsersData', params);
    return response.data;
    //return { totalCount: 0, users: [] };
  },

  fetchUser: async (userId: string) => {
    const response = await axios.post<UserDisplayResponse>('/Admin/UserData', {
      userID: userId,
    });
    return response.data;
  },

  fetchUserInfo: async (userId: string) => {
    const response = await axios.post<UserInfoModelResponse>('/Admin/GetUser', {
      userID: userId,
    });
    return response.data;
  },

  fetchUserFamily: async (userId: string) => {
    const response = await axios.get<FamilyUserInfoResponse[]>('/api/users/GetUserFamily', {
      params: { userID: userId },
    });
    return response.data;
  },

  fetchGroupCoaches: async (groupId: number) => {
    const response = await axios.get<GroupCoachResponse[]>('/api/users/GetGroupCoaches', {
      params: { groupId },
    });
    return response.data.map((e) => GroupCoachModel.fromResponse(e));
  },

  fetchUsersCurrentAbonnements: async (usersIds: string[]) => {
    const response = await axios.post<UserAbonnementInfoJson[]>('/api/users/GetUsersCurrentAbonnements', {
      usersIds,
    });
    return response.data.map((e) => UserAbonnementInfo.fromJson(e));
  },

  deleteUser: async (userId: string, confirmed: boolean = false): Promise<UserDeleteResponse> => {
    const response = await axios.post<UserDeleteResponse>('/Admin/DeleteUser', {
      userID: userId,
      confirmed: confirmed,
    });
    return response.data;
  },

  changePassword: async (oldPassword: string, newPassword: string): Promise<void> => {
    await axios.post('/Account/ChangePasswordEx', {
      oldPassword,
      newPassword,
    });
  },

  resetPassword: async (userId: string, password: string): Promise<void> => {
    await axios.post('/Admin/ResetUserPassword', {
      userID: userId,
      password: password,
      passwordConfirm: password,
    });
  },

  fetchUserNotes: async (userID: string) => {
    const response = await axios.get<UserNoteResponse[]>('/api/users/GetUserNotes', {
      params: { userID },
    });
    return response.data;
  },

  fetchUserLogItems: async (userID: string) => {
    const response = await axios.get<UserLogItem[]>('/api/users/GetUserLogItems', {
      params: { userID },
    });
    return response.data;
  },

  saveUserNote: async (userID: string, noteText: string) => {
    const response = await axios.post<UserNoteResponse>('/api/users/AddUserNote', {
      userID,
      note: { Text: noteText },
    });
    return response.data;
  },

  saveUser: async (userModel: UserModel) => {
    const userSaveRequest = {
      userID: userModel.Id,

      userSaveMode: userModel.saveMode,

      newTestTrainingDate: userModel.newTestTrainingDate,
      newTestTrainingGroupId: userModel.newTestTrainingGroupId,

      newAbonnementId: userModel.newAbonnementId,
      newAbonnementDateFrom: DateUtils.format(userModel.newAbonnementDateFrom),

      newDiscountSelected: userModel.newDiscountSelected,
      newDiscountId: userModel.newDiscountSelected ? userModel.newDiscountId : null,
      newDiscountDateFrom: DateUtils.format(userModel.newDiscountDateFrom),

      archiveComment: userModel.archiveComment,
      archiveDateFrom: DateUtils.format(userModel.archiveDateFrom),
      archiveCallDate: DateUtils.format(userModel.archiveCallDate),

      reserveComment: userModel.reserveComment,
      reserveDateFrom: DateUtils.format(userModel.reserveDateFrom),
      reserveCallDate: DateUtils.format(userModel.reserveCallDate),
      reserveGroupsIds: userModel.reserveGroupsIds,

      newGroupId: userModel.newGroupId,
      newGroupDateFrom: DateUtils.format(userModel.newGroupDateFrom),

      userLogin: userModel.customLogin,

      userFirstName: userModel.FirstName,
      userLastName: userModel.LastName,
      userMiddleName: userModel.MiddleName,
      userPhone: userModel.PhoneNumber,
      userDesc: userModel.Description,
      userPhone2: userModel.PhoneNumber2,
      userPhoneDesc2: userModel.PhoneDesc2,
      userGender: userModel.Gender,
      userBirthday: DateUtils.format(userModel.BirthDay),
      userSource: userModel.Source,
      userSourceComment: userModel.SourceComment,
      userRoles: userModel.Roles,
      userGroups: userModel.Groups.map((group) => group.id),
      userComment: userModel.Comment,
      userIsActive: userModel.IsActive,
      userStatus: userModel.Status,

      familyUserIds: userModel.familyUserIds,
      isFamilyUsersChanged: userModel.isFamilyUsersChanged,
    };

    const response = await axios.post<UserSaveResponse>('/Admin/SaveUserEx', userSaveRequest);
    return response.data;
  },

  saveUserDocument: async (userID: string, type: UserDocumentType, document: UserDocumentEditModel) => {
    var form = new FormData();
    form.append('userID', userID);
    form.append('type', String(type));
    form.append('exist', document.exists ? 'true' : 'false');
    form.append('date', DateUtils.format_DDMMYYYY(document.date));
    form.append('title', document.title || '');
    form.append('isFileDeleted', 'false');

    if (document.file) {
      form.append('file', document.file);
    }

    const response = await axios({
      url: '/admin/SaveUserDocument',
      method: 'POST',
      data: form,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },

  fetchRoles: async () => {
    const response = await axios.get<UserRoleResponse[]>('/api/users/GetRoles');
    return response.data;
  },

  checkLogin: async (phoneNumber: string) => {
    const response = await axios.post<CheckLoginResult>('/Admin/CheckLogin', {
      phoneNumber,
    });
    return response.data;
  },

  fetchAllSelectedSources: async () => {
    const response = await axios.get<UserSourcesData>('/Admin/GetAllUserSources');
    return response.data;
  },

  fetchSourceGroups: async () => {
    const response = await axios.get<UserSourceGroup[]>('/Admin/GetUserSourceGroups');
    return response.data;
  },

  addUserSourceGroup: async (groupTitle: string) => {
    await axios.post('/api/settings/AddUserSourceGroup', {
      groupTitle: groupTitle,
    });
  },

  renameUserSourceGroup: async (oldGroupTitle: string, newGroupTitle: string) => {
    await axios.post('/api/settings/RenameUserSourceGroup', {
      oldGroupTitle: oldGroupTitle,
      newGroupTitle: newGroupTitle,
    });
  },

  renameUserSource: async (oldTitle: string, newTitle: string) => {
    await axios.post('/api/settings/RenameUserSource', {
      oldTitle: oldTitle,
      newTitle: newTitle,
    });
  },

  addUserSource: async (groupTitle: string, sourceTitle: string) => {
    await axios.post('/api/settings/AddUserSource', {
      groupTitle: groupTitle,
      sourceTitle: sourceTitle,
    });
  },

  deleteUserSource: async (sourceTitle: string) => {
    await axios.post('/api/settings/DeleteUserSource', {
      sourceTitle: sourceTitle,
    });
  },

  deleteUserSourceGroup: async (groupTitle: string) => {
    await axios.post('/api/settings/DeleteUserSourceGroup', {
      groupTitle: groupTitle,
    });
  },

  fetchAllCoaches: async (): Promise<User[]> => {
    const response = await axios.get<UserResponse[]>('/api/users/GetAllCoaches');
    return response.data.map((resp) => User.fromResponse(resp));
  },
};
