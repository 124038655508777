import { observable } from 'mobx';

export class PaymentsPageParams {
  @observable year: number = 0;
  @observable month: number = 0;
  @observable groups: number[] = [];
  @observable search: string = '';
  @observable statuses: number[] = [];
  @observable payments: string[] = [];
  @observable paymentTypes: number[] = [];
  @observable hasErrors: boolean = false;
  @observable userId: string = '';
}
