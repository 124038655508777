import { Button } from 'antd';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import styles from './UserPayments.module.scss';
import { DateUtils } from '../../../utils/date';
import { useStore } from '../../../stores/storeProvider';
import { PaymentsPageUserRowPayment } from '../models/paymentsPageUserRowPayment';
import { PaymentStatus as PaymnetStatusEnum } from '../models/paymentStatus';
import { Payment } from '../../../models/payments/payment';
import { useLocalStore } from '../store';
import { PaymentStatus } from '../PaymentStatus/PaymentStatus';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

interface UserPaymentInfoProps {
  info: PaymentsPageUserRowPayment | null;
  userId: string;
}

export const UserPaymentInfo: React.FC<UserPaymentInfoProps> = observer(({ info, userId }) => {
  const { appStore, paymentsStore } = useStore();
  const store = useLocalStore(appStore);

  const paymentInfo = useMemo(() => {
    return info || new PaymentsPageUserRowPayment();
  }, [info]);

  const handlePaymentClick = () => {
    if (info !== null) {
      paymentsStore.showPaymentDialog(paymentInfo.payment.id, store.onPaymentChanged.bind(store));
    } else {
      const payment = new Payment();
      payment.year = store.params.year;
      payment.month = store.params.month;
      payment.userId = userId;
      paymentsStore.showNewPaymentDialog(payment, store.onPaymentChanged.bind(store));
    }
  };

  return (
    <>
      <div className={styles.title}>{paymentInfo.payment.title}</div>

      <div className={styles.payment_status}>
        <PaymentStatus item={paymentInfo} />
      </div>

      <div>
        {paymentInfo.status !== PaymnetStatusEnum.Empty && (
          <div className={classNames(styles.payment_sum, styles.payment_sum_payed)} title="оплачено">
            {paymentInfo.payment.sumPayed
              ? paymentInfo.payment.sumPayed.toString()
              : !paymentInfo.payment.isSumEmpty()
              ? '0'
              : ''}
          </div>
        )}
      </div>

      <div>{paymentInfo.status !== PaymnetStatusEnum.Empty && <span>/</span>}</div>

      <div>
        {paymentInfo.status !== PaymnetStatusEnum.Empty && (
          <div
            className={classNames(styles.payment_sum, {
              [styles.payment_sum_empty]: paymentInfo.status === PaymnetStatusEnum.Empty,
            })}
            title="к оплате"
          >
            {paymentInfo.payment.sumToPay ? paymentInfo.payment.sumToPay.toString() : ' '}
          </div>
        )}
      </div>

      <div>{paymentInfo.hasErrors ? 'ошибки' : ''}</div>
      <div className={styles.date}>{DateUtils.format(paymentInfo.payment.date)}</div>
      <div></div>
      <div className={styles.payment_button}>
        <Button onClick={handlePaymentClick}>платеж</Button>
      </div>

      <div className={styles.payment_comment}>
        <div>{paymentInfo.payment.comment}</div>
        {!isEmpty(paymentInfo.payment.clientComment) && (
          <div>
            <span className={styles.payment_comment_label}>Для родителей:</span> {paymentInfo.payment.clientComment}
          </div>
        )}
      </div>
    </>
  );
});
