import { Moment } from 'moment';
import { Training } from './../../../models/trainings/training';
import { observable, action } from 'mobx';

export class TrainingsTransferDialogData {
  @observable
  isOpen: boolean = false;

  @observable
  comment: string = '';

  @observable
  placeId: number = 0;

  @observable
  trainingId: number = -1;

  @observable
  date: Moment | null = null;

  @observable
  fromTime: Moment | null = null;

  @observable
  toTime: Moment | null = null;

  @action
  showDialog(training: Training) {
    this.isOpen = true;
    this.trainingId = training.id;
    this.placeId = training.place?.id || 0;
    this.comment = '';
  }

  @action
  hideDialog() {
    this.isOpen = false;
  }

  @action
  setComment(comment: string) {
    this.comment = comment;
  }

  @action
  setPlaceId(placeId: number) {
    this.placeId = placeId;
  }

  @action
  setDate(date: Moment | null) {
    this.date = date;
  }

  @action
  setFromTime(fromTime: Moment | null) {
    this.fromTime = fromTime;
  }

  @action
  seToTime(toTime: Moment | null) {
    this.toTime = toTime;
  }
}
