import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import { UserEditDialogState } from '../UserEditDialogState';
import { action } from 'mobx';
import { SelectUsersDialog } from './SelectUsersDialog';
import { FamilyUserInfo } from '../../../../models/user';
import '../UserEditDialog.scss';

export interface IUserEditFamilyInfoDialogProps {
  editDialogState: UserEditDialogState;
  isOpen: boolean;
  onClose: () => void;
}

export const UserEditFamilyInfoDialog: React.FC<IUserEditFamilyInfoDialogProps> = observer(
  ({ editDialogState, isOpen, onClose }) => {
    const [isSelectUserDialogOpen, setIsSelectUserDialogOpen] = useState(false);
    const [familyUsers, setFamilyUsers] = useState<FamilyUserInfo[]>([]);

    useEffect(() => {
      if (isOpen) {
        setFamilyUsers([...editDialogState.familyUsers]);
      }
    }, [editDialogState.familyUsers, isOpen]);

    const handleCancel = () => {
      onClose();
    };

    const handleSave = action(() => {
      editDialogState.familyUsers = [...familyUsers];
      editDialogState.isFamilyUsersChanged = true;
      onClose();
    });

    const handleAdd = () => {
      setIsSelectUserDialogOpen(true);
    };

    const handleClear = action(() => {
      setFamilyUsers([]);
    });

    const handleCloseSelectDialog = () => {
      setIsSelectUserDialogOpen(false);
    };

    const handleSelectUser = (id: string, displayName: string, statusTitle: string) => {
      const familyUser = new FamilyUserInfo();
      familyUser.id = id;
      familyUser.displayName = displayName;
      familyUser.statusTitle = statusTitle;
      setFamilyUsers([familyUser, ...familyUsers]);
    };

    return (
      <div>
        <Modal
          visible={isOpen}
          title="Семья ученика"
          onCancel={handleCancel}
          footer={[
            <Button key="apply" onClick={handleSave} type="primary">
              Сохранить
            </Button>,
            <Button key="cancel" onClick={handleCancel}>
              Закрыть
            </Button>,
          ]}
        >
          <div className="UserEditFamilyInfoDialog-Actions">
            <Button key="add" onClick={handleAdd} type="link">
              Добавить
            </Button>
            {familyUsers.length > 0 && (
              <Button key="clear" onClick={handleClear} type="link">
                Очистить
              </Button>
            )}
          </div>

          <div>
            {familyUsers.map((familyUser) => (
              <div key={familyUser.id}>
                {familyUser.displayName} ({familyUser.statusTitle})
              </div>
            ))}
          </div>

          <SelectUsersDialog
            editDialogState={editDialogState}
            isOpen={isSelectUserDialogOpen}
            onSelect={handleSelectUser}
            onClose={handleCloseSelectDialog}
          />
        </Modal>
      </div>
    );
  }
);
