import { observable } from 'mobx';
import moment, { Moment } from 'moment';

export type GroupSheduleResponse = {
  ID: number;
  WeekDay: number;
  FromTimeHour: number;
  FromTimeMinute: number;
  ToTimeHour: number;
  ToTimeMinute: number;
  FromDay: string;
  ToDay: string | null;
};

export class GroupShedule {
  id: number = 0;
  @observable
  weekDay: number = 1;
  @observable
  fromTimeHour: number = 0;
  @observable
  fromTimeMinute: number = 0;
  @observable
  toTimeHour: number = 0;
  @observable
  toTimeMinute: number = 0;
  @observable
  fromDay: Moment = moment();
  @observable
  toDay: Moment | null = null;

  public static fromResponse(resp: GroupSheduleResponse) {
    const result = new GroupShedule();
    result.id = resp.ID;
    result.weekDay = resp.WeekDay;
    result.fromTimeHour = resp.FromTimeHour;
    result.fromTimeMinute = resp.FromTimeMinute;
    result.toTimeHour = resp.ToTimeHour;
    result.toTimeMinute = resp.ToTimeMinute;
    result.fromDay = moment(resp.FromDay);
    result.toDay = resp.ToDay !== null ? moment(resp.ToDay) : null;
    return result;
  }
}

export type GroupSheduleSaveModel = {
  id: number;
  weekDay: number;
  FromTimeHour: number;
  FromTimeMinute: number;
  ToTimeHour: number;
  ToTimeMinute: number;
  FromDayStr: string;
  ToDayStr: string;
};
