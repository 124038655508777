import { Pagination, Table, Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import PageContainer from '../../components/PageContainer';
import UserEditButton from '../../components/User/UserEditButton';
import { useStore } from '../../stores/storeProvider';
import { Spacer } from '../../ui-kit';
import { DateUtils, getDayOfWeekShortName } from '../../utils/date';
import { Notification, NotificationType } from './models/notification';
import { useLocalStore } from './store';
import styles from './styles.module.scss';
import { Toolbar } from './Toolbar/Toolbar';

export const NotificationsPage = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  useEffect(() => {
    document.title = 'Торнадо / Уведомления';
    appStore.setCurrentPageTitle('Уведомления');

    store.init();
  }, [appStore, store]);

  const columns = useMemo(
    () => [
      {
        title: 'Дата',
        width: 120,
        render: (_: any, item: Notification) => {
          return (
            <>
              <div className={styles.notification_date}>
                {DateUtils.format_DDMMYYYY(item.date)} {getDayOfWeekShortName(item.date.isoWeekday())}
              </div>
            </>
          );
        },
      },
      {
        title: 'Тип',
        width: 150,
        render: (_: any, item: Notification) => {
          const color = item.type === NotificationType.UserCall ? 'geekblue' : 'gray';
          return (
            <div className={styles.notification_type}>
              <Tag color={color}>{item.typeTitle}</Tag>
            </div>
          );
        },
      },
      {
        title: 'Контакты',
        width: 450,
        render: (_: any, item: Notification) => {
          return (
            <div className={styles.notification_contacts}>
              <div className={styles.user_name}>
                <div>{item.userName}</div>
                <Spacer />
                <div className={styles.user_edit}>
                  <UserEditButton userId={item.userId} />
                </div>
              </div>
              <div>
                {item.userPhone} <span className={styles.phone_desc}>{item.userPhoneDesc}</span>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Описание',
        render: (_: any, item: Notification) => {
          return (
            <div>
              {item.comments.map((comment) => (
                <div>{comment}</div>
              ))}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <PageContainer className={styles.page}>
      <Toolbar />

      <Table
        className={styles.notifications_table}
        columns={columns}
        dataSource={store.currentPageNotifications}
        rowKey="id"
        bordered
        size="small"
        loading={store.loading}
        pagination={false}
        // onRow={(item) => {
        //   return {
        //     onMouseEnter: () => item.setActive(true),
        //     onMouseLeave: () => item.setActive(false),
        //   };
        // }}
      />

      <div className={styles.pagination}>
        <Spacer />
        <Pagination
          current={store.pagination.pageNumber}
          pageSize={store.pagination.pageSize}
          total={store.notifications.length}
          size="small"
          showSizeChanger={false}
          onChange={(pageNumber) => store.pagination.setPageNumber(pageNumber)}
        />
      </div>
    </PageContainer>
  );
});
