import { UserPaymentCalculation, UserPaymentCalculationResponse } from './models/userPaymentCalculation';
import axios from 'axios';
import { PaymentsPageParams } from './models/paymentsPageParams';
import { PaymentsPageUserRow, PaymentsPageUserRowResponse } from './models/paymentsPageUserRow';

export const PaymentsPageApi = {
  fetchData: async (params: PaymentsPageParams): Promise<PaymentsPageUserRow[]> => {
    const response = await axios.post<PaymentsPageUserRowResponse[]>('/pages/payments/GetData', params);
    return response.data.map((resp) => PaymentsPageUserRow.fromResponse(resp));
  },

  fetchPaymentCalculations: async (
    year: number,
    month: number,
    groupId: number,
    userIds: string[]
  ): Promise<UserPaymentCalculation[]> => {
    const response = await axios.post<UserPaymentCalculationResponse[]>('/pages/payments/GetUsersPaymentsCalculations', {
      year: year,
      month: month,
      groupId: groupId,
      usersIDs: userIds,
    });
    return response.data.map((resp) => UserPaymentCalculation.fromResponse(resp));
  },

  calcPaymentCalculations: async (
    year: number,
    month: number,
    groupId: number,
    userIds: string[]
  ): Promise<UserPaymentCalculation[]> => {
    const response = await axios.post<UserPaymentCalculationResponse[]>('/pages/payments/CalcUsersPaymentsCalculations', {
      year: year,
      month: month,
      groupId: groupId,
      usersIDs: userIds,
    });
    return response.data.map((resp) => UserPaymentCalculation.fromResponse(resp));
  },

  savePaymentCalculations: async (year: number, month: number, calcs: UserPaymentCalculation[]): Promise<void> => {
    const paymentsCalculations: UserPaymentCalculationResponse[] = calcs.map((e) => ({
      ID: e.id,
      UserID: e.userId,
      Sum: e.sum,
      Detail: e.detail,
      Payment: null,
      PrevPayment: null,
      CurMonthTrainings: [],
      PrevMonthTrainings: [],
    }));
    await axios.post('/pages/payments/SaveUsersPaymentsCalculations', {
      year: year,
      month: month,
      paymentsCalculations: paymentsCalculations,
    });
  },

  applyPaymentCalculations: async (year: number, month: number, userIds: string[]): Promise<void> => {
    await axios.post('/pages/payments/ApplyUsersPaymentsCalculations', {
      year: year,
      month: month,
      usersIDs: userIds,
    });
  },

  copyPaymentCommentFromPrevMonth: async (year: number, month: number, userId: string): Promise<void> => {
    await axios.post('/pages/payments/CopyPaymentCommentFromPrevMonth', {
      year: year,
      month: month,
      userId: userId,
    });
  },

  copyPaymentClientCommentFromPrevMonth: async (year: number, month: number, userId: string): Promise<void> => {
    await axios.post('/pages/payments/CopyPaymentClientCommentFromPrevMonth', {
      year: year,
      month: month,
      userId: userId,
    });
  },
};
