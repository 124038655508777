import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Input, Col } from 'antd';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import FormItem from '../../../components/FormItem';

export interface IRenameSourceDialogProps {
  isOpen: boolean;
  title: string;
  onSave: (oldTitle: string, newTitle: string) => void;
  onClose: () => void;
}

export const RenameSourceDialog: React.FC<IRenameSourceDialogProps> = observer(({ isOpen, title, onSave, onClose }) => {
  const [newTitle, setNewTitle] = useState('');

  useEffect(() => {
    setNewTitle(title);
  }, [title]);

  const handleSave = () => {
    if (isEmpty(newTitle)) {
      message.warning('Не задано наименование источника', 3);
      return;
    }

    if (title === newTitle) {
      onClose();
      return;
    }

    onSave(title, newTitle);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        visible={isOpen}
        title="Изменение источника"
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleSave} type="primary">
            Сохранить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <FormItem label="Наименование источника">
              <Input style={{ width: '100%' }} placeholder="" value={newTitle} onChange={(e) => setNewTitle(e.target.value)} />
            </FormItem>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
