import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, Row, Col, Input, Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/storeProvider';
import { useLocalStore } from '../store';
import FormItem from '../../../components/FormItem';
import { getTrainingVisitStatusTitle, TrainingVisitStatus } from '../../../models/trainings/trainingVisitStatus';
import { UserTrainingStatusIcon } from '../../../components/User/UserTrainings/UserTrainingStatusIcon';
import styles from './TrainingsVisitsDialog.module.scss';
import { action } from 'mobx';
import { DownOutlined } from '@ant-design/icons';

export const TrainingsVisitsDialog = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  const handleCancel = () => {
    store.trainingsVisitsDialog.hideDialog();
  };

  return (
    <div>
      <Modal
        visible={store.trainingsVisitsDialog.isOpen}
        title="Отметки посещения"
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={() => store.saveTrainigVisits()} type="primary">
            Сохранить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <div
              className={styles.user_visits}
              style={{ maxHeight: (store.trainingsVisitsDialog.trainingVisits.usersTrainigs.length / 2 + 1) * 50 }}
            >
              {store.trainingsVisitsDialog.trainingVisits.usersTrainigs.map((userVisit) => (
                <div className={styles.user_visit_info}>
                  <div
                    className={styles.user_visit_icon}
                    onClick={action(() => {
                      if (userVisit.visitStatus === TrainingVisitStatus.Visit) userVisit.visitStatus = TrainingVisitStatus.Skip;
                      else if (userVisit.visitStatus === TrainingVisitStatus.Test)
                        userVisit.visitStatus = TrainingVisitStatus.TestSkip;
                      else if (userVisit.visitStatus === TrainingVisitStatus.TestSkip)
                        userVisit.visitStatus = TrainingVisitStatus.Test;
                      else userVisit.visitStatus = TrainingVisitStatus.Visit;
                    })}
                  >
                    <UserTrainingStatusIcon visitStatus={userVisit.visitStatus}></UserTrainingStatusIcon>
                  </div>

                  <div>
                    <div className={styles.user_name}>{userVisit.user.displayName}</div>
                    <div className={styles.user_visit_status_title}>
                      <Dropdown
                        trigger={['click']}
                        overlay={
                          <Menu>
                            {!userVisit.isTest && (
                              <>
                                <Menu.Item
                                  onClick={action(() => {
                                    userVisit.visitStatus = TrainingVisitStatus.Visit;
                                  })}
                                >
                                  <div className={styles.user_visit_menu_item}>
                                    <div className={styles.user_visit_drop_icon}>
                                      <UserTrainingStatusIcon visitStatus={TrainingVisitStatus.Visit} />
                                    </div>
                                    <div>{getTrainingVisitStatusTitle(TrainingVisitStatus.Visit)}</div>
                                  </div>
                                </Menu.Item>
                                <Menu.Item
                                  onClick={action(() => {
                                    userVisit.visitStatus = TrainingVisitStatus.Skip;
                                  })}
                                >
                                  <div className={styles.user_visit_menu_item}>
                                    <div className={styles.user_visit_drop_icon}>
                                      <UserTrainingStatusIcon visitStatus={TrainingVisitStatus.Skip} />
                                    </div>
                                    <div>{getTrainingVisitStatusTitle(TrainingVisitStatus.Skip)}</div>
                                  </div>
                                </Menu.Item>
                                <Menu.Item
                                  onClick={action(() => {
                                    userVisit.visitStatus = TrainingVisitStatus.Vacation;
                                  })}
                                >
                                  <div className={styles.user_visit_menu_item}>
                                    <div className={styles.user_visit_drop_icon}>
                                      <UserTrainingStatusIcon visitStatus={TrainingVisitStatus.Vacation} />
                                    </div>
                                    <div>{getTrainingVisitStatusTitle(TrainingVisitStatus.Vacation)}</div>
                                  </div>
                                </Menu.Item>
                                <Menu.Item
                                  onClick={action(() => {
                                    userVisit.visitStatus = TrainingVisitStatus.HealthCertificate;
                                  })}
                                >
                                  <div className={styles.user_visit_menu_item}>
                                    <div className={styles.user_visit_drop_icon}>
                                      <UserTrainingStatusIcon visitStatus={TrainingVisitStatus.HealthCertificate} />
                                    </div>
                                    <div>{getTrainingVisitStatusTitle(TrainingVisitStatus.HealthCertificate)}</div>
                                  </div>
                                </Menu.Item>
                                <Menu.Item
                                  onClick={action(() => {
                                    userVisit.visitStatus = TrainingVisitStatus.HealthSkip;
                                  })}
                                >
                                  <div className={styles.user_visit_menu_item}>
                                    <div className={styles.user_visit_drop_icon}>
                                      <UserTrainingStatusIcon visitStatus={TrainingVisitStatus.HealthSkip} />
                                    </div>
                                    <div>{getTrainingVisitStatusTitle(TrainingVisitStatus.HealthSkip)}</div>
                                  </div>
                                </Menu.Item>
                              </>
                            )}
                            {userVisit.isTest && (
                              <>
                                <Menu.Item
                                  onClick={action(() => {
                                    userVisit.visitStatus = TrainingVisitStatus.Test;
                                  })}
                                >
                                  <div className={styles.user_visit_menu_item}>
                                    <div className={styles.user_visit_drop_icon}>
                                      <UserTrainingStatusIcon visitStatus={TrainingVisitStatus.Test} />
                                    </div>
                                    <div>{getTrainingVisitStatusTitle(TrainingVisitStatus.Test)}</div>
                                  </div>
                                </Menu.Item>
                                <Menu.Item
                                  onClick={action(() => {
                                    userVisit.visitStatus = TrainingVisitStatus.TestSkip;
                                  })}
                                >
                                  <div className={styles.user_visit_menu_item}>
                                    <div className={styles.user_visit_drop_icon}>
                                      <UserTrainingStatusIcon visitStatus={TrainingVisitStatus.TestSkip} />
                                    </div>
                                    <div>{getTrainingVisitStatusTitle(TrainingVisitStatus.TestSkip)}</div>
                                  </div>
                                </Menu.Item>
                              </>
                            )}
                          </Menu>
                        }
                      >
                        <div>
                          {getTrainingVisitStatusTitle(userVisit.visitStatus)} <DownOutlined />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <FormItem label="Комментарий">
              <Input.TextArea
                rows={2}
                value={store.trainingsVisitsDialog.trainingVisits.comment}
                onChange={(e) => store.trainingsVisitsDialog.setComment(e.target.value)}
              />
            </FormItem>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
