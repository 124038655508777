import { EditStaffUserDialogData } from './EditStaffUserDialog/EditStaffUserDialogData';
import { observable, runInAction } from 'mobx';
import { AppStore } from '../../../stores/AppStore';
import { StaffSettingsPageApi } from './api';
import { StaffSettingsPageParams } from './models/staffSettingsPageParams';
import { StaffUser } from './models/staffUser';
import { ResetPasswordDialogData } from './models/resetPasswordDialogData';

export class StaffSettingsPageStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  params = new StaffSettingsPageParams();

  @observable
  coaches: StaffUser[] = [];

  @observable
  managers: StaffUser[] = [];

  editStaffUserDialogData = new EditStaffUserDialogData();
  resetPasswordDialogData = new ResetPasswordDialogData();

  async refresh() {
    await Promise.all([this.loadCoaches(), this.loadManagers()]);
  }

  async loadCoaches() {
    try {
      const coaches = await StaffSettingsPageApi.fetchCoaches(this.params);
      runInAction(() => {
        this.coaches = coaches;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async loadManagers() {
    try {
      const managers = await StaffSettingsPageApi.fetchManagers(this.params);
      runInAction(() => {
        this.managers = managers;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async saveStaffUser(user: StaffUser) {
    try {
      this.editStaffUserDialogData.setSaving(true);
      await StaffSettingsPageApi.saveStaffUser(this.editStaffUserDialogData.user);
      await this.refresh();
      this.editStaffUserDialogData.hideDialog();
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
      this.editStaffUserDialogData.setSaving(false);
    }
  }
}

let localStore: StaffSettingsPageStore | null = null;

export const useLocalStore = (appStore: AppStore) => {
  if (localStore === null) {
    localStore = new StaffSettingsPageStore(appStore);
  }
  return localStore;
};
