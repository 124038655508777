import React, { useState } from 'react';
import './UserDocument.scss';
import { AiFillFile } from 'react-icons/ai';
import { FaHeartbeat, FaShieldAlt, FaFileAlt } from 'react-icons/fa';
import Modal from 'antd/lib/modal/Modal';
import { Button, Tooltip, Checkbox, DatePicker } from 'antd';
import {
  UserDisplayModel,
  UserDocument as UserDocumentObject,
  UserDocumentType,
  UserDocumentEditModel,
} from '../../../models/user';
import classNames from 'classnames';
import TextArea from 'antd/lib/input/TextArea';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment, { Moment } from 'moment';
import { UsersApi } from '../../../api/user';
import { useStore } from '../../../stores/storeProvider';
import { DeleteOutlined } from '@ant-design/icons';

interface IUserDocumentProps {
  user: UserDisplayModel;
  document: UserDocumentObject;
  children?: never;
  onChange: (userId: string) => void;
}

export const UserDocument: React.FC<IUserDocumentProps> = ({ user, document, onChange }) => {
  const { appStore } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editDocument, setEditDocument] = useState<UserDocumentEditModel>({
    exists: !document.isEmpty,
    date: document.date ? moment(document.date) : null,
    title: document.title,
    file: null,
    isFileDeleted: false,
  });

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleSave = () => {
    const saveDocument = async () => {
      try {
        setSaving(true);
        await UsersApi.saveUserDocument(user.Id, document.type, editDocument);
        onChange && onChange(user.Id);
        setIsOpen(false);
      } catch (error) {
        appStore.setErrorMessage((error as Error).message);
      } finally {
        setSaving(false);
      }
    };
    saveDocument();
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleExistsChange = (e: CheckboxChangeEvent) => {
    setEditDocument({
      ...editDocument,
      exists: e.target.checked,
    });
  };

  const handleDateChange = (value: Moment | null) => {
    setEditDocument({
      ...editDocument,
      date: value,
    });
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditDocument({
      ...editDocument,
      title: e.target.value || '',
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditDocument({
      ...editDocument,
      file: e.target.files != null ? e.target.files[0] : null,
    });
  };

  const handleDeleteFile = () => {
    setEditDocument({
      ...editDocument,
      isFileDeleted: true,
      file: null,
    });
  };

  return (
    <div>
      <UserDocumentIcon
        type={document.type}
        title={document.tooltip}
        colorClass={document.colorClassText}
        onClick={handleClick}
      />

      <Modal
        visible={isOpen}
        title={`${document.infoText} - ${user.DisplayName}`}
        onCancel={handleCancel}
        footer={[
          <Button key="add" onClick={handleSave} loading={saving} type="primary">
            Сохранить
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <div className="UserDocument-FormItem">
          <Checkbox checked={editDocument.exists} onChange={handleExistsChange}>
            Документ
          </Checkbox>
        </div>
        <div className="UserDocument-FormItem">
          <label>Дата документа</label>
          <DatePicker value={editDocument.date} onChange={handleDateChange} />
        </div>
        <div className="UserDocument-FormItem">
          <label>Описание</label>
          <TextArea value={editDocument.title} onChange={handleTitleChange} />
        </div>
        <div className="UserDocument-FormItem">
          <label>Файл</label>
          <div>
            {document.fileId != null && !editDocument.isFileDeleted && (
              <>
                <a href={document.fileUrl} target="_blank" rel="noopener noreferrer" title={document.fileTitle || ''}>
                  {document.fileName || 'Файл документа'}
                </a>
                <span className="UserDocument-DeleteFileButton">
                  <DeleteOutlined onClick={handleDeleteFile} />
                </span>
              </>
            )}
          </div>

          <input
            type="file"
            name="documentFile"
            onChange={handleFileChange}
            className={classNames({
              'UserDocument-FileInput__hidden': !!document.fileName && !editDocument.isFileDeleted,
            })}
          />
        </div>
      </Modal>
    </div>
  );
};

interface IUserDocumentIconProps {
  type: UserDocumentType;
  title: string;
  colorClass: string;
  onClick: () => void;
  children?: never;
}

const UserDocumentIcon: React.FC<IUserDocumentIconProps> = ({ type, title, colorClass, onClick }) => {
  switch (type) {
    case UserDocumentType.Contract:
      return (
        <Tooltip title={title}>
          <AiFillFile className={classNames('UserDocumentIcon', `UserDocumentIcon_${colorClass}`)} onClick={onClick} size="20" />
        </Tooltip>
      );
    case UserDocumentType.ContractAdditional:
      return (
        <Tooltip title={title}>
          <FaFileAlt className={classNames('UserDocumentIcon', `UserDocumentIcon_${colorClass}`)} onClick={onClick} size="20" />
        </Tooltip>
      );
    case UserDocumentType.HealthCertificate:
      return (
        <Tooltip title={title}>
          <FaHeartbeat className={classNames('UserDocumentIcon', `UserDocumentIcon_${colorClass}`)} onClick={onClick} size="20" />
        </Tooltip>
      );
    case UserDocumentType.Insurance:
      return (
        <Tooltip title={title}>
          <FaShieldAlt className={classNames('UserDocumentIcon', `UserDocumentIcon_${colorClass}`)} onClick={onClick} size="20" />
        </Tooltip>
      );
    default:
      break;
  }
  return null;
};

// const getUserDocumentIcon = (type: UserDocumentType): ReactElement | null => {
//   switch (type) {
//     case UserDocumentType.Contract:
//       return <AiFillFile />;
//     default:
//       break;
//   }
//   return null;
// };
