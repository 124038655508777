import { Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

export const MainTable = observer(() => {
  // const { rentsReportStore: store } = useStore();

  return (
    <div>
      <Space>main</Space>
    </div>
  );
});
