import { Training, TrainingResponse } from '../../../models/trainings/training';
import { TrainingVisitStatus } from '../../../models/trainings/trainingVisitStatus';

export type UserPaymentCalcTrainingResponse = {
  Training: TrainingResponse;
  VisitStatus: TrainingVisitStatus | null;
};

export class UserPaymentCalcTraining {
  training: Training = new Training();
  visitStatus: TrainingVisitStatus | null = null;

  public static fromResponse(resp: UserPaymentCalcTrainingResponse) {
    const result = new UserPaymentCalcTraining();
    result.visitStatus = resp.VisitStatus;
    result.training = Training.fromResponse(resp.Training);
    return result;
  }
}
