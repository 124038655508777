export type AdditionalPaymentResponse = {
  ID: string;
  Title: string;
  Sum: number;
  IsActive: boolean;
  Acquiring: boolean;
};

// информация о дополнительном платеже (лига, форма...)
export class AdditionalPayment {
  id: string = '';
  title: string = '';
  sum: number = 0;
  isActive: boolean = true;
  acquiring: boolean = true;

  public static fromResponse(response: AdditionalPaymentResponse): AdditionalPayment {
    let result = new AdditionalPayment();
    result.id = response.ID;
    result.title = response.Title;
    result.sum = response.Sum;
    result.isActive = response.IsActive;
    result.acquiring = response.Acquiring;
    return result;
  }
}

export type AdditionalPaymentSaveModel = {
  ID: string;
  Title: string;
  Sum: number;
  IsActive: boolean;
  Acquiring: boolean;
};
