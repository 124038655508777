import { Button, Checkbox, DatePicker, Input, Select, Space, Switch, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isEmpty } from 'lodash';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useMemo } from 'react';
import FilterItem from '../../../components/FilterItem';
import GroupSelect from '../../../components/GroupSelect';
import { useStore } from '../../../stores/storeProvider';
import { paymentStatusOptions } from '../models/paymentStatus';
import { useLocalStore } from '../store';
import styles from './Toolbar.module.scss';

export const Toolbar = observer(() => {
  const { appStore, groupStore, paymentsStore } = useStore();
  const store = useLocalStore(appStore);

  const handlePeriodChange = action((value: moment.Moment | null, _: string) => {
    if (value) {
      store.params.year = value.year();
      store.params.month = value.month() + 1;
    } else {
      const currentDate = new Date();
      store.params.year = currentDate.getFullYear();
      store.params.month = currentDate.getMonth() + 1;
    }
    store.refresh();
  });

  const period = useMemo(() => {
    return moment({ years: store.params.year, months: store.params.month - 1, date: 1 });
  }, [store.params.month, store.params.year]);

  const handleGroupChange = action((value: number | string) => {
    store.params.groups = typeof value === 'number' ? [value] : [];
    store.calcMode = store.calcMode && store.params.groups.length === 1;
    store.refresh();
  });

  const handleStatusChange = action((value: number[]) => {
    store.params.statuses = value;
  });

  const handleAdditionalPaymentChange = action((value: string[]) => {
    store.params.payments = value;
  });

  const handleHasErrorsChange = action((e: CheckboxChangeEvent) => {
    store.params.hasErrors = e.target.checked;
  });

  const handleSearchChange = action((e: React.ChangeEvent<HTMLInputElement>) => {
    store.params.search = e.target.value;
  });

  const handleCalcModeChange = action((checked: boolean) => {
    store.calcMode = checked;
    store.refresh();
  });

  const handleRefresh = () => {
    store.refresh();
  };

  const handleCalcPayments = () => {
    store.calcPayments();
  };

  const handleSaveCalcPayments = () => {
    store.saveCalcPayments();
  };

  const handleApplyCalcPayments = () => {
    store.applyCalcPayments();
  };

  return (
    <>
      <div className={styles.toolbar}>
        <Space className={styles.filter} size="middle">
          <FilterItem label="Период">
            <DatePicker value={period} onChange={handlePeriodChange} picker="month" allowClear={false} />
          </FilterItem>

          <FilterItem label="Группы">
            <GroupSelect
              groups={groupStore.activeGroups}
              value={!isEmpty(store.params.groups) ? store.params.groups[0] : ''}
              onChange={handleGroupChange}
            />
          </FilterItem>

          <FilterItem label="Статусы">
            <Select
              style={{ minWidth: 200, maxWidth: 500 }}
              mode="multiple"
              allowClear
              placeholder="Все"
              onChange={handleStatusChange}
            >
              {paymentStatusOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </FilterItem>

          <FilterItem label="Доп. оплаты">
            <Select
              style={{ minWidth: 200, maxWidth: 500 }}
              mode="multiple"
              allowClear
              placeholder="Все"
              onChange={handleAdditionalPaymentChange}
            >
              {paymentsStore.additionalPayments
                .filter((e) => e.isActive)
                .map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.title}
                  </Select.Option>
                ))}
            </Select>
          </FilterItem>

          <FilterItem label="Поиск">
            <Input value={store.params.search} onChange={handleSearchChange} allowClear={true}></Input>
          </FilterItem>

          <div className={styles.filter_check}>
            <Checkbox checked={store.params.hasErrors} onChange={handleHasErrorsChange}>
              только с ошибками
            </Checkbox>
          </div>

          <div>
            <Button type="primary" onClick={handleRefresh}>
              Применить
            </Button>
          </div>

          {store.params.groups.length === 1 && (
            <>
              <FilterItem label=" ">
                {!store.loading && !store.finalizeInfo.isFinalized && (
                  <Tooltip title="Отметка, что информация по группе в текущем месяце полностью подготовлена">
                    <Button onClick={() => store.changeMonthFinalize(true)}>Закрыть месяц</Button>
                  </Tooltip>
                )}

                {!store.loading && store.finalizeInfo.isFinalized && (
                  <div>
                    <div>Месяц закрыт</div>
                    <div>
                      {store.finalizeInfo.finalizeDate} {store.finalizeInfo.finalizeUserName}
                    </div>
                  </div>
                )}
              </FilterItem>

              <FilterItem label=" ">
                {!store.loading && store.finalizeInfo.isFinalized && (
                  <>
                    {appStore.account?.isAdminOrManager && (
                      <Button onClick={() => store.changeMonthFinalize(false)}>Открыть</Button>
                    )}
                  </>
                )}
              </FilterItem>
            </>
          )}
        </Space>
      </div>

      <div className={styles.calc_toolbar}>
        {store.params.groups.length === 1 && appStore.account?.isAdminOrMainManager && (
          <div className={styles.calc_mode}>
            <Switch checked={store.calcMode} onChange={handleCalcModeChange} />
            <span className={styles.calc_mode_label} onClick={() => handleCalcModeChange(!store.calcMode)}>
              Расчёт платежей
            </span>
          </div>
        )}

        {store.calcMode && (
          <Space>
            <Tooltip placement="bottom" title="Рассчитать оплаты учеников за занятия в текущем месяце">
              <Button onClick={handleCalcPayments}>Рассчитать</Button>
            </Tooltip>
            <Tooltip placement="bottom" title="Сохранить расчёты для выставления оплат">
              <Button onClick={handleSaveCalcPayments}>Сохранить</Button>
            </Tooltip>
            <Tooltip
              placement="bottom"
              title="Выставить оплаты ученикам (только тем, кому ещё не выставлена оплата в текущем месяце и сохранён расчёт)"
            >
              <Button onClick={handleApplyCalcPayments}>Выставить оплаты</Button>
            </Tooltip>
          </Space>
        )}
      </div>
    </>
  );
});
