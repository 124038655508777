import { Place, PlaceJson } from '../places/place';
import moment from 'moment';
import { User, UserResponse } from '../users/user';
import { zeroPad } from '../../utils/format';

export enum TrainingStatus {
  Normal = 0,
  Cancel = 1,
  Checked = 2,
}

export const getTrainingStatusTitle = (trainingStatus: TrainingStatus) => {
  if (trainingStatus === TrainingStatus.Normal) return 'Запланирована';
  if (trainingStatus === TrainingStatus.Cancel) return 'Отменена';
  if (trainingStatus === TrainingStatus.Checked) return 'Отмечена';
  return '';
};

export type TrainingResponse = {
  ID: number;
  Date: string;
  FromTime: string | number;
  ToTime: string | number;
  Status: TrainingStatus;
  IsTransfer: boolean;
  IsTransfered: boolean;
  Place: PlaceJson | null;
  Comment: string;
  Coaches: UserResponse[];
  CoachesEx: UserResponse[];
  TransferTrainingDate: string | null;
};

export class Training {
  id: number = 0;
  date: moment.Moment = moment();
  fromTime: string = '';
  toTime: string = '';
  status: TrainingStatus = TrainingStatus.Normal;
  isTransfer: boolean = false;
  isTransfered: boolean = false;
  place: Place | null = null;
  comment: string = '';
  coaches: User[] = [];
  coachesEx: User[] = [];
  transferTrainigDate: moment.Moment | null = null;

  public static fromResponse(resp: TrainingResponse) {
    let result = new Training();
    result.id = resp.ID;
    result.date = moment(resp.Date);
    if (typeof resp.FromTime === 'number' && typeof resp.ToTime === 'number') {
      result.fromTime = `${zeroPad(Math.floor(resp.FromTime / 100), 2)}:${zeroPad(Math.floor(resp.FromTime % 100), 2)}`;
      result.toTime = `${zeroPad(Math.floor(resp.ToTime / 100), 2)}:${zeroPad(Math.floor(resp.ToTime % 100), 2)}`;
    } else {
      result.fromTime = moment(resp.FromTime, 'HH:mm:ss').format('HH:mm');
      result.toTime = moment(resp.ToTime, 'HH:mm:ss').format('HH:mm');
    }
    result.status = resp.Status;
    result.isTransfer = resp.IsTransfer;
    result.isTransfered = resp.IsTransfered;
    result.comment = resp.Comment;
    if (resp.Place) result.place = Place.fromJson(resp.Place);
    if (resp.Coaches) result.coaches = resp.Coaches.map((e) => User.fromResponse(e));
    if (resp.CoachesEx) result.coachesEx = resp.CoachesEx.map((e) => User.fromResponse(e));
    if (resp.TransferTrainingDate) result.transferTrainigDate = moment(resp.TransferTrainingDate);
    return result;
  }
}
