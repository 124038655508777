import { DiscountsApi } from './../api/discounts';
import { observable, action, runInAction, computed } from 'mobx';
import { AppStore } from './AppStore';
import { Discount } from '../models/discount';
import { isEmpty } from 'lodash';

export class DiscountsStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  discounts: Discount[] = [];

  @computed
  get activeDiscounts() {
    return this.discounts?.filter((item) => item.IsActive) || [];
  }

  @action
  async loadDiscounts(force: boolean = false) {
    try {
      if (!isEmpty(this.discounts) && !force) {
        return;
      }
      const discounts = await DiscountsApi.fetchDiscounts();
      runInAction(() => {
        this.discounts = discounts;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  getNameById(discountId: string): string | null {
    return this.discounts?.find((discount) => discount.ID === discountId)?.Name || null;
  }

  @action
  async deleteDiscount(id: string) {
    try {
      await DiscountsApi.deleteDiscount(id);
      await this.loadDiscounts(true);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }
}
