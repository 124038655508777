import { UserPaymentCalculation } from './models/userPaymentCalculation';
import { action, observable, runInAction } from 'mobx';
import { AppStore } from '../../stores/AppStore';
import { PaymentsPageApi } from './api';
import { isEmpty } from 'lodash';
import { PaymentsPageParams } from './models/paymentsPageParams';
import { PaymentsPageUserRow } from './models/paymentsPageUserRow';
import { Payment } from '../../models/payments/payment';
import { Modal } from 'antd';
import { PaymentApi } from '../../api/payment';
import { TrainingsMonthFinalizeInfo } from '../Trainings/models/trainingsMonthFinalizeInfo';

export class PaymentsPageStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  loading: boolean = false;

  @observable
  items: PaymentsPageUserRow[] = [];

  @observable
  calcs: UserPaymentCalculation[] = [];

  @observable
  params: PaymentsPageParams = new PaymentsPageParams();

  @observable
  calcMode: boolean = false;

  @observable
  finalizeInfo: TrainingsMonthFinalizeInfo = new TrainingsMonthFinalizeInfo();

  @action
  async init() {
    try {
      const currentDate = new Date();

      this.params.year = currentDate.getFullYear();
      this.params.month = currentDate.getMonth() + 1;

      await this.refresh();
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async refresh() {
    try {
      this.loading = true;
      const items = await PaymentsPageApi.fetchData(this.params);
      await this.loadFinalizeInfo();
      if (this.calcMode) {
        await this.loadCalcs(items.map((e) => e.userId));
      }
      runInAction(() => {
        for (const item of items) {
          for (const paymentInfo of item.payments) {
            paymentInfo.payment.title = isEmpty(paymentInfo.payment.additionalPaymentId)
              ? 'занятия'
              : this.appStore.paymentsStore.getAdditionalPayment(paymentInfo.payment.additionalPaymentId!).title;
          }
        }
        this.items = items;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  async loadCalcs(userIds: string[]) {
    try {
      if (this.params.groups.length !== 1) {
        return;
      }
      const calcs = await PaymentsPageApi.fetchPaymentCalculations(
        this.params.year,
        this.params.month,
        this.params.groups[0],
        userIds
      );
      runInAction(() => {
        this.calcs = calcs;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async calcPayments() {
    try {
      if (this.params.groups.length !== 1) {
        return;
      }
      this.loading = true;
      const calcs = await PaymentsPageApi.calcPaymentCalculations(
        this.params.year,
        this.params.month,
        this.params.groups[0],
        this.items.map((e) => e.userId)
      );
      runInAction(() => {
        this.calcs = calcs;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  async saveCalcPayments() {
    try {
      this.loading = true;
      await PaymentsPageApi.savePaymentCalculations(this.params.year, this.params.month, this.calcs);
      await this.loadCalcs(this.calcs.map((e) => e.userId));
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  async applyCalcPayments() {
    try {
      if (this.calcs.some((e) => isEmpty(e.id) || e.isChanged)) {
        this.appStore.showWarning('Для выставления счетов необходимо сохранить все расчёты');
        return;
      }
      this.loading = true;
      await PaymentsPageApi.applyPaymentCalculations(
        this.params.year,
        this.params.month,
        this.calcs.map((e) => e.userId)
      );
      await this.refresh();
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async onPaymentChanged(payment: Payment) {
    this.refreshUserPayments(payment.userId);
  }

  async refreshUserPayments(userId: string) {
    try {
      const params = new PaymentsPageParams();
      params.year = this.params.year;
      params.month = this.params.month;
      params.userId = userId;
      const items = await PaymentsPageApi.fetchData(params);
      runInAction(() => {
        for (const paymentInfo of items[0].payments) {
          paymentInfo.payment.title = isEmpty(paymentInfo.payment.additionalPaymentId)
            ? 'занятия'
            : this.appStore.paymentsStore.getAdditionalPayment(paymentInfo.payment.additionalPaymentId!).title;
        }
        const itemIndex = this.items.findIndex((item) => item.userId === items[0].userId);
        this.items.splice(itemIndex, 1, items[0]);
        this.items = [...this.items];
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async copyPaymentComment(userId: string) {
    try {
      await PaymentsPageApi.copyPaymentCommentFromPrevMonth(this.params.year, this.params.month, userId);
      await this.refreshUserPayments(userId);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async copyPaymentClientComment(userId: string) {
    try {
      await PaymentsPageApi.copyPaymentClientCommentFromPrevMonth(this.params.year, this.params.month, userId);
      await this.refreshUserPayments(userId);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async loadFinalizeInfo() {
    if (this.params.groups.length !== 1) return;

    try {
      const finalizeInfo = await PaymentApi.fetchMonthFinalizeInfo(this.params.groups[0], this.params.year, this.params.month);
      runInAction(() => {
        this.finalizeInfo = finalizeInfo;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async changeMonthFinalize(finalize: boolean) {
    let message = 'Вы действительно хотите закрыть месяц для выбранной группы?';
    if (!finalize) {
      message = 'Вы действительно хотите отменить закрытие месяца для выбранной группы?';
    }

    if (this.params.groups.length !== 1) return;

    Modal.confirm({
      title: 'Внимание',
      content: message,
      okText: 'Да',
      cancelText: 'Отмена',
      onOk: async () => {
        try {
          await PaymentApi.finalizeMonth(this.params.groups[0], this.params.year, this.params.month, finalize);
          const finalizeInfo = await PaymentApi.fetchMonthFinalizeInfo(
            this.params.groups[0],
            this.params.year,
            this.params.month
          );
          runInAction(() => {
            this.finalizeInfo = finalizeInfo;
          });
        } catch (error) {
          this.appStore.setErrorMessage((error as Error).message);
        }
      },
    });
  }
}

let localStore: PaymentsPageStore | null = null;

export const useLocalStore = (appStore: AppStore) => {
  if (localStore === null) {
    localStore = new PaymentsPageStore(appStore);
  }
  return localStore;
};
