import React from 'react';
import styles from './FilterItem.module.scss';

interface IFormItemProps {
  label: string;
  className?: string;
}

export const FilterItem: React.FC<IFormItemProps> = ({ label, children, className }) => {
  return (
    <div className={className}>
      <div className={styles.label}>{label}</div>
      <div>{children}</div>
    </div>
  );
};
