import { Moment } from 'moment';
import moment from 'moment';
import { uniqueId } from 'lodash';
import { observable, action } from 'mobx';

export type NotificationJson = {
  Date: string;
  Type: number;
  UserId: string;
  UserName: string;
  UserPhone: string;
  UserPhoneDesc: string;
  Comments: string[];
};

export enum NotificationType {
  None = -1,
  UserCall = 0,
}

export const getNotificationTypeTitle = (type: NotificationType) => {
  if (type === NotificationType.UserCall) return 'Обзвон ученика';
  return `${type}`;
};

export class Notification {
  id: string = uniqueId();
  date: Moment = moment();
  type: number = NotificationType.None;
  typeTitle: string = '';
  userId: string = '';
  userName: string = '';
  userPhone: string = '';
  userPhoneDesc: string = '';
  comments: string[] = [];

  @observable
  isActive: boolean = false;

  @action
  setActive(active: boolean) {
    this.isActive = active;
  }

  static fromJson(json: NotificationJson) {
    var res = new Notification();
    res.date = moment(json.Date);
    res.type = json.Type;
    res.typeTitle = getNotificationTypeTitle(json.Type);
    res.userId = json.UserId;
    res.userName = json.UserName;
    res.userPhone = json.UserPhone;
    res.userPhoneDesc = json.UserPhoneDesc;
    res.comments = json.Comments;
    return res;
  }
}
