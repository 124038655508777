import { isEmpty } from 'lodash';
import { observable } from 'mobx';
import moment from 'moment';
import { DateUtils } from '../../utils/date';

export type PaymentResponse = {
  ID: number;
  UserID: string;
  SumToPay: number | null;
  SumPayed: number | null;
  SumToNextMonth: number | null;
  Date: string | null;
  Year: number;
  Month: number;
  Comment: string;
  ClientComment: string;
  AdditionalPaymentID: string | null;
  IsTerminal: boolean;
};

export class Payment {
  id: number = 0;
  userId: string = '';
  @observable
  sumToPay: number | null = null;
  @observable
  sumPayed: number | null = null;
  @observable
  sumToNextMonth: number | null = null;
  @observable
  date: moment.Moment | null = null;
  year: number = 0;
  month: number = 0;
  @observable
  comment: string = '';
  @observable
  clientComment: string = '';
  additionalPaymentId: string | null = null;
  isTerminal: boolean = false;

  title: string = 'занятия';

  getPeriodTitle() {
    return DateUtils.formatEx(moment({ year: this.year, month: this.month - 1, day: 1 }), 'MMMM YYYY');
  }

  isSumEmpty() {
    return !this.sumPayed && !this.sumToPay;
  }

  get isNew() {
    return this.id === 0;
  }

  public static fromResponse(resp: PaymentResponse) {
    let result = new Payment();
    result.id = resp.ID;
    result.userId = resp.UserID;
    result.sumToPay = resp.SumToPay;
    result.sumPayed = resp.SumPayed;
    result.sumToNextMonth = resp.SumToNextMonth;
    result.date = isEmpty(resp.Date) ? null : moment(resp.Date!);
    result.year = resp.Year;
    result.month = resp.Month;
    result.comment = resp.Comment;
    result.clientComment = resp.ClientComment;
    result.additionalPaymentId = resp.AdditionalPaymentID;
    result.isTerminal = resp.IsTerminal;

    if (result.sumToPay === 0) result.sumToPay = null;
    if (result.sumPayed === 0) result.sumPayed = null;

    return result;
  }
}

export class PaymentInfo {
  ID: string = '';
  Title: string = '';
  YearFrom: number = 0;
  MonthFrom: number = 0;
  YearTo: number | null = null;
  MonthTo: number | null = null;
}
