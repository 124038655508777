import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, Row, Col, Input } from 'antd';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/storeProvider';
import { useLocalStore } from '../store';
import FormItem from '../../../components/FormItem';
// import styles from './TrainingsCancelDialog.module.scss';

export const TrainingsCancelDialog = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  const handleCancel = () => {
    store.trainingsCancelDialog.hideDialog();
  };

  return (
    <div>
      <Modal
        visible={store.trainingsCancelDialog.isOpen}
        title="Отмена тренировки"
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={() => store.cancelTrainig()} type="primary">
            Применить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <FormItem label="Укажите причину отмены">
              <Input.TextArea
                rows={4}
                value={store.trainingsCancelDialog.comment}
                onChange={(e) => store.trainingsCancelDialog.setComment(e.target.value)}
              />
            </FormItem>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
