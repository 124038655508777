import { Button, DatePicker, Space, Tooltip } from 'antd';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FaSync } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import FilterItem from '../../../components/FilterItem';
import GroupSelect from '../../../components/GroupSelect';
import { useStore } from '../../../stores/storeProvider';
import { Spacer } from '../../../ui-kit';
import { useLocalStore } from '../store';
import styles from './Toolbar.module.scss';

export const Toolbar = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);
  const history = useHistory();

  const updateUrlSearchParams = () => {
    history.replace({
      search:
        '?' +
        new URLSearchParams({
          year: String(store.params.year),
          month: String(store.params.month),
          group: String(store.params.groupId),
        }).toString(),
    });
  };

  const handlePeriodChange = action((value: moment.Moment | null, _: string) => {
    if (value) {
      store.params.year = value.year();
      store.params.month = value.month() + 1;
    } else {
      const currentDate = new Date();
      store.params.year = currentDate.getFullYear();
      store.params.month = currentDate.getMonth() + 1;
    }
    updateUrlSearchParams();
    store.refresh();
  });

  const period = useMemo(() => {
    return moment({ years: store.params.year, months: store.params.month - 1, date: 1 });
  }, [store.params.month, store.params.year]);

  const handleGroupChange = action((value: number | string) => {
    store.params.groupId = typeof value === 'number' ? value : 0;
    updateUrlSearchParams();
    store.refresh();
  });

  return (
    <div className={styles.toolbar}>
      <Space className={styles.filter} size="middle">
        <FilterItem label="">
          <Tooltip title="обновить">
            <Button icon={<FaSync />} onClick={() => store.refresh()}></Button>
          </Tooltip>
        </FilterItem>

        <FilterItem label="Период">
          <DatePicker value={period} onChange={handlePeriodChange} picker="month" allowClear={false} />
        </FilterItem>

        <FilterItem label="Группы">
          <GroupSelect
            groups={store.activeGroups}
            value={store.params.groupId}
            onChange={handleGroupChange}
            allowSelectAll={false}
          />
        </FilterItem>

        <FilterItem label=" ">
          {!store.loading && !store.finalizeInfo.isFinalized && (
            <Tooltip title="Отметка, что информация по группе в текущем месяце полностью подготовлена">
              <Button onClick={() => store.changeMonthFinalize(true)}>Закрыть месяц</Button>
            </Tooltip>
          )}

          {!store.loading && store.finalizeInfo.isFinalized && (
            <div>
              <div>Месяц закрыт</div>
              <div>
                {store.finalizeInfo.finalizeDate} {store.finalizeInfo.finalizeUserName}
              </div>
            </div>
          )}
        </FilterItem>

        <FilterItem label=" ">
          {!store.loading && store.finalizeInfo.isFinalized && (
            <>{appStore.account?.isAdminOrManager && <Button onClick={() => store.changeMonthFinalize(false)}>Открыть</Button>}</>
          )}
        </FilterItem>
      </Space>

      <Spacer />

      <Space className={styles.filter} size="middle">
        <FilterItem label=" ">
          {!store.loading && appStore.account?.isAdminOrManager && (
            <Button onClick={() => store.setIsTrainingsExportDialogOpen(true)}>Экспорт</Button>
          )}
        </FilterItem>
      </Space>
    </div>
  );
});
