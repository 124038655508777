import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Input, Col, Checkbox, Space, DatePicker, Tooltip, Popconfirm } from 'antd';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import FormItem from '../../../../components/FormItem';
import { useLocalStore } from '../store';
import { useStore } from '../../../../stores/storeProvider';
import { action } from 'mobx';
import Divider from '../../../../components/Divider';
import { DeleteOutlined } from '@ant-design/icons';

export const EditPlaceDialog = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  const handleSave = action(() => {
    if (isEmpty(store.editingPlace.name)) {
      message.warning('Не задано наименование', 3);
      return;
    }

    if (isEmpty(store.editingPlace.address)) {
      message.warning('Не задан адрес', 3);
      return;
    }

    store.saveEditPlace();
  });

  const handleCancel = () => {
    store.setIsEditDialogOpen(false);
  };

  return (
    <div>
      <Modal
        visible={store.isEditDialogOpen}
        title={store.editingPlace.id === 0 ? 'Добавление площадки' : 'Изменение площадки'}
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleSave} type="primary" loading={store.saving}>
            Сохранить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <FormItem label="Наименование">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editingPlace.name}
                onChange={action((e) => (store.editingPlace.name = e.target.value))}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={14}>
            <FormItem label="Адрес">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editingPlace.address}
                onChange={action((e) => (store.editingPlace.address = e.target.value))}
              />
            </FormItem>
          </Col>
          <Col span={10}>
            <FormItem label="Координаты">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editingPlace.geoCoords}
                onChange={action((e) => (store.editingPlace.geoCoords = e.target.value))}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Checkbox
              checked={store.editingPlace.isActive}
              onChange={action((e) => (store.editingPlace.isActive = e.target.checked))}
            >
              активна
            </Checkbox>
          </Col>
        </Row>
        {store.canViewRents && (
          <>
            <Divider title="Аренда" bold />

            <Row>
              <Col span={24}>
                <Space direction="vertical" size="middle">
                  <Checkbox
                    checked={store.editingPlace.isRentSumForTraining}
                    onChange={action((e) => (store.editingPlace.isRentSumForTraining = e.target.checked))}
                  >
                    <Tooltip title="арендная плата выставляется за каждую тренировку отдельно в режиме тренировок">
                      арендная плата за тренировку
                    </Tooltip>
                  </Checkbox>

                  {!store.editingPlace.isRentSumForTraining && (
                    <>
                      <Space>
                        <div>Текущая сумма аренды</div>
                        <Input
                          style={{ width: '130px' }}
                          value={store.editingPlaceRent.sum || ''}
                          onChange={(e) => store.editingPlaceRent.setSum(isEmpty(e.target.value) ? null : Number(e.target.value))}
                        />
                        <div>с</div>
                        <DatePicker
                          picker="month"
                          format="MMM YYYY"
                          value={store.editingPlaceRent.dateFrom}
                          onChange={(value) => store.editingPlaceRent.setDateFrom(value)}
                        />
                      </Space>

                      <Button type="link" onClick={action(() => (store.isRentHistoryOpen = !store.isRentHistoryOpen))}>
                        {!store.isRentHistoryOpen ? 'показать историю' : 'скрыть историю'}
                      </Button>
                      {store.isRentHistoryOpen && (
                        <>
                          {store.placesRents
                            .filter((e) => e.placeId === store.editingPlace.id)
                            .map((rent) => (
                              <Space>
                                <div>Сумма</div>
                                <Input
                                  style={{ width: '130px' }}
                                  value={rent.sum || ''}
                                  onChange={(e) => rent.setSum(isEmpty(e.target.value) ? null : Number(e.target.value))}
                                />
                                <div>с</div>
                                <DatePicker
                                  picker="month"
                                  format="MMM YYYY"
                                  value={rent.dateFrom}
                                  onChange={(value) => rent.setDateFrom(value)}
                                />
                                <Popconfirm
                                  title="Вы действительно хотите удалить запись?"
                                  okText="Да"
                                  cancelText="Нет"
                                  onConfirm={() => store.deletePlaceRent(rent)}
                                >
                                  <Tooltip title="удалить" placement="bottom">
                                    <Button shape="circle" icon={<DeleteOutlined />} />
                                  </Tooltip>
                                </Popconfirm>
                              </Space>
                            ))}
                        </>
                      )}
                    </>
                  )}
                </Space>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </div>
  );
});
