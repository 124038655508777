import { observable, action } from 'mobx';
import { TrainingVisitsInfo } from '../../../models/trainings/trainingVisitsInfo';

export class TrainingsVisitsDialogData {
  @observable
  isOpen: boolean = false;

  @observable
  trainingId: number = 0;

  @observable
  trainingVisits: TrainingVisitsInfo = new TrainingVisitsInfo();

  @action
  showDialog(trainingId: number, trainingVisits: TrainingVisitsInfo) {
    this.isOpen = true;
    this.trainingId = trainingId;
    this.trainingVisits = trainingVisits;
  }

  @action
  hideDialog() {
    this.isOpen = false;
  }

  @action
  setComment(comment: string) {
    this.trainingVisits.comment = comment;
  }
}
