export type UserTrainingInfoJson = {
  UserId: string;
  TrainingId: number;
  IsActive: boolean;
  IsTestTraining: boolean;
};

export class UserTrainingInfo {
  userId: string = '';
  trainingId: number = 0;
  isActive: boolean = false;
  isTestTraining: boolean = false;

  public static fromJson(json: UserTrainingInfoJson) {
    let result = new UserTrainingInfo();
    result.userId = json.UserId;
    result.trainingId = json.TrainingId;
    result.isActive = json.IsActive;
    result.isTestTraining = json.IsTestTraining;
    return result;
  }
}
