import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, Collapse, DatePicker, Select, Popconfirm, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { useLocalStore } from '../store';
import { useStore } from '../../../../stores/storeProvider';
import { action, runInAction } from 'mobx';
import { GroupShedule } from '../../../../models/groupShedule';
import { cloneDeep } from 'lodash';
import styles from './EditGroupSheduleDialog.module.scss';

import FormItem from '../../../../components/FormItem';
import { DeleteOutlined } from '@ant-design/icons';
import { DateUtils } from '../../../../utils/date';

const ShedulesEdit: React.FC<{
  items: GroupShedule[];
  onDelete: (item: GroupShedule, shedules: GroupShedule[]) => void;
}> = observer(({ items, onDelete }) => {
  return (
    <div className={styles.shedule_row}>
      {items.map((shedule) => (
        <>
          <FormItem label="Дата начала">
            <DatePicker value={shedule.fromDay} onChange={action((e) => (shedule.fromDay = e !== null ? e : shedule.fromDay))} />
          </FormItem>
          <FormItem label="Дата окончания">
            <DatePicker value={shedule.toDay} onChange={action((e) => (shedule.toDay = e))} />
          </FormItem>
          <FormItem label="День недели">
            <Select style={{ width: '100%' }} onChange={(e) => runInAction(() => (shedule.weekDay = e))} value={shedule.weekDay}>
              <Select.Option value={1}>Понедельник</Select.Option>
              <Select.Option value={2}>Вторник</Select.Option>
              <Select.Option value={3}>Среда</Select.Option>
              <Select.Option value={4}>Четверг</Select.Option>
              <Select.Option value={5}>Пятница</Select.Option>
              <Select.Option value={6}>Суббота</Select.Option>
              <Select.Option value={7}>Воскресенье</Select.Option>
            </Select>
          </FormItem>
          <div>c</div>
          <FormItem label="Часы">
            <Select
              style={{ width: '100%' }}
              onChange={(e) => runInAction(() => (shedule.fromTimeHour = e))}
              value={shedule.fromTimeHour}
            >
              <Select.Option value={6}>06</Select.Option>
              <Select.Option value={7}>07</Select.Option>
              <Select.Option value={8}>08</Select.Option>
              <Select.Option value={9}>09</Select.Option>
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={11}>11</Select.Option>
              <Select.Option value={12}>12</Select.Option>
              <Select.Option value={13}>13</Select.Option>
              <Select.Option value={14}>14</Select.Option>
              <Select.Option value={15}>15</Select.Option>
              <Select.Option value={16}>16</Select.Option>
              <Select.Option value={17}>17</Select.Option>
              <Select.Option value={18}>18</Select.Option>
              <Select.Option value={19}>19</Select.Option>
              <Select.Option value={20}>20</Select.Option>
              <Select.Option value={21}>21</Select.Option>
              <Select.Option value={22}>22</Select.Option>
            </Select>
          </FormItem>
          <div>:</div>
          <FormItem label="Минуты">
            <Select
              style={{ width: '100%' }}
              onChange={(e) => runInAction(() => (shedule.fromTimeMinute = e))}
              value={shedule.fromTimeMinute}
            >
              <Select.Option value={0}>00</Select.Option>
              <Select.Option value={5}>05</Select.Option>
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={15}>15</Select.Option>
              <Select.Option value={20}>20</Select.Option>
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={30}>30</Select.Option>
              <Select.Option value={35}>35</Select.Option>
              <Select.Option value={40}>40</Select.Option>
              <Select.Option value={45}>45</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={55}>55</Select.Option>
            </Select>
          </FormItem>
          <div>по</div>
          <FormItem label="Часы">
            <Select
              style={{ width: '100%' }}
              onChange={(e) => runInAction(() => (shedule.toTimeHour = e))}
              value={shedule.toTimeHour}
            >
              <Select.Option value={6}>06</Select.Option>
              <Select.Option value={7}>07</Select.Option>
              <Select.Option value={8}>08</Select.Option>
              <Select.Option value={9}>09</Select.Option>
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={11}>11</Select.Option>
              <Select.Option value={12}>12</Select.Option>
              <Select.Option value={13}>13</Select.Option>
              <Select.Option value={14}>14</Select.Option>
              <Select.Option value={15}>15</Select.Option>
              <Select.Option value={16}>16</Select.Option>
              <Select.Option value={17}>17</Select.Option>
              <Select.Option value={18}>18</Select.Option>
              <Select.Option value={19}>19</Select.Option>
              <Select.Option value={20}>20</Select.Option>
              <Select.Option value={21}>21</Select.Option>
              <Select.Option value={22}>22</Select.Option>
            </Select>
          </FormItem>
          <div>:</div>
          <FormItem label="Минуты">
            <Select
              style={{ width: '100%' }}
              onChange={(e) => runInAction(() => (shedule.toTimeMinute = e))}
              value={shedule.toTimeMinute}
            >
              <Select.Option value={0}>00</Select.Option>
              <Select.Option value={5}>05</Select.Option>
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={15}>15</Select.Option>
              <Select.Option value={20}>20</Select.Option>
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={30}>30</Select.Option>
              <Select.Option value={35}>35</Select.Option>
              <Select.Option value={40}>40</Select.Option>
              <Select.Option value={45}>45</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={55}>55</Select.Option>
            </Select>
          </FormItem>
          <FormItem label="Удалить">
            <Popconfirm
              title="Вы действительно хотите удалить расписание？"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => onDelete(shedule, items)}
            >
              <Tooltip title="удалить" placement="bottom">
                <Button shape="circle" icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </FormItem>
        </>
      ))}
    </div>
  );
});

export const EditGroupSheduleDialog = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);
  const [shedules, setShedules] = useState<GroupShedule[]>([]);
  const [oldShedules, setOldShedules] = useState<GroupShedule[]>([]);

  useEffect(() => {
    if (store.isEditSheduleDialogOpen) {
      setShedules(store.editDialogGroup.shedules.filter((e) => e.toDay === null).map((e) => cloneDeep(e)));
      setOldShedules(store.editDialogGroup.shedules.filter((e) => e.toDay !== null).map((e) => cloneDeep(e)));
    }
  }, [store.editDialogGroup, store.isEditSheduleDialogOpen]);

  const handleSave = action(() => {
    store.saveGroupShedules(
      [...shedules, ...oldShedules].map((e) => ({
        id: e.id,
        weekDay: e.weekDay,
        FromTimeHour: e.fromTimeHour,
        FromTimeMinute: e.fromTimeMinute,
        ToTimeHour: e.toTimeHour,
        ToTimeMinute: e.toTimeMinute,
        FromDayStr: DateUtils.format(e.fromDay),
        ToDayStr: e.toDay !== null ? DateUtils.format(e.toDay) : '',
      }))
    );
  });

  const handleAdd = () => {
    setShedules([new GroupShedule(), ...shedules]);
  };

  const handleCancel = () => {
    store.setIsEditSheduleDialogOpen(false);
  };

  const handleDelete = action((shedule: GroupShedule, items: GroupShedule[]) => {
    var newItems = items.filter((e) => e !== shedule);
    if (items === shedules) setShedules(newItems);
    else setOldShedules(newItems);
  });

  return (
    <div>
      <Modal
        visible={store.isEditSheduleDialogOpen}
        title="Изменение расписания"
        width={1000}
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleSave} type="primary">
            Сохранить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <div>
          При изменении расписания добавлять новые записи, а в старых проставлять дату окончания. При удалении строки расписания
          все тренировки по этому расписанию будут удалены (удалять только если строка ошибочно добавлена).
        </div>
        <div>
          <Button onClick={handleAdd}>Добавить</Button>
        </div>
        <ShedulesEdit items={shedules} onDelete={handleDelete} />
        <Collapse defaultActiveKey={[]} ghost>
          <Collapse.Panel header="Старые расписания" key="1">
            <ShedulesEdit items={oldShedules} onDelete={handleDelete} />
          </Collapse.Panel>
        </Collapse>
      </Modal>
    </div>
  );
});
