import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popconfirm, Space, Table, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import PageContainer from '../../../components/PageContainer';
import { Place } from '../../../models/places/place';
import { useStore } from '../../../stores/storeProvider';
import { EditPlaceDialog } from './EditPlaceDialog/EditPlaceDialog';
import { useLocalStore } from './store';
import styles from './styles.module.scss';

export const PlacesSettingsPage = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  useEffect(() => {
    document.title = 'Торнадо / Настройки / Площадки';
    appStore.setCurrentPageTitle('Настройки / Площадки');
    store.refresh();
  }, [appStore, store]);

  const handleAdd = () => {
    store.editPlace(new Place());
  };

  const [columns] = useState([
    {
      title: 'Наименование',
      render: (_: any, item: Place) => {
        return <div>{item.name}</div>;
      },
    },
    {
      title: 'Адрес',
      render: (_: any, item: Place) => {
        return <div>{item.address}</div>;
      },
    },
    ...(store.canViewRents
      ? [
          {
            title: 'Аренда',
            render: (_: any, item: Place) => {
              return <div>{item.rentInfo}</div>;
            },
          },
        ]
      : []),
    {
      title: 'Статус',
      render: (_: any, item: Place) => {
        return (
          <div>
            <Checkbox checked={item.isActive}>активная</Checkbox>
          </div>
        );
      },
    },
    {
      title: 'Действия',
      render: (_: any, item: Place) => {
        return (
          <Space>
            <Tooltip title="изменить" placement="bottom">
              <Button shape="circle" icon={<EditOutlined />} onClick={(e) => store.editPlace(item)} />
            </Tooltip>
            <Popconfirm
              title="Вы действительно хотите удалить площадку？"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => store.deletePlace(item.id)}
            >
              <Tooltip title="удалить" placement="bottom">
                <Button shape="circle" icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
    },
  ]);

  return (
    <PageContainer className={styles.page}>
      <div>
        <Button onClick={handleAdd}>Добавить площадку</Button>
      </div>

      {!isEmpty(store.places) && (
        <Table className={styles.table} columns={columns} dataSource={store.places} pagination={false} />
      )}

      <EditPlaceDialog />
    </PageContainer>
  );
});
