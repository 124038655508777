import React from 'react';
import './Error.scss';

export const ErrorPage = () => {
  return (
    <div className="Error">
      <div className="ErrorMessage">Ошибка загрузки приложения. Попробуйте обновить страницу</div>
    </div>
  );
};
