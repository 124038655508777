import { observable } from 'mobx';
import moment from 'moment';

export type UnionTrainingResponse = {
  TrainingDate: string;
  GroupID: number;
};

export class UnionTraining {
  @observable
  trainingDate: moment.Moment = moment();

  @observable
  groupId: number = 0;

  public static fromResponse(resp: UnionTrainingResponse) {
    const result = new UnionTraining();
    result.groupId = resp.GroupID;
    result.trainingDate = moment(resp.TrainingDate);
    return result;
  }
}
