import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Select, Button, message, Row, Col, DatePicker } from 'antd';
import { useStore } from '../../../../stores/storeProvider';
import { observer } from 'mobx-react';
import { UserEditDialogState } from '../UserEditDialogState';
import { action } from 'mobx';
import { UserSaveMode } from '../../../../models/user';
import FormItem from '../../../FormItem';
import moment from 'moment';

const { Option } = Select;

export interface IUserEditChangeGroupDialogProps {
  editDialogState: UserEditDialogState;
  isOpen: boolean;
  onClose: () => void;
}

export const UserEditChangeGroupDialog: React.FC<IUserEditChangeGroupDialogProps> = observer(
  ({ editDialogState, isOpen, onClose }) => {
    const { appStore } = useStore();
    const [groupId, setGroupId] = useState<number | null>(null);
    const [dateFrom, setDateFrom] = useState<moment.Moment | null>(moment());

    useEffect(() => {
      if (isOpen) {
        setGroupId(editDialogState.userModel.newGroupId);
        setDateFrom(moment(editDialogState.userModel.newGroupDateFrom));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleApply = action(() => {
      if (!groupId) {
        message.warning('Не задана группа для перевода', 3);
        return;
      }

      if (!dateFrom) {
        message.warning('Не задана дата перевода', 3);
        return;
      }

      editDialogState.userModel.saveMode = UserSaveMode.ChangeGroup;
      editDialogState.userModel.newGroupId = groupId!;
      editDialogState.userModel.newGroupDateFrom = dateFrom!;

      onClose();
    });

    const handleCancel = () => {
      onClose();
    };

    return (
      <div>
        <Modal
          visible={isOpen}
          title="Перевод в другую группу"
          onCancel={handleCancel}
          footer={[
            <Button key="apply" onClick={handleApply} type="primary">
              Применить
            </Button>,
            <Button key="cancel" onClick={handleCancel}>
              Закрыть
            </Button>,
          ]}
        >
          <Row>Ученик будет переведён из текущих групп в новую</Row>
          <Row>
            <Col span={12}>
              <FormItem label="Группа">
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  value={groupId || undefined}
                  onChange={(value) => {
                    setGroupId(value);
                  }}
                >
                  {appStore.groupStore.activeGroups?.map((group) => (
                    <Option key={group.id} value={group.id}>
                      {group.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Дата перевода">
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder=""
                  format="DD.MM.YYYY"
                  value={dateFrom}
                  onChange={(value) => {
                    setDateFrom(value);
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
);
