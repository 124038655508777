import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, Row, Col, Input, Select, DatePicker, Space, TimePicker } from 'antd';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/storeProvider';
import { useLocalStore } from '../store';
import FormItem from '../../../components/FormItem';
// import styles from './TrainingsCancelDialog.module.scss';

export const TrainingsTransferDialog = observer(() => {
  const { appStore, placesStore } = useStore();
  const store = useLocalStore(appStore);

  const handleCancel = () => {
    store.trainingsTransferDialog.hideDialog();
  };

  return (
    <div>
      <Modal
        visible={store.trainingsTransferDialog.isOpen}
        title="Перенос тренировки"
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={() => store.transferTraining()} type="primary">
            Применить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <FormItem label="Новая дата и время">
              <Space>
                <DatePicker
                  format="DD.MM.YYYY"
                  value={store.trainingsTransferDialog.date}
                  onChange={(e) => store.trainingsTransferDialog.setDate(e)}
                />
                <TimePicker
                  value={store.trainingsTransferDialog.fromTime}
                  onChange={(e) => store.trainingsTransferDialog.setFromTime(e)}
                  format="HH:mm"
                  minuteStep={5}
                />
                <TimePicker
                  value={store.trainingsTransferDialog.toTime}
                  onChange={(e) => store.trainingsTransferDialog.seToTime(e)}
                  format="HH:mm"
                  minuteStep={5}
                />
              </Space>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <FormItem label="Место проведения">
              <Select
                style={{ width: '100%' }}
                dropdownMatchSelectWidth={false}
                listHeight={500}
                onChange={(value) => store.trainingsTransferDialog.setPlaceId(value)}
                value={store.trainingsTransferDialog.placeId}
              >
                {placesStore.activePlaces.map((place) => (
                  <Select.Option key={place.id} value={place.id}>
                    {place.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label="Причина переноса">
              <Input
                value={store.trainingsTransferDialog.comment}
                onChange={(e) => store.trainingsTransferDialog.setComment(e.target.value)}
              />
            </FormItem>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
