import { NotificationsPageApi } from './api';
import { NotificationPageParams } from './models/notificationPageParams';
import { Notification } from './models/notification';
import { action, computed, observable, runInAction } from 'mobx';
import { AppStore } from '../../stores/AppStore';
import { NotificationPagePagination } from './models/notificationPagePagination';

export class NotificationsPageStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  loading: boolean = false;

  @observable
  params: NotificationPageParams = new NotificationPageParams();

  @observable
  notifications: Notification[] = [];

  @observable
  visibleNotifications: Notification[] = [];

  pagination: NotificationPagePagination = new NotificationPagePagination();

  @action
  async init() {
    try {
      await this.refresh();
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async refresh() {
    try {
      this.loading = true;
      this.pagination.setPageNumber(1);
      const notifications = await NotificationsPageApi.fetchData(this.params);
      runInAction(() => {
        this.notifications = notifications;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @computed
  get currentPageNotifications() {
    return this.notifications.slice(
      (this.pagination.pageNumber - 1) * this.pagination.pageSize,
      this.pagination.pageNumber * this.pagination.pageSize
    );
  }
}

let localStore: NotificationsPageStore | null = null;

export const useLocalStore = (appStore: AppStore) => {
  if (localStore === null) {
    localStore = new NotificationsPageStore(appStore);
  }
  return localStore;
};
