import { TrainingVisitSaveModel } from './../models/trainings/trainingVisitSaveModel';
import { Moment } from 'moment';
import { Training, TrainingResponse } from '../models/trainings/training';
import axios from 'axios';
import { TrainingUserInfo, TrainingUserInfoResponse } from '../models/trainings/trainingUserInfo';
import { TrainingVisitsInfo, TrainingVisitsInfoJson } from '../models/trainings/trainingVisitsInfo';

export const TrainingsApi = {
  fetchGroupTrainings: async (groupID: number, year: number, month: number): Promise<Training[]> => {
    const response = await axios.get<TrainingResponse[]>('/Admin/GetGroupTrainings', {
      params: { groupID, year, month },
    });
    return response.data.map((item) => Training.fromResponse(item));
  },

  fetchAllUserTrainings: async (userId: string): Promise<TrainingUserInfo[]> => {
    const response = await axios.get<TrainingUserInfoResponse[]>('/api/trainings/GetAllUserTrainings', {
      params: { userId },
    });
    return response.data.map((item) => TrainingUserInfo.fromResponse(item));
  },

  fetchTrainingsDetail: async (trainingIds: number[]) => {
    const response = await axios.post<TrainingResponse[]>('/api/trainings/GetTrainingsDetail', {
      trainingIds: trainingIds,
    });
    return response.data.map((item) => Training.fromResponse(item));
  },

  cancelTraining: async (trainingId: number, comment: string) => {
    await axios.post('/admin/TrainingCancel', {
      id: trainingId,
      comment,
    });
  },

  restoreTraining: async (trainingId: number) => {
    await axios.post('/admin/TrainingRestore', {
      id: trainingId,
    });
  },

  transferTraining: async (
    trainingId: number,
    comment: string,
    date: Moment,
    fromTimeHour: number,
    fromTimeMinute: number,
    toTimeHour: number,
    toTimeMinute: number,
    placeId: number
  ) => {
    await axios.post('/admin/TrainingTransfer', {
      id: trainingId,
      comment,
      date,
      fromTimeHour,
      fromTimeMinute,
      toTimeHour,
      toTimeMinute,
      placeID: placeId,
    });
  },

  saveTrainingInfo: async (
    trainingId: number,
    comment: string,
    placeId: number,
    coachesIds: string[],
    coachesExIds: string[]
  ) => {
    await axios.post('/admin/SaveTrainingInfo', {
      trainingId,
      comment,
      coaches: coachesIds,
      coachesEx: coachesExIds,
      placeID: placeId,
      isApp2: true,
    });
  },

  fetchTrainingVisits: async (trainingId: number): Promise<TrainingVisitsInfo> => {
    const response = await axios.post<TrainingVisitsInfoJson>('/admin/GetTrainingInfo', {
      trainingID: trainingId,
    });
    return TrainingVisitsInfo.fromJson(response.data);
  },

  saveTrainingVisits: async (trainingId: number, comment: string, placeId: number, userVisits: TrainingVisitSaveModel[]) => {
    await axios.post('/admin/SaveTrainingVisits', {
      trainingID: trainingId,
      comment,
      userVisits,
      placeID: placeId,
    });
  },
};
