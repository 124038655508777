import { Table } from 'antd';
import classNames from 'classnames';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import PageContainer from '../../components/PageContainer';
import UserEditButton from '../../components/User/UserEditButton';
import { useStore } from '../../stores/storeProvider';
import { PaymentsPageUserRow } from './models/paymentsPageUserRow';
import { useLocalStore } from './store';
import styles from './styles.module.scss';
import { Toolbar } from './Toolbar/Toolbar';
import { UserPayments } from './UserPayments/UserPayments';

export const PaymentsPage = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  useEffect(() => {
    document.title = 'Торнадо / Платежи';
    appStore.setCurrentPageTitle('Платежи');

    store.init();
  }, [appStore, store]);

  const [columns] = useState([
    {
      title: 'Ученик',
      render: (_: any, item: PaymentsPageUserRow) => {
        return (
          <div className={styles.user}>
            <div className={styles.user_name}>{item.userName}</div>

            {item.abonnementIds.map((abonnementId) => (
              <div className={styles.user_abonnement}>{appStore.abonnementsStore.getNameById(abonnementId)}</div>
            ))}
            {item.discountIds.map((discountId) => (
              <div className={styles.user_discount}>{appStore.discountsStore.getNameById(discountId)}</div>
            ))}

            <div className={styles.user_edit}>
              <UserEditButton userId={item.userId} />
            </div>
          </div>
        );
      },
    },
    {
      title: 'Группа',
      render: (_: any, item: PaymentsPageUserRow) => {
        return <div>{item.groupsName}</div>;
      },
    },
    {
      title: 'Платежи',
      render: (_: any, item: PaymentsPageUserRow) => {
        return <UserPayments item={item} />;
      },
    },
  ]);

  return (
    <PageContainer className={styles.page}>
      <Toolbar />

      <div>Всего: {store.items.length}</div>

      <Table
        className={classNames(styles.payments_table, { [styles.payments_table_calc_mode]: store.calcMode })}
        columns={columns}
        dataSource={store.items}
        rowKey="userID"
        bordered
        size="middle"
        loading={store.loading}
        pagination={{
          pageSize: 50,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        onRow={(item) => {
          return {
            onMouseEnter: action(() => {
              item.isActive = true;
            }),
            onMouseLeave: action(() => {
              item.isActive = false;
            }),
          };
        }}
      />
    </PageContainer>
  );
});
