import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Input, Col, Checkbox, Select, DatePicker } from 'antd';
import { observer } from 'mobx-react';
import { difference, isEmpty } from 'lodash';
import FormItem from '../../../../components/FormItem';
import { useLocalStore } from '../store';
import { useStore } from '../../../../stores/storeProvider';
import { action } from 'mobx';
import moment, { Moment } from 'moment';

export const EditGroupDialog = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);
  const [name, setName] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [primaryPlaceId, setPrimaryPlaceId] = useState(0);
  const [placesIds, setPlacesIds] = useState<number[]>([]);
  const [coachesIds, setCoachesIds] = useState<string[]>([]);
  const [coachesChangeDate, setCoachesChangeDate] = useState<Moment>(moment());

  useEffect(() => {
    if (store.isEditDialogOpen) {
      setName(store.editDialogGroup.name);
      setIsActive(store.editDialogGroup.isActive);
      setPrimaryPlaceId(store.editDialogGroup.primaryPlaceId);
      setPlacesIds(store.editDialogGroup.places.map((e) => e.id));
      setCoachesIds(store.editDialogGroup.coaches.map((e) => e.id));
    }
  }, [store.editDialogGroup, store.isEditDialogOpen]);

  const handleSave = action(() => {
    if (isEmpty(name)) {
      message.warning('Не задано наименование', 3);
      return;
    }

    if (primaryPlaceId === 0) {
      message.warning('Не выбрана основная площадка', 3);
      return;
    }

    store.saveEditGroup({
      groupID: store.editDialogGroup.id,
      groupIsActive: isActive,
      groupName: name,
      groupPlaces: placesIds,
      groupCoaches: coachesIds,
      groupPrimaryPlaceID: primaryPlaceId,
      groupCoachesChangeDate: coachesChangeDate.toISOString(),
    });
  });

  const handleCancel = () => {
    store.setIsEditDialogOpen(false);
  };

  const handlePlacesChange = (value: number[]) => {
    setPlacesIds(value);
  };

  const handleCoachesChange = (value: string[]) => {
    setCoachesIds(value);
  };

  return (
    <div>
      <Modal
        visible={store.isEditDialogOpen}
        title={isEmpty(store.editDialogGroup.id) ? 'Добавление группы' : 'Изменение группы'}
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleSave} type="primary">
            Сохранить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <FormItem label="Наименование">
              <Input style={{ width: '100%' }} placeholder="" value={name} onChange={(e) => setName(e.target.value)} />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)}>
              активна
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label="Площадки">
              <Select style={{ width: '100%' }} mode="multiple" onChange={handlePlacesChange} value={placesIds}>
                {store.places
                  .filter((e) => e.isActive || store.editDialogGroup.places.findIndex((p) => p.id === e.id) !== -1)
                  .map((place) => (
                    <Select.Option key={place.id} value={place.id}>
                      {place.name} ({place.address})
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label="Основная площадка">
              <Select style={{ width: '100%' }} onChange={(e) => setPrimaryPlaceId(e)} value={primaryPlaceId}>
                <Select.Option value="0">площадка не выбрана</Select.Option>
                {store.places
                  .filter((e) => placesIds.includes(e.id))
                  .map((place) => (
                    <Select.Option key={place.id} value={place.id}>
                      {place.name}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label="Тренеры">
              <Select style={{ width: '100%' }} mode="multiple" onChange={handleCoachesChange} value={coachesIds}>
                {store.coaches.map((coach) => (
                  <Select.Option key={coach.id} value={coach.id}>
                    {coach.displayName}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        {(difference(
          store.editDialogGroup.coaches.map((e) => e.id),
          coachesIds
        ).length !== 0 ||
          store.editDialogGroup.coaches.length !== coachesIds.length) && (
          <Row>
            <Col span={24}>
              <FormItem label="Дата изменения тренеров" simple={true}>
                <div>
                  Внимание! У группы изменен список тренеров. Введите дату изменений (последней датой тренера в составе группы
                  будет считаться день, предшествующий дате изменения).
                </div>
                <div>
                  <DatePicker
                    value={coachesChangeDate}
                    onChange={(e) => setCoachesChangeDate(e !== null ? e : moment())}
                    allowClear={false}
                  />
                </div>
              </FormItem>
            </Col>
          </Row>
        )}
      </Modal>
    </div>
  );
});
