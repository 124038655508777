import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Input, Col, DatePicker } from 'antd';
import { observer } from 'mobx-react';
import { UserEditDialogState } from '../UserEditDialogState';
import { action } from 'mobx';
import { UserSaveMode, UserStatus } from '../../../../models/user';
import FormItem from '../../../FormItem';
import moment from 'moment';
import { DateUtils } from '../../../../utils/date';

export interface IUserEditArchiveDialogProps {
  editDialogState: UserEditDialogState;
  isOpen: boolean;
  onClose: () => void;
}

export const UserEditArchiveDialog: React.FC<IUserEditArchiveDialogProps> = observer(({ editDialogState, isOpen, onClose }) => {
  const [comment, setComment] = useState('');
  const [callDate, setCallDate] = useState<moment.Moment | null>(null);
  const [dateFrom, setDateFrom] = useState<moment.Moment | null>(moment());

  useEffect(() => {
    if (isOpen) {
      setComment(editDialogState.userModel.archiveComment || '');
      setCallDate(editDialogState.userModel.archiveCallDate != null ? editDialogState.userModel.archiveCallDate : null);
      setDateFrom(editDialogState.userModel.archiveDateFrom != null ? editDialogState.userModel.archiveDateFrom : moment());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleApply = action(() => {
    if (dateFrom === null) {
      message.warning('Не задана дата перевода в архив', 3);
      return;
    }

    if (!comment) {
      message.warning('Не задана причина перевода в архив', 3);
      return;
    }

    if (callDate === null) {
      message.warning('Не задана дата звонка', 3);
      return;
    }

    editDialogState.userModel.saveMode = UserSaveMode.Archive;
    editDialogState.userModel.Status = UserStatus.Archive;
    editDialogState.userModel.archiveComment = comment;
    editDialogState.userModel.archiveDateFrom = dateFrom;
    editDialogState.userModel.archiveCallDate = callDate;

    onClose();
  });

  const handleCancel = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        visible={isOpen}
        title="Перевод в архив"
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleApply} type="primary">
            Применить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>{`Перевод в архив с ${DateUtils.format_DDMMYYYY(dateFrom)}`}</Row>
        <Row>
          <Col span={12}>
            <FormItem label="Дата звонка">
              <DatePicker
                style={{ width: '100%' }}
                placeholder=""
                format="DD.MM.YYYY"
                value={callDate}
                onChange={(value) => setCallDate(value)}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label="Причина">
              <Input style={{ width: '100%' }} value={comment} onChange={(e) => setComment(e.target.value)} />
            </FormItem>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
