import { observable } from 'mobx';

export type DiscountResponse = {
  ID: string;
  Name: string;
  IsActive: boolean;
  Description: string;
  Sum: number | null;
  Percent: number | null;
};

export type UserDiscount = {
  ID: string;
  Discount: DiscountResponse;
  DateFrom: string;
  DateTo: string | null;
};

export class Discount {
  ID: string = '';

  @observable
  Name: string = '';

  @observable
  IsActive: boolean = true;

  @observable
  Description: string = '';

  @observable
  Sum: number | null = null;

  @observable
  Percent: number | null = null;

  public static fromResponse(resp: DiscountResponse) {
    const result = new Discount();
    result.ID = resp.ID;
    result.Name = resp.Name;
    result.IsActive = resp.IsActive;
    result.Description = resp.Description;
    result.Sum = resp.Sum;
    result.Percent = resp.Percent;
    return result;
  }
}

export type DiscountSaveModel = {
  ID: string;
  Name: string;
  IsActive: boolean;
  Description: string;
  Sum: number | null;
  Percent: number | null;
};
