import axios from 'axios';
import { AdditionalPayment, AdditionalPaymentResponse, AdditionalPaymentSaveModel } from './../models/payments/additionalPayment';
import { Payment, PaymentResponse } from '../models/payments/payment';
import { PaymentOperation, PaymentOperationResponse } from '../models/payments/paymentOperation';
import { PaymentsParams } from '../models/payments/paymentsParams';
import { TrainingsMonthFinalizeInfoResponse, TrainingsMonthFinalizeInfo } from '../pages/Trainings/models/trainingsMonthFinalizeInfo';

export const PaymentApi = {
  fetchAllUserPayments: async (userId: string): Promise<Payment[]> => {
    const response = await axios.get<PaymentResponse[]>('/api/payments/GetAllUserPayments', {
      params: { userId },
    });
    return response.data.map((resp) => Payment.fromResponse(resp));
  },

  fetchPayment: async (paymentId: number): Promise<Payment> => {
    const response = await axios.get<PaymentResponse>('/api/payments/GetPaymentById', { params: { paymentID: paymentId } });
    return Payment.fromResponse(response.data);
  },

  fetchPaymentsByUsersIds: async (usersIds: string[], year: number, month: number): Promise<Payment[]> => {
    const response = await axios.post<PaymentResponse[]>('/api/payments/GetPayments', { usersIds, year, month });
    return response.data.map((e) => Payment.fromResponse(e));
  },

  savePayment: async (payment: Payment): Promise<Payment> => {
    const paymentSaveModel: PaymentResponse = {
      ID: payment.id,
      UserID: payment.userId,
      SumToPay: payment.sumToPay,
      SumPayed: payment.sumPayed,
      SumToNextMonth: payment.sumToNextMonth,
      Date: payment.date ? payment.date.toISOString() : null,
      Year: payment.year,
      Month: payment.month,
      Comment: payment.comment,
      ClientComment: payment.clientComment,
      AdditionalPaymentID: payment.additionalPaymentId,
      IsTerminal: false,
    };
    const response = await axios.post<PaymentResponse>('/api/payments/SavePaymentEx', paymentSaveModel);
    return Payment.fromResponse(response.data);
  },

  deletePayment: async (paymentId: number): Promise<void> => {
    await axios.post<PaymentResponse>('/api/payments/DeletePayment', { paymentId });
  },

  fetchAdditionalPayments: async (): Promise<AdditionalPayment[]> => {
    const response = await axios.get<AdditionalPaymentResponse[]>('/api/payments/GetAdditionalPayments');
    return response.data.map((payment) => AdditionalPayment.fromResponse(payment));
  },

  saveAdditionalPayment: async (additionalPayment: AdditionalPaymentSaveModel): Promise<void> => {
    await axios.post('/api/payments/SaveAdditionalPayment', additionalPayment);
  },

  deleteAdditionalPayment: async (paymentId: string): Promise<void> => {
    await axios.post('/api/payments/DeleteAdditionalPayment', { paymentId: paymentId });
  },

  fetchPaymentOperations: async (paymentId: number): Promise<PaymentOperation[]> => {
    const response = await axios.get<PaymentOperationResponse[]>('/api/payments/GetPaymentOperations', {
      params: { paymentID: paymentId },
    });
    return response.data.map((payment) => PaymentOperation.fromResponse(payment));
  },

  fetchPaymentsParams: async () => {
    const response = await axios.get<PaymentsParams>('/api/settings/GetPaymentsParams');
    return PaymentsParams.fromJson(response.data);
  },

  savePaymentsParams: async (paymentsParams: PaymentsParams): Promise<void> => {
    await axios.post('/api/settings/SavePaymentsParams', { paymentsParams });
  },

  finalizeMonth: async (groupId: number, year: number, month: number, finalize: boolean) => {
    await axios.post('/admin/FinalizeGroupMonth', {
      groupID: groupId,
      year: year,
      month: month,
      finalize: finalize,
    });
  },

  fetchMonthFinalizeInfo: async (groupId: number, year: number, month: number) => {
    const response = await axios.get<TrainingsMonthFinalizeInfoResponse>('/admin/GetTrainingsModelParams', {
      params: { groupId, month, year },
    });
    return TrainingsMonthFinalizeInfo.fromResponse(response.data);
  },
};
