import { Moment } from 'moment';
import { action, observable } from 'mobx';

export enum NotificationsPeriod {
  All = 0,
  Today = 1,
  CurrentWeek = 2,
  Custom = 3,
}

export class NotificationPageParams {
  @observable
  period: NotificationsPeriod = NotificationsPeriod.Today;

  @observable
  periodFrom: Moment | null = null;

  @observable
  periodTo: Moment | null = null;

  @action
  setPeriod(period: NotificationsPeriod) {
    this.period = period;
  }

  @action
  setCustomPeriod(periodFrom: Moment | null, periodTo: Moment | null) {
    this.periodFrom = periodFrom;
    this.periodTo = periodTo;
  }
}
