import { ErrorResponse, UnauthorizedResponse } from './types/common';
import { AccountResponse } from './types/account';
import axios from 'axios';

export const AccountApi = {
  fetchAccount: async () => {
    const response = await axios.get<AccountResponse | UnauthorizedResponse>('/api/users/GetAuthData');
    if ((response.data as UnauthorizedResponse).unauthorized) {
      throw Error('unauthorized');
    }
    return response.data as AccountResponse;
  },

  login: async (username: string, password: string) => {
    const response = await axios.post('/api/users/Login', {
      username,
      password,
    });
    if ((response.data as ErrorResponse).error) {
      throw Error((response.data as ErrorResponse).error);
    }
  },

  logout: async () => {
    await axios.post('/api/users/Logout');
  },

  setCurrentRegion: async (regionId: string) => {
    await axios.post('/api/users/SetCurrentRegion', {
      regionID: regionId,
    });
  },
};
