import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, Row, Input, Col, Spin, Select, Space } from 'antd';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { useStore } from '../../../stores/storeProvider';
import { useLocalStore } from '../store';
import FormItem from '../../../components/FormItem';
import styles from './TrainingsExportDialog.module.scss';

export const TrainingsExportDialog = observer(() => {
  const { appStore, groupStore } = useStore();
  const store = useLocalStore(appStore);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await store.loadTrainingsExportParams();
      setLoading(false);
    };
    if (store.isTrainingsExportDialogOpen && !store.isTrainigsExportParamsLoaded) {
      init();
    }
  }, [store, store.isTrainingsExportDialogOpen]);

  const handleCancel = () => {
    store.setIsTrainingsExportDialogOpen(false);
  };

  return (
    <div>
      <Modal
        visible={store.isTrainingsExportDialogOpen}
        title="Экспорт в Excel"
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={() => store.exportTrainings()} type="primary">
            Экспорт
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Spin spinning={loading} />
        <Row>
          <Col span={24}>
            <FormItem label="Группы">
              <Select
                style={{ width: '100%' }}
                dropdownMatchSelectWidth={false}
                listHeight={500}
                onChange={action((value: number[]) => (store.trainingsExportGroupsIds = value))}
                mode="multiple"
                value={store.trainingsExportGroupsIds}
              >
                {groupStore.activeGroups.map((group) => (
                  <Select.Option key={group.id} value={group.id}>
                    {group.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <div>Суммы для отметок:</div>
            <div className={styles.params}>
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.trainingsExportParams.visitSum}
                onChange={action((e) => (store.trainingsExportParams.visitSum = e.target.value))}
              />
              <div>Посещено</div>

              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.trainingsExportParams.visitNotPayedSum}
                onChange={action((e) => (store.trainingsExportParams.visitNotPayedSum = e.target.value))}
              />
              <div>Посещено (не оплачено)</div>

              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.trainingsExportParams.skipSum}
                onChange={action((e) => (store.trainingsExportParams.skipSum = e.target.value))}
              />
              <div>Пропуск</div>

              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.trainingsExportParams.healthSkipSum}
                onChange={action((e) => (store.trainingsExportParams.healthSkipSum = e.target.value))}
              />
              <div>Болезнь (без справки)</div>

              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.trainingsExportParams.healthCertificateSum}
                onChange={action((e) => (store.trainingsExportParams.healthCertificateSum = e.target.value))}
              />
              <div>Справка</div>

              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.trainingsExportParams.vacationSum}
                onChange={action((e) => (store.trainingsExportParams.vacationSum = e.target.value))}
              />
              <div>Отпуск</div>

              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.trainingsExportParams.testSum}
                onChange={action((e) => (store.trainingsExportParams.testSum = e.target.value))}
              />
              <div>Пробная</div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <div style={{ marginTop: '15px' }}>Оплата доп. тренеров:</div>
            <Space>
              <div>От</div>
              <Input
                style={{ width: '40px' }}
                placeholder=""
                value={store.trainingsExportParams.CoachExFrom1Cnt ?? ''}
                onChange={action((e) => (store.trainingsExportParams.CoachExFrom1Cnt = Number(e.target.value)))}
              />
              <div>учеников</div>
              <div>сумма</div>
              <Input
                style={{ width: '100px' }}
                placeholder=""
                value={store.trainingsExportParams.CoachExFrom1Sum ?? ''}
                onChange={action((e) => (store.trainingsExportParams.CoachExFrom1Sum = Number(e.target.value)))}
              />
            </Space>
            <Space>
              <div>От</div>
              <Input
                style={{ width: '40px' }}
                placeholder=""
                value={store.trainingsExportParams.CoachExFrom2Cnt ?? ''}
                onChange={action((e) => (store.trainingsExportParams.CoachExFrom2Cnt = Number(e.target.value)))}
              />
              <div>учеников</div>
              <div>сумма</div>
              <Input
                style={{ width: '100px' }}
                placeholder=""
                value={store.trainingsExportParams.CoachExFrom2Sum ?? ''}
                onChange={action((e) => (store.trainingsExportParams.CoachExFrom2Sum = Number(e.target.value)))}
              />
            </Space>
            <Space>
              <div>От</div>
              <Input
                style={{ width: '40px' }}
                placeholder=""
                value={store.trainingsExportParams.CoachExFrom3Cnt ?? ''}
                onChange={action((e) => (store.trainingsExportParams.CoachExFrom3Cnt = Number(e.target.value)))}
              />
              <div>учеников</div>
              <div>сумма</div>
              <Input
                style={{ width: '100px' }}
                placeholder=""
                value={store.trainingsExportParams.CoachExFrom3Sum ?? ''}
                onChange={action((e) => (store.trainingsExportParams.CoachExFrom3Sum = Number(e.target.value)))}
              />
            </Space>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
