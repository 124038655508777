import { UserPaymentCalcTraining, UserPaymentCalcTrainingResponse } from './userPaymentCalcTraining';
import { isEmpty } from 'lodash';
import { observable } from 'mobx';
import { Payment, PaymentResponse } from '../../../models/payments/payment';

export type UserPaymentCalculationResponse = {
  ID: string;
  UserID: string;
  Payment: PaymentResponse | null;
  PrevPayment: PaymentResponse | null;
  Sum: number | null;
  Detail: string[];
  CurMonthTrainings: UserPaymentCalcTrainingResponse[];
  PrevMonthTrainings: UserPaymentCalcTrainingResponse[];
};

export class UserPaymentCalculation {
  payment: Payment | null = null;
  prevPayment: Payment | null = null;
  id: string = '';
  userId: string = '';
  @observable
  sum: number | null = null;
  detail: string[] = [];
  isChanged: boolean = false;
  curMonthTrainings: UserPaymentCalcTraining[] = [];
  prevMonthTrainings: UserPaymentCalcTraining[] = [];

  isEmpty() {
    return isEmpty(this.detail);
  }

  public static fromResponse(resp: UserPaymentCalculationResponse) {
    const result = new UserPaymentCalculation();
    result.id = resp.ID;
    result.userId = resp.UserID;
    result.sum = resp.Sum;
    result.detail = resp.Detail;
    result.payment = resp.Payment ? Payment.fromResponse(resp.Payment) : null;
    result.prevPayment = resp.PrevPayment ? Payment.fromResponse(resp.PrevPayment) : null;
    result.curMonthTrainings = resp.CurMonthTrainings.map((e) => UserPaymentCalcTraining.fromResponse(e));
    result.prevMonthTrainings = resp.PrevMonthTrainings.map((e) => UserPaymentCalcTraining.fromResponse(e));
    return result;
  }
}
