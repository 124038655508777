import 'normalize.css';
import 'antd/dist/antd.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from './stores/storeProvider';
import { BrowserRouter } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import antRuLocale from 'antd/es/locale/ru_RU';
import 'moment/locale/ru';

import AppCore from './components/AppCore';
import AppRoutes from './components/AppRoutes';

import UserAddButton from './components/User/UserAddButton';
import { UserEditDialogProvider } from './components/User/UserEditDialog/UserEditDialogProvider';
import UserEditButton from './components/User/UserEditButton';

if (document.getElementById('root') !== null) {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <StoreProvider>
          <ConfigProvider locale={antRuLocale}>
            <AppCore>
              <AppRoutes />
            </AppCore>
          </ConfigProvider>
        </StoreProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

if (document.getElementById('element-user-add') !== null) {
  ReactDOM.render(
    <React.StrictMode>
      <StoreProvider>
        <ConfigProvider locale={antRuLocale}>
          <AppCore>
            <UserEditDialogProvider>
              <UserAddButton onSave={() => {}} />
            </UserEditDialogProvider>
          </AppCore>
        </ConfigProvider>
      </StoreProvider>
    </React.StrictMode>,
    document.getElementById('element-user-add')
  );
}

document.addEventListener('usersLoaded', function (event) {
  document.querySelectorAll<HTMLElement>('.element-user-edit').forEach((element) => {
    ReactDOM.render(
      <React.StrictMode>
        <StoreProvider>
          <ConfigProvider locale={antRuLocale}>
            <UserEditDialogProvider>
              <UserEditButton userId={element.getAttribute('data-id') || ''} onSave={() => {}} />
            </UserEditDialogProvider>
          </ConfigProvider>
        </StoreProvider>
      </React.StrictMode>,
      element
    );
  });
});
