import React, { ReactText, useEffect, useState } from 'react';
import { Button, Space, Spin, Tree, Input } from 'antd';
import { observer } from 'mobx-react';
import { UsersPageStore } from '../UsersPageStore';
import { action } from 'mobx';
import { UserSourceDataItem } from '../../../models/user';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import { isEmpty } from 'lodash';

const { Search } = Input;

export interface IUsersPageSourcesFilterProps {
  usersPageStore: UsersPageStore;
  setSelectedKeys: (selectedKeys: string[]) => void;
  confirm: () => void;
  clearFilters?: () => void;
}

const isItemVisible = (searchText: string, item: UserSourceDataItem): boolean => {
  return item.title.toLowerCase().indexOf(searchText) !== -1 || item.children.some((child) => isItemVisible(searchText, child));
};

const getChildNodesRecursive = (item: UserSourceDataItem, childs: UserSourceDataItem[] | null = null): UserSourceDataItem[] => {
  let curChilds = childs || [];

  item.children.forEach((child) => {
    if (!curChilds.some((item) => item.key === child.key)) {
      curChilds.push(child);
    }
    getChildNodesRecursive(child, curChilds);
  });

  return curChilds;
};

const isFirstParentOfSecond = (item1: UserSourceDataItem, item2: UserSourceDataItem): boolean => {
  let childs: UserSourceDataItem[] = [];

  getChildNodesRecursive(item1, childs);

  return childs.some((node) => node.key === item2.key);
};

const filterItem = (searchText: string, item: UserSourceDataItem, items: UserSourceDataItem[]) => {
  if (isItemVisible(searchText, item)) {
    let filteredItem = {
      type: item.type,
      key: item.key,
      title: item.title,
      children: [],
    };

    items.push(filteredItem);

    item.children.forEach((childItem) => filterItem(searchText, childItem, filteredItem.children));
  }
};

export const UsersPageSourcesFilter: React.FC<IUsersPageSourcesFilterProps> = observer(
  ({ usersPageStore, setSelectedKeys, confirm, clearFilters }) => {
    const [searchText, setSearchText] = useState('');
    const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [checkedNodes, setCheckedNodes] = useState<UserSourceDataItem[]>([]);
    const [filteredNodes, setFilteredNodes] = useState<UserSourceDataItem[]>([]);

    useEffect(() => {
      setFilteredNodes(usersPageStore.sources);
    }, [usersPageStore.sources]);

    const handleCheck = (
      checkedKeys: ReactText[] | { checked: ReactText[]; halfChecked: ReactText[] },
      info: { checkedNodes: DataNode[] }
    ) => {
      let newCheckedNodes = info.checkedNodes as UserSourceDataItem[];

      let addedNodes = newCheckedNodes.filter((node) => !checkedNodes.some((node1) => node1.key === node.key));
      let removedNodes = checkedNodes.filter((node) => !newCheckedNodes.some((node1) => node1.key === node.key));

      let allCheckedNodes = [...newCheckedNodes];

      addedNodes.forEach((node) => {
        getChildNodesRecursive(node, allCheckedNodes);
      });

      let allRemovedNodes = [...removedNodes];
      removedNodes.forEach((node) => {
        getChildNodesRecursive(node, allRemovedNodes);
      });

      let allNodes = [...usersPageStore.sources];
      usersPageStore.sources.forEach((node) => {
        getChildNodesRecursive(node, allNodes);
      });

      let allRemovedParents = allNodes.filter((node) => removedNodes.some((node1) => isFirstParentOfSecond(node, node1)));

      allCheckedNodes = allCheckedNodes.filter(
        (node) =>
          !allRemovedNodes.some((node1) => node1.key === node.key) && !allRemovedParents.some((node1) => node1.key === node.key)
      );

      setCheckedKeys(allCheckedNodes.map((node) => node.key));
      setCheckedNodes(allCheckedNodes);
    };

    const handleClear = action(() => {
      setSearchText('');
      setSelectedKeys([]);
      setCheckedKeys([]);
      setCheckedNodes([]);
      setExpandedKeys([]);
      setFilteredNodes(usersPageStore.sources);

      clearFilters && clearFilters();
      if (usersPageStore.filter.sources.length > 0) {
        usersPageStore.filter.sources = [];
        usersPageStore.loadUsers();
      }
    });

    const handleApply = action(() => {
      setSelectedKeys(['fictive key']);
      confirm();
      usersPageStore.filter.sources = checkedNodes;
      usersPageStore.loadUsers();
    });

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);

      let searchText = e.target.value.toLowerCase().trim();
      let nodes = usersPageStore.sources;

      if (!isEmpty(searchText)) {
        nodes = [];
        usersPageStore.sources.forEach((item) => filterItem(searchText, item, nodes));
      }

      setFilteredNodes(nodes);
    };

    const handleSelect = (
      selectedKeys: ReactText[],
      info: {
        node: EventDataNode;
      }
    ) => {
      if (!info.node.isLeaf) {
        const nodeKey = info.node.key as string;
        setExpandedKeys(info.node.expanded ? expandedKeys.filter((key) => key !== nodeKey) : expandedKeys.concat(nodeKey));
      }
    };

    const handleExpand = (expandedKeys: ReactText[]) => {
      setExpandedKeys(expandedKeys as string[]);
    };

    return (
      <div style={{ padding: 8 }}>
        {usersPageStore.sources.length > 0 && (
          <Search style={{ marginBottom: 8 }} placeholder="Search" value={searchText} onChange={handleSearch} allowClear />
        )}
        {usersPageStore.sources.length > 0 && (
          <div className="UsersPage-SourcesList">
            <Tree
              treeData={filteredNodes}
              height={400}
              checkable
              selectable
              checkStrictly
              checkedKeys={checkedKeys}
              expandedKeys={expandedKeys}
              onCheck={handleCheck}
              onSelect={handleSelect}
              onExpand={handleExpand}
            />
          </div>
        )}

        {usersPageStore.sourcesLoading && (
          <div className="UsersPage-SourcesList-Loading">
            <Spin />
          </div>
        )}
        {!usersPageStore.sourcesLoading && (
          <Space>
            <Button type="primary" onClick={handleApply} size="small" style={{ width: 90 }}>
              Применить
            </Button>
            <Button onClick={handleClear} size="small" style={{ width: 90 }} type="link">
              Сбросить
            </Button>
          </Space>
        )}
      </div>
    );
  }
);
