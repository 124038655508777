import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Input, Col, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import FormItem from '../../../../components/FormItem';
import { useLocalStore } from '../store';
import { useStore } from '../../../../stores/storeProvider';
import { action } from 'mobx';

export const EditPaymentDialog = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);
  const [title, setTitle] = useState('');
  const [sum, setSum] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [acquiring, setAcquiring] = useState(true);

  useEffect(() => {
    if (store.isEditDialogOpen) {
      setTitle(store.editDialogPayment.title);
      setSum(store.editDialogPayment.sum.toString());
      setIsActive(store.editDialogPayment.isActive);
      setAcquiring(store.editDialogPayment.acquiring);
    }
  }, [store.editDialogPayment, store.isEditDialogOpen]);

  const handleSave = action(() => {
    if (isEmpty(title)) {
      message.warning('Не задано наименование', 3);
      return;
    }

    if (isEmpty(sum)) {
      message.warning('Не задана сумма', 3);
      return;
    }

    store.editDialogPayment.title = title;
    store.editDialogPayment.sum = Number(sum);
    store.editDialogPayment.isActive = isActive;
    store.editDialogPayment.acquiring = acquiring;

    store.saveEditPayment();
  });

  const handleCancel = () => {
    store.setIsEditDialogOpen(false);
  };

  return (
    <div>
      <Modal
        visible={store.isEditDialogOpen}
        title={isEmpty(store.editDialogPayment.id) ? 'Добавление оплаты' : 'Изменение оплаты'}
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleSave} type="primary">
            Сохранить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={18}>
            <FormItem label="Наименование">
              <Input style={{ width: '100%' }} placeholder="" value={title} onChange={(e) => setTitle(e.target.value)} />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="Сумма">
              <Input style={{ width: '100%' }} placeholder="" value={sum} onChange={(e) => setSum(e.target.value)} />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)}>
              активна
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Checkbox checked={acquiring} onChange={(e) => setAcquiring(e.target.checked)}>
              эквайринг
            </Checkbox>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
