import React, { useState, useEffect } from 'react';
import './UserPayments.scss';
import { Table } from 'antd';
import { useStore } from '../../../stores/storeProvider';
import { isEmpty } from 'lodash';
import { PaymentApi } from '../../../api/payment';
import { Payment } from '../../../models/payments/payment';
import { DateUtils } from '../../../utils/date';

interface IUserPaymentsProps {
  userId: string;
  isVisible: boolean;
  children?: never;
}

const columns = [
  {
    title: 'Период',
    render: (_: any, payment: Payment) => (
      <div>
        {payment.year}.{payment.month}
      </div>
    ),
  },
  {
    title: 'Оплата',
    render: (_: any, payment: Payment) => <div>{payment.title}</div>,
  },
  {
    title: 'К оплате',
    render: (_: any, payment: Payment) => <div>{payment.sumToPay}</div>,
  },
  {
    title: 'Оплачено',
    render: (_: any, payment: Payment) => <div>{payment.sumPayed}</div>,
  },
  {
    title: 'Дата платежа',
    render: (_: any, payment: Payment) => <div>{DateUtils.format(payment.date)}</div>,
  },
];

export const UserPayments: React.FC<IUserPaymentsProps> = ({ userId, isVisible }) => {
  const { appStore, paymentsStore } = useStore();
  const [payments, setPayments] = useState<Payment[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        setLoaded(false);
        setPayments([]);
        setLoading(true);
        const result = await PaymentApi.fetchAllUserPayments(userId);
        for (const payment of result) {
          payment.title = isEmpty(payment.additionalPaymentId)
            ? 'занятия'
            : paymentsStore.getAdditionalPayment(payment.additionalPaymentId!).title;
        }
        setPayments(result);
        setLoaded(true);
      } catch (error) {
        appStore.setErrorMessage((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    if (isVisible && isEmpty(payments) && !loading && !loaded) {
      fetchPayments();
    }
  }, [appStore, isVisible, loading, loaded, payments, userId, paymentsStore]);

  if (!isVisible) {
    return null;
  }

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={payments}
      size="small"
      tableLayout="fixed"
      loading={loading}
      pagination={false}
      expandable={{
        expandedRowRender: (payment) => (
          <div className="CommentsRow">
            {!isEmpty(payment.comment) && <div>{payment.comment}</div>}
            {!isEmpty(payment.clientComment) && (
              <div>
                <i>Комментарий для родителей</i>: {payment.clientComment}
              </div>
            )}
          </div>
        ),
        rowExpandable: (payment) => !isEmpty(payment.comment) || !isEmpty(payment.clientComment),
        expandedRowKeys: payments.map((e) => e.id),
        expandIcon: () => null,
        columnWidth: 0,
      }}
    />
  );
};
