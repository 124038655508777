import React from 'react';
import './PageContainer.scss';
import classNames from 'classnames';

interface IPageContainerProps {
  className?: string;
}

export const PageContainer: React.FC<IPageContainerProps> = ({ children, className }) => {
  return <div className={classNames('PageContainer', className)}>{children}</div>;
};
