import React, { useState, useEffect } from 'react';
import './UserTrainings.scss';
import { Space, Table } from 'antd';
import { useStore } from '../../../stores/storeProvider';
import { isEmpty, orderBy, uniqBy } from 'lodash';
import { TrainingUserInfo } from '../../../models/trainings/trainingUserInfo';
import { TrainingsApi } from '../../../api/training';
import moment from 'moment';
import { DateUtils } from '../../../utils/date';
import { UserTrainingStatusIcon } from './UserTrainingStatusIcon';

interface IUserTrainingsProps {
  userId: string;
  isVisible: boolean;
  children?: never;
}

class MonthTrainings {
  month: moment.Moment = moment();
  userTrainings: TrainingUserInfo[] = [];
}

const columns = [
  {
    title: 'Период',
    render: (_: any, monthTrainings: MonthTrainings) => <div>{DateUtils.format_YYYYMM(monthTrainings.month)}</div>,
  },
  {
    title: 'Отметки',
    render: (_: any, monthTrainings: MonthTrainings) => {
      return (
        <div className="UserVisitStatuses">
          <Space>
            {monthTrainings.userTrainings.map((userTraining) => (
              <div key={DateUtils.format(userTraining.date)}>
                <UserTrainingStatusIcon
                  visitStatus={userTraining.visitStatus}
                  title={`${DateUtils.format(userTraining.date)} ${userTraining.groupName}`}
                />
              </div>
            ))}
          </Space>
        </div>
      );
    },
  },
];

export const UserTrainings: React.FC<IUserTrainingsProps> = ({ userId, isVisible }) => {
  const { appStore } = useStore();
  const [trainings, setTrainings] = useState<MonthTrainings[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        setLoaded(false);
        setTrainings([]);
        setLoading(true);

        const userTrainings = await TrainingsApi.fetchAllUserTrainings(userId);
        const months = orderBy(
          uniqBy(
            userTrainings.map((item) => moment(item.date).startOf('month')),
            (item) => item.format()
          ),
          (item) => item.format('YYYYMM'),
          ['desc']
        );

        const monthTrainings = months.map((month) => {
          let monthTrainings = new MonthTrainings();
          monthTrainings.month = month;
          monthTrainings.userTrainings = userTrainings.filter((userTraining) =>
            moment(userTraining.date).startOf('month').isSame(month)
          );
          return monthTrainings;
        });

        setTrainings(monthTrainings);
        setLoaded(true);
      } catch (error) {
        appStore.setErrorMessage((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    if (isVisible && isEmpty(trainings) && !loading && !loaded) {
      fetchTrainings();
    }
  }, [appStore, loading, loaded, trainings, userId, isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <Table
      rowKey={(monthTrainings: MonthTrainings) => DateUtils.format(monthTrainings.month)}
      columns={columns}
      dataSource={trainings}
      size="small"
      tableLayout="fixed"
      loading={loading}
      pagination={false}
    />
  );
};
