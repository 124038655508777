import { RentsReportParams } from './models/rentsReportParams';
import { action, observable, runInAction } from 'mobx';
import { AppStore } from '../../../stores/AppStore';

export class RentsReportStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  loading: boolean = false;

  reportParams = new RentsReportParams();

  @action
  async init() {
    try {
      await this.refresh();
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async refresh() {
    try {
      this.loading = true;
      runInAction(() => {});
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}
