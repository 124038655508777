import { LoadingOutlined } from '@ant-design/icons';
import { DatePicker, Modal, Space, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { ReportsApi } from '../../../api/reports';
import moment from 'moment';
import styles from '../styles.module.scss';

export const GroupPaymentsReport = observer(() => {
  const [loading, setLoading] = useState(false);
  const [isParamsDialogVisible, setIsParamsDialogVisible] = useState(false);
  const [period, setPeriod] = useState<moment.Moment | null>(moment());

  const handlePeriodChange = (date: moment.Moment | null) => {
    setPeriod(date);
  };

  const handleReportClick = () => {
    setIsParamsDialogVisible(true);
  };

  const handlePrepare = () => {
    if (loading || !period) {
      return;
    }
    setIsParamsDialogVisible(false);
    setLoading(true);
    ReportsApi.prepareGroupPaymentsReport(period.toISOString()).finally(() => {
      setLoading(false);
    });
  };

  const handleCancel = () => {
    setLoading(false);
    setIsParamsDialogVisible(false);
  };

  return (
    <>
      <div className={styles.report_card} onClick={handleReportClick}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loading}>
          <div className={styles.report_card_title}>Платежи по группам</div>
          <div className={styles.report_card_desc}>Выгрузка в Excel платежей по группам за период</div>
        </Spin>
      </div>

      <Modal title="Платежи по группам" visible={isParamsDialogVisible} onOk={handlePrepare} onCancel={handleCancel}>
        <div className={styles.modal_label}>Выберите период отчёта</div>

        <div className={styles.modal_section}>
          <Space>
            <DatePicker value={period} onChange={handlePeriodChange} picker="month" />
          </Space>
        </div>
      </Modal>
    </>
  );
});
