import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popconfirm, Space, Table, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import PageContainer from '../../../components/PageContainer';
import { Abonnement } from '../../../models/abonnement';
import { useStore } from '../../../stores/storeProvider';
import { EditAbonnementDialog } from './EditAbonnementDialog/EditAbonnementDialog';
import { useLocalStore } from './store';
import styles from './styles.module.scss';

export const AbonnementsSettingsPage = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  useEffect(() => {
    document.title = 'Торнадо / Настройки / Абонементы';
    appStore.setCurrentPageTitle('Настройки / Абонементы');
  }, [appStore]);

  const handleAdd = () => {
    store.editAbonnement(new Abonnement());
  };

  const [columns] = useState([
    {
      title: 'Наименование',
      render: (_: any, item: Abonnement) => {
        return <div>{item.Name}</div>;
      },
    },
    {
      title: 'Тренировок',
      render: (_: any, item: Abonnement) => {
        return <div>{item.TrainingsInMonthCount}</div>;
      },
    },
    {
      title: 'Стоимость',
      render: (_: any, item: Abonnement) => {
        return <div>{item.Sum}</div>;
      },
    },
    {
      title: 'Перерасчёт',
      render: (_: any, item: Abonnement) => {
        return <div>{item.recalcTitle()}</div>;
      },
    },
    {
      title: 'Сумма перерасчёта',
      render: (_: any, item: Abonnement) => {
        return <div>{item.RecalcFixSum}</div>;
      },
    },
    {
      title: 'Статус',
      render: (_: any, item: Abonnement) => {
        return (
          <div>
            <Checkbox checked={item.IsActive}>активный</Checkbox>
          </div>
        );
      },
    },
    {
      title: 'Действия',
      render: (_: any, item: Abonnement) => {
        return (
          <Space>
            <Tooltip title="изменить" placement="bottom">
              <Button shape="circle" icon={<EditOutlined />} onClick={(e) => store.editAbonnement(item)} />
            </Tooltip>
            <Popconfirm
              title="Вы действительно хотите удалить абоннемент？"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => appStore.abonnementsStore.deleteAbonnement(item.ID)}
            >
              <Tooltip title="удалить" placement="bottom">
                <Button shape="circle" icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
    },
  ]);

  return (
    <PageContainer className={styles.page}>
      <div>
        <Button onClick={handleAdd}>Добавить абоннемент</Button>
      </div>

      {!isEmpty(appStore.abonnementsStore.abonnements) && (
        <Table className={styles.table} columns={columns} dataSource={appStore.abonnementsStore.abonnements} pagination={false} />
      )}

      <EditAbonnementDialog />
    </PageContainer>
  );
});
