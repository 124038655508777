import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import PageContainer from '../../../components/PageContainer';
import { useStore } from '../../../stores/storeProvider';
import { MainTable } from './MainTable/MainTable';
import styles from './styles.module.scss';
import { Toolbar } from './Toolbar/Toolbar';

export const RentsReport = observer(() => {
  const { appStore, rentsReportStore: store } = useStore();

  useEffect(() => {
    document.title = 'Торнадо / Отчёты / Аренда';
    appStore.setCurrentPageTitle('Отчёт по арендам');
    store.init();
  }, [appStore, store]);

  return (
    <PageContainer className={styles.page}>
      <Toolbar />
      <MainTable />
    </PageContainer>
  );
});
