import React from 'react';
import styles from './GroupSelect.module.scss';
import classNames from 'classnames';
import { Select } from 'antd';
import { Group } from '../../models/group';
import { useState } from 'react';
import { isEmpty } from 'lodash';

interface GroupSelectProps {
  className?: string;
  width?: number;
  allowSelectAll?: boolean;
  groups: Group[];
  value: number | null | '';
  onChange: (value: number | string) => void;
  dropdownAlign?: 'left' | 'right';
}

export const GroupSelect: React.FC<GroupSelectProps> = ({
  className,
  groups,
  value,
  onChange,
  width = 170,
  allowSelectAll = true,
  dropdownAlign = 'left',
}) => {
  const [open, setOpen] = useState(false);

  const parts = new Map<string, Group[]>();
  for (const group of groups) {
    let result = Array.from(group.name.matchAll(/(\D{1,2})[\d\s]/gi));
    if (result.length > 0 && result[0].length > 1) {
      const part = result[0][1].trim();
      if (!parts.has(part)) parts.set(part, []);
      parts.get(part)!.push(group);
    } else {
      if (!parts.has('-')) parts.set('-', []);
      parts.get('-')!.push(group);
    }
  }

  const handleGroupChange = (value: number | string) => {
    onChange(value);
    setOpen(false);
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    setOpen(open);
  };

  return (
    <Select
      dropdownMatchSelectWidth={false}
      listHeight={500}
      style={{ width: width }}
      className={classNames(styles.group_select, className)}
      value={value !== null ? value : ''}
      open={open}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      onChange={handleGroupChange}
      allowClear
      dropdownAlign={{
        points: dropdownAlign === 'left' ? ['bl', 'tl'] : ['br', 'tr'],
        offset: [0, -3],
      }}
      dropdownRender={(menu) => (
        <div className={styles.groups_list}>
          {isEmpty(groups) && <div>Нет активных групп</div>}

          {allowSelectAll && (
            <div className={styles.groups_block}>
              <div
                className={classNames(styles.group, { [styles.group_selected]: value === '' || value === null })}
                onClick={() => handleGroupChange('')}
              >
                Все группы
              </div>
            </div>
          )}

          {[
            ...Array.from(parts.keys()).filter((key) => key !== '-'),
            ...Array.from(parts.keys()).filter((key) => key === '-'),
          ].map((key) => (
            <>
              <div className={styles.divider}>{key !== '-' ? key : '---'}</div>
              <div key={key} className={styles.groups_block}>
                {parts.get(key)!.map((group) => (
                  <div
                    key={group.id}
                    className={classNames(styles.group, { [styles.group_selected]: value === group.id })}
                    onClick={() => handleGroupChange(group.id)}
                  >
                    {group.name}
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>
      )}
    >
      <Select.Option value="">Все группы</Select.Option>
      {groups.map((group) => (
        <Select.Option key={group.id} value={group.id}>
          {group.name}
        </Select.Option>
      ))}
    </Select>
  );

  // <Select
  //           onChange={handleGroupChange}
  //           value={usersPageStore.filter.groupId !== null ? usersPageStore.filter.groupId : ''}
};
