import moment from 'moment';

export const PaymentOperationStatus = {
  Confirmed: 4,
  Error: 7,
  ManualChange: 14,
  CalcPayments: 16,
};

export type PaymentOperationResponse = {
  ID: string;
  Date: string;
  OrderID: string;
  Description: string;
  Detail: string;
  Status: number;
};

export class PaymentOperation {
  id: string = '';
  date: moment.Moment = moment();
  orderID: string = '';
  description: string = '';
  detail: string = '';
  status: number = 0;

  public static fromResponse(resp: PaymentOperationResponse) {
    let result = new PaymentOperation();
    result.id = resp.ID;
    result.orderID = resp.OrderID;
    result.description = resp.Description;
    result.detail = resp.Detail;
    result.date = moment(resp.Date);
    result.status = resp.Status;
    return result;
  }
}
