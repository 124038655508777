import { PaymentApi } from './../../../api/payment';
import { AdditionalPayment } from './../../../models/payments/additionalPayment';
import { action, observable } from 'mobx';
import { AppStore } from '../../../stores/AppStore';

export class SettingsPaymentsPageStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  isEditDialogOpen: boolean = false;

  @observable
  editDialogPayment: AdditionalPayment = new AdditionalPayment();

  @action
  setIsEditDialogOpen(isOpen: boolean) {
    this.isEditDialogOpen = isOpen;
  }

  @action
  editPayment(payment: AdditionalPayment) {
    this.editDialogPayment = payment;
    this.setIsEditDialogOpen(true);
  }

  @action
  async deletePayment(paymentId: string) {
    try {
      await PaymentApi.deleteAdditionalPayment(paymentId);

      await this.appStore.paymentsStore.loadAdditionalPayments(true);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async saveEditPayment() {
    try {
      await PaymentApi.saveAdditionalPayment({
        ID: this.editDialogPayment.id,
        Title: this.editDialogPayment.title,
        IsActive: this.editDialogPayment.isActive,
        Acquiring: this.editDialogPayment.acquiring,
        Sum: this.editDialogPayment.sum,
      });

      this.setIsEditDialogOpen(false);

      await this.appStore.paymentsStore.loadAdditionalPayments(true);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }
}

let localStore: SettingsPaymentsPageStore | null = null;

export const useLocalStore = (appStore: AppStore) => {
  if (localStore === null) {
    localStore = new SettingsPaymentsPageStore(appStore);
  }
  return localStore;
};
