import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Input, Col, Checkbox, DatePicker, Select, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import FormItem from '../../../../components/FormItem';
import { useLocalStore } from '../store';
import { useStore } from '../../../../stores/storeProvider';
import { action } from 'mobx';
import { getRoleTitle, UserRoleName } from '../../../../models/userRole';
import { MaskedInput } from 'antd-mask-input';
import { formatPhoneNumber } from '../../../../models/user';
import { DateUtils } from '../../../../utils/date';
import Divider from '../../../../components/Divider';

export const EditStaffUserDialog = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  const handleSave = action(() => {
    if (isEmpty(store.editStaffUserDialogData.user.lastName)) {
      message.warning('Введите фамилию', 3);
      return;
    }
    store.saveStaffUser(store.editStaffUserDialogData.user);
  });

  const handleCancel = () => {
    store.editStaffUserDialogData.hideDialog();
  };

  const getTitle = () => {
    if (store.editStaffUserDialogData.user.IsManager) {
      return isEmpty(store.editStaffUserDialogData.user.id) ? 'Добавление менеджера' : 'Изменение менеджера';
    }
    if (store.editStaffUserDialogData.user.IsCoach) {
      return isEmpty(store.editStaffUserDialogData.user.id) ? 'Добавление тренера' : 'Изменение тренера';
    }
  };

  return (
    <div>
      <Modal
        visible={store.editStaffUserDialogData.isOpen}
        title={getTitle()}
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleSave} type="primary" loading={store.editStaffUserDialogData.saving}>
            Сохранить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={12}>
            <FormItem label="Фамилия">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editStaffUserDialogData.user.lastName}
                onChange={action((e) => (store.editStaffUserDialogData.user.lastName = e.target.value))}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Имя">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editStaffUserDialogData.user.firstName}
                onChange={action((e) => (store.editStaffUserDialogData.user.firstName = e.target.value))}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <FormItem label="Телефон">
              <MaskedInput
                mask="+7 (111) 111-11-11"
                value={formatPhoneNumber(store.editStaffUserDialogData.user.PhoneNumber)}
                onChange={action((e) => {
                  const value = e.target.value.replace(' ', '').replace('(', '');
                  if (value.startsWith('+78') || value.startsWith('+77')) {
                    store.editStaffUserDialogData.user.PhoneNumber = '+7';
                  } else {
                    store.editStaffUserDialogData.user.PhoneNumber = formatPhoneNumber(e.target.value);
                  }
                })}
              />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem label="День рождения">
              <DatePicker
                value={DateUtils.parse_DDMMYYYY(store.editStaffUserDialogData.user.BirthDay)}
                format="DD.MM.YYYY"
                onChange={action((date) => {
                  store.editStaffUserDialogData.user.BirthDay = date?.toISOString() || '';
                })}
              />
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Checkbox
              checked={store.editStaffUserDialogData.user.IsActive}
              onChange={action((e) => (store.editStaffUserDialogData.user.IsActive = e.target.checked))}
              disabled={!appStore.account?.isAdminOrMainManager}
            >
              Активен
            </Checkbox>
          </Col>
        </Row>

        {store.editStaffUserDialogData.user.IsCoach && (
          <>
            <Row>
              <Col span={24}>
                <FormItem label="Группа">
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    mode="multiple"
                    value={store.editStaffUserDialogData.user.GroupIds}
                    onChange={action((value: number[]) => (store.editStaffUserDialogData.user.GroupIds = value))}
                  >
                    {appStore.groupStore.activeGroups.map((group) => (
                      <Select.Option value={group.id}>{group.name}</Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label="Группа (второй тренер)">
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    mode="multiple"
                    value={store.editStaffUserDialogData.user.CoachExGroupIds}
                    onChange={action((value: number[]) => (store.editStaffUserDialogData.user.CoachExGroupIds = value))}
                  >
                    {appStore.groupStore.activeGroups.map((group) => (
                      <Select.Option value={group.id}>{group.name}</Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>
          </>
        )}

        <Divider title="Роли" />

        <Row>
          <Col span={24}>
            <Checkbox
              checked={store.editStaffUserDialogData.hasRole(UserRoleName.ChangeUserDocuments)}
              onChange={action((e) => store.editStaffUserDialogData.setRole(UserRoleName.ChangeUserDocuments, e.target.checked))}
              disabled={!appStore.account?.isAdminOrMainManager}
            >
              <Tooltip placement="right" title="Роль для изменения документов учеников (справка, договор)">
                {getRoleTitle(UserRoleName.ChangeUserDocuments)}
              </Tooltip>
            </Checkbox>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Checkbox
              checked={store.editStaffUserDialogData.hasRole(UserRoleName.PaymentSum)}
              onChange={action((e) => store.editStaffUserDialogData.setRole(UserRoleName.PaymentSum, e.target.checked))}
              disabled={!appStore.account?.isAdminOrMainManager}
            >
              <Tooltip placement="right" title="Роль для изменения платежей учеников">
                {getRoleTitle(UserRoleName.PaymentSum)}
              </Tooltip>
            </Checkbox>
          </Col>
        </Row>

        {store.editStaffUserDialogData.user.IsManager && (
          <>
            <Row>
              <Col span={24}>
                <Checkbox
                  checked={store.editStaffUserDialogData.hasRole(UserRoleName.ReportsManager)}
                  onChange={action((e) => store.editStaffUserDialogData.setRole(UserRoleName.ReportsManager, e.target.checked))}
                  disabled={!appStore.account?.isAdminOrMainManager}
                >
                  <Tooltip placement="right" title="Доступ только к разделу отчётов">
                    {getRoleTitle(UserRoleName.ReportsManager)}
                  </Tooltip>
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Checkbox
                  checked={store.editStaffUserDialogData.isMainManager}
                  onChange={action((e) => store.editStaffUserDialogData.setMainManager(e.target.checked))}
                  disabled={!appStore.account?.isAdminOrMainManager}
                >
                  {getRoleTitle(UserRoleName.MainManager)}
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Checkbox
                  checked={store.editStaffUserDialogData.hasPlaceRentSum}
                  onChange={action((e) => store.editStaffUserDialogData.setRole(UserRoleName.PlaceRentSum, e.target.checked))}
                  disabled={!appStore.account?.isAdminOrMainManager}
                >
                  <Tooltip placement="right" title="Роль для просмотра и редактирования арендных платежей площадок">
                    {getRoleTitle(UserRoleName.PlaceRentSum)}
                  </Tooltip>
                </Checkbox>
              </Col>
            </Row>
          </>
        )}

        {appStore.account?.isAdminOrMainManager && (
          <Button type="link" onClick={() => store.resetPasswordDialogData.showDialog(store.editStaffUserDialogData.user)}>
            Сбросить пароль
          </Button>
        )}
      </Modal>
    </div>
  );
});
