import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Input, Col } from 'antd';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import FormItem from '../../../components/FormItem';

export interface IRenameSourceGroupDialogProps {
  isOpen: boolean;
  groupTitle: string;
  onSave: (oldGroupTitle: string, newGroupTitle: string) => void;
  onClose: () => void;
}

export const RenameSourceGroupDialog: React.FC<IRenameSourceGroupDialogProps> = observer(
  ({ isOpen, groupTitle, onSave, onClose }) => {
    const [newGroupTitle, setNewGroupTitle] = useState('');

    useEffect(() => {
      setNewGroupTitle(groupTitle);
    }, [groupTitle]);

    const handleSave = () => {
      if (isEmpty(newGroupTitle)) {
        message.warning('Не задано имя группы', 3);
        return;
      }

      if (groupTitle === newGroupTitle) {
        onClose();
        return;
      }

      onSave(groupTitle, newGroupTitle);
    };

    const handleCancel = () => {
      onClose();
    };

    return (
      <div>
        <Modal
          visible={isOpen}
          title="Изменение группы"
          onCancel={handleCancel}
          footer={[
            <Button key="apply" onClick={handleSave} type="primary">
              Сохранить
            </Button>,
            <Button key="cancel" onClick={handleCancel}>
              Закрыть
            </Button>,
          ]}
        >
          <Row>
            <Col span={24}>
              <FormItem label="Имя группы">
                <Input
                  style={{ width: '100%' }}
                  placeholder=""
                  value={newGroupTitle}
                  onChange={(e) => setNewGroupTitle(e.target.value)}
                />
              </FormItem>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
);
