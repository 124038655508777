import { observer } from 'mobx-react';
import React from 'react';
import { PaymentsPageUserRowPayment } from '../models/paymentsPageUserRowPayment';
import { Tag } from 'antd';
import { PaymentStatusInfo } from '../models/paymentStatus';

interface PaymentStatusProps {
  item: PaymentsPageUserRowPayment;
}

export const PaymentStatus: React.FC<PaymentStatusProps> = observer(({ item }) => {
  return (
    <>
      <Tag style={{ minWidth: 100, textAlign: 'center' }} color={PaymentStatusInfo[item.status].color}>
        {PaymentStatusInfo[item.status].title}
      </Tag>

      {item.hasErrors && <Tag color="red">ошибка</Tag>}
    </>
  );
});
