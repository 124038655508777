import { RentsReportStore } from './../pages/Reports/RentsReport/store';
import { PlacesStore } from './PlacesStore';
import { PaymentsStore } from './PaymentsStore';
import { SourcesStore } from './SourcesStore';
import { DiscountsStore } from './DiscountsStore';
import { AbonnementsStore } from './AbonnementsStore';
import { UserRoleName } from './../models/userRole';
import { UsersApi } from './../api/user';
import { isErrorResult } from '../models/error';
import { observable, action, runInAction, computed } from 'mobx';
import { Account } from '../models/account';
import axios from 'axios';
import { UserRole } from '../models/user';
import _ from 'lodash';
import { GroupStore } from './GroupStore';
import { AccountApi } from '../api/account';
import { message } from 'antd';

export class AppStore {
  constructor() {
    this.registerApiErrorInterceptor();

    this.groupStore = new GroupStore(this);
    this.placesStore = new PlacesStore(this);
    this.abonnementsStore = new AbonnementsStore(this);
    this.discountsStore = new DiscountsStore(this);
    this.sourcesStore = new SourcesStore(this);
    this.paymentsStore = new PaymentsStore(this);
    this.rentsReportStore = new RentsReportStore(this);
  }

  groupStore: GroupStore;
  placesStore: PlacesStore;
  abonnementsStore: AbonnementsStore;
  discountsStore: DiscountsStore;
  sourcesStore: SourcesStore;
  paymentsStore: PaymentsStore;
  rentsReportStore: RentsReportStore;

  @observable
  initialized: boolean = false;

  @observable
  initFailed: boolean = false;

  @observable
  account: Account | null = null;

  @observable
  currentPageTitle: string | null = null;

  @observable
  errorMessage: string | null = null;

  @observable
  notificationMessage: string | null = null;

  @observable
  roles: UserRole[] | null = null;

  @observable
  pageLoading: boolean = false;

  @action
  setPageLoading(loading: boolean) {
    this.pageLoading = loading;
  }

  @computed
  get mainRoles() {
    return this.roles?.filter((role) => !role.Name.startsWith('role_'));
  }

  @action
  async init() {
    try {
      if (!this.initialized) {
        await this.loadAccount();
        await this.loadRoles();
        await this.abonnementsStore.loadAbonnements();
        await this.discountsStore.loadDiscounts();
        await this.groupStore.loadGroups();
        await this.placesStore.loadPlaces();
        await this.sourcesStore.loadSources();
        await this.paymentsStore.loadAdditionalPayments();
      }
    } catch (error) {
      if ((error as Error).message !== 'unauthorized') {
        runInAction(() => {
          this.initFailed = true;
          this.setErrorMessage((error as Error).message);
        });
      }
    } finally {
      runInAction(() => {
        this.initialized = true;
      });
    }
  }

  @action
  setCurrentPageTitle(title: string) {
    this.currentPageTitle = title;
  }

  @action
  setErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  @action
  setNotificationMessage(notificationMessage: string) {
    this.notificationMessage = notificationMessage;
  }

  @action
  async clearErrorMessage() {
    this.errorMessage = '';
  }

  @action
  async clearNotificationMessage() {
    this.notificationMessage = '';
  }

  @action
  async loadAccount() {
    this.initFailed = false;
    const accountResponse = await AccountApi.fetchAccount();
    runInAction(() => {
      this.account = new Account(accountResponse);
    });
  }

  @action
  async setCurrentRegion(regionId: string) {
    await AccountApi.setCurrentRegion(regionId);
    runInAction(async () => {
      this.groupStore.resetGroups();
      this.initialized = false;

      await this.init();
    });
  }

  @action
  async login(username: string, password: string) {
    try {
      await AccountApi.login(username, password);
      await this.loadAccount();
    } catch (error) {
      this.setErrorMessage((error as Error).message);
    }
  }

  @action
  async logout() {
    try {
      await AccountApi.logout();
      runInAction(() => {
        this.account = null;
      });
    } catch (error) {
      this.setErrorMessage((error as Error).message);
    }
  }

  @action
  async loadRoles() {
    try {
      if (this.roles !== null) {
        return;
      }

      const rolesResponse = await UsersApi.fetchRoles();

      runInAction(() => {
        this.roles = rolesResponse.map((roleResponse) => new UserRole(roleResponse));
      });
    } catch (error) {
      this.setErrorMessage((error as Error).message);
    }
  }

  getRoleByName(roleName: UserRoleName): UserRole | null {
    if (this.roles === null) {
      return null;
    }
    return _.find(this.roles, { Name: roleName }) || null;
  }

  registerApiErrorInterceptor() {
    axios.interceptors.response.use(function (response) {
      if (isErrorResult(response.data)) {
        throw Error(response.data.error);
      }
      return response;
    });
  }

  showWarning(text: string) {
    message.warning(text, 3);
  }
}
