import { DateUtils } from './../../utils/date';
import { action, observable } from 'mobx';
import moment, { Moment } from 'moment';

export type PlaceRentJson = {
  Id: string;
  PlaceId: number;
  Sum: number;
  DateFrom: string;
};

export class PlaceRent {
  id: string = '';

  placeId: number = 0;

  @observable
  sum: number | null = null;

  @observable
  dateFrom: Moment | null = null;

  isChanged: boolean = false;

  public static fromJson(json: PlaceRentJson) {
    const result = new PlaceRent();
    result.id = json.Id;
    result.placeId = json.PlaceId;
    result.sum = json.Sum;
    result.dateFrom = DateUtils.parse(json.DateFrom) || moment();
    return result;
  }

  @action
  setSum(sum: number | null) {
    this.sum = sum;
    this.isChanged = true;
  }

  @action
  setDateFrom(dateFrom: Moment | null) {
    this.dateFrom = dateFrom;
    this.isChanged = true;
  }
}
