import { UsersApi } from './../../../api/user';
import { UserStatus, UserSaveMode, formatPhoneNumber, CheckLoginResult, FamilyUserInfo } from './../../../models/user';
import { TrainingsApi } from './../../../api/training';
import { AppStore } from './../../../stores/AppStore';
import { UserInfoModel, UserModel } from '../../../models/user';
import { observable, computed, action, runInAction } from 'mobx';
import moment from 'moment';
import { Training, TrainingStatus } from '../../../models/trainings/training';
import { message } from 'antd';
import { UserRoleName } from '../../../models/userRole';

const roleToDialogTitleMap = new Map<UserRoleName, string>([
  [UserRoleName.Client, 'Добавление ученика'],
  [UserRoleName.Coach, 'Добавление тренера'],
]);

export const PHONE_NUMBER_MASK = '+7 (111) 111-11-11';

export enum UserEditDialogPanels {
  Info = 'info',
  Notes = 'notes',
  Payments = 'payments',
  Trainings = 'trainings',
}

export class UserEditDialogState {
  constructor(userId: string | null, dialogMode: UserRoleName | null, appStore: AppStore) {
    this.userId = userId;
    this.dialogMode = dialogMode;
    this.appStore = appStore;
  }

  @observable
  appStore: AppStore;

  @observable
  saving: boolean = false;

  @observable
  loading: boolean = false;

  userId: string | null = null;

  @observable
  userInfo: UserInfoModel = new UserInfoModel();

  @observable
  familyUsers: FamilyUserInfo[] = [];
  isFamilyUsersChanged: boolean = false;

  @observable
  userFamily: UserInfoModel = new UserInfoModel();

  @observable
  userModel: UserModel = new UserModel();

  @observable
  dialogMode: UserRoleName | null = null;

  @observable
  testTrainingDate: moment.Moment | null = null;

  @observable
  testTrainingGroupId: number | null = null;

  @observable
  testTrainingMonth: moment.Moment = moment();

  @observable
  testTrainings: Training[] = [];

  @observable
  checkLoginResult: CheckLoginResult | null = null;

  @observable
  checkLoginProcessing: boolean = false;

  @computed get isNewUser() {
    return this.userId === null;
  }

  get isNewTestTraining() {
    return (
      this.userModel.saveMode === UserSaveMode.TestTraining && this.testTrainingDate !== null && this.testTrainingGroupId !== null
    );
  }

  get isTestTrainingNeedTransfer() {
    return (
      // ученик уже сохранён и в статусе заявка
      !this.isNewUser &&
      (this.userModel.Status === UserStatus.Claim ||
        this.userModel.Status === UserStatus.ClaimRetry ||
        this.userModel.Status === UserStatus.ClaimVisit ||
        this.userModel.Status === UserStatus.ClaimNotVisit)
    );
  }

  @computed
  get dialogTitle() {
    return this.isNewUser ? roleToDialogTitleMap.get(this.dialogMode!) || '' : 'Изменение пользователя';
  }

  @computed
  get dialogTestTrainingTitle() {
    return this.isNewUser || this.userModel.Status === UserStatus.Archive || this.userModel.Status === UserStatus.Reserve
      ? 'Запись на пробную'
      : 'Перенос пробной';
  }

  @computed
  get currentAbonnementID() {
    return this.userModel.newAbonnementId || this.userInfo.currentAbonnementID;
  }

  @computed
  get currentDiscountID() {
    return this.userModel.newDiscountId || this.userInfo.currentDiscountID;
  }

  @action
  async init() {
    this.loading = true;

    try {
      await this.appStore.init();

      if (!this.isNewUser && this.userId !== null) {
        this.dialogMode = null;

        const userInfoModelResponse = await UsersApi.fetchUserInfo(this.userId);
        const familyUsers = await UsersApi.fetchUserFamily(this.userId);

        runInAction(() => {
          const userInfoModel = new UserInfoModel(userInfoModelResponse);

          this.userInfo = userInfoModel;
          this.familyUsers = familyUsers.map((e) => new FamilyUserInfo(e));
          this.userModel = userInfoModel.user;
          this.dialogMode = userInfoModel.getUserRoleName();
          this.userModel.PhoneNumber = formatPhoneNumber(this.userModel.PhoneNumber);
          this.userModel.PhoneNumber2 = formatPhoneNumber(this.userModel.PhoneNumber2);
          this.userModel.prevStatus = this.userModel.Status;
        });
      } else {
        runInAction(() => {
          this.userModel = new UserModel();
          this.userInfo = new UserInfoModel();
        });
      }
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  async checkLogin() {
    try {
      if (!this.userModel.PhoneNumber || this.userModel.PhoneNumber.replace('_', '').length !== PHONE_NUMBER_MASK.length) {
        return;
      }

      this.checkLoginProcessing = true;

      const result = await UsersApi.checkLogin(this.userModel.PhoneNumber);

      if (result.exists) {
        runInAction(() => {
          this.checkLoginResult = result;
        });
      }
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        this.checkLoginProcessing = false;
      });
    }
  }

  @action
  async save() {
    try {
      this.saving = true;

      this.userModel.familyUserIds = this.familyUsers.map((e) => e.id);
      this.userModel.isFamilyUsersChanged = this.isFamilyUsersChanged;

      // роль пользователя
      if (this.isNewUser) {
        this.userModel.Roles = [this.appStore.getRoleByName(this.dialogMode! || UserRoleName.Client)?.Id!];
      }

      if (this.isNewUser && this.userModel.saveMode === UserSaveMode.Simple) {
        message.warning('Новый ученик должен быть записан на тестовую тренировку или поставлен в резерв', 3);
        return;
      }

      if (
        this.userModel.saveMode === UserSaveMode.TestTraining &&
        (this.userModel.newTestTrainingDate === null || this.userModel.newTestTrainingGroupId === null)
      ) {
        message.warning('Не задана группа или дата тестовой тренировки', 3);
        return;
      }

      const userSaveResult = await UsersApi.saveUser(this.userModel);
      return userSaveResult;
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        this.saving = false;
      });
    }
  }

  @action
  async loadTrainingsForSelect() {
    try {
      this.testTrainings = [];
      this.loading = true;
      if (this.testTrainingGroupId !== null && this.testTrainingMonth !== null) {
        const groupTrainings = await TrainingsApi.fetchGroupTrainings(
          this.testTrainingGroupId,
          this.testTrainingMonth.year(),
          this.testTrainingMonth.month() + 1
        );
        runInAction(() => {
          this.testTrainings = groupTrainings.filter((e) => e.status !== TrainingStatus.Cancel);
        });
      }
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}
