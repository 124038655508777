import { DatePicker, Select, Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import FilterItem from '../../../components/FilterItem';
import { useStore } from '../../../stores/storeProvider';
import { NotificationsPeriod } from '../models/notificationPageParams';
import { useLocalStore } from '../store';
import styles from './Toolbar.module.scss';

export const Toolbar = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  const handlePeriodChange = (value: NotificationsPeriod) => {
    store.params.setPeriod(value);
    store.refresh();
  };

  const handleCustomPeriodChange = (values: any) => {
    store.params.setCustomPeriod(values === null ? null : values[0], values === null ? null : values[1]);
    store.refresh();
  };

  return (
    <div className={styles.toolbar}>
      <Space className={styles.filter} size="middle">
        <FilterItem label="Период">
          <Select
            style={{ width: 170 }}
            dropdownMatchSelectWidth={false}
            listHeight={500}
            onChange={handlePeriodChange}
            value={store.params.period}
          >
            <Select.Option key={NotificationsPeriod.All} value={NotificationsPeriod.All}>
              Все
            </Select.Option>
            <Select.Option key={NotificationsPeriod.Today} value={NotificationsPeriod.Today}>
              Сегодня
            </Select.Option>
            <Select.Option key={NotificationsPeriod.CurrentWeek} value={NotificationsPeriod.CurrentWeek}>
              На этой неделе
            </Select.Option>
            <Select.Option key={NotificationsPeriod.Custom} value={NotificationsPeriod.Custom}>
              Произвольный
            </Select.Option>
          </Select>
        </FilterItem>

        {store.params.period === NotificationsPeriod.Custom && (
          <FilterItem label="">
            <DatePicker.RangePicker
              value={[store.params.periodFrom, store.params.periodTo]}
              onChange={handleCustomPeriodChange}
            />
          </FilterItem>
        )}
      </Space>
    </div>
  );
});
