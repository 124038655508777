import React, { createContext } from 'react';
import { configure } from 'mobx';
import { AppStore } from './AppStore';
import { GroupStore } from './GroupStore';
import { SourcesStore } from './SourcesStore';
import { PaymentsStore } from './PaymentsStore';
import { PlacesStore } from './PlacesStore';
import { RentsReportStore } from '../pages/Reports/RentsReport/store';

configure({
  enforceActions: 'observed',
  computedRequiresReaction: true,
});

export interface IStore {
  appStore: AppStore;
  groupStore: GroupStore;
  placesStore: PlacesStore;
  sourcesStore: SourcesStore;
  paymentsStore: PaymentsStore;
  rentsReportStore: RentsReportStore;
}

const appStore = new AppStore();

const createStores = () => {
  return {
    appStore,
    groupStore: appStore.groupStore,
    placesStore: appStore.placesStore,
    sourcesStore: appStore.sourcesStore,
    paymentsStore: appStore.paymentsStore,
    rentsReportStore: appStore.rentsReportStore,
  };
};

const StoreContext = createContext<IStore | null>(null);

export const StoreProvider = ({ children }: any) => {
  const stores = createStores();
  return <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>;
};

export const useStore = () => {
  const stores = React.useContext(StoreContext);
  if (!stores) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return stores;
};
