import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Input, Col, message } from 'antd';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import FormItem from '../../FormItem';
import { UsersApi } from '../../../api/user';
import { useStore } from '../../../stores/storeProvider';

export interface IUserEditResetPasswordDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UserChangePasswordDialog: React.FC<IUserEditResetPasswordDialogProps> = observer(({ isOpen, onClose }) => {
  const { appStore } = useStore();
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const handleApply = action(() => {
    if (!password) {
      message.warning('Введите новый пароль', 3);
      return;
    }

    if (password.length < 4) {
      message.warning('Пароль должен быть не менее 4 символов', 3);
      return;
    }

    if (!passwordConfirm) {
      message.warning('Введите подтверждение пароля', 3);
      return;
    }

    if (password !== passwordConfirm) {
      message.warning('Пароли не совпадают', 3);
      return;
    }

    const change = async () => {
      try {
        await UsersApi.changePassword(oldPassword, password).then(() => {
          message.success('Пароль успешно изменён', 3);
        });
        onClose();
      } catch (error) {
        appStore.setErrorMessage((error as Error).message);
      }
    };

    change();
  });

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    setOldPassword('');
    setPassword('');
    setPasswordConfirm('');
  }, [isOpen]);

  return (
    <Modal
      visible={isOpen}
      title="Изменение пароля"
      onCancel={handleCancel}
      width={400}
      footer={[
        <Button key="apply" onClick={handleApply} type="primary">
          Изменить
        </Button>,
        <Button key="cancel" onClick={handleCancel}>
          Закрыть
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <FormItem label="Старый пароль">
            <Input.Password
              style={{ width: '100%' }}
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem label="Новый пароль">
            <Input.Password
              style={{ width: '100%' }}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem label="Подтверждение пароля">
            <Input.Password
              style={{ width: '100%' }}
              value={passwordConfirm}
              onChange={(e) => {
                setPasswordConfirm(e.target.value);
              }}
            />
          </FormItem>
        </Col>
      </Row>
    </Modal>
  );
});
