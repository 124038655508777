import { NotificationPageParams } from './models/notificationPageParams';
import { Notification, NotificationJson } from './models/notification';
import axios from 'axios';

export const NotificationsPageApi = {
  fetchData: async (params: NotificationPageParams) => {
    const response = await axios.post<NotificationJson[]>('/pages/notifications/GetNotifications', { params: params });
    return response.data.map((resp) => Notification.fromJson(resp));
  },
};
