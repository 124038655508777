import { DiscountsApi } from './../../../api/discounts';
import { cloneDeep } from 'lodash';
import { action, observable } from 'mobx';
import { AppStore } from '../../../stores/AppStore';
import { Discount } from '../../../models/discount';

export class SettingsDiscountsPageStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  isEditDialogOpen: boolean = false;

  @observable
  editingDiscount: Discount = new Discount();

  @action
  setIsEditDialogOpen(isOpen: boolean) {
    this.isEditDialogOpen = isOpen;
  }

  @action
  editDiscount(discount: Discount) {
    this.editingDiscount = cloneDeep(discount);
    this.setIsEditDialogOpen(true);
  }

  @action
  async saveEditDiscount() {
    try {
      await DiscountsApi.saveDiscount({
        ID: this.editingDiscount.ID,
        Name: this.editingDiscount.Name,
        IsActive: this.editingDiscount.IsActive,
        Description: this.editingDiscount.Description,
        Sum: this.editingDiscount.Sum,
        Percent: this.editingDiscount.Percent,
      });
      this.setIsEditDialogOpen(false);
      await this.appStore.discountsStore.loadDiscounts(true);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }
}

let localStore: SettingsDiscountsPageStore | null = null;

export const useLocalStore = (appStore: AppStore) => {
  if (localStore === null) {
    localStore = new SettingsDiscountsPageStore(appStore);
  }
  return localStore;
};
