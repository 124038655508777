import React from 'react';
import styles from './Divider.module.scss';
import { Divider as AntDivider } from 'antd';

interface DividerProps {
  className?: string;
  bold?: boolean;
  title?: string;
}

export const Divider: React.FC<DividerProps> = ({ className, title = '', bold = false }) => {
  return (
    <div className={styles.divider}>
      <AntDivider plain={!bold} orientation="left">
        {title}
      </AntDivider>
    </div>
  );
};
