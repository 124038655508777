export enum UserRoleName {
  Admin = 'admin',
  Manager = 'manager',
  Coach = 'coach',
  Client = 'client',
  MainManager = 'role_MainManager',
  AnalyzeManage = 'role_AnalyzeManage',
  PaymentSum = 'role_PaymentSum',
  ChangeUserDocuments = 'role_ChangeUserDocuments',
  Franchise = 'role_Franchise',
  TerminalPayment = 'role_TerminalPayment',
  PlaceRentSum = 'role_PlaceRentSum',
  ReportsManager = 'role_ReportsManager',
}

export const isSpecialRole = (roleName: string) => {
  return roleName.startsWith('role_');
};

export const getRoleTitle = (roleName: string) => {
  if (roleName === UserRoleName.MainManager) return 'Главный менеджер';
  if (roleName === UserRoleName.TerminalPayment) return 'Работа с оплатами через терминал';
  if (roleName === UserRoleName.ChangeUserDocuments) return 'Изменение документов';
  if (roleName === UserRoleName.PaymentSum) return 'Изменение сумм платежей';
  if (roleName === UserRoleName.PlaceRentSum) return 'Изменение арендных платежей';
  if (roleName === UserRoleName.ReportsManager) return 'Менеджер отчётов';
  return roleName;
};
