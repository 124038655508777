import React, { useEffect, useCallback } from 'react';
import './UsersPage.scss';
import { useStore } from '../../stores/storeProvider';
import PageContainer from '../../components/PageContainer';
import { Table, Space, Button, Modal, message, Input, Select, Dropdown, Menu } from 'antd';
import { UserDisplayModel, UserStatus, UserSortType, getUserStatusTitle } from '../../models/user';
import { observer } from 'mobx-react';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import UserNotes from '../../components/User/UserNotes';
import UserDocument from '../../components/User/UserDocument';
import { AiOutlineSearch } from 'react-icons/ai';
import _, { isEmpty } from 'lodash';
import { Spacer } from '../../ui-kit';
import { usePageStore } from './UsersPageStore';
import UserAddButton from '../../components/User/UserAddButton';
import UserEditButton from '../../components/User/UserEditButton';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { UsersPageSourcesFilter } from './components/UsersPageSourcesFilter';
import GroupSelect from '../../components/GroupSelect';

const { Option } = Select;

export const UsersPage = observer(() => {
  const { appStore, groupStore } = useStore();
  const usersPageStore = usePageStore(appStore);

  const setSearchTextDebounced = useCallback(
    _.debounce((searchText: string) => {
      usersPageStore.setSearchText(searchText);
    }, 1000),
    []
  );

  useEffect(() => {
    document.title = 'Торнадо - Ученики';
    appStore.setCurrentPageTitle('Ученики');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initPage = async () => {
      await usersPageStore.loadUsers();
      window.scrollTo(0, 0);
    };
    initPage();
  }, [appStore, groupStore, usersPageStore]);

  // const handleExcelExport = () => {
  //   //TODO: url
  //   download('/pages/analyzepayments/ExportAnalyzePaymentsToExcel', {
  //     year: 2019,
  //     month: 1,
  //   });
  // };

  // const pageHeader = (
  //   <>
  //     <Button
  //       shape="circle"
  //       icon={<FileExcelOutlined />}
  //       size="middle"
  //       title="экспорт в эксель"
  //       onClick={handleExcelExport}
  //     />
  //   </>
  // );

  const handlePageChange = (pageNumber: number, pageSize?: number) => {
    usersPageStore.setCurrentPage(pageNumber, pageSize || usersPageStore.filter.pageSize);
    window.scrollTo(0, 0);
  };

  const handleUserDocumentChange = (userId: string) => {
    usersPageStore.loadUser(userId);
  };

  const handleDelete = (user: UserDisplayModel) => {
    Modal.confirm({
      title: 'Удаление пользователя',
      content: `Вы действительно хотите удалить пользователя ${user.DisplayName}?`,
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk: async () => {
        const confirmText = await usersPageStore.deleteUser(user.Id, false);
        Modal.confirm({
          title: 'Информация об удаляемом пользователе',
          content: confirmText,
          okText: 'Подтвердить удаление',
          cancelText: 'Отмена',
          onOk: async () => {
            await usersPageStore.deleteUser(user.Id, true);
            setTimeout(() => {
              message.success(`Пользователь ${user.DisplayName} удалён`, 2);
            }, 100);
          },
        });
      },
    });
  };

  const handleSave = (userId: string) => {
    usersPageStore.loadUser(userId);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextDebounced(e.target.value);
  };

  const handleGroupChange = (value: number | string) => {
    usersPageStore.setGroup(typeof value === 'number' ? value : null);
  };

  const handleStatusChange = (value: string) => {
    usersPageStore.setStatus(value === '' ? UserStatus.All.toString() : value);
  };

  const handleSortChange = (value: UserSortType | string) => {
    usersPageStore.setSort(typeof value === 'number' ? value : UserSortType.DateRegister);
  };

  const columns = [
    {
      title: 'Описание',
      render: (_: any, user: UserDisplayModel) => (
        <div className="UserInfo">
          <div className="UserInfo-Main">
            <div className="UserInfo-Name">{user.DisplayName}</div>
            <div>
              <Space>
                <UserNotes user={user} />
                <UserDocument user={user} document={user.contractInfo} onChange={handleUserDocumentChange} />
                <UserDocument user={user} document={user.contractAdditionalInfo} onChange={handleUserDocumentChange} />
                <UserDocument user={user} document={user.healthCertificateInfo} onChange={handleUserDocumentChange} />
                <UserDocument user={user} document={user.insuranceInfo} onChange={handleUserDocumentChange} />
              </Space>
            </div>
          </div>
          <div>
            <Space>
              <span className="UserInfo-Group">{user.Groups}</span>
              <span className="UserInfo-Status">{user.statusTitle}</span>
            </Space>
          </div>
          <div>
            <Space>
              <span className="UserInfo-Abonnement">{user.CurrentAbonnementText}</span>
              <span className="UserInfo-Discount">{user.CurrentDiscountText}</span>
            </Space>
          </div>
        </div>
      ),
    },
    {
      title: 'Контакты',
      render: (_: any, user: UserDisplayModel) => (
        <>
          <div className="UserInfo-Phone">{user.PhoneNumberText}</div>
          <div className="UserInfo-Desc">{user.Description}</div>
        </>
      ),
    },
    {
      title: 'ДР',
      render: (_: any, user: UserDisplayModel) => (
        <div>
          {user.BirthDay && (
            <>
              <div>{user.BirthDay}</div>
              <div>{user.BirthDayYears}</div>
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Источник',
      render: (_: any, user: UserDisplayModel) => (
        <div>
          {user.Source}
          {!isEmpty(user.SourceComment) ? ` - ${user.SourceComment}` : ''}
        </div>
      ),
      filterMultiple: false,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          usersPageStore.loadSources();
        }
      },
      filterDropdown: (props: FilterDropdownProps) => (
        <UsersPageSourcesFilter
          usersPageStore={usersPageStore}
          setSelectedKeys={props.setSelectedKeys}
          confirm={props.confirm}
          clearFilters={props.clearFilters}
        />
      ),
    },
    {
      title: 'Даты',
      render: (_: any, user: UserDisplayModel) => (
        <>
          <div>
            <span className="UserInfo-Label">Дата заявки: </span>
            <span>{user.RequestDate}</span>
          </div>
          <div>
            <span className="UserInfo-Label">Дата пробной: </span>
            <span>{user.RegisterDate}</span>
          </div>
          <div>
            <span className="UserInfo-Label">Был на пробной: </span>
            <span>{user.TestTrainingDate}</span>
          </div>
          <div>
            <span className="UserInfo-Label">Первая оплата: </span>
            <span>{user.FirstPaymentDate}</span>
          </div>
        </>
      ),
    },
    {
      title: '',
      render: (_: any, user: UserDisplayModel) => (
        <Space>
          <UserEditButton userId={user.Id} onSave={handleSave} />

          {appStore.account?.isAdmin && (
            <Dropdown
              overlay={
                <Menu onClick={() => handleDelete(user)}>
                  <Menu.Item key="1" icon={<DeleteOutlined />}>
                    Удалить
                  </Menu.Item>
                </Menu>
              }
            >
              <Button>
                <MenuOutlined />
              </Button>
            </Dropdown>
          )}
        </Space>
      ),
    },
  ];

  return (
    <PageContainer className="UsersPage">
      <div className="UsersPage-Filter">
        <Space>
          <Input style={{ width: 300 }} placeholder="Поиск" prefix={<AiOutlineSearch />} allowClear onChange={handleSearch} />

          <UserAddButton onSave={handleSave} />
        </Space>
        <Spacer />
        <Space>
          <GroupSelect
            groups={groupStore.activeGroups}
            value={usersPageStore.filter.groupId}
            onChange={handleGroupChange}
            dropdownAlign="right"
          />

          {appStore.account !== null && !appStore.account.isCoach && (
            <Select
              style={{ width: 170 }}
              allowClear
              onChange={handleStatusChange}
              value={usersPageStore.filter.status}
              dropdownMatchSelectWidth={false}
              listHeight={1000}
            >
              <Option value={UserStatus.All.toString()}>Все статусы</Option>
              <Option value={UserStatus.Claim.toString()}>{getUserStatusTitle(UserStatus.Claim)}</Option>
              <Option value={UserStatus.ClaimRetry.toString()}>{getUserStatusTitle(UserStatus.ClaimRetry)}</Option>
              <Option value={UserStatus.ClaimVisit.toString()}>{getUserStatusTitle(UserStatus.ClaimVisit)}</Option>
              <Option value={UserStatus.ClaimNotVisit.toString()}>{getUserStatusTitle(UserStatus.ClaimNotVisit)}</Option>
              <Option value={UserStatus.PaymentAdded.toString()}>{getUserStatusTitle(UserStatus.PaymentAdded)}</Option>
              <Option value={UserStatus.Reserve.toString()}>{getUserStatusTitle(UserStatus.Reserve)}</Option>
              <Option value={UserStatus.Archive.toString()}>{getUserStatusTitle(UserStatus.Archive)}</Option>
              <Option value={UserStatus.ArchiveNotVisit.toString()}>{getUserStatusTitle(UserStatus.ArchiveNotVisit)}</Option>
              <Option value={UserStatus.FirstPayment.toString()}>{getUserStatusTitle(UserStatus.FirstPayment)}</Option>
              <Option value={UserStatus.Common.toString()}>{getUserStatusTitle(UserStatus.Common)}</Option>
            </Select>
          )}

          {/* {appStore.account !== null && appStore.account.isAdminOrManager && (
            <Select
              style={{ width: 170 }}
              dropdownMatchSelectWidth={false}
              allowClear
              onChange={handleRoleChange}
              value={usersPageStore.filter.roleID || ''}
            >
              <Option value="all">Все роли</Option>
              {appStore.mainRoles &&
                appStore.mainRoles.map((role) => (
                  <Option key={role.Id} value={role.Id}>
                    {role.Title}
                  </Option>
                ))}
            </Select>
          )} */}

          <Select
            style={{ width: 170 }}
            dropdownMatchSelectWidth={false}
            allowClear
            onChange={handleSortChange}
            value={usersPageStore.filter.sort}
          >
            {appStore.account?.isAdminOrManager && (
              <>
                <Option value={UserSortType.DateRegister}>По дате пробной</Option>
                <Option value={UserSortType.DateClaim}>По дате заявки</Option>
                <Option value={UserSortType.FirstPayment}>По первой оплате</Option>
              </>
            )}
            <Option value={UserSortType.Name}>По имени</Option>
            <Option value={UserSortType.Birthday}>По дню рождения</Option>
          </Select>
        </Space>
      </div>
      <div>
        {usersPageStore.totalCount > 0 && (
          <span>
            Всего: {usersPageStore.totalCount} (страница {usersPageStore.filter.pageNumber})
          </span>
        )}
      </div>
      <Table
        className="Users-Table"
        rowKey="Id"
        columns={columns}
        dataSource={usersPageStore.users}
        bordered
        size="small"
        loading={usersPageStore.loading}
        pagination={{
          total: usersPageStore.totalCount,
          pageSize: usersPageStore.filter.pageSize,
          current: usersPageStore.filter.pageNumber,
          onChange: handlePageChange,
          showSizeChanger: false,
        }}
      />
    </PageContainer>
  );
});
