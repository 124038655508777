import { UserSourceDataItem, UserSourceInfo } from './../../models/user';
import { UserStatus, UserSortType } from '../../models/user';
import { UsersApi } from '../../api/user';
import { observable, action, runInAction } from 'mobx';
import { UserDisplayModel } from '../../models/user';
import { AppStore } from '../../stores/AppStore';
import { UserRoleName } from '../../models/userRole';

export class UsersPageStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
    this.filter = new UsersFilter();
    this.filter.roleID = appStore.getRoleByName(UserRoleName.Client)?.Id || null;
  }

  appStore: AppStore;

  @observable loading: boolean = false;

  @observable users: UserDisplayModel[] = [];

  @observable totalCount: number = 0;

  @observable filter: UsersFilter;

  @observable sources: UserSourceDataItem[] = [];

  @observable sourcesLoading: boolean = false;

  @action
  async loadUsers() {
    try {
      this.loading = true;
      const usersResponse = await UsersApi.fetchUsers(this.filter);
      runInAction(() => {
        this.totalCount = usersResponse.totalCount;
        this.users = usersResponse.users.map((userResponse) => new UserDisplayModel(userResponse));
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  async loadUser(userId: string) {
    try {
      const $this = this;
      const userResponse = await UsersApi.fetchUser(userId);
      runInAction(() => {
        const userIndex = $this.users.findIndex((user) => user.Id === userId);
        if (userIndex !== -1) {
          $this.users.splice(userIndex, 1, new UserDisplayModel(userResponse));
        } else {
          $this.users.splice(0, 0, new UserDisplayModel(userResponse));
        }
        $this.users = [...$this.users];
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async loadSources() {
    const $this = this;
    try {
      if ($this.sources.length > 0) {
        return;
      }
      $this.sourcesLoading = true;
      const result = await UsersApi.fetchAllSelectedSources();
      runInAction(() => {
        $this.sources = result.sources;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        $this.sourcesLoading = false;
      });
    }
  }

  @action
  async deleteUser(userId: string, confirmed: boolean): Promise<string> {
    try {
      const $this = this;
      const userDeleteResponse = await UsersApi.deleteUser(userId, confirmed);
      if (!confirmed) {
        if (!userDeleteResponse.confirmText) {
          this.appStore.setErrorMessage('Ошибка удаления пользователя. Получен пустой текст подтверждения');
        } else {
          return userDeleteResponse.confirmText || '';
        }
      }
      runInAction(() => {
        const userIndex = $this.users.findIndex((user) => user.Id === userId);
        $this.users.splice(userIndex, 1);
        $this.users = [...$this.users];
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
    return '';
  }

  @action
  setCurrentPage(pageNumber: number, pageSize: number) {
    this.filter.pageNumber = pageNumber;
    this.filter.pageSize = pageSize;
    this.loadUsers();
  }

  @action
  setSearchText(searchText: string) {
    this.filter.search = searchText;
    this.loadUsers();
  }

  @action
  setGroup(groupId: number | null) {
    this.filter.groupId = groupId;
    this.loadUsers();
  }

  @action
  setRole(roleID: string | null) {
    this.filter.roleID = roleID;
    this.loadUsers();
  }

  @action
  setStatus(status: string) {
    this.filter.status = status;
    this.loadUsers();
  }

  @action
  setSort(sort: UserSortType) {
    this.filter.sort = sort;
    this.loadUsers();
  }
}

export class UsersFilter {
  roleID: string | null = null;
  groupId: number | null = null;
  sort: UserSortType = UserSortType.DateRegister;
  status: string = UserStatus.All.toString();
  pageSize: number = 30;
  pageNumber: number = 1;
  search: string | null = null;
  letter: string | null = null;
  sources: UserSourceInfo[] = [];
}

let usersPageStore: UsersPageStore | null = null;

export const usePageStore = (appStore: AppStore) => {
  if (usersPageStore === null) {
    usersPageStore = new UsersPageStore(appStore);
  }
  return usersPageStore;
};
