import React, { useEffect } from 'react';
import './GroupsPage.scss';

export const GroupsPage = () => {
  useEffect(() => {
    document.title = 'Торнадо - Пользователи';
  }, []);

  return <div>Groups</div>;
};
