import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import PageContainer from '../../../components/PageContainer';
import { Region } from '../../../models/region';
import { useStore } from '../../../stores/storeProvider';
import { EditRegionDialog } from './EditRegionDialog/EditRegionDialog';
import { useLocalStore } from './store';
import styles from './styles.module.scss';

export const RegionsSettingsPage = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  useEffect(() => {
    document.title = 'Торнадо / Настройки / Разделы';
    appStore.setCurrentPageTitle('Настройки / Разделы');
    store.loadRegions();
  }, [appStore, store]);

  const handleAdd = () => {
    store.editRegionDialogData.showDialog('', '');
  };

  const [columns] = useState([
    {
      title: 'Наименование',
      render: (_: any, item: Region) => {
        return <div>{item.name}</div>;
      },
    },
    {
      title: 'Действия',
      render: (_: any, item: Region) => {
        return (
          <Space>
            <Tooltip title="изменить" placement="bottom">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={(e) => store.editRegionDialogData.showDialog(item.id, item.name)}
              />
            </Tooltip>
            <Popconfirm
              title="Вы действительно хотите удалить раздел？"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => store.deleteRegion(item)}
            >
              <Tooltip title="удалить" placement="bottom">
                <Button shape="circle" icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
    },
  ]);

  return (
    <PageContainer className={styles.page}>
      <div>
        <Button onClick={handleAdd}>Добавить раздел</Button>
      </div>

      {!isEmpty(store.regions) && (
        <Table className={styles.table} columns={columns} dataSource={store.regions} pagination={false} />
      )}

      <EditRegionDialog />
    </PageContainer>
  );
});
