import { observable } from 'mobx';

export type PaymentsParamsJson = {
  SberOperatorInitUrl: string;
  SberOperatorStatusUrl: string;
  SberOperatorUser: string;
  SberOperatorPassword: string;
  Requisites: string;
};

export class PaymentsParams {
  @observable
  SberOperatorInitUrl: string = '';

  @observable
  SberOperatorStatusUrl: string = '';

  @observable
  SberOperatorUser: string = '';

  @observable
  SberOperatorPassword: string = '';

  @observable
  Requisites: string = '';

  public static fromJson(resp: PaymentsParamsJson) {
    const result = new PaymentsParams();
    result.SberOperatorInitUrl = resp.SberOperatorInitUrl;
    result.SberOperatorStatusUrl = resp.SberOperatorStatusUrl;
    result.SberOperatorUser = resp.SberOperatorUser;
    result.SberOperatorPassword = resp.SberOperatorPassword;
    result.Requisites = resp.Requisites;
    return result;
  }
}
