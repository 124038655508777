import {
  UserDisplayResponse,
  UserNoteResponse,
  UserRoleResponse,
  UserInfoModelResponse,
  FamilyUserInfoResponse,
} from './../api/types/user';
import { Moment } from 'moment';
import { observable, computed, action } from 'mobx';
import { Group } from './group';
import moment from 'moment';
import { UserAbonnement } from './abonnement';
import { UserDiscount } from './discount';
import { UserRoleName } from './userRole';

export enum UserGender {
  None,
  Male,
  Female,
}

export enum UserDocumentType {
  Contract = 1,
  ContractAdditional = 2,
  //TODO: нет индикатора
  Anketa = 3,
  HealthCertificate = 4,
  Insurance = 5,
}

export enum UserSortType {
  Name = 1,
  DateRegister,
  DateClaim,
  DateLogin,
  Birthday,
  TrainingStatus,
  FirstPayment,
}

export enum UserStatus {
  All = -1,
  /// <summary> Обычный </summary>
  Common = 0,
  /// <summary> Пользователь в архиве, занятия посещал </summary>
  Archive = 1,
  /// <summary> Пользователь в архиве, занятия не посещал </summary>
  ArchiveNotVisit = 5,
  /// <summary> Заявка </summary>
  Claim = 2,
  /// <summary> Заявка, на пробную не пришёл </summary>
  ClaimNotVisit = 3,
  /// <summary> Заявка, пришёл на пробную (Был на пробной) </summary>
  ClaimVisit = 4,
  /// <summary> Резерв
  /// При звонке пользователя если нет возможности записать в группу ученик ставится в резерв
  /// с указанием группы и даты напоминания
  /// </summary>
  Reserve = 6,
  /// <summary> Заявка, повторная пробная </summary>
  ClaimRetry = 7,
  /// <summary> Выставлен счёт </summary>
  PaymentAdded = 8,
  /// <summary> Первая оплата </summary>
  FirstPayment = 9,
}

export type UserStatusInfo = {
  id: string;
  status: UserStatus;
  statusTitle: string;
  dateFrom: string;
  dateTo: string | null;
  description: string;
};

export type UserGroupMembership = {
  id: string;
  name: string;
  from: string;
  to: string;
};

export enum UserSaveMode {
  Simple = 'simple',
  TestTraining = 'test-training',
  Archive = 'archive',
  Reserve = 'reserve',
  ChangeGroup = 'change-group',
  AddToGroup = 'add-to-group',
  AddToSecondGroup = 'add-to-second-group',
}

export class UserDisplayModel {
  constructor(userDisplayResponse: UserDisplayResponse) {
    Object.assign(this, userDisplayResponse);
  }

  Id: string = '';
  DisplayName: string = '';
  Groups: string = '';
  statusTitle: string = '';
  CurrentAbonnementText: string = '';
  CurrentDiscountText: string = '';
  PhoneNumberText: string = '';
  Description: string = '';
  BirthDay: string = '';
  BirthDayYears: string = '';
  Source: string = '';
  SourceComment: string = '';
  RequestDate: string = '';
  RegisterDate: string = '';
  TestTrainingDate: string = '';
  FirstPaymentDate: string = '';

  contractInfo: UserDocument = new UserDocument();
  contractAdditionalInfo: UserDocument = new UserDocument();
  healthCertificateInfo: UserDocument = new UserDocument();
  insuranceInfo: UserDocument = new UserDocument();
}

export class UserModel {
  Id: string = '';
  UserName: string = '';
  Email: string = '';
  @observable FirstName: string = '';
  @observable LastName: string = '';
  @observable MiddleName: string = '';
  @observable PhoneNumber: string = '';
  // Комментарий к телефону или краткое описание
  @observable Description: string = '';
  @observable PhoneNumber2: string = '';
  // Комментарий к телефону 2 или краткое описание
  @observable PhoneDesc2: string = '';
  // Краткое описание пользователя
  @observable Comment: string = '';
  @observable Gender: UserGender = UserGender.None;
  DisplayName: string = '';
  RequestDate: string = '';
  @observable Source: string = '';
  @observable SourceComment: string = '';
  RegisterDate: string = '';
  @observable BirthDay: string | null = '';
  Age: number | null = null;
  IsActive: boolean = true;
  @observable Status: number | null = null;

  @observable Groups: Group[] = [];
  @observable Roles: string[] = [];

  @observable saveMode: UserSaveMode = UserSaveMode.Simple;

  @observable newTestTrainingDate: string | null = null;
  @observable newTestTrainingGroupId: number | null = null;

  @observable newAbonnementId: string | null = null;
  @observable newAbonnementDateFrom: moment.Moment | null = null;

  @observable newDiscountId: string | null = null;
  @observable newDiscountDateFrom: moment.Moment | null = null;
  @observable newDiscountSelected: boolean = false;

  @observable archiveComment: string | null = null;
  @observable archiveDateFrom: moment.Moment | null = null;
  @observable archiveCallDate: moment.Moment | null = null;

  @observable reserveComment: string | null = null;
  @observable reserveDateFrom: moment.Moment | null = null;
  @observable reserveCallDate: moment.Moment | null = null;
  @observable reserveGroupsIds: number[] = [];

  @observable newGroupId: number | null = null;
  @observable newGroupDateFrom: Moment = moment();

  @observable customLogin: string | null = null;

  @observable prevStatus: UserStatus | null = null;

  @observable familyUserIds: string[] = [];
  @observable isFamilyUsersChanged: boolean = false;

  @computed get GroupsText() {
    return this.Groups.map((e) => e.name).join(', ');
  }
}

export class UserInfoModel {
  constructor(userInfoModelResponse: UserInfoModelResponse | null = null) {
    if (userInfoModelResponse !== null) {
      Object.assign(this, userInfoModelResponse);

      this.user = new UserModel();
      Object.assign(this.user, userInfoModelResponse.user);

      this.updateReserveDataFromCurStatus();
      this.updateArchiveDataFromCurStatus();
    }
  }

  user: UserModel = new UserModel();

  rolesNames: string[] = [];
  rolesTitle: string = '';

  // группы, в которых состоит пользователь (в том числе резерв)
  groups: number[] = [];
  groupNames: string = '';
  groupsHistory: UserGroupMembership[] = [];

  statuses: UserStatusInfo[] = [];
  prevStatus: UserStatus | null = null;
  curStatus: UserStatusInfo | null = null;
  statusData: UserStatusData | null = null;

  userAbonnements: UserAbonnement[] = [];
  userDiscounts: UserDiscount[] = [];

  get currentAbonnementID() {
    return this.userAbonnements.find((e) => e.DateTo === null)?.Abonnement.ID || null;
  }

  get currentDiscountID() {
    return this.userDiscounts.find((e) => e.DateTo === null)?.Discount.ID || null;
  }

  get isClient() {
    return this.rolesNames.includes(UserRoleName.Client);
  }

  get isCoach() {
    return this.rolesNames.includes(UserRoleName.Coach);
  }

  get isManager() {
    return this.rolesNames.includes(UserRoleName.Manager);
  }

  get isAdmin() {
    return this.rolesNames.includes(UserRoleName.Admin);
  }

  get isAdminOrManager() {
    return this.isAdmin || this.isManager;
  }

  getUserRoleName(): UserRoleName {
    if (this.isAdminOrManager) return UserRoleName.Manager;
    if (this.isCoach) return UserRoleName.Coach;
    return UserRoleName.Client;
  }

  @action
  updateReserveDataFromCurStatus() {
    if (this.user.Status === UserStatus.Reserve && this.curStatus != null && this.statusData !== null) {
      this.user.reserveDateFrom = this.curStatus.dateFrom != null ? moment(this.curStatus.dateFrom) : null;
      this.user.reserveCallDate = this.statusData.CallDate != null ? moment(this.statusData.CallDate) : null;
      this.user.reserveComment = this.statusData.Comment;
      this.user.reserveGroupsIds = this.statusData.ReserveGroupIds;

      if (this.user.reserveGroupsIds === null || this.user.reserveGroupsIds.length === 0) {
        this.user.reserveGroupsIds = this.groups;
      }
    }
  }

  @action
  updateArchiveDataFromCurStatus() {
    if (
      (this.user.Status === UserStatus.Archive || this.user.Status === UserStatus.ArchiveNotVisit) &&
      this.curStatus != null &&
      this.statusData !== null
    ) {
      this.user.archiveDateFrom = this.curStatus.dateFrom != null ? moment(this.curStatus.dateFrom) : null;
      this.user.archiveCallDate = this.statusData.CallDate != null ? moment(this.statusData.CallDate) : null;
      this.user.archiveComment = this.statusData.Comment;
    }
  }
}

export type UserStatusData = {
  ReserveGroupIds: number[];
  CallDate: string | null;
  Comment: string | null;
};

export class UserNote {
  constructor(userNoteResponse: UserNoteResponse) {
    Object.assign(this, userNoteResponse);
  }

  Id: string = '';
  UserId: string = '';
  Date: string = '';
  Author: string = '';
  Type: string = '';
  Text: string = '';
  DisplayType: string = '';
}

export class UserLogItem {
  constructor(userLogItemResponse: object) {
    Object.assign(this, userLogItemResponse);
  }

  Id: string = '';
  Date: string = '';
  Type: string = '';
  AuthorName: string = '';
  Description: string[] = [];
  GroupMemberships: string[] = [];
  Statuses: string[] = [];
  ChangeItems: string[] = [];
}

export class UserDocument {
  isEmpty: boolean = false;
  infoText: string = '';
  tooltip: string = '';
  colorClassText: string = '';
  type: UserDocumentType = UserDocumentType.Contract;
  title: string = '';
  date: string | null = null;
  fileId: string | null = null;
  fileInfoUrl: string | null = null;
  fileUrl: string = '';
  fileTitle: string | null = null;
  fileIsImage: boolean = false;
  fileName: string | null = null;
}

export type UserDocumentEditModel = {
  exists: boolean;
  date: Moment | null;
  title: string;
  file: File | null;
  isFileDeleted: boolean;
};

export class UserRole {
  constructor(userRoleResponse: UserRoleResponse) {
    Object.assign(this, userRoleResponse);
  }

  Id: string = '';
  Name: string = '';
  Title: string = '';
  Description: string = '';
}

export class FamilyUserInfo {
  constructor(familyUserInfoResponse?: FamilyUserInfoResponse) {
    if (familyUserInfoResponse) {
      this.id = familyUserInfoResponse.ID;
      this.displayName = familyUserInfoResponse.DisplayName;
      this.statusTitle = familyUserInfoResponse.StatusTitle;
    }
  }

  id: string = '';
  displayName: string = '';
  statusTitle: string = '';
}

const userStatusTitleMap = new Map<UserStatus, string>([
  [UserStatus.Common, 'Постоянный клиент'],
  [UserStatus.Claim, 'Записан на пробную'],
  [UserStatus.ClaimRetry, 'Записан повторно на пробную'],
  [UserStatus.ClaimNotVisit, 'Не пришёл на пробную'],
  [UserStatus.ClaimVisit, 'Был на пробном'],
  [UserStatus.Archive, 'Архив'],
  [UserStatus.ArchiveNotVisit, 'Архив, не ходил'],
  [UserStatus.Reserve, 'Резерв'],
  [UserStatus.PaymentAdded, 'Выставлен счёт'],
  [UserStatus.FirstPayment, 'Первая оплата'],
]);

export const getUserStatusTitle = (status: UserStatus | null) => {
  if (status === null) {
    return '';
  }
  return userStatusTitleMap.get(status) || '';
};

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';

  var text = phoneNumber.replace(/ /g, '').replace('+', '').replace('(', '').replace(')', '').replace(/-/g, '').replace(/_/g, '');
  if (text.length !== 11) return phoneNumber;

  return `+7 (${text.substring(1, 4)}) ${text.substring(4, 7)}-${text.substring(7, 9)}-${text.substring(9)}`;
};

export const clearPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';

  var text = phoneNumber.replace(/ /g, '').replace('+', '').replace('(', '').replace(')', '').replace(/-/g, '').replace(/_/g, '');
  if (text.length !== 11) return phoneNumber;

  return `8${text.substring(1)}`;
};

export type CheckLoginResult = {
  exists: boolean;
  info: string;
  userId: string;
};

export type UserSourceInfo = {
  key: string;
  type: string;
};

export type UserSourceDataItem = {
  title: string;
  key: string;
  type: string;
  children: UserSourceDataItem[];
};

export type UserSourcesData = {
  sources: UserSourceDataItem[];
};
