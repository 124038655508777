import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, Row, Input, Col } from 'antd';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import FormItem from '../../../../components/FormItem';
import { useLocalStore } from '../store';
import { useStore } from '../../../../stores/storeProvider';
import { action } from 'mobx';
import { Region } from '../../../../models/region';

export const EditRegionDialog = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  const handleSave = action(() => {
    if (isEmpty(store.editRegionDialogData.regionName)) {
      message.warning('Название раздела не может быть пустым', 3);
      return;
    }

    const region = new Region();
    region.id = store.editRegionDialogData.regionId;
    region.name = store.editRegionDialogData.regionName;
    store.saveRegion(region);
  });

  const handleCancel = () => {
    store.editRegionDialogData.hideDialog();
  };

  return (
    <div>
      <Modal
        visible={store.editRegionDialogData.isOpen}
        title={isEmpty(store.editRegionDialogData.regionId) ? 'Добавление раздела' : 'Изменение раздела'}
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleSave} type="primary" loading={store.editRegionDialogData.saving}>
            Сохранить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <FormItem label="Наименование">
              <Input
                style={{ width: '100%' }}
                placeholder=""
                value={store.editRegionDialogData.regionName}
                onChange={(e) => store.editRegionDialogData.setRegionName(e.target.value)}
              />
            </FormItem>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
