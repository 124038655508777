import { UserRoleName } from './userRole';
import { AccountResponse } from './../api/types/account';

export class Account {
  constructor(accountResponse: AccountResponse) {
    Object.assign(this, accountResponse);
  }

  user: {
    id: string;
    displayName: string;
    roles: string[];
  } | null = null;
  region: {
    id: string;
    name: string;
  } | null = null;
  roles: {
    id: string;
    name: string;
    title: string;
  }[] = [];
  regions: {
    id: string;
    name: string;
  }[] = [];

  // get isCoach() {
  //   return _.some(this.roles, { name: UserRoleName.Coach });
  // }

  // get isAdmin() {
  //   return _.some(this.roles, { name: UserRoleName.Admin });
  // }

  // get isAdminOrManager() {
  //   return _.some(this.roles, { name: UserRoleName.Admin }) || _.some(this.roles, { name: UserRoleName.Manager });
  // }

  // hasRole(roleName: UserRoleName) {
  //   return _.some(this.roles, { name: roleName });
  // }

  get isCoach() {
    return this.user && this.user.roles.includes(UserRoleName.Coach);
  }

  get isAdmin() {
    return this.user && this.user.roles.includes(UserRoleName.Admin);
  }

  get isAdminOrManager() {
    return this.user && (this.user.roles.includes(UserRoleName.Admin) || this.user.roles.includes(UserRoleName.Manager));
  }

  get isAdminOrMainManager() {
    return this.user && (this.user.roles.includes(UserRoleName.Admin) || this.user.roles.includes(UserRoleName.MainManager));
  }

  hasRole(roleName: UserRoleName) {
    return this.user && this.user.roles.includes(roleName);
  }
}
