import { AbonnementsApi } from './../api/abonnements';
import { observable, action, runInAction, computed } from 'mobx';
import { AppStore } from './AppStore';
import { Abonnement } from '../models/abonnement';
import { isEmpty } from 'lodash';

export class AbonnementsStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  abonnements: Abonnement[] = [];

  @computed
  get activeAbonnements() {
    return this.abonnements === null ? [] : this.abonnements.filter((item) => item.IsActive);
  }

  @action
  async loadAbonnements(force: boolean = false) {
    try {
      if (!isEmpty(this.abonnements) && !force) {
        return;
      }
      const abonnements = await AbonnementsApi.fetchAbonnements();
      runInAction(() => {
        this.abonnements = abonnements;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  getNameById(abonnementId: string): string | null {
    return this.abonnements?.find((abonnement) => abonnement.ID === abonnementId)?.Name || null;
  }

  @action
  async deleteAbonnement(id: string) {
    try {
      await AbonnementsApi.deleteAbonnement(id);
      await this.loadAbonnements(true);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }
}
