import React, { useEffect } from 'react';
import './Login.scss';
import { Form, Input, Button } from 'antd';
import { useStore } from '../../stores/storeProvider';
import { Store } from 'antd/lib/form/interface';
import { useLocation, useHistory } from 'react-router-dom';
import { RouteParamNames } from '../routes';
import LogoImg from '../../assets/images/logo_tornado.png';
import classNames from 'classnames';

export const LoginPage = () => {
  const { appStore } = useStore();
  const location = useLocation();
  const history = useHistory();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const onFinish = (values: Store) => {
    const login = async () => {
      await appStore.login(values.username, values.password);
      const query = new URLSearchParams(location.search);
      const returnUrl = query.get(RouteParamNames.ReturnUrl) || '/';
      history.replace(returnUrl);
    };

    login();
  };

  useEffect(() => {
    document.title = 'Торнадо - Вход в систему';
  }, []);

  return (
    <div className={classNames('Login', { user_logged: appStore.account !== null })}>
      <img className="Login-Logo" src={LogoImg} alt="" />

      <Form className="Login-Form" {...layout} name="basic" onFinish={onFinish}>
        <Form.Item label="Логин" name="username" rules={[{ required: true, message: 'пожалуйста введите логин!' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'пожалуйста, введите пароль!' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Вход
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
