import React, { createContext, useState } from 'react';
import { UserEditDialog } from './UserEditDialog';
import { UserRoleName } from '../../../models/userRole';

export interface IUserEditDialogContext {
  showUserEditDialog: (userId: string | null, userRoleName: UserRoleName | null, onSave?: (userId: string) => void) => void;
}

const UserEditDialogContext = createContext<IUserEditDialogContext | null>(null);

export const UserEditDialogProvider = ({ children }: any) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [userRoleName, setUserRoleName] = useState<UserRoleName | null>(UserRoleName.Client);
  const [onSaveCallback, setOnSaveCallback] = useState<((userId: string) => void) | null>(null);

  const showUserEditDialog = (
    userId: string | null,
    userRoleName: UserRoleName | null = null,
    onSave?: (userId: string) => void
  ) => {
    setUserId(userId);
    setUserRoleName(userRoleName);
    setOnSaveCallback(onSave ? () => onSave : null);
    setIsDialogOpen(true);
  };

  const context = {
    showUserEditDialog,
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleSave = (userId: string) => {
    setIsDialogOpen(false);
    onSaveCallback && onSaveCallback(userId);
  };

  return (
    <UserEditDialogContext.Provider value={context}>
      {children}
      <UserEditDialog
        userId={userId}
        userRoleName={userRoleName}
        isOpen={isDialogOpen}
        isReadonly={!onSaveCallback}
        onClose={handleClose}
        onSave={handleSave}
      />
    </UserEditDialogContext.Provider>
  );
};

export const useUserEditDialog = () => {
  const context = React.useContext(UserEditDialogContext);
  if (!context) {
    throw new Error('useUserEditDialog must be used within a UserEditDialogProvider.');
  }
  return context;
};
