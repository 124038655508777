import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import PageContainer from '../../components/PageContainer';
import { useStore } from '../../stores/storeProvider';
import { GroupPaymentsReport } from './GroupPaymentsReport/GroupPaymentsReport';
import { PaymentOperationsReport } from './PaymentOperationsReport/PaymentOperationsReport';
// import { RentsReportCard } from './RentsReport/card';
import styles from './styles.module.scss';
import { UsersInfoReport } from './UsersInfoReport/UsersInfoReport';
import { ActivePaymentsReport } from './ActivePaymentsReport/ActivePaymentsReport';

export const ReportsPage = observer(() => {
  const { appStore } = useStore();

  useEffect(() => {
    document.title = 'Торнадо / Отчёты';
    appStore.setCurrentPageTitle('Отчёты');
  }, [appStore]);

  return (
    <PageContainer className={styles.page}>
      <PaymentOperationsReport />
      {/* <RentsReportCard /> */}
      <GroupPaymentsReport />
      <UsersInfoReport />
      <ActivePaymentsReport />
    </PageContainer>
  );
});
