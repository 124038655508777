import { observable } from 'mobx';

export type PlaceJson = {
  ID: number;
  Name: string;
  Address: string;
  IsActive: boolean;
  GeoCoords: string;
  IsRentSumForTraining: boolean;
};

export class Place {
  id: number = 0;

  @observable
  name: string = '';

  @observable
  address: string = '';

  @observable
  isActive: boolean = true;

  @observable
  geoCoords: string = '';

  @observable
  isRentSumForTraining: boolean = false;

  @observable
  rentInfo: string = '';

  public static fromJson(json: PlaceJson) {
    const result = new Place();
    result.id = json.ID;
    result.name = json.Name;
    result.address = json.Address;
    result.isActive = json.IsActive;
    result.geoCoords = json.GeoCoords;
    result.isRentSumForTraining = json.IsRentSumForTraining;
    return result;
  }
}
