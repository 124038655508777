import { Button, Dropdown, Menu, message, Modal, Space, Table } from 'antd';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';
import PageContainer from '../../../components/PageContainer';
import { UserSource, UserSourceGroup } from '../../../models/userSource';
import { useStore } from '../../../stores/storeProvider';
import { AddSourceDialog } from './AddSourceDialog';
import { AddSourceGroupDialog } from './AddSourceGroupDialog';
import './SourcesPage.scss';
import { FaEllipsisH } from 'react-icons/fa';
import { RenameSourceGroupDialog } from './RenameSourceGroupDialog';
import { RenameSourceDialog } from './RenameSourceDialog';
import { isEmpty } from 'lodash';

interface IDataItem {
  title: string;
  isGroup: boolean;
  sourceGroup: UserSourceGroup | null;
  source: UserSource | null;
}

export const SourcesPage = observer(() => {
  const { appStore, sourcesStore } = useStore();
  const [isAddSourceGroupDialogOpen, setIsAddSourceGroupDialogOpen] = useState(false);
  const [isAddSourceDialogOpen, setIsAddSourceDialogOpen] = useState(false);
  const [isRenameSourceGroupDialogOpen, setIsRenameSourceGroupDialogOpen] = useState(false);
  const [isRenameSourceDialogOpen, setIsRenameSourceDialogOpen] = useState(false);
  const [currentSourceGroup, setCurrentSourceGroup] = useState<UserSourceGroup | null>(null);
  const [currentSource, setCurrentSource] = useState<UserSource | null>(null);

  const data = useMemo((): IDataItem[] => {
    let items = [];

    for (const sourceGroup of sourcesStore.sourceGroups) {
      items.push({
        title: sourceGroup.Title,
        isGroup: true,
        sourceGroup: sourceGroup,
        source: null,
      });

      for (const source of sourceGroup.Sources) {
        items.push({
          title: source.Title,
          isGroup: false,
          sourceGroup: null,
          source: source,
        });
      }
    }

    return items;
  }, [sourcesStore.sourceGroups]);

  useEffect(() => {
    document.title = 'Торнадо - Настройки - Источники';
    appStore.setCurrentPageTitle('Настройки - Источники');

    sourcesStore.loadSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddSourceGroup = (groupTitle: string) => {
    sourcesStore.addSourceGroup(groupTitle).then(() => {
      setIsAddSourceGroupDialogOpen(false);
    });
  };

  const handleDeleteSourceGroup = useCallback(
    (groupTitle: string) => {
      Modal.confirm({
        title: 'Внимание',
        content: `Вы действительно хотите удалить группу '${groupTitle}'`,
        okText: 'Подтвердить удаление',
        cancelText: 'Отмена',
        onOk: async () => {
          try {
            await sourcesStore.deleteSourceGroup(groupTitle);
            setTimeout(() => {
              message.success(`Группа удалена`, 2);
            }, 100);
          } catch (Error) {}
        },
      });
    },
    [sourcesStore]
  );

  const handleDeleteSource = useCallback(
    (sourceTitle: string) => {
      Modal.confirm({
        title: 'Внимание',
        content: `Вы действительно хотите удалить источник '${sourceTitle}'`,
        okText: 'Подтвердить удаление',
        cancelText: 'Отмена',
        onOk: async () => {
          try {
            await sourcesStore.deleteSource(sourceTitle);
            setTimeout(() => {
              message.success(`Источник удалён`, 2);
            }, 100);
          } catch (Error) {}
        },
      });
    },
    [sourcesStore]
  );

  const handleRenameSourceGroup = (oldGroupTitle: string, newGroupTitle: string) => {
    sourcesStore.renameSourceGroup(oldGroupTitle, newGroupTitle).then(() => {
      setIsRenameSourceGroupDialogOpen(false);
    });
  };

  const handleRenameSource = (oldTitle: string, newTitle: string) => {
    sourcesStore.renameSource(oldTitle, newTitle).then(() => {
      setIsRenameSourceDialogOpen(false);
    });
  };

  const handleAddSource = (sourceTitle: string) => {
    if (currentSourceGroup !== null) {
      sourcesStore.addSource(currentSourceGroup.Title, sourceTitle).then(() => {
        setIsAddSourceDialogOpen(false);
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        title: 'Источник',
        dataIndex: 'title',
        key: 'title',
        render: (text: string, record: IDataItem) => {
          if (record.isGroup) {
            return <div className="SourceGroup">{text}</div>;
          }
          return <div className="Source">{text}</div>;
        },
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (text: string, record: IDataItem) => {
          if (record.isGroup) {
            return (
              <Dropdown
                placement="bottomRight"
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={() => {
                        setCurrentSourceGroup(record.sourceGroup);
                        setIsAddSourceDialogOpen(true);
                      }}
                    >
                      Добавить источник
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        setCurrentSourceGroup(record.sourceGroup);
                        setIsRenameSourceGroupDialogOpen(true);
                      }}
                    >
                      Переименовать
                    </Menu.Item>
                    <Menu.Item onClick={() => handleDeleteSourceGroup(record.sourceGroup?.Title || '')}>Удалить</Menu.Item>
                  </Menu>
                }
              >
                <FaEllipsisH className="ActionsMenu" />
              </Dropdown>
            );
          }
          return (
            <Dropdown
              placement="bottomRight"
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setCurrentSource(record.source);
                      setIsRenameSourceDialogOpen(true);
                    }}
                  >
                    Переименовать
                  </Menu.Item>
                  <Menu.Item onClick={() => handleDeleteSource(record.source?.Title || '')}>Удалить</Menu.Item>
                </Menu>
              }
            >
              <FaEllipsisH className="ActionsMenu" />
            </Dropdown>
          );
        },
      },
    ],
    [handleDeleteSource, handleDeleteSourceGroup]
  );

  return (
    <PageContainer className="SourcesPage">
      <div>
        <Space>
          <Button onClick={() => setIsAddSourceGroupDialogOpen(true)}>Добавить группу</Button>
        </Space>
      </div>

      {!isEmpty(data) && (
        <Table className="SourcesTable" columns={columns} dataSource={data} pagination={false} showHeader={false} />
      )}

      <AddSourceGroupDialog
        isOpen={isAddSourceGroupDialogOpen}
        onClose={() => setIsAddSourceGroupDialogOpen(false)}
        onAdd={handleAddSourceGroup}
      />

      <RenameSourceGroupDialog
        isOpen={isRenameSourceGroupDialogOpen}
        groupTitle={currentSourceGroup?.Title || ''}
        onClose={() => setIsRenameSourceGroupDialogOpen(false)}
        onSave={handleRenameSourceGroup}
      />

      <RenameSourceDialog
        isOpen={isRenameSourceDialogOpen}
        title={currentSource?.Title || ''}
        onClose={() => setIsRenameSourceDialogOpen(false)}
        onSave={handleRenameSource}
      />

      <AddSourceDialog isOpen={isAddSourceDialogOpen} onClose={() => setIsAddSourceDialogOpen(false)} onAdd={handleAddSource} />
    </PageContainer>
  );
});
