import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, message, DatePicker } from 'antd';
import { observer } from 'mobx-react';
import { UserEditDialogState } from '../UserEditDialogState';
import { action } from 'mobx';
import moment from 'moment';
import { UserStatus } from '../../../../models/user';

export interface IUserEditChangeAbonnementDialogProps {
  editDialogState: UserEditDialogState;
  isOpen: boolean;
  onClose: () => void;
}

export const UserEditChangeAbonnementDialog: React.FC<IUserEditChangeAbonnementDialogProps> = observer(
  ({ editDialogState, isOpen, onClose }) => {
    const [dateFrom, setDateFrom] = useState<moment.Moment | null>(moment());

    useEffect(() => {
      let firstChangeDateFrom = moment();
      if (editDialogState.userModel.Status === UserStatus.ClaimVisit) {
        firstChangeDateFrom = moment(editDialogState.userModel.RegisterDate);
      }

      if (isOpen) {
        setDateFrom(
          editDialogState.userModel.newAbonnementDateFrom != null
            ? editDialogState.userModel.newAbonnementDateFrom
            : firstChangeDateFrom
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleApply = action(() => {
      if (dateFrom === null) {
        message.warning('Не задана дата начала абонемента', 3);
        return;
      }

      editDialogState.userModel.newAbonnementDateFrom = dateFrom;

      onClose();
    });

    const handleCancel = () => {
      onClose();
    };

    return (
      <Modal
        className="UserEditDialog-NewAbonnementDate"
        visible={isOpen}
        title="Введите дату начала абонемента"
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleApply}>
            Применить
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <DatePicker format="DD.MM.YYYY" value={dateFrom} onChange={(value) => setDateFrom(value)} />
      </Modal>
    );
  }
);
