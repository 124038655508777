import { User } from './../users/user';
import { UserResponse2 } from '../users/user';
import { TrainingVisitStatus } from './trainingVisitStatus';
import { observable } from 'mobx';

export type TrainingVisitUserInfoJson = {
  ID: number;
  VisitStatus: TrainingVisitStatus;
  User: UserResponse2;
  IsTest: boolean;
};

export class TrainingVisitUserInfo {
  id: number = 0;
  @observable
  visitStatus: TrainingVisitStatus = TrainingVisitStatus.Skip;
  user: User = new User();
  isTest: boolean = false;

  public static fromJson(json: TrainingVisitUserInfoJson) {
    let result = new TrainingVisitUserInfo();
    result.id = json.ID;
    result.visitStatus = json.VisitStatus;
    result.user = User.fromResponse2(json.User);
    result.isTest = json.IsTest;
    return result;
  }
}

export type TrainingVisitsInfoJson = {
  usersTrainigs: TrainingVisitUserInfoJson[];
  placeID: number;
  comment: string;
};

export class TrainingVisitsInfo {
  @observable
  usersTrainigs: TrainingVisitUserInfo[] = [];
  placeId: number = 0;
  @observable
  comment: string = '';

  public static fromJson(json: TrainingVisitsInfoJson) {
    let result = new TrainingVisitsInfo();
    result.usersTrainigs = json.usersTrainigs.map((e) => TrainingVisitUserInfo.fromJson(e));
    result.placeId = json.placeID;
    result.comment = json.comment;
    return result;
  }
}
