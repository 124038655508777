import { DateUtils } from './../../utils/date';
import { PlaceRent } from './placeRent';

export class PlaceRentSaveModel {
  Id: string | null = null;
  PlaceId: number = 0;
  Sum: number | null = null;
  DateFrom: string | null = null;
  IsChanged: boolean = false;

  static fromPlaceRent(placeRent: PlaceRent) {
    const res = new PlaceRentSaveModel();
    res.Id = placeRent.id;
    res.PlaceId = placeRent.placeId;
    res.Sum = placeRent.sum;
    res.DateFrom = DateUtils.format_DDMMYYYY(placeRent.dateFrom);
    res.IsChanged = placeRent.isChanged;
    return res;
  }
}
