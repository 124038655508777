import { action, observable, runInAction } from 'mobx';
import { AppStore } from '../../../stores/AppStore';
import { Group, GroupSaveModel } from '../../../models/group';
import { GroupsApi } from '../../../api/groups';
import { UsersApi } from '../../../api/user';
import { User } from '../../../models/users/user';
import { Place } from '../../../models/places/place';
import { PlacesApi } from '../../../api/places';
import { GroupSheduleSaveModel } from '../../../models/groupShedule';

export class SettingsGroupsPageStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  isEditDialogOpen: boolean = false;

  @observable
  isEditSheduleDialogOpen: boolean = false;

  @observable
  coaches: User[] = [];

  @observable
  places: Place[] = [];

  @observable
  editDialogGroup: Group = new Group();

  @action
  setIsEditDialogOpen(isOpen: boolean) {
    this.isEditDialogOpen = isOpen;
  }

  @action
  setIsEditSheduleDialogOpen(isOpen: boolean) {
    this.isEditSheduleDialogOpen = isOpen;
  }

  @action
  editGroup(group: Group) {
    this.editDialogGroup = group;
    this.setIsEditDialogOpen(true);
  }

  @action
  editGroupShedule(group: Group) {
    this.editDialogGroup = group;
    this.setIsEditSheduleDialogOpen(true);
  }

  @action
  async deleteGroup(id: number) {
    try {
      await GroupsApi.deleteGroup(id);
      await this.appStore.groupStore.loadGroups(true);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async saveEditGroup(editModel: GroupSaveModel) {
    try {
      await GroupsApi.saveGroup(editModel);
      this.setIsEditDialogOpen(false);
      runInAction(() => {
        this.editDialogGroup = new Group();
      });
      await this.appStore.groupStore.loadGroups(true);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async saveGroupShedules(shedules: GroupSheduleSaveModel[]) {
    try {
      await GroupsApi.saveGroupShedule(this.editDialogGroup.id, shedules);
      this.setIsEditSheduleDialogOpen(false);
      runInAction(() => {
        this.editDialogGroup = new Group();
      });
      await this.appStore.groupStore.loadGroups(true);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async loadCoaches() {
    try {
      const coaches = await UsersApi.fetchAllCoaches();
      runInAction(() => {
        this.coaches = coaches;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async loadPlaces() {
    try {
      const places = await PlacesApi.fetchAllPlaces();
      runInAction(() => {
        this.places = places;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }
}

let localStore: SettingsGroupsPageStore | null = null;

export const useLocalStore = (appStore: AppStore) => {
  if (localStore === null) {
    localStore = new SettingsGroupsPageStore(appStore);
  }
  return localStore;
};
