import React from 'react';
import { Button } from 'antd';
import { useUserEditDialog } from '../UserEditDialogProvider';

export interface IUserEditShowExistingButtonProps {
  userId: string;
}

export const UserEditShowExistingButton: React.FC<IUserEditShowExistingButtonProps> = ({ userId }) => {
  const { showUserEditDialog } = useUserEditDialog();

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    showUserEditDialog(userId, null);
  };

  return (
    <Button type="link" onClick={handleClick}>
      показать
    </Button>
  );
};
