import React, { useCallback, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, Col, Input, Row } from 'antd';
import { observer } from 'mobx-react';
import { UserEditDialogState } from '../UserEditDialogState';
import _ from 'lodash';
import { AiOutlineSearch } from 'react-icons/ai';
import { Spin } from 'antd';
import { useStore } from '../../../../stores/storeProvider';
import { UsersApi } from '../../../../api/user';
import { UsersFilter } from '../../../../pages/Users/UsersPageStore';
import FormItem from '../../../FormItem';
import '../UserEditDialog.scss';

export interface ISelectUsersDialogProps {
  editDialogState: UserEditDialogState;
  isOpen: boolean;
  onSelect: (id: string, displayName: string, statusTitle: string) => void;
  onClose: () => void;
}

type UserInfo = {
  id: string;
  displayName: string;
  groups: string;
  status: string;
};

export const SelectUsersDialog: React.FC<ISelectUsersDialogProps> = observer(({ editDialogState, isOpen, onSelect, onClose }) => {
  const { appStore } = useStore();
  const [users, setUsers] = useState<UserInfo[]>([]);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    onClose();
  };

  const loadUsers = async (searchText: string) => {
    try {
      setLoading(true);
      setUsers([]);
      if (searchText.length < 3) return;
      const filter = new UsersFilter();
      filter.search = searchText;
      const usersResponse = await UsersApi.fetchUsers(filter);
      setUsers(
        usersResponse.users.map((userResponse) => ({
          id: userResponse.Id,
          displayName: userResponse.DisplayName,
          groups: userResponse.Groups,
          status: userResponse.statusTitle,
        }))
      );
    } catch (error) {
      appStore.setErrorMessage((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const findUser = useCallback(
    _.debounce((searchText: string) => {
      loadUsers(searchText);
    }, 1000),
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    findUser(e.target.value);
  };

  const handleUserClick = (user: UserInfo) => {
    onSelect(user.id, user.displayName, user.status);
    onClose();
  };

  return (
    <div>
      <Modal
        visible={isOpen}
        title="Выбор ученика"
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <FormItem label="Имя ученика или идентификатор">
              <Input
                style={{ width: '100%' }}
                placeholder="Поиск"
                prefix={<AiOutlineSearch />}
                allowClear
                onChange={handleSearch}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="SelectUserDialog-UsersPanel">
              {loading && <Spin spinning={loading}></Spin>}

              {users.map((user) => (
                <div className="SelectUserDialog-User" key={user.id} onClick={() => handleUserClick(user)}>
                  <div className="SelectUserDialog-UserInfo">
                    <div>{user.displayName}</div>
                    <div>{user.groups}</div>
                  </div>
                  <div>{user.status}</div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
});
