import { isEmpty } from 'lodash';
import { observable, runInAction, computed } from 'mobx';
import { AppStore } from './AppStore';
import { PlacesApi } from '../api/places';
import { Place } from '../models/places/place';

export class PlacesStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  places: Place[] = [];

  @computed
  get activePlaces() {
    return this.places.filter((place) => place.isActive) || [];
  }

  getPlaceById(placeId: number): Place | null {
    return this.places.find((place) => place.id === placeId) || null;
  }

  getPlaceNameById(placeId: number | null): string {
    return placeId !== null ? this.getPlaceById(placeId)?.name || '' : '';
  }

  getPlaceNamesByIds(placeIds: number[] | null): string {
    return (placeIds || []).map((id) => this.getPlaceById(id)?.name).join(', ');
  }

  async loadPlaces(force: boolean = false) {
    try {
      if (!force && !isEmpty(this.places)) {
        return;
      }
      const places = await PlacesApi.fetchAllPlaces();
      runInAction(() => {
        this.places = places;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }
}
