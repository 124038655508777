import { observable } from 'mobx';

export type RegionJson = {
  ID: string;
  Name: string;
};

export class Region {
  id: string = '';

  @observable
  name: string = '';

  public static fromJson(json: RegionJson) {
    const result = new Region();
    result.id = json.ID;
    result.name = json.Name;
    return result;
  }

  public toJson() {
    const json: RegionJson = {
      ID: this.id,
      Name: this.name,
    };
    return json;
  }
}
