import { isEmpty } from 'lodash';
import { GroupCoachModel } from '../../../models/group';
import { Training } from '../../../models/trainings/training';
import { observable, action } from 'mobx';

export class TrainingsInfoDialogData {
  @observable
  isOpen: boolean = false;

  @observable
  comment: string = '';

  @observable
  placeId: number = 0;

  @observable
  trainingId: number = -1;

  @observable
  coachesIds: string[] = [];

  @observable
  coachesExIds: string[] = [];

  @action
  showDialog(training: Training, groupCoaches: GroupCoachModel[]) {
    this.isOpen = true;
    this.trainingId = training.id;
    this.placeId = training.place?.id || 0;
    this.comment = '';
    this.coachesIds = training.coaches.map((e) => e.id);
    this.coachesExIds = training.coachesEx.map((e) => e.id);
    // если не заданы основные тренеры - проставляем текущих основных и дополнительных тренеров группы
    if (isEmpty(this.coachesIds)) {
      this.coachesIds = groupCoaches.filter((e) => !e.isSecondCoach).map((e) => e.id);
      if (isEmpty(this.coachesExIds)) {
        this.coachesExIds = groupCoaches.filter((e) => e.isSecondCoach).map((e) => e.id);
      }
    }
  }

  @action
  hideDialog() {
    this.isOpen = false;
  }

  @action
  setComment(comment: string) {
    this.comment = comment;
  }

  @action
  setPlaceId(placeId: number) {
    this.placeId = placeId;
  }

  @action
  setCoachesIds(coachesIds: string[]) {
    this.coachesIds = coachesIds;
  }

  @action
  setCoachesExIds(coachesExIds: string[]) {
    this.coachesExIds = coachesExIds;
  }
}
