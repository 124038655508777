export type TrainingsMonthFinalizeInfoResponse = {
  isFinalized: boolean;
  finalizeInfo: {
    finalizeUserName: string;
    finalizeDate: string;
  } | null;
};

export class TrainingsMonthFinalizeInfo {
  isFinalized: boolean = false;
  finalizeUserName: string = '';
  finalizeDate: string = '';

  public static fromResponse(resp: TrainingsMonthFinalizeInfoResponse) {
    const result = new TrainingsMonthFinalizeInfo();
    result.isFinalized = resp.isFinalized;
    if (resp.finalizeInfo) {
      result.finalizeUserName = resp.finalizeInfo.finalizeUserName;
      result.finalizeDate = resp.finalizeInfo.finalizeDate;
    }
    return result;
  }
}
