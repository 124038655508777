import React from 'react';
import BallImg from '../../assets/icons/loading_ball.gif';
import './Loading.scss';

export const LoadingPage = () => {
  return (
    <div className="Loading">
      <img className="Loading-Ball" src={BallImg} alt="" />
    </div>
  );
};
