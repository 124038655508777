import { action, observable } from 'mobx';

export class NotificationPagePagination {
  @observable
  pageNumber: number = 1;

  pageSize: number = 30;

  @action
  setPageNumber(pageNumber: number) {
    this.pageNumber = pageNumber;
  }
}
