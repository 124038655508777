import axios from 'axios';
import { Discount, DiscountResponse, DiscountSaveModel } from '../models/discount';

export const DiscountsApi = {
  fetchDiscounts: async () => {
    const response = await axios.get<{ discounts: DiscountResponse[] }>('/Settings/DiscountsData');
    return response.data.discounts.map((e) => Discount.fromResponse(e));
  },
  deleteDiscount: async (id: string) => {
    await axios.post('/api/settings/DeleteDiscount', { id: id });
  },
  saveDiscount: async (discount: DiscountSaveModel) => {
    await axios.post('/api/settings/SaveDiscount', discount);
  },
};
