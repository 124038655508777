import { Button, Space, Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import PageContainer from '../../../components/PageContainer';
import { UserEditResetPasswordDialog } from '../../../components/User/UserEditDialog/Components/UserEditResetPasswordDialog';
import { getRoleTitle, isSpecialRole } from '../../../models/userRole';
import { useStore } from '../../../stores/storeProvider';
import { EditStaffUserDialog } from './EditStaffUserDialog/EditStaffUserDialog';
import { StaffUser } from './models/staffUser';
import { useLocalStore } from './store';
import styles from './styles.module.scss';
import { isEmpty } from 'lodash';

export const StaffSettingsPage = observer(() => {
  const { appStore } = useStore();
  const store = useLocalStore(appStore);

  useEffect(() => {
    document.title = 'Торнадо / Настройки / Сотрудники';
    appStore.setCurrentPageTitle('Настройки / Сотрудники');
    store.refresh();
  }, [appStore, store]);

  const handleAddManager = () => {
    const user = new StaffUser();
    user.IsManager = true;
    store.editStaffUserDialogData.showDialog(user);
  };

  const handleAddCoach = () => {
    const user = new StaffUser();
    user.IsCoach = true;
    store.editStaffUserDialogData.showDialog(user);
  };

  return (
    <PageContainer className={styles.page}>
      <Tabs defaultActiveKey="1" type="card">
        <Tabs.TabPane tab="Менеджеры" key="1">
          <div>
            <Button onClick={handleAddManager}>Добавить менеджера</Button>
          </div>
          <div className={styles.users}>
            {store.managers.map((manager) => (
              <div className={styles.user_card} onClick={() => store.editStaffUserDialogData.showDialog(manager)}>
                <div className={styles.card_title}>{manager.displayName}</div>
                <div>{manager.PhoneNumber}</div>
                <div className={styles.roles_list}>
                  {manager.roleNames
                    .filter((roleName) => isSpecialRole(roleName))
                    .map((roleName) => (
                      <div>{getRoleTitle(roleName)}</div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Тренеры" key="2">
          <div>
            <Button onClick={handleAddCoach}>Добавить тренера</Button>
          </div>
          <div className={styles.users}>
            {store.coaches.map((coach) => (
              <div className={styles.user_card} onClick={() => store.editStaffUserDialogData.showDialog(coach)}>
                <div className={styles.card_title}>{coach.displayName}</div>
                <div>{coach.PhoneNumber}</div>
                <Space style={{ flexWrap: 'wrap' }}>
                  {coach.GroupIds.map((groupId) => (
                    <div>{appStore.groupStore.getGroupNameById(groupId)}</div>
                  ))}
                </Space>
                {!isEmpty(coach.CoachExGroupIds) && (
                  <>
                    <div className={styles.second_coach_label}>второй тренер:</div>
                    <Space style={{ flexWrap: 'wrap' }}>
                      {coach.CoachExGroupIds.map((groupId) => (
                        <div>{appStore.groupStore.getGroupNameById(groupId)}</div>
                      ))}
                    </Space>
                  </>
                )}
                <div>
                  {coach.roleNames
                    .filter((roleName) => isSpecialRole(roleName))
                    .map((roleName) => (
                      <div>{getRoleTitle(roleName)}</div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </Tabs.TabPane>
      </Tabs>

      <EditStaffUserDialog />

      <UserEditResetPasswordDialog
        isOpen={store.resetPasswordDialogData.isOpen}
        userId={store.resetPasswordDialogData.user.id}
        onClose={() => store.resetPasswordDialogData.hideDialog()}
      />
    </PageContainer>
  );
});
