import React, { useState, useEffect } from 'react';
import './UserNotes.scss';
import Modal from 'antd/lib/modal/Modal';
import { Button, Row, Col, Spin } from 'antd';
import { UserNote } from '../../../models/user';
import { UsersApi } from '../../../api/user';
import { useStore } from '../../../stores/storeProvider';
import { DateUtils } from '../../../utils/date';
import classNames from 'classnames';
import TextArea from 'antd/lib/input/TextArea';
import { isEmpty } from 'lodash';

interface IUserNotesPanelProps {
  userId: string;
  isVisible: boolean;
  children?: never;
}

export const UserNotesPanel: React.FC<IUserNotesPanelProps> = ({ userId, isVisible }) => {
  const { appStore } = useStore();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [notes, setNotes] = useState<UserNote[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [newNoteText, setNewNoteText] = useState('');

  const handleSave = () => {
    const saveNote = async () => {
      try {
        setSaving(true);
        const savedUserNoteResponse = await UsersApi.saveUserNote(userId, newNoteText);
        setNotes([new UserNote(savedUserNoteResponse), ...notes]);
        setIsAddOpen(false);
      } catch (error) {
        appStore.setErrorMessage((error as Error).message);
      } finally {
        setSaving(false);
      }
    };

    saveNote();
  };

  const handleNewNoteTextChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewNoteText(e.target.value);
  };

  const handleAdd = () => {
    setNewNoteText('');
    setIsAddOpen(true);
  };

  const handleAddCancel = () => {
    setIsAddOpen(false);
  };

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        setNotes([]);
        setLoading(true);
        const userNotesResponse = await UsersApi.fetchUserNotes(userId);
        const userNotes = userNotesResponse.map((noteResponse) => new UserNote(noteResponse));
        setNotes(userNotes);
      } catch (error) {
        appStore.setErrorMessage((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    if (isVisible && isEmpty(notes) && !loading) {
      fetchNotes();
    }
  }, [appStore, isVisible, loading, notes, userId]);

  return (
    <div>
      <Button key="add" onClick={handleAdd} type="link" style={{ paddingLeft: 0, marginBottom: 10 }}>
        Добавить заметку
      </Button>

      <Row gutter={16} className={classNames('UserNotes', 'SpinContainer')}>
        <Spin spinning={loading}></Spin>

        <Col span="12">
          {notes
            .filter((e) => e.DisplayType.length === 0)
            .map((note) => (
              <div key={`${note.Id}${note.Date}${note.Text}`} className="UserNote">
                <div className="UserNote-Info">
                  {DateUtils.format_DDMMYYYY(note.Date)} {note.Author}
                </div>
                <div className="UserNote-Text">{note.Text}</div>
              </div>
            ))}
        </Col>
        <Col span="12">
          {notes
            .filter((e) => e.DisplayType.length !== 0)
            .map((note) => (
              <div key={`${note.Id}${note.Date}${note.Text}`} className="UserNote">
                <div className="UserNote-Info">
                  {DateUtils.format_DDMMYYYY(note.Date)} {note.Author}
                </div>
                <div
                  className={classNames('UserNote-Text', {
                    UserNote__Important: note.DisplayType === 'important' || note.DisplayType === '[important]',
                    'UserNote__Important-Warn': note.DisplayType === 'important_warn',
                  })}
                >
                  {note.Text}
                </div>
              </div>
            ))}
        </Col>
      </Row>

      <Modal
        visible={isAddOpen}
        title="Добавление заметки"
        onCancel={handleAddCancel}
        footer={[
          <Button key="add" onClick={handleSave} loading={saving}>
            Сохранить
          </Button>,
          <Button key="back" onClick={handleAddCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <TextArea value={newNoteText} onChange={handleNewNoteTextChanged} />
      </Modal>
    </div>
  );
};
