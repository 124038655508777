import { EditOutlined, DeleteOutlined, CalendarOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popconfirm, Space, Table, Tooltip, Tag } from 'antd';
import { isEmpty, sortBy } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import PageContainer from '../../../components/PageContainer';
import { Group } from '../../../models/group';
import { useStore } from '../../../stores/storeProvider';
import { getDayOfWeekShortName } from '../../../utils/date';
import { zeroPad } from '../../../utils/format';
import { EditGroupDialog } from './EditGroupDialog/EditGroupDialog';
import { EditGroupSheduleDialog } from './EditGroupSheduleDialog/EditGroupSheduleDialog';
import { useLocalStore } from './store';
import styles from './styles.module.scss';

export const GroupsSettingsPage = observer(() => {
  const { appStore, groupStore } = useStore();
  const store = useLocalStore(appStore);

  useEffect(() => {
    document.title = 'Торнадо / Настройки / Группы';
    appStore.setCurrentPageTitle('Настройки / Группы');

    const init = async () => {
      await store.loadCoaches();
      await store.loadPlaces();
    };

    init();
  }, [appStore, store]);

  const handleAdd = () => {
    store.editGroup(new Group());
  };

  const [columns] = useState([
    {
      title: 'Наименование',
      render: (_: any, item: Group) => {
        return <div>{item.name}</div>;
      },
    },
    {
      title: 'Статус',
      render: (_: any, item: Group) => {
        return (
          <div>
            <Checkbox checked={item.isActive}>активная</Checkbox>
          </div>
        );
      },
    },
    {
      title: 'Площадки',
      render: (_: any, item: Group) => {
        return (
          <div>
            {item.places.map((place) => (
              <div className={styles.cell_list_item} key={place.id}>
                <Tag>{place.name}</Tag>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Тренеры',
      render: (_: any, item: Group) => {
        return (
          <div>
            {item.coaches.map((coach) => (
              <div className={styles.cell_list_item} key={coach.id}>
                <Tag>{coach.displayName}</Tag>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Расписание',
      render: (_: any, item: Group) => {
        return (
          <div>
            {sortBy(
              item.shedules.filter((e) => e.toDay === null),
              (e) => e.weekDay
            ).map((shedule) => (
              <div key={shedule.id}>
                {getDayOfWeekShortName(shedule.weekDay)} c {zeroPad(shedule.fromTimeHour, 2)}:{zeroPad(shedule.fromTimeMinute, 2)}{' '}
                по {zeroPad(shedule.toTimeHour, 2)}:{zeroPad(shedule.toTimeMinute, 2)}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Действия',
      render: (_: any, item: Group) => {
        return (
          <Space>
            <Tooltip title="изменить" placement="bottom">
              <Button shape="circle" icon={<EditOutlined />} onClick={(e) => store.editGroup(item)} />
            </Tooltip>
            <Tooltip title="расписание" placement="bottom">
              <Button shape="circle" icon={<CalendarOutlined />} onClick={(e) => store.editGroupShedule(item)} />
            </Tooltip>
            <Popconfirm
              title="Вы действительно хотите удалить группу？"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => store.deleteGroup(item.id)}
            >
              <Tooltip title="удалить" placement="bottom">
                <Button shape="circle" icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
    },
  ]);

  return (
    <PageContainer className={styles.page}>
      <div>
        <Button onClick={handleAdd}>Добавить группу</Button>
      </div>

      {!isEmpty(groupStore.groups) && (
        <Table
          className={styles.table}
          columns={columns}
          dataSource={[...groupStore.activeGroups, ...groupStore.inactiveGroups]}
          pagination={false}
          size="small"
        />
      )}

      <EditGroupDialog />

      <EditGroupSheduleDialog />
    </PageContainer>
  );
});
