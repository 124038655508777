import { StaffUser } from './../models/staffUser';
import { observable, action } from 'mobx';
import { cloneDeep } from 'lodash';

export class ResetPasswordDialogData {
  @observable
  isOpen: boolean = false;

  @observable
  user: StaffUser = new StaffUser();

  @action
  showDialog(user: StaffUser) {
    this.isOpen = true;
    this.user = cloneDeep(user);
  }

  @action
  hideDialog() {
    this.isOpen = false;
  }
}
