import { Place } from './../models/places/place';
import axios from 'axios';
import { PlaceJson } from '../models/places/place';
import { PlaceSaveModel } from '../models/places/placeSaveModel';
import { PlaceRentJson, PlaceRent } from '../models/places/placeRent';
import { PlaceRentSaveModel } from '../models/places/placeRentSaveModel';

export const PlacesApi = {
  fetchAllPlaces: async () => {
    const response = await axios.get<PlaceJson[]>('/api/settings/GetAllPlaces');
    return response.data.map((e) => Place.fromJson(e));
  },
  fetchAllPlacesRents: async () => {
    const response = await axios.get<PlaceRentJson[]>('/api/settings/GetAllPlacesRents');
    return response.data.map((e) => PlaceRent.fromJson(e));
  },
  deletePlace: async (id: number) => {
    await axios.post('/admin/DeletePlace', { id: id });
  },
  savePlace: async (place: PlaceSaveModel) => {
    const response = await axios.post<{ placeId: number }>('/admin/SavePlace', place);
    return response.data.placeId;
  },
  savePlaceRents: async (placeId: number, activePlaceRent: PlaceRentSaveModel, placeRents: PlaceRentSaveModel[]) => {
    await axios.post('/api/settings/SavePlaceRents', {
      PlaceId: placeId,
      ActivePlaceRent: activePlaceRent,
      PlaceRents: placeRents,
    });
  },
};
