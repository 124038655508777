export class TrainingsTotalInfo {
  totalCount: number = 0;
  paymentPaymentSum: number = 0;
  paymentSum: number = 0;
  paymentFull: number = 0;
  paymentPartial: number = 0;
  trainingsStat: {
    trainingID: number;
    VisitCount: number;
    SkipCount: number;
    HealthCertificateCount: number;
    HealthSkipCount: number;
    VacationCount: number;
    TestCount: number;
  }[] = [];
}
