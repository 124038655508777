import React, { useState } from 'react';
import { Menu, Dropdown, Space, BackTop, Button } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import { useStore } from '../../stores/storeProvider';
import { Spacer } from '../../ui-kit';
import { RouteNames } from '../../pages/routes';
import LogoImg from '../../assets/images/logo_tornado.png';
import { UserOutlined, CopyrightOutlined, ArrowUpOutlined, AppstoreOutlined } from '@ant-design/icons';
import './Layout.scss';
import { observer } from 'mobx-react';
import { FaBell, FaCogs, FaFileContract, FaRubleSign, FaUserFriends } from 'react-icons/fa';
import { UserPaymetDialog } from '../User/UserPaymetDialog/UserPaymetDialog';
import { ReactComponent as TrainingsSvg } from '../../assets/icons/trainings.svg';
import { UserChangePasswordDialog } from '../User/UserChangePasswordDialog/UserChangePasswordDialog';
import { UserRoleName } from '../../models/userRole';

export const Layout: React.FC = observer(({ children }) => {
  const { appStore } = useStore();
  const history = useHistory();
  const [year] = useState(new Date().getFullYear());
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);

  const handleLogout = () => {
    const logout = async () => {
      await appStore.logout();
      history.push(RouteNames.AppRoot);
    };

    logout();
  };

  const handleChangePassword = () => {
    setIsChangePasswordDialogOpen(true);
  };

  const getCurrentMenuKey = () => {
    const url = window.location.pathname.toLowerCase();
    if (url.includes(RouteNames.Trainings)) return 'trainings';
    if (url.includes(RouteNames.Payments)) return 'payments';
    if (url.includes(RouteNames.Settings)) return 'settings';
    if (url.includes(RouteNames.Reports)) return 'reports';
    if (url.includes(RouteNames.Notifications)) return 'notifications';
    return 'users';
  };

  const isMenuAvaliable = (key: string) => {
    if (!appStore.account) return false;

    if (appStore.account.hasRole(UserRoleName.ReportsManager) && key !== 'reports') return false;

    if (appStore.account.isCoach && !['users', 'groups', 'trainings'].includes(key)) return false;

    return true;
  };

  const userMenu = (
    <Menu>
      <Menu.Item onClick={handleChangePassword}>Изменить пароль</Menu.Item>
      <Menu.Item onClick={handleLogout}>Выход</Menu.Item>
    </Menu>
  );

  const regionsMenu = (
    <Menu>
      {appStore.account?.regions.map((region) => (
        <Menu.Item
          key={region.id}
          onClick={() => {
            appStore.setCurrentRegion(region.id);
          }}
        >
          {region.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const leftPanel = (
    <div className="Layout-LeftPanel">
      <img className="Layout-LeftPanel-Logo" src={LogoImg} alt="" />

      <Menu className="Layout-LeftPanel-Menu" defaultSelectedKeys={[getCurrentMenuKey()]} mode="inline">
        {isMenuAvaliable('users') && (
          <Menu.Item key="users">
            <NavLink to={RouteNames.Users} className="nav-text">
              <div className="Layout-LeftPanel-MenuItem">
                Ученики <Spacer /> <FaUserFriends />
              </div>
            </NavLink>
          </Menu.Item>
        )}

        {isMenuAvaliable('trainings') && (
          <Menu.Item key="trainings">
            <NavLink to={RouteNames.Trainings} className="nav-text">
              <div className="Layout-LeftPanel-MenuItem">
                Тренировки <Spacer /> <TrainingsSvg width={20} height={20} />
              </div>
            </NavLink>
          </Menu.Item>
        )}

        {isMenuAvaliable('payments') && (
          <Menu.Item key="payments">
            <NavLink to={RouteNames.Payments} className="nav-text">
              <div className="Layout-LeftPanel-MenuItem">
                Платежи <Spacer /> <FaRubleSign />
              </div>
            </NavLink>
          </Menu.Item>
        )}

        {isMenuAvaliable('reports') && (
          <Menu.Item key="reports">
            <NavLink to={RouteNames.Reports} className="nav-text">
              <div className="Layout-LeftPanel-MenuItem">
                Отчёты <Spacer /> <FaFileContract />
              </div>
            </NavLink>
          </Menu.Item>
        )}

        {isMenuAvaliable('notifications') && (
          <Menu.Item key="notifications">
            <NavLink to={RouteNames.Notifications} className="nav-text">
              <div className="Layout-LeftPanel-MenuItem">
                Уведомления <Spacer /> <FaBell />
              </div>
            </NavLink>
          </Menu.Item>
        )}

        {isMenuAvaliable('settings') && (
          <Menu.Item key="settings">
            <NavLink to={RouteNames.Settings} className="nav-text">
              <div className="Layout-LeftPanel-MenuItem">
                Настройки <Spacer /> <FaCogs />
              </div>
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );

  const header = (
    <div className="Layout-Header">
      <div className="Layout-Header-Title">{appStore.currentPageTitle}</div>
      <Spacer />

      {appStore.account?.isAdmin && (
        <Dropdown key="regions" overlay={regionsMenu}>
          <span className="Layout-Header-UserMenu">
            <Space>
              <AppstoreOutlined />
              {appStore.account?.region?.name}
            </Space>
          </span>
        </Dropdown>
      )}

      <Dropdown key="user" overlay={userMenu}>
        <span className="Layout-Header-UserMenu">
          <Space>
            <UserOutlined />
            {appStore.account?.user?.displayName}
          </Space>
        </span>
      </Dropdown>
    </div>
  );

  const footer = (
    <div className="Layout-Footer">
      <Spacer />
      <Space>
        <CopyrightOutlined />
        <span> Школа футбола ТОРНАДО, {year}</span>
      </Space>
    </div>
  );

  return (
    <div className="Layout">
      {leftPanel}

      {header}

      <div className="Layout-Content">{children}</div>

      <BackTop>
        <Button type="primary" shape="circle" icon={<ArrowUpOutlined />} />
      </BackTop>

      <UserPaymetDialog />

      <UserChangePasswordDialog isOpen={isChangePasswordDialogOpen} onClose={() => setIsChangePasswordDialogOpen(false)} />

      {footer}
    </div>
  );
});
