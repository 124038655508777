import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Select, Button, message, Row, Input, Col, DatePicker } from 'antd';
import { useStore } from '../../../../stores/storeProvider';
import { observer } from 'mobx-react';
import { UserEditDialogState } from '../UserEditDialogState';
import { UserSaveMode, UserStatus } from '../../../../models/user';
import FormItem from '../../../FormItem';
import moment from 'moment';
import { action } from 'mobx';
import { DateUtils } from '../../../../utils/date';

const { Option } = Select;

export interface IUserEditReserveDialogProps {
  editDialogState: UserEditDialogState;
  isOpen: boolean;
  onClose: () => void;
}

export const UserEditReserveDialog: React.FC<IUserEditReserveDialogProps> = observer(({ editDialogState, isOpen, onClose }) => {
  const { appStore } = useStore();
  const [groupsIds, setGroupsIds] = useState<number[]>([]);
  const [comment, setComment] = useState('');
  const [callDate, setCallDate] = useState<moment.Moment | null>(null);
  const [dateFrom, setDateFrom] = useState<moment.Moment | null>(moment());

  useEffect(() => {
    if (isOpen) {
      setGroupsIds(editDialogState.userModel.reserveGroupsIds);
      setComment(editDialogState.userModel.reserveComment || '');
      setCallDate(editDialogState.userModel.reserveCallDate != null ? editDialogState.userModel.reserveCallDate : null);
      setDateFrom(editDialogState.userModel.reserveDateFrom != null ? editDialogState.userModel.reserveDateFrom : moment());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleApply = action(() => {
    if (dateFrom === null) {
      message.warning('Не задана дата перевода в резерв', 3);
      return;
    }

    if (callDate === null) {
      message.warning('Не задана дата звонка', 3);
      return;
    }

    if (!comment) {
      message.warning('Не задана причина перевода в резерв', 3);
      return;
    }

    editDialogState.userModel.saveMode = UserSaveMode.Reserve;
    editDialogState.userModel.Status = UserStatus.Reserve;
    editDialogState.userModel.reserveComment = comment;
    editDialogState.userModel.reserveDateFrom = dateFrom;
    editDialogState.userModel.reserveCallDate = callDate;
    editDialogState.userModel.reserveGroupsIds = groupsIds;

    onClose();
  });

  const handleCancel = () => {
    onClose();
  };

  return (
    <div>
      <Modal
        visible={isOpen}
        title="Перевод в резерв"
        onCancel={handleCancel}
        footer={[
          <Button key="apply" onClick={handleApply} type="primary">
            Применить
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Закрыть
          </Button>,
        ]}
      >
        <Row>{`Перевод в резерв с ${DateUtils.format_DDMMYYYY(dateFrom)}`}</Row>
        <Row>
          <Col span={12}>
            <FormItem label="Группа">
              <Select
                showSearch
                style={{ width: '100%' }}
                optionFilterProp="children"
                mode="multiple"
                value={groupsIds}
                onChange={(value: number[]) => setGroupsIds(value)}
              >
                {appStore.groupStore.activeGroups?.map((group) => (
                  <Option key={group.id} value={group.id}>
                    {group.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Дата звонка">
              <DatePicker
                style={{ width: '100%' }}
                placeholder=""
                format="DD.MM.YYYY"
                value={callDate}
                onChange={(value) => setCallDate(value)}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label="Причина">
              <Input style={{ width: '100%' }} value={comment} onChange={(e) => setComment(e.target.value)} />
            </FormItem>
          </Col>
          {/* <Col>
              <FormItem label="Дата перевода">
                <DatePicker
                  style={{ width: 200 }}
                  placeholder=""
                  value={dialogState.reserveDateFrom}
                  disabled={true}
                  onChange={action((value) => {
                    dialogState.reserveDateFrom = value;
                  })}
                />
              </FormItem>
            </Col> */}
        </Row>
      </Modal>
    </div>
  );
});
