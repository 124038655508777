import { GroupSaveModel } from '../models/group';
import axios from 'axios';
import { Group, GroupResponse } from '../models/group';
import { GroupSheduleSaveModel } from '../models/groupShedule';

export const GroupsApi = {
  fetchGroups: async () => {
    const response = await axios.get<GroupResponse[]>('/api/groups/GetGroups');
    return response.data.map((e) => Group.fromResponse(e));
  },

  deleteGroup: async (id: number) => {
    await axios.post('/admin/DeleteGroup', { groupID: id });
  },

  saveGroup: async (group: GroupSaveModel) => {
    await axios.post('/admin/SaveGroup', group);
  },

  saveGroupShedule: async (groupId: number, shedules: GroupSheduleSaveModel[]) => {
    await axios.post('/admin/SaveShedule', { groupID: groupId, shedules: shedules });
  },
};
