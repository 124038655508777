export type UserResponse = {
  ID: string;
  DisplayName: string;
};

//TODO: remove
export type UserResponse2 = {
  Id: string;
  DisplayName: string;
};

export class User {
  id: string = '';
  displayName: string = '';

  // порядковый номер при сортировке по статусу
  statusOrder: number = 0;

  public static fromResponse(resp: UserResponse) {
    const result = new User();
    result.id = resp.ID;
    result.displayName = resp.DisplayName;
    return result;
  }

  public static fromResponse2(resp: UserResponse2) {
    const result = new User();
    result.id = resp.Id;
    result.displayName = resp.DisplayName;
    return result;
  }
}
