import moment, { Moment } from 'moment';

const weekDayNames = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const getDayOfWeekShortName = (dayOfWeek: number) => {
  return weekDayNames[dayOfWeek - 1];
};

export const DateUtils = {
  format: (val: string | Moment | null) => (val !== null && val !== '' ? moment(val).format('DD.MM.YYYY') : ''),
  formatISO: (val: string | Moment | null) => (val !== null && val !== '' ? moment(val).toISOString() : ''),
  formatEx: (val: string | Moment | null, format: string) => (val !== null && val !== '' ? moment(val).format(format) : ''),
  format_DDMMYYYY: (val: string | Moment | null) => (val !== null && val !== '' ? moment(val).format('DD.MM.YYYY') : ''),
  format_YYYYMM: (val: string | Moment | null) => (val !== null && val !== '' ? moment(val).format('YYYY.MM') : ''),
  parse: (val: string | null) => (val !== null && val !== '' ? moment(val) : null),
  parse_DDMMYYYY: (val: string | null) => (val !== null && val !== '' ? moment(val, 'DD.MM.YYYY') : null),
};
