import { Payment, PaymentResponse } from '../../../models/payments/payment';

export type PaymentsPageUserRowPaymentResponse = {
  Payment: PaymentResponse;
  ConfirmedCount: number;
  ManualChangeCount: number;
  Status: number;
  HasErrors: boolean;
};

export class PaymentsPageUserRowPayment {
  payment: Payment = new Payment();
  confirmedCount: number = 0;
  manualChangeCount: number = 0;
  status: number = 0;
  hasErrors: boolean = false;

  public static fromResponse(resp: PaymentsPageUserRowPaymentResponse) {
    const result = new PaymentsPageUserRowPayment();
    result.confirmedCount = resp.ConfirmedCount;
    result.manualChangeCount = resp.ManualChangeCount;
    result.status = resp.Status;
    result.hasErrors = resp.HasErrors;
    result.payment = Payment.fromResponse(resp.Payment);
    return result;
  }
}
