import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import styles from './UserPayments.module.scss';
import { PaymentsPageUserRow } from '../models/paymentsPageUserRow';
import { UserPaymentInfo } from './UserPaymentInfo';
import { Button, Dropdown, Input, Menu, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useStore } from '../../../stores/storeProvider';
import { Payment } from '../../../models/payments/payment';
import { useLocalStore } from '../store';
import classNames from 'classnames';
import { action } from 'mobx';
import { isEmpty } from 'lodash';
import { UserTrainigVisitsStat } from './UserTrainigVisitsStat';
import { FaCopy } from 'react-icons/fa';

interface UserPaymentsProps {
  item: PaymentsPageUserRow;
}

export const UserPayments: React.FC<UserPaymentsProps> = observer(({ item }) => {
  const { appStore, paymentsStore } = useStore();
  const store = useLocalStore(appStore);
  const calcInfo = useMemo(() => {
    const calcIndex = store.calcs.findIndex((e) => e.userId === item.userId);
    if (calcIndex !== -1) {
      return store.calcs[calcIndex];
    }
    return null;
  }, [item.userId, store.calcs]);

  const handleAddPayment = (additionalPaymentId: string) => {
    const payment = new Payment();
    payment.year = store.params.year;
    payment.month = store.params.month;
    payment.userId = item.userId;
    payment.additionalPaymentId = additionalPaymentId;
    payment.sumToPay = paymentsStore.getAdditionalPayment(additionalPaymentId).sum;
    paymentsStore.showNewPaymentDialog(payment, store.onPaymentChanged.bind(store));
  };

  const handleCopyComment = () => {
    if (calcInfo && calcInfo.prevPayment && calcInfo.prevPayment.comment) {
      store.copyPaymentComment(item.userId);
    }
  };

  const handleCopyClientComment = () => {
    if (calcInfo && calcInfo.prevPayment && calcInfo.prevPayment.clientComment) {
      store.copyPaymentClientComment(item.userId);
    }
  };

  const addPaymentMenu = (
    <Menu>
      {paymentsStore.additionalPayments
        .filter((e) => e.isActive && !item.getAdditionalPaymentsIds().includes(e.id))
        .map((additionalPayment) => (
          <Menu.Item key={additionalPayment.id} onClick={() => handleAddPayment(additionalPayment.id)}>
            {additionalPayment.title}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <>
      <div className={styles.user_payments}>
        {!item.hasMainPayment() && <UserPaymentInfo info={null} userId={item.userId} />}

        {item.payments.map((paymentInfo) => (
          <UserPaymentInfo info={paymentInfo} userId={item.userId} />
        ))}

        <div className={classNames(styles.add_payment_button, { [styles.active]: item.isActive })}>
          <Dropdown overlay={addPaymentMenu}>
            <Button>
              добавить оплату <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>

      {store.calcMode && calcInfo && (
        <div className={styles.calc_info}>
          {calcInfo.isEmpty() && <div>Расчёт не выполнялся</div>}
          {!calcInfo.isEmpty() && (
            <>
              <div className={styles.calc_info_main}>
                <div>Рассчитанная сумма к оплате:</div>
                <div className={styles.calc_info_sum}>
                  <Input
                    width={50}
                    value={calcInfo.sum || ''}
                    onChange={action((e) => {
                      calcInfo.sum = e.target.value ? Number(e.target.value) : null;
                      calcInfo.isChanged = true;
                    })}
                  />
                </div>
                {(isEmpty(calcInfo.id) || calcInfo.isChanged) && <div>Внимание! Расчёт не сохранён</div>}
              </div>

              <div className={styles.calc_prev_payment}>
                <div>
                  Оплата в прошлом месяце:
                  {!calcInfo.prevPayment
                    ? ' нет'
                    : ` ${calcInfo.prevPayment.sumPayed || '0'} из ${calcInfo.prevPayment.sumPayed || '0'}`}
                  {!calcInfo.prevPayment || !calcInfo.prevPayment.sumToNextMonth ? (
                    ''
                  ) : (
                    <>
                      <span>, </span>
                      <span
                        style={{ color: 'orange', fontWeight: 'bold' }}
                      >{`переноc ${calcInfo.prevPayment.sumToNextMonth} руб.`}</span>
                    </>
                  )}
                  {calcInfo.prevPayment && calcInfo.prevPayment.comment && (
                    <div>
                      Комментарий: {calcInfo.prevPayment.comment}
                      <Tooltip title="копировать комментарий в текущий платёж">
                        <FaCopy onClick={handleCopyComment} style={{ color: 'gray', marginLeft: 10 }} />
                      </Tooltip>
                    </div>
                  )}
                  {calcInfo.prevPayment && calcInfo.prevPayment.clientComment && (
                    <div>
                      Комментарий для родителей: {calcInfo.prevPayment.clientComment}{' '}
                      <Tooltip title="копировать комментарий в текущий платёж">
                        <FaCopy onClick={handleCopyClientComment} style={{ color: 'gray', marginLeft: 10 }} />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.calc_trainings}>
                <UserTrainigVisitsStat title="Тренировки в этом месяце" trainings={calcInfo.curMonthTrainings} />

                <UserTrainigVisitsStat title="Тренировки в прошлом месяце" trainings={calcInfo.prevMonthTrainings} />
              </div>

              {calcInfo.detail.map((text) => (
                <div className={classNames(styles.detail_item, { [styles.detail_warning]: text.includes('!') })}>{text}</div>
              ))}
            </>
          )}
        </div>
      )}
    </>
  );
});
