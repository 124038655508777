import React from 'react';
import { Button } from 'antd';
import { useUserEditDialog } from '../UserEditDialog/UserEditDialogProvider';
import { useStore } from '../../../stores/storeProvider';
import './UserAddButton.scss';
import { UserRoleName } from '../../../models/userRole';
import { observer } from 'mobx-react';

interface IUserAddButtonProps {
  onSave?: (userId: string) => void;
}

export const UserAddButton: React.FC<IUserAddButtonProps> = observer(({ onSave }) => {
  const { appStore } = useStore();
  const { showUserEditDialog } = useUserEditDialog();

  const handleAddClient = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    showUserEditDialog(null, UserRoleName.Client, onSave);
  };

  // const handleAddCoach = () => {
  //   showUserEditDialog(null, UserRoleName.Coach, handleSave);
  // };

  // const handleSave = (userId: string) => {
  //   onSave && onSave(userId);
  // };

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="1" onClick={handleAddCoach}>
  //       Добавить тренера
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <>
      {appStore.account?.isAdminOrManager && (
        <Button onClick={handleAddClient} className="UserAddButton">
          Добавить
        </Button>
      )}

      {/* {appStore.account?.isAdminOrManager && (
          <Dropdown.Button
            onClick={handleAddClient}
            overlay={menu}
            className="UserAddButton"
          >
            Добавить
          </Dropdown.Button>
        )} */}
    </>
  );
});
