import { Group, GroupCoachModel } from './../../models/group';
import { TrainingsInfoDialogData } from './models/trainingsInfoDialogData';
import { PaymentApi } from './../../api/payment';
import { UnionTraining } from './models/unionTraining';
import { TrainingsUnionDialogData } from './models/trainingsUnionDialogData';
import { TrainingsMonthFinalizeInfo } from './models/trainingsMonthFinalizeInfo';
import { TrainingsApi } from './../../api/training';
import { TrainingsPageApi } from './api';
import { TrainingsData } from './models/trainingsData';
import { action, observable, runInAction } from 'mobx';
import { AppStore } from '../../stores/AppStore';
import { TrainingsPageParams } from './models/trainingsPageParams';
import { isEmpty, orderBy } from 'lodash';
import { Training } from '../../models/trainings/training';
import { message, Modal } from 'antd';
import { TrainingsExportParams } from './models/trainingsExportParams';
import { Payment } from '../../models/payments/payment';
import { TrainingsCanceDialogData } from './models/trainingsCancelDialogData';
import { TrainingsTransferDialogData } from './models/trainingTransferDialogData';
import { Moment } from 'moment';
import { User } from '../../models/users/user';
import { UsersApi } from '../../api/user';
import { TrainingsVisitsDialogData } from './models/trainingsVisitsDialogData';
import { getUrlSearchNumberParam } from '../../utils/url';
import { UserAbonnementInfo } from '../../models/users/userAbonnementInfo';
import { TrainingsTotalInfo } from './models/trainingsTotalInfo';

export class TrainigsPageStore {
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  appStore: AppStore;

  @observable
  loading: boolean = false;

  @observable
  params: TrainingsPageParams = new TrainingsPageParams();

  @observable
  data: TrainingsData = new TrainingsData();

  @observable
  activeGroups: Group[] = [];

  @observable
  trainings: Training[] = [];

  @observable
  coaches: User[] = [];

  @observable
  groupCoaches: GroupCoachModel[] = [];

  @observable
  unionTrainings: UnionTraining[] = [];

  @observable
  usersAbonnements: UserAbonnementInfo[] = [];

  @observable
  payments: Payment[] = [];

  @observable
  finalizeInfo: TrainingsMonthFinalizeInfo = new TrainingsMonthFinalizeInfo();

  @observable
  trainingsExportParams: TrainingsExportParams = new TrainingsExportParams();

  @observable
  isTrainigsExportParamsLoaded: boolean = false;

  @observable
  trainingsExportGroupsIds: number[] = [];

  @observable
  isTrainingsExportDialogOpen: boolean = false;

  @observable
  totalInfo: TrainingsTotalInfo = new TrainingsTotalInfo();

  trainingsUnionDialog: TrainingsUnionDialogData = new TrainingsUnionDialogData();
  trainingsCancelDialog: TrainingsCanceDialogData = new TrainingsCanceDialogData();
  trainingsTransferDialog: TrainingsTransferDialogData = new TrainingsTransferDialogData();
  trainingsInfoDialog: TrainingsInfoDialogData = new TrainingsInfoDialogData();
  trainingsVisitsDialog: TrainingsVisitsDialogData = new TrainingsVisitsDialogData();

  @action
  async init() {
    try {
      const currentDate = new Date();

      this.params.year = currentDate.getFullYear();
      this.params.month = currentDate.getMonth() + 1;

      this.params.year = getUrlSearchNumberParam('year', this.params.year);
      this.params.month = getUrlSearchNumberParam('month', this.params.month);

      this.params.groupId = getUrlSearchNumberParam('group', this.params.groupId);

      await this.refresh();
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  @action
  async refresh() {
    try {
      this.loading = true;

      await this.loadCurrentPeriodGroups();

      const data = await TrainingsPageApi.fetchData(this.params);
      const trainings = await TrainingsApi.fetchTrainingsDetail(data.trainingsIds);
      const unionTrainings = await TrainingsPageApi.fetchUnionTrainings(this.params.groupId, this.params.year, this.params.month);
      const coaches = await UsersApi.fetchAllCoaches();
      const groupCoaches = await UsersApi.fetchGroupCoaches(this.params.groupId);
      const usersAbonnements = await UsersApi.fetchUsersCurrentAbonnements(data.users.map((e) => e.id));
      const totalInfo = await TrainingsPageApi.fetchTrainingsTotalInfo(this.params.groupId, this.params.year, this.params.month);

      runInAction(() => {
        this.data = data;
        this.trainings = trainings;
        this.unionTrainings = unionTrainings;
        this.coaches = coaches;
        this.groupCoaches = groupCoaches;
        this.usersAbonnements = usersAbonnements;
        this.totalInfo = totalInfo;

        this.loadUsersPayments();
        this.loadFinalizeInfo();
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  async loadCurrentPeriodGroups() {
    try {
      const data = await TrainingsPageApi.fetchGroups(this.params);

      runInAction(() => {
        this.activeGroups = [];
        for (const groupData of data.groups) {
          const group = new Group();
          group.id = groupData.id;
          group.name = groupData.name;
          this.activeGroups.push(group);
        }

        if (data.currentGroupId && this.activeGroups.findIndex((e) => e.id === data.currentGroupId) !== -1)
          this.params.groupId = data.currentGroupId;
        else if (!isEmpty(this.activeGroups)) this.params.groupId = this.activeGroups[0].id;
        else this.params.groupId = 0;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async loadTrainingsExportParams() {
    try {
      const trainingsExportParams = await TrainingsPageApi.fetchTrainingsExportParams();
      runInAction(() => {
        this.trainingsExportGroupsIds = [];
        this.trainingsExportParams = trainingsExportParams;
        this.isTrainigsExportParamsLoaded = true;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async loadUsersPayments() {
    try {
      const usersIds = this.data.users.map((e) => e.id);
      const payments = await PaymentApi.fetchPaymentsByUsersIds(usersIds, this.params.year, this.params.month);
      runInAction(() => {
        this.payments = payments;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async loadFinalizeInfo() {
    try {
      const finalizeInfo = await PaymentApi.fetchMonthFinalizeInfo(this.params.groupId, this.params.year, this.params.month);
      runInAction(() => {
        this.finalizeInfo = finalizeInfo;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async exportTrainings() {
    try {
      if (isEmpty(this.trainingsExportGroupsIds)) {
        this.appStore.showWarning('Необходимо выбрать одну или несколько групп');
        return;
      }
      await TrainingsPageApi.prepareTrainingsReport(this.params, this.trainingsExportGroupsIds, this.trainingsExportParams);
      runInAction(() => {
        this.trainingsExportGroupsIds = [];
        this.isTrainingsExportDialogOpen = false;
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async saveUnionTrainings() {
    try {
      await TrainingsPageApi.setUnionTrainings(
        this.params.groupId,
        this.trainingsUnionDialog.trainingDate,
        this.trainingsUnionDialog.unionGroupsIds
      );
      const unionTrainings = await TrainingsPageApi.fetchUnionTrainings(this.params.groupId, this.params.year, this.params.month);
      runInAction(() => {
        this.unionTrainings = unionTrainings;
        this.trainingsUnionDialog.hideDialog();
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async cancelTrainig() {
    try {
      await TrainingsApi.cancelTraining(this.trainingsCancelDialog.trainingId, this.trainingsCancelDialog.comment);
      await this.refresh();
      runInAction(() => {
        this.trainingsCancelDialog.hideDialog();
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async restoreTrainig(trainingId: number) {
    const $this = this;
    Modal.confirm({
      title: 'Внимание',
      content: 'Вы действительно хотите вернуть тренировку',
      okText: 'Вернуть',
      cancelText: 'Отмена',
      onOk: async () => {
        try {
          await TrainingsApi.restoreTraining(trainingId);
          await this.refresh();
          setTimeout(() => {
            message.success(`Тренировка успешно возвращена`, 2);
          }, 100);
        } catch (error) {
          $this.appStore.setErrorMessage((error as Error).message);
        }
      },
    });
  }

  async transferTraining() {
    try {
      if (!this.trainingsTransferDialog.date) {
        this.appStore.showWarning('Выберите дату тренировки');
        return;
      }
      if (!this.trainingsTransferDialog.fromTime) {
        this.appStore.showWarning('Выберите время начала');
        return;
      }
      if (!this.trainingsTransferDialog.toTime) {
        this.appStore.showWarning('Выберите время окончания');
        return;
      }
      await TrainingsApi.transferTraining(
        this.trainingsTransferDialog.trainingId,
        this.trainingsTransferDialog.comment,
        this.trainingsTransferDialog.date,
        this.trainingsTransferDialog.fromTime.hours(),
        this.trainingsTransferDialog.fromTime.minutes(),
        this.trainingsTransferDialog.toTime.hours(),
        this.trainingsTransferDialog.toTime.minutes(),
        this.trainingsTransferDialog.placeId
      );
      await this.refresh();
      runInAction(() => {
        this.trainingsTransferDialog.hideDialog();
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async saveTrainigInfo() {
    try {
      await TrainingsApi.saveTrainingInfo(
        this.trainingsInfoDialog.trainingId,
        this.trainingsInfoDialog.comment,
        this.trainingsInfoDialog.placeId,
        this.trainingsInfoDialog.coachesIds,
        this.trainingsInfoDialog.coachesExIds
      );
      await this.refresh();
      runInAction(() => {
        this.trainingsInfoDialog.hideDialog();
      });
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async showTrainigVisits(trainingId: number) {
    try {
      const trainingVisits = await TrainingsApi.fetchTrainingVisits(trainingId);
      this.trainingsVisitsDialog.showDialog(trainingId, trainingVisits);
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  async saveTrainigVisits() {
    try {
      await TrainingsApi.saveTrainingVisits(
        this.trainingsVisitsDialog.trainingId,
        this.trainingsVisitsDialog.trainingVisits.comment,
        this.trainingsVisitsDialog.trainingVisits.placeId,
        this.trainingsVisitsDialog.trainingVisits.usersTrainigs.map((e) => ({
          id: e.id,
          userId: e.user.id,
          status: e.visitStatus,
        }))
      );
      runInAction(() => {
        this.trainingsVisitsDialog.hideDialog();
      });
      await this.refresh();
    } catch (error) {
      this.appStore.setErrorMessage((error as Error).message);
    }
  }

  getUnionTrainingsIds(trainingDate: moment.Moment) {
    return this.unionTrainings
      .filter((e) => e.trainingDate.isSame(trainingDate))
      .map((e) => e.groupId)
      .filter((e) => e !== this.params.groupId);
  }

  getUnionTrainingsInfo(trainingDate: moment.Moment) {
    const unionGroupNames = this.getUnionTrainingsIds(trainingDate).map((e) => this.appStore.groupStore.getGroupNameById(e));
    if (isEmpty(unionGroupNames)) return '';
    return `совместно с ${unionGroupNames.join(', ')}`;
  }

  async changeMonthFinalize(finalize: boolean) {
    let message = 'Вы действительно хотите закрыть месяц для выбранной группы?';
    if (!finalize) {
      message = 'Вы действительно хотите отменить закрытие месяца для выбранной группы?';
    }

    Modal.confirm({
      title: 'Внимание',
      content: message,
      okText: 'Да',
      cancelText: 'Отмена',
      onOk: async () => {
        try {
          await PaymentApi.finalizeMonth(this.params.groupId, this.params.year, this.params.month, finalize);
          const finalizeInfo = await PaymentApi.fetchMonthFinalizeInfo(this.params.groupId, this.params.year, this.params.month);
          runInAction(() => {
            this.finalizeInfo = finalizeInfo;
          });
        } catch (error) {
          this.appStore.setErrorMessage((error as Error).message);
        }
      },
    });
  }

  @action
  setIsTrainingsExportDialogOpen(isOpen: boolean) {
    this.isTrainingsExportDialogOpen = isOpen;
  }

  @action
  sortUsersByName() {
    this.data.users = orderBy(this.data.users, (e) => e.displayName);
  }

  @action
  sortUsersByStatus() {
    this.data.users = orderBy(this.data.users, (e) => e.statusOrder);
  }

  getTrainigStat(trainingId: number) {
    return this.totalInfo.trainingsStat.find((e) => e.trainingID === trainingId);
  }
}

let localStore: TrainigsPageStore | null = null;

export const useLocalStore = (appStore: AppStore) => {
  if (localStore === null) {
    localStore = new TrainigsPageStore(appStore);
  }
  return localStore;
};
