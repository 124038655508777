import { observable } from 'mobx';
import { GroupShedule, GroupSheduleResponse } from './groupShedule';
import { Place, PlaceJson } from './places/place';
import { User, UserResponse } from './users/user';
import { GroupCoachResponse } from '../api/types/user';

export type GroupResponse = {
  ID: number;
  Name: string;
  IsActive: boolean;
  Places: PlaceJson[];
  Coaches: UserResponse[];
  Shedules: GroupSheduleResponse[];
  PrimaryPlaceID: number;
};

export class Group {
  id: number = 0;

  @observable
  name: string = '';

  @observable
  isActive: boolean = true;

  @observable
  places: Place[] = [];

  @observable
  coaches: User[] = [];

  @observable
  primaryPlaceId: number = 0;

  @observable
  shedules: GroupShedule[] = [];

  public static fromResponse(resp: GroupResponse) {
    const result = new Group();
    result.id = resp.ID;
    result.name = resp.Name;
    result.isActive = resp.IsActive;
    result.places = resp.Places.map((e) => Place.fromJson(e));
    result.coaches = resp.Coaches.map((e) => User.fromResponse(e));
    result.shedules = resp.Shedules.map((e) => GroupShedule.fromResponse(e));
    result.primaryPlaceId = resp.PrimaryPlaceID;
    return result;
  }
}

export type GroupSaveModel = {
  groupID: number;
  groupName: string;
  groupCoaches: string[];
  groupPlaces: number[];
  groupPrimaryPlaceID: number;
  groupIsActive: boolean;
  groupCoachesChangeDate: string;
};

export class GroupCoachModel {
  id: string = '';
  displayName: string = '';
  isSecondCoach: boolean = false;

  public static fromResponse(resp: GroupCoachResponse) {
    const result = new GroupCoachModel();
    result.id = resp.ID;
    result.displayName = resp.DisplayName;
    result.isSecondCoach = resp.IsSecondCoach;
    return result;
  }
};
