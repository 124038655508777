import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { ReportsApi } from '../../../api/reports';
import styles from '../styles.module.scss';

export const ActivePaymentsReport = observer(() => {
  const [loading, setLoading] = useState(false);

  const handlePrepare = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    ReportsApi.prepareActivePaymentsReport().finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <div className={styles.report_card} onClick={handlePrepare}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loading}>
          <div className={styles.report_card_title}>Выгрузка выставленных платежей</div>
          <div className={styles.report_card_desc}>Выгрузка в Excel информации о выставленных платежах в текущем месяце</div>
        </Spin>
      </div>
    </>
  );
});
