import axios from 'axios';
import { Region, RegionJson } from './../models/region';

export const RegionsApi = {
  fetchRegions: async () => {
    const response = await axios.get<RegionJson[]>('/Admin/GetRegionsList');
    return response.data.map((item) => Region.fromJson(item));
  },

  saveRegion: async (region: Region) => {
    await axios.post('/admin/SaveRegion', {
      region: region.toJson(),
    });
  },

  deleteRegion: async (region: Region) => {
    await axios.post('/admin/DeleteRegion', {
      region: region.toJson(),
    });
  },
};
